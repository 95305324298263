import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommonLoader from '../../components/CommonLoader';
import { fetchBluePrintGet, deleteBluePrintById } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { CommonModel } from "../../components/CommonModel";
import { formValidationPatten,RowPerPage,RowsPerPageOptions} from "../../services/Constants";
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export type Props = {
    loading?: boolean
    records?: any
    page: number
    per_page: number
    total: number
    totalPage: number
    fetchBluePrintGet: (data: any) => any;
    deleteBluePrintById: (id: number) => any;
}

interface StateTypes {
    records: any;
    search: string,
    hasMore: boolean,
    page: any,
    page_no: any,
    SortOrderData: string,
    OrderNameData: string,
    showDelete: boolean,
    deleteId: any,
    ascOrder?: boolean,
    academicYear?: any,
    sort_by?: string,
    sort_class_name?: string,
    sort_title_name?: string,
    per_page?: any
}
export class BluePrintGrid extends Component<Props, StateTypes> {

    constructor(props: any) {
        super(props);
        this.state = {
            records: [],
            search: '',
            hasMore: true,
            page: 1,
            page_no: 1,
            SortOrderData: '',
            OrderNameData: '',
            showDelete: false,
            deleteId:'',
            ascOrder: true,
            academicYear: null,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order',
            per_page: RowPerPage
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getBluePrintDetails()
    }

    getBluePrintDetails() {
        const params = {
          page_no: 1,
          per_page: this.state.per_page,
          search: this.state.search,
          order_by: this.state.OrderNameData,
          sort_by: this.state.SortOrderData,
        }
        this.props.fetchBluePrintGet(params).then((res: any)=>{
            this.setState({ hasMore: true, page:1 })
        })
    }

    showFilterData(getValues: any) {
        const { academicYear, page } = this.state;
        if (getValues.sort_by === 'desc') {
          this.setState({
            ascOrder: false,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by
          })
          const postValue = {
            page_no: 1,
            per_page: this.state.per_page,
            academic_year: academicYear,
            search: this.state.search,
            sort_by: getValues.sort_by,
            order_by: getValues.order_by
          }
          this.setState({
            hasMore: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
            page: 1,
            sort_by: 'asc',
            sort_class_name: 'fa fa-sort-amount-desc',
            sort_title_name: 'Descending order'
          })
          this.props.fetchBluePrintGet(postValue).then((res: any) => {
            
          });
        } else {
          this.setState({
            ascOrder: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
          })
          const postValue = {
            page_no: 1,
            per_page: this.state.per_page,
            academic_year: academicYear,
            search: this.state.search,
            sort_by: getValues.sort_by,
            order_by: getValues.order_by
          }
          this.setState({
            hasMore: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
            page: 1,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order'
          })
          this.props.fetchBluePrintGet(postValue).then((res: any) => {
            
          });
        }
      }

    public fetchMoreStudentData = (newPage?:any,numberOfRows?:any) => {
        const { page } = this.state;
        if (this.state.hasMore === true) {
            if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
                const params = {
                    page_no: newPage,
                    per_page: numberOfRows,
                    search: this.state.search,
                    order_by: this.state.OrderNameData,
                    sort_by: this.state.SortOrderData,
                }
                this.props.fetchBluePrintGet(params).then((res: any)=>{
                    // this.setState({ page: page + 1 })
                })
            }  
            if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
                this.setState({
                  hasMore: false,
                })
            }
        }
    }
    getMuiTheme = () => createTheme({
        components: {
          MuiPopover: {
            styleOverrides: {
              paper: {
                padding: "12px"
              }
            }
          }      
        }
      })
    private renderBluePrint(records: any) {
        const { loading,total } = this.props;
        const {per_page} = this.state;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const columns = [
            {
              name: "blueprint_name",
              label: "Blueprint Name",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "internal_grade_name",
              label: "Grade",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "internal_subject_name",
              label: "Subject",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "exam_type_name",
              label: "Exam Type",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
                name: "exam_total_marks",
                label: "Marks",
                options: {
                  customBodyRender: (value:any) => value || '-',
                },
              },
              {
                name: "uid",
                label: "Edit",
                options: {
                    sort: false,
                    download: false,
                    customBodyRender: (tableMeta:any,rowData:any,rowIndex:any) => {
                        const items = records[rowData.rowIndex];
                        return items.uid && items.questionpaper_is_active == false ? (
                            <Link to={`/blueprint/edit/${items.uid}`}>
                                <button className="btn btn-primary btn-xs" title="Edit">
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </button>
                            </Link>
                            ) : (
                            <button className="btn btn-disable btn-xs" title="Edit">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                        )                        
                    }
                }
            },            
            {
                name: "uid",
                label: "View",
                options: {
                    sort: false,
                    download: false,
                    customBodyRender: (uid:any) => (
                        <>
                        {uid? (
                             <Link to={`/viewblueprint/${uid}`}>
                             <button className="btn btn-primary btn-xs"
                                 title="View"><i className="fa fa-eye" aria-hidden="true"></i></button></Link> 
                        ):(
                         <button className="btn btn-disable btn-xs"
                             title="View"><i className="fa fa-eye" aria-hidden="true"></i></button>
                        )}
                        </>
                    )
                }

            },
            {
                name:"uid",
                label:"Delete",
                options: {
                    sort: false,
                    download: false,
                    customBodyRender: (uid:any) => (
                        <>
                            {uid ? (
                                <button className="btn btn-primary btn-xs" title="Delete" onClick={() => this.showDeletePopup(uid)}>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                ) : (
                                <button className="btn btn-disable btn-xs" title="Delete" disabled>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            )}
                        </>
                    )
                }
            }
          ];

          const options = {
            rowsPerPage: per_page,
            rowsPerPageOptions: RowsPerPageOptions,
            count: total,
            selectableRows: false,
            page:this.state.page - 1,
            filter:false,
            print:false,
            downloadOptions:{
              filename: "BluePrints.csv"
            },
            onChangePage: (newPage:any) => {
                this.setState({ page: newPage +1}); 
                this.fetchMoreStudentData(newPage + 1,this.state.per_page);
            },
            onColumnSortChange:(changedColumn:any, direction:any) =>{
                const sortData = {
                    sort_by: changedColumn,
                    order_by: direction
                }
                this.showFilterData(sortData);
            },
            onChangeRowsPerPage: (numberOfRows:any) => {
              const page = 1
              this.setState({ per_page: numberOfRows,page:page });
              this.fetchMoreStudentData(page,numberOfRows);
            },
            onSearchChange: (searchText:any, row:any) => {
                this.handleInputChange({ target: { value: searchText } });
            }
        };
        return (
            <div>
                <ThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                data={records}
                columns={columns}
                options={options}
                />
                </ThemeProvider>
            </div>
        )
    }

    showDeletePopup (getData: any) {
        if(getData){
            this.setState({ showDelete: true, deleteId: getData});
        }
    }

    public hideBluePrintDelete = () => {
        this.setState({ showDelete: false });
    }

    public handleDelete = () => {
        this.setState({ showDelete: false, page: 1, hasMore: true });
        this.props.deleteBluePrintById(this.state.deleteId).then((res:any)=>{
            this.getBluePrintDetails()
        })
    }

    handleInputChange = (e: any) => {
        const { value } = e.target;
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
           if (getSearchValue) {
          const postSearchValue:any = {
            page_no: 1,
            per_page: this.state.per_page,
            search: getSearchValue,
          }
          this.props.fetchBluePrintGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: getSearchValue
          })
        } else {
          const postSearchValue:any = {
            page_no: 1,
            per_page: this.state.per_page,
            search: '',
          }      
          this.props.fetchBluePrintGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: ''
          })
        }
        }
      }

      refreshBluePrintTable = () =>{
        const postSearchValue:any = {
            page_no: 1,
            search: '',
        }
        this.setState({
          search: '',
          SortOrderData:'',
          OrderNameData:'',
        }, () => {
            this.props.fetchBluePrintGet(postSearchValue)
        });  
    }

    render() {
        const { loading, records } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const { showDelete } = this.state;
        const postValue:any = {
            title:'Please Confirm',
            action_status:'delete',
            action_name:'Blue Print'
        }
        return (
            <div className="page-wrapper">
                  <CommonModel
                    onState={showDelete}
                    currentState={postValue}
                    onConfirm={this.handleDelete}
                    onCancel={this.hideBluePrintDelete}
                />
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Blueprints']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Blueprints']} />

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-topline-red">
                                    <div className="card-head">
                                        <header>
                                            <Link to={QuestionBank.AddBluePrint} className="ml-2 mt-1">
                                                <button className="btn btn-pink">Add BluePrint</button>
                                            </Link>
                                        </header>
                                    </div>
                                    <div className="card-body no-padding height-9">
                                        <div className="row">
                                            <div className="table-responsive">
                                                {this.renderBluePrint(records)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        records: state.QuestionBank.records,
        page: state.QuestionBank.page,
        per_page: state.QuestionBank.per_page,
        total: state.QuestionBank.total,
        totalPage: state.QuestionBank.totalPage
    }
}

export default connect(mapStateToProps, {
    fetchBluePrintGet,
    deleteBluePrintById
})(BluePrintGrid)