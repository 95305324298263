import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchGetAllClassListTeacherperformance } from "../../store/diary/Actions";
import { fetchConcernPoint } from "../../store/dashboard/Actions";
import { getLeastAttendees } from "../../store/teacher/Actions";
import CommonLoader from "../../components/CommonLoader";
import { Link } from "react-router-dom";
import history from "../../History";
import { getAttendanceStudentView } from "../../store/profile/Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { stopYearData } from '../../store/profile/Actions'
import {stateTypes} from './Types';

export type PostsTeacherSubjectProps = {
  getChangeYearData?:any;
	stopYearDate?:any;
  fetchGetAllClassListTeacherperformance: (postPage: any) => any;
  fetchConcernPoint: (postValue: any) => any;
  getLeastAttendees: (postData: any) => any;
  getAttendanceStudentView: (postValue: any) => any;
  stopYearData:()=>any;
  getAllClassList: any;
  getProfile: any;
  getDuration: any;
  getPointValue?: any;
  loading: boolean;
  LeastAttendees?: any;
  RecordsLeastAttendees?: any;
  PerPageLeastAttendees?: any;
  PageLeastAttendees?: any;
  TotalLeastAttendees?: any;
  loadingTeacher?: any;
  isSubjectLoader?:boolean;
  isPointofConcernLoader?:boolean;
  isLeastAttendeeLoader?:boolean;
};

export class TeacherSubject extends Component<
  PostsTeacherSubjectProps,
  stateTypes
> {
  constructor(props: any) {
    super(props);
    this.state = {
      attendFilter: "This Year",
      classid: "",
      page: 1,
      search: "",
      hasMore: true,
      SortOrderData: "",
      OrderNameData: "",
      getLeastAttendeesRecord: [],
      recordPointValue: [],
      academicYear:null
    };
  }
  componentDidMount(): void {
  this.UpdateYearDate()
  }
   
  componentWillUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.UpdateYearDate(this.props.getChangeYearData)
    }
}

componentDidUpdate() {
    const {stopYearDate} = this.props;
    if(stopYearDate === true){
        this.props.stopYearData()
    }
}

UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.getInitTeacherSubject(getYear)
        this.setState({academicYear:getYear})
    }else {
        this.getInitTeacherSubject(this.props.getChangeYearData)
        this.setState({academicYear:this.props.getChangeYearData})
    }
}

getInitTeacherSubject(getYear?:any){
  window.scrollTo(0, 0);
  this.getTeacherSubject(getYear);
  this.getLeastAttendessData(getYear);
}
  getTeacherSubject(getYear?:any) {
    const loadMoreType = {
      academic_year:getYear,
    };
    this.props.fetchGetAllClassListTeacherperformance(loadMoreType).then((res: any) => {
      window.scrollTo(0, 0);
      const { getAllClassList } = this.props;
      if (getAllClassList && getAllClassList?.length) {
        const postValue = {
          academic_year:getYear,
          class_id: getAllClassList[0].id,
          filter: "This Year",
        };
        this.setState({ classid: getAllClassList[0].id, attendFilter:"This Year" });
        this.props.fetchConcernPoint(postValue).then((res: any) => {
          const { getPointValue } = this.props;
          if (
            getPointValue &&
            getPointValue !== undefined &&
            getPointValue !== null &&
            getPointValue.length > 0
          ) {
            this.setState({ recordPointValue: getPointValue });
          } else {
            this.setState({ recordPointValue: [] });
          }
        });
      } else {
        this.setState({ recordPointValue: [] });
      }
    });
  }

  getLeastAttendessData(getYear?:any) {
    const { page, search, SortOrderData, OrderNameData } = this.state;
    const postValue = {
      page_no: page,
      search: search,
      sort_by: SortOrderData,
      order_by: OrderNameData,
      academic_year:getYear,
    };
    this.props.getLeastAttendees(postValue).then((res: any) => {
      const { RecordsLeastAttendees } = this.props;
      if (
        RecordsLeastAttendees &&
        RecordsLeastAttendees !== undefined &&
        RecordsLeastAttendees !== null &&
        RecordsLeastAttendees.length > 0
      ) {
        this.setState({ getLeastAttendeesRecord: RecordsLeastAttendees });
      } else {
        this.setState({ getLeastAttendeesRecord: [] });
      }
    });
  }
  public fetchLeastAttendees = () => {
    const {academicYear} = this.state;
    if (this.state.hasMore === true) {
      if (
        Math.ceil(
          this.props.TotalLeastAttendees / this.props.PerPageLeastAttendees
        ) > this.props.PageLeastAttendees
      ) {
        const postValue: any = {
          page_no: this.state.page + 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:academicYear,
        };
        this.props.getLeastAttendees(postValue).then((res: any) => {
          const { RecordsLeastAttendees } = this.props;
          if (
            RecordsLeastAttendees &&
            RecordsLeastAttendees !== undefined &&
            RecordsLeastAttendees !== null &&
            RecordsLeastAttendees.length > 0
          ) {
            this.setState({ getLeastAttendeesRecord: RecordsLeastAttendees });
          } else {
            this.setState({ getLeastAttendeesRecord: [] });
          }
        });
        this.setState({
          page: this.state.page + 1,
        });
      }

      if (
        Math.ceil(
          this.props.TotalLeastAttendees / this.props.PerPageLeastAttendees
        ) === this.props.PageLeastAttendees
      ) {
        this.setState({
          hasMore: false,
        });
      }
    }
  };
  getPointChange = (e: any) => {
    const { value } = e.target;
    const { attendFilter, academicYear } = this.state;
    if (value) {
      const postValue = {
        academic_year:academicYear,
        class_id: value,
        filter: attendFilter,
      };
      this.setState({ classid: value });
      this.props.fetchConcernPoint(postValue).then((res: any) => {
        const { getPointValue } = this.props;
        if (
          getPointValue &&
          getPointValue !== undefined &&
          getPointValue !== null &&
          getPointValue.length > 0
        ) {
          this.setState({ recordPointValue: getPointValue });
        } else {
          this.setState({ recordPointValue: [] });
        }
      });
    }
  };
  getYearChange = (e: any) => {
    const { value } = e.target;
    const { classid, academicYear } = this.state;
    if (value) {
      const postValue = {
        academic_year: academicYear,
        class_id: classid,
        filter: value,
      };
      this.setState({ attendFilter: value });
      this.props.fetchConcernPoint(postValue).then((res: any) => {
        const { getPointValue } = this.props;
        if (
          getPointValue &&
          getPointValue !== undefined &&
          getPointValue !== null &&
          getPointValue.length > 0
        ) {
          this.setState({ recordPointValue: getPointValue });
        } else {
          this.setState({ recordPointValue: [] });
        }
      });
    }
  };
  getStudentDetails = (getValue: any) => {
    let getStudentId: any = getValue.card_id;
    if (getStudentId) {
      history.push(`/student_view`);
      let getPostValue: any = {
        card_id: getValue.card_id,
        class_id: getValue.class_id,
        postURL: ``,
        titleName: "Dashboard",
      };
      this.props.getAttendanceStudentView(getPostValue);
    }
  };
  refreshPointOfConcern = () => {
    const {academicYear} = this.state;
    this.getTeacherSubject(academicYear);
  };
  refreshLeastAttendees = () => {
    const {academicYear} = this.state;
    this.getLeastAttendessDataRefresh(academicYear);
  };

  getLeastAttendessDataRefresh(getYear?:any) {
    const postValue = {
      page_no: 1,
      search: '',
      sort_by: '',
      order_by: '',
      academic_year:getYear,
    };
    this.setState({page:1,search:'',SortOrderData:'',OrderNameData:'',hasMore:true})
    this.props.getLeastAttendees(postValue).then((res: any) => {
      const { RecordsLeastAttendees } = this.props;
      if (
        RecordsLeastAttendees &&
        RecordsLeastAttendees !== undefined &&
        RecordsLeastAttendees !== null &&
        RecordsLeastAttendees.length > 0
      ) {
        this.setState({ getLeastAttendeesRecord: RecordsLeastAttendees });
      } else {
        this.setState({ getLeastAttendeesRecord: [] });
      }
    });
  }

  render() {
    const { isSubjectLoader, isPointofConcernLoader, getAllClassList, getDuration,isLeastAttendeeLoader } =
      this.props;
    const { recordPointValue, getLeastAttendeesRecord } = this.state;
    const isPointofConcernLoaderVisible = { display: isSubjectLoader ? "block" : isPointofConcernLoader ? "block" :'none' };
    const isLeastAttendeeLoaderVisible = { display: isLeastAttendeeLoader ? "block" :'none' };
    return (
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="card card-topline-red">
            <div className="overlay" style={isPointofConcernLoaderVisible}><div className='custom-spinner-individual'></div></div>
              <div className="card-head">
                <header>Point Of Concern</header>
                <div className="tools">
                  <div className="d-flex">
                    <div className="mr-2">
                      {getAllClassList ? (
                        <select
                          name="getAll"
                          id=""
                          className="form-control"
                          value={this.state.classid}                          
                          onChange={this.getPointChange}
                        >
                          {getAllClassList.map((items: any) => (
                            <option value={items.id}>
                              {" "}
                              {items.grade_standard}{" "}
                            </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                    <div className="mr-2">
                      {getDuration ? (
                        <select
                          name="duration"
                          id=""
                          className="form-control"
                          value={this.state.attendFilter}
                          onChange={this.getYearChange}
                        >
                          {getDuration.map((items: any) => (
                            <option value={items.value}> {items.value} </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                    <div className="ml-2 mt-1">
                      <span
                        className="fa fa-repeat btn-color box-refresh pointer"
                        onClick={this.refreshPointOfConcern}
                      ></span>
                      <span className="t-collapse btn-color fa fa-chevron-up pointer"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <ul
                    className="docListWindow
                                                small-slimscroll-style overflowBox"
                  >
                    <li className="title-sticky" style={{zIndex:2}}>
                      <div className="row">
                        <div className="col-md-3 col-xs-3">
                          <strong>Student Name</strong>
                        </div>
                        <div className="col-md-2 col-xs-2 text-center">
                          <strong >Correct</strong>
                        </div>
                        <div className="col-md-2 col-xs-2 text-center">
                          <strong>Incorrect</strong>
                        </div>
                        <div className="col-md-2 col-xs-2 text-center">
                          <strong>Missing</strong>
                        </div>
                        <div className="col-md-3 col-xs-3 text-center">
                          <strong>Percentage</strong>
                        </div>
                      </div>
                    </li>
                    {recordPointValue &&
                    recordPointValue !== undefined &&
                    recordPointValue !== null &&
                    recordPointValue.length > 0 ? (
                      recordPointValue.map((items: any) => (
                        <li>
                          <div className="row">
                            <div
                              className="col-md-1
                                                            col-sm-1"
                            >
                              <div className="prog-avatar">
                                <button
                                  className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink"
                                  onClick={() => this.getStudentDetails(items)}
                                >
                                  <span>{items.student_name.charAt(0)}</span>
                                </button>
                              </div>
                            </div>
                            <div
                              className="col-md-2
                                                            col-sm-2"
                            >
                              <div className="details">
                                <div
                                  className="title
                                                                    mt-2"
                                >
                                  <Link
                                    to={"#"}
                                    onClick={() =>
                                      this.getStudentDetails(items)
                                    }
                                  >
                                    {items.student_name}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-2
                                col-sm-2
                                rating-style"
                            >
                              <p className="text-center">{items.correct}</p>
                            </div>
                            <div
                              className="col-md-2
                                                            col-sm-2
                                                            rating-style"
                            >
                              <p className="text-center">{items.incorrect}</p>
                            </div>
                            <div
                              className="col-md-2
                                                            col-sm-2
                                                            rating-style"
                            >
                              <p className="text-center">{items.missing}</p>
                            </div>
                            <div
                              className="col-md-3
                                                            col-sm-3
                                                            rating-style"
                            >
                              <p className="text-center">{items.performance}</p>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <CommonLoader />
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="card card-topline-red">
            <div className="overlay" style={isLeastAttendeeLoaderVisible}><div className='custom-spinner-individual'></div></div>
              <div className="card-head">
                <header className="mt-2 mb-1">Least Attendees</header>
                <div className="tools">
                  <div className="d-flex">
                    <div className="ml-2 mt-1">
                      <span
                        className="fa fa-repeat btn-color box-refresh pointer"
                        onClick={this.refreshLeastAttendees}
                      ></span>
                      <span className="t-collapse btn-color fa fa-chevron-up pointer"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <InfiniteScroll
                  dataLength={getLeastAttendeesRecord.length}
                  next={this.fetchLeastAttendees}
                  hasMore={this.state.hasMore}
                  loader={<h4 style={isLeastAttendeeLoaderVisible}>Loading...</h4>}
                >
                  <ul
                    className="docListWindow
                                                small-slimscroll-style overflowBox"
                  >
                    <li className="title-sticky" style={{zIndex:2}}>
                      <div className="row">
                        <div className="col-md-9 col-xs-9">
                          <strong>Student Name</strong>
                        </div>
                        <div className="col-md-3 col-xs-3 text-center">
                          <strong>Absents Percentage</strong>
                        </div>
                      </div>
                    </li>
                    {getLeastAttendeesRecord &&
                    getLeastAttendeesRecord !== undefined &&
                    getLeastAttendeesRecord !== null &&
                    getLeastAttendeesRecord.length > 0 ? (
                      getLeastAttendeesRecord.map((items: any) => (
                        <li>
                          <div className="row">
                            <div
                              className="col-md-1
                                                            col-sm-1"
                            >
                              <div className="prog-avatar">
                                <button
                                  className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink"
                                  onClick={() => this.getStudentDetails(items)}
                                >
                                  <span>{items.student_name.charAt(0)}</span>
                                </button>
                              </div>
                            </div>
                            <div
                              className="col-md-8
                                                            col-sm-8"
                            >
                              <div className="details">
                                <div
                                  className="title
                                                                    mt-2"
                                >
                                  <Link
                                    to={"#"}
                                    onClick={() =>
                                      this.getStudentDetails(items)
                                    }
                                  >
                                    {items.student_name}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-3
                                                            col-sm-3
                                                            rating-style"
                            >
                              <p className="text-center">{items.percentage}%</p>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <CommonLoader />
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getAllClassList: state.diary.gradelist,
    getDuration: state.profile.getDuration,
    getChangeYearData:state.profile.getYear,
    stopYearDate:state.profile.yearStatusFails,
    getProfile: state.profile.profileData,
    getPointValue: state.dashboard.getConcernPoint,
    loading: state.dashboard.loading,
    loadingTeacher: state.teacher.loading,
    LeastAttendees: state.teacher.getLeastAttendees,
    RecordsLeastAttendees: state.teacher.RecordsLeastAttendees,
    PerPageLeastAttendees: state.teacher.PerPageLeastAttendees,
    PageLeastAttendees: state.teacher.PageLeastAttendees,
    TotalLeastAttendees: state.teacher.TotalLeastAttendees,
    isSubjectLoader:state.diary.isSubjectTeacherPerformanceLoader,
    isPointofConcernLoader:state.dashboard.isPointofConcernLoader,
    isLeastAttendeeLoader:state.teacher.isLeastAttendeeLoader,
  };
};

export default connect(mapStateToProps, {
  fetchGetAllClassListTeacherperformance,
  fetchConcernPoint,
  getLeastAttendees,
  getAttendanceStudentView,
  stopYearData
})(TeacherSubject);
