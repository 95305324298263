import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSchoolsViewGet } from '../../store/school/Actions'
import { Field, Formik, Form } from 'formik';
import { RootState } from '../../store/Index';
import { FormGroup } from '@material-ui/core';
import { SchoolFieldsType } from '../../store/school/Types'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import { LoadMoreType } from '../../components/type';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import { ModelImageView } from '../../components/ModelImageView';
function withMyHook(Component:any) {
    return function WrappedComponent(props:any) {
         const jsApiLoader = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: 'AIzaSyA3yhFDKARfSmU95yaMMQ3SbsW1kOX_gsA',
          });
          return <Component {...props} jsApiLoader={jsApiLoader} />;
    }
  }
export interface PostsListProps {
    loading: boolean
    schoolDetails: any;
    jsApiLoader:any;
    fetchSchoolsViewGet: (loadMoreType: LoadMoreType) => any;
}
const containerStyle = {
    width: '100%',
    height: '400px'
};

    export interface StateProps {
    isLoaded: boolean;
    map: any;
    image?: any;
    showImageView?: any;
 }

class SchoolAdminProfile extends React.Component<PostsListProps,StateProps>  {
    jsApiLoader:any;
    onLoad:any;
    onUnmount:any;
    constructor(props:any) {
        super(props);
        console.log(props)
        this.state = {
          isLoaded: props.jsApiLoader.isLoaded,
          map: null,
          image: "",
          showImageView: false,
        };
        this.onLoad = this.onLoadMethod.bind(this);
        this.onUnmount = this.onUnmountMethod.bind(this);
      }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        const postValue = {
            page_no: 1
        }
        this.props.fetchSchoolsViewGet(postValue);
    }

    componentDidUpdate() {
        if(this.state.isLoaded != this.props.jsApiLoader.isLoaded){
            this.setState({ isLoaded: this.props.jsApiLoader.isLoaded });
        }
    }
    onLoadMethod(map:any) {
        map.setZoom(14)
        this.setState({ map });
      }
    
      onUnmountMethod() {
        this.setState({ map: null });
      }

    showImage = (image: any) => {
        this.setState({ image: image, showImageView: true });
    }
    onClose = () => {
        this.setState({ showImageView: false });
    }

    render() {
        const { loading } = this.props;
        const { showImageView, image} = this.state;
        const loadingSchoolView = { display: loading ? "block" : "none" };
        const getToken = localStorage.getItem('token');
        if (!getToken) {
            history.push("/");
        }
        const getAdminDetail: any = this.props.schoolDetails;
        let schoolAdmin;
        let schoolName:any;
        let position: any;
        let contactPersonsName;
        let contactPersonsDesignation;
        let contactPersonsPhoneNumber;
        let contactPersonsemailId;
        let contactPersons;
        let schoolCoordinates;
        let latitude;
        let longitude;
        let getStatTime;
        let getEndTime;
        let schoolAdminId;
        let schoolAdminName;
        let schoolAdminAddress;
        let schoolAdminStartTime;
        let schoolAdminEndTime;
        let schoolAdminCategory;
        let schoolCoverageArea;
        let school_logo:any;
        if (getAdminDetail) {
            const getRecodeList = getAdminDetail;
            if (getRecodeList.length > 0) {
                schoolAdmin = getRecodeList[0];
                schoolName = schoolAdmin.school_name;
                contactPersons = schoolAdmin.contact_persons;
                schoolCoordinates = schoolAdmin.school_coordinates;
                schoolAdminId = schoolAdmin.id;
                schoolAdminName = schoolAdmin.school_name;
                schoolAdminAddress = schoolAdmin.address;
                schoolAdminStartTime = schoolAdmin.acadamic_start_month;
                schoolAdminEndTime = schoolAdmin.acadamic_end_month;
                schoolAdminCategory = schoolAdmin.category;
                schoolCoverageArea = schoolAdmin.coverage_area;
            }
            if (schoolAdmin) {
                getStatTime = schoolAdmin.start_time;
                getEndTime = schoolAdmin.end_time;
                school_logo = schoolAdmin.school_logo
            }
            if (schoolCoordinates) {
                position = {
                    lat: parseFloat(schoolCoordinates[0].latitude),
                    lng: parseFloat(schoolCoordinates[0].longitude)
                }
                latitude = schoolCoordinates[0].latitude;
                longitude = schoolCoordinates[0].longitude;
            }
            if (contactPersons) {
                contactPersonsName = contactPersons[0].name;
                contactPersonsDesignation = contactPersons[0].designation;
                contactPersonsPhoneNumber = contactPersons[0].phone_number;
                contactPersonsemailId = contactPersons[0].email_id;
            }
        }

        const initialSchoolManage: SchoolFieldsType = {
            id: schoolAdminId,
            school_name: schoolAdminName,
            address: schoolAdminAddress,
            name: contactPersonsName,
            designation: contactPersonsDesignation,
            phone_number: contactPersonsPhoneNumber,
            email_id: contactPersonsemailId,
            category: schoolAdminCategory,
            latitude: latitude,
            longitude: longitude,
            acadamic_start_month: schoolAdminStartTime,
            acadamic_end_month: schoolAdminEndTime,
            start_time: moment(getStatTime, ["h:mm A"]).format("HH:mm"),
            end_time: moment(getEndTime, ["h:mm A"]).format("HH:mm"),
            school_coverage_area: schoolCoverageArea,
            school_logo: school_logo,
        }
        
        return (
            <div>
                <ModelImageView
                onState={showImageView}
                onCancel={this.onClose}
                image={image}
                />
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['School']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['View School']} />
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-12">
                                    {position && getAdminDetail && schoolName && schoolAdmin ?
                                        <div className="card">                                          
                                            <div className="card-head card-topline-red">                                            
                                                <header>View School</header>
                                                <div className="tools">
                                                    <Link to={"/edit_school/" + schoolAdmin.id}>
                                                        <button
                                                            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                                                            title="Edit Profile">Edit <i className="fa fa-pencil" aria-hidden="true" ></i></button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="card-body no-padding height-9">
                                                <Formik
                                                    initialValues={initialSchoolManage} onSubmit={(values, formikHelpers) => {

                                                    }}>
                                                    {({ values, errors, isSubmitting, isValidating, dirty, touched }) => (
                                                        <Form>
                                                            <div>

                                                                <div className="mb-3">
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div className="mdl-textfield 
                                                                            mdl-js-textfield mdl-textfield--floating-label 
                                                                            txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>School Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="school_name"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={true}
                                                                                        readonly
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 categoryPadding">
                                                                            <div className="mdl-textfield 
                                                                            mdl-js-textfield mdl-textfield--floating-label 
                                                                            txt-full-width mt-2 pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Category</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="category"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="name"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Designation</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="designation"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0  pb-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="phone_number"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0 pb-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="email_id"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-t-20">
                                                                        <div className="mdl-textfield 
                                                                        mdl-js-textfield mdl-textfield--floating-label 
                                                                        txt-full-width">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    name="address"
                                                                                    component={TextField}
                                                                                    label={<><span>Address</span><span className='formmandatorycolor'> *</span></>}
                                                                                    className="textfield__input"
                                                                                    rows="1"
                                                                                    disabled={true}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0  pb-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="time"
                                                                                            name="start_time"
                                                                                            label={<><span>Start Time</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0  pb-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="time"
                                                                                            name="end_time"
                                                                                            label={<><span>End Time</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width pt-0  pb-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                        type="number"
                                                                                        name="school_coverage_area"
                                                                                        label={<><span>School Coverage Area(In Meters)</span><span className='formmandatorycolor'> *</span></>}
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        fullwidth="true"
                                                                                        disabled={true}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Academic Start Month</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="acadamic_start_month"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield 
                                                                                mdl-js-textfield mdl-textfield--floating-label 
                                                                                txt-full-width">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Academic End Month</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="acadamic_end_month"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {school_logo ? (
                                                                            <Link to={'#'} onClick={() => this.showImage(school_logo)}>
                                                                                <img src={school_logo} alt="img" className="multiImage" />
                                                                            </Link>
                                                                            ) : null}
                                                                    </div>
                                                                    <div className="mt-3 mb-3">
                                                                        {
                                                                             this.state.isLoaded && position && position !==''?
                                                                            <GoogleMap
                                                                                mapContainerStyle={containerStyle}
                                                                                center={position}
                                                                                zoom={14}
                                                                                onLoad={this.onLoad}
                                                                                onUnmount={this.onUnmount}
                                                                            >
                                                                                <Marker
                                                                                    position={position}
                                                                                    draggable={false}
                                                                                />
                                                                                <></>
                                                                            </GoogleMap>
                                                                            :null
                                                                        }                                                                  
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>

                                            </div>
                                        </div>
                                        : <div><SpinnerLoader /></div>}
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingSchoolView}><SpinnerLoader /></div>
            </div>
        );
    }
}

const mapStateToProps = ({ schools }: RootState) => {
    return {
        schoolDetails: schools.records,
        loading: schools.loading
    };
};

export default connect(
    mapStateToProps, { fetchSchoolsViewGet }
)(withMyHook(SchoolAdminProfile));
