import React from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb'
import { fetchSubjectsPost, fetchSubject, deletePost } from '../../store/subject/Actions'
import { addSubject } from '../../store/subject/Actions';
import { Link } from 'react-router-dom';
import { SubjectFieldsType, SubjectTypes, loadMoreType, ISearchBarState } from '../../store/subject/Types'
import { UserDetails } from '../../store/authentication/Types'
import history from '../../History';
import { RowPerPage, RowsPerPageOptions, UserRoles } from '../../services/Constants'
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import Switch from '@material-ui/core/Switch';
import { formValidationPatten } from '../../services/Constants';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface PostsListProps {
  loading: boolean
  getYear?:any;
  subjectDetails: SubjectTypes;
  subjectDetailDelete: SubjectFieldsType;
  tokenValue: UserDetails;
  addSubjectDetails: any;
  deleteDetails: any;
  fetchSubjectsPost: (getData: loadMoreType) => any;
  fetchSubject: (id: number) => void;
  deletePost: (subjectDetailDelete: number) => any;
  addSubject: (subject: SubjectFieldsType) => void;
  stopYearData:()=>any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
  profileInfo:any;
}

class ViewSubjectDetails extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteSubjectId: [],
      query: '',
      subjectsData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 0,
      ascOrder: true,
      descOrder: false,
      page: 1,
      search: '',
      getResponseData: [],
      SortOrderData: '',
      OrderNameData: '',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      academicYear:null,
      per_page:RowPerPage
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.UpdateYearDate();
    this.checkUserType();
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
      this.UpdateYearDate(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
    UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.setState({academicYear:getYear})
        this.getOnSubjectData(getYear)
    }else {
        this.setState({academicYear:this.props.getYear})
        this.getOnSubjectData(this.props.getYear)
    }
    }
  public getOnSubjectData(getYear?:any) {
    const postValue = {
      page_no: 1,
      per_page: this.state.per_page,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      academic_year:getYear
    }
    this.props.fetchSubjectsPost(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page:1})
    });
    
  }

  checkStatusProcess() {
    const selectDataList = this.props.deleteDetails;
    if (selectDataList === true) {
      this.UpdateYearDate();
    }
  }
  public fetchMoreData = (newPage?:any,numberOfRows?:any) => {
    const {academicYear} = this.state
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: newPage,
          per_page: numberOfRows,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:academicYear
        }
        this.props.fetchSubjectsPost(postValue);
        // this.setState({
        //   page: this.state.page + 1
        // })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  public handleSubjectDelete = () => {
    this.props.deletePost(this.state.deleteSubjectId).then((res:any)=>{
      this.checkStatusProcess()
    });
    this.setState({ showDelete: false, page: 1, hasMore: true });
  }

  public hideSubjectDelete = () => {
    this.setState({ showDelete: false })
  }
  public showSubjectDelete = (getData: any) => {
    let postValue:any
    let titleClassMessage: any;
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'Subject'
      }
      this.setState({ showDelete: true, deleteSubjectId: getData, postData:postValue });
    }
  }

  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        ascOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchSubjectsPost(postValue).then((res: any) => {
        
      });
    } else {
      this.setState({
        ascOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchSubjectsPost(postValue).then((res: any) => {
        
      });
    }
  }
  // This is the function used to check user type
  checkUserType() {
    const {profileInfo} = this.props;
    let getSubjectToken:any;
    if(profileInfo.usertype){
      
      getSubjectToken = String(profileInfo.usertype);
    if (getSubjectToken === UserRoles.acadamicAdmin) {
      this.setState({ acadamicAdmin: true })
    } else if (getSubjectToken === UserRoles.schoolAdmin) {
      this.setState({ schoolAdmin: true })
    } else if (getSubjectToken === UserRoles.teacher) {
      this.setState({ teacher: true })
    } else if (getSubjectToken === UserRoles.parent) {
      this.setState({ parent: true })
    }
  }
  }
  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      }      
    }
  })
  private renderSubjectManageView(records: any) {
    const { loading,total } = this.props;
    const { sort_by, sort_class_name, sort_title_name, acadamicAdmin,per_page} = this.state
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const columns = [
      { name: "name", label: "Name"},
      { name: "short_name", label: "Short Name"},
      { name: "category", label: "Category"},
      { name: "school_category", label: "School Category",
        options: {
          sort: false,
        }},
      ...(acadamicAdmin ? [
      {
        name: "school_list",
        label: "Schools",
        options: {
          customBodyRender: (value:any) => (
            value.length > 0 ? value.map((item:any) => (
              <button className="btn btn-xs btn-circle btn-pink m-1">{item['school_name'] || '-'}</button>
            )) : '-'
          )
        }
      },
      {
        name: "actions",
        label: "Actions",
        options: {
          sort: false,
          download: false,
          customBodyRender: (value:any, tableMeta:any) => {
            const data = records[tableMeta.rowIndex];
            return (
              <>
                <Link to={`/edit_subject/${data.id}`}>
                  <button className="btn btn-primary btn-xs" title="Edit Subject">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </Link>
                <Switch
                  checked={data.is_active}
                  onClick={() => this.showSubjectDelete({ id: String(data['id']), isActive: !data.is_active })}
                  name="checkedUser"
                  inputProps={{ 'aria-label': 'checkbox' }}
                  title={data.is_active ? "Deactivate Subject" : "Activate Subject"}
                />
              </>
            );
          }
        }
      }
    ]: [])
    ];
    const options = {
      rowsPerPage: per_page,
      rowsPerPageOptions: RowsPerPageOptions,
      count: total,
      selectableRows: false,
      page:this.state.page - 1,
      filter:false,
      print:false,
      downloadOptions:{
        filename: "SubjectsList.csv"
      },
      onChangePage: (newPage:any) => {
          this.setState({ page: newPage +1}); 
          this.fetchMoreData(newPage + 1,this.state.per_page);
      },
      onColumnSortChange:(changedColumn:any, direction:any) =>{
          const sortData = {
              sort_by: direction,
              order_by: changedColumn
          }
          this.showFilterData(sortData);
      },
      onChangeRowsPerPage: (numberOfRows:any) => {
        const page = 1
        this.setState({ per_page: numberOfRows,page:page });
        this.fetchMoreData(page,numberOfRows);
      },
      onSearchChange: (searchText:any, row:any) => {
          this.handleInputSubjectChange({ target: { value: searchText } });
      }
  };
    return (
      <div>
        <ThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
          data={records}
          columns={columns}
          options={options}
          />
        </ThemeProvider>
      </div>
    )
  }

  handleInputSubjectChange = (e: any) => {
    const { value } = e.target;
    const {academicYear} = this.state
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          per_page: this.state.per_page,
          search: getSearchValue,
          academic_year:academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSubjectsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      } else {
        const postSearchValue = {
          page_no: 1,
          search: '',
          academic_year:academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSubjectsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: ''
        })
      }
    }

  }
  refreshSubjectTable = () =>{
    const {academicYear} = this.state
    this.setState({
      search: '',
      SortOrderData:'',
      OrderNameData:''
    }, () => {
      this.getOnSubjectData(academicYear)
    });  
  }
  render() {
    const { loading } = this.props;
    const { showDelete, postData, search} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getSubjectToken = localStorage.getItem('token');
    const subjectTotal = this.props.total;
    if (!getSubjectToken) {
      history.push("/");
    }
    return (
      <div>
      <CommonModel
       onState={showDelete}
       currentState={postData}
       onConfirm={this.handleSubjectDelete}
       onCancel={this.hideSubjectDelete}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb titleName={['Subjects']} homeName={['Home']} url={['dashboard']} mainPageTitle={['Subjects']} />
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">
                    <div className="card-head">
                      <header>
                        {/* <Link to="/add_subject" style={{ display: acadamicAdmin ? "block" : "none" }}>
                          <button className="btn btn-pink">Map Subject</button>
                        </Link> */}
                      </header>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">

                        <div className="table-responsive">
                          {this.renderSubjectManageView(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
    subjectDetails: state.subjects.items,
    loading: state.subjects.loading,
    total: state.subjects.total,
    per_page: state.subjects.per_page,
    records: state.subjects.records,
    page: state.subjects.page,
    totalPage: state.subjects.totalPage,
    deleteDetails: state.subjects.isAuthenticated,
    profileInfo:state.profile.profileData,
  };
};
export default connect(
  mapStateToProps, { fetchSubjectsPost, fetchSubject, deletePost, addSubject, stopYearData }
)(ViewSubjectDetails);