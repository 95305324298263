import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchStudentProfile } from '../../../store/student/Actions';
import SpinnerLoader from '../../../components/spinner/SpinnerLoader';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'

interface propsTypes {
    getChangeYearData?:any;
	stopYearDate?:any;
    getStudentInfoDetails?: any;
    subComponentStatus?:any
    stopSubComponentYear:(postValue?: any)=>any;
    fetchStudentProfile: (postValue: any) => any;
    stopYearData:()=>any;
    loading?: any
    studentProfileInfo: any;
    loginProfile?: any;
}
export class StudentProfileInfo extends Component<propsTypes> {
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear();
    }
    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) { 
        const { stopYearDate, subComponentStatus} = this.props;
        if(subComponentStatus === true){
			this.props.stopSubComponentYear()
		}else if(stopYearDate === true){
			this.props.stopYearData()
		}  
    }
         // This is life cycle is initial load  component Will Update
         componentWillUpdate(nextProps:any, nextState:any) {
            const {stopYearDate, subComponentStatus} = this.props;
            if(subComponentStatus === true){
                this.props.stopSubComponentYear()
                this.getCurrectYear(this.props.getChangeYearData)
            }else if(stopYearDate === true){
                this.getCurrectYear(this.props.getChangeYearData)
            }
        }
    
        // This is function is used get correct year
        getCurrectYear(getYear?:any){
            if(getYear && getYear !== undefined && getYear !== null){
                this.getStudentFullDetails(getYear)
            }else {
                this.getStudentFullDetails(this.props.getChangeYearData)
            }
        }
    getStudentFullDetails(getYear?:any) {
        let getStudentId: any = this.props.getStudentInfoDetails;
        const { loginProfile } = this.props;
        if (loginProfile) {
            if (loginProfile.usertype === 1) {
                if (getStudentId) {
                    let postValue: any = {
                        academic_year:getYear,
                        class_id: getStudentId.class_id,
                        card_id: getStudentId.card_id,
                        school_id: getStudentId.school_id
                    }
                    this.props.fetchStudentProfile(postValue);
                }
            } else if (loginProfile.usertype === 2 || loginProfile.usertype === 3) {
                let postValue: any = {
                    academic_year:getYear,
                    class_id: getStudentId.class_id,
                    card_id: getStudentId.card_id
                }
                this.props.fetchStudentProfile(postValue);
            }
        }
    }

    addDefaultSrc = (ev: any) => {
        ev.target.src = '../assets/img/user/teacher-profile.jpg'
    }

    render() {
        const { studentProfileInfo, loading } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        return (
            <div>
                {/* This part student profile details */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-topline-red">
                            <div className="card-body no-padding height-9">
                                {studentProfileInfo ?
                                    <div className="row">
                                        <div className="col-md-4">

                                            <div className="row mt-2">
                                                <div className="ml-3 d-flex">
                                                    {studentProfileInfo.profile_picture ?
                                                        <div>
                                                            <div className="profileImage">
                                                                <img onError={this.addDefaultSrc} src={`${studentProfileInfo.profile_picture}`}
                                                                    alt="Student Profile" width="40"
                                                                    height="40" />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <button
                                                                className="mdl-button
                                                mdl-js-button
                                                mdl-button--fab
                                                mdl-button--mini-fab
                                                ml-3 mt-3 btn-info">{
                                                                    studentProfileInfo.student_name ?
                                                                        <span>{studentProfileInfo.student_name.charAt(0)}</span>
                                                                        : null}
                                                            </button>
                                                        </div>
                                                    }

                                                    <div>
                                                        <div
                                                            className="text-center">
                                                            <h3 className="my-0 titleCapitalize">{studentProfileInfo.student_name}</h3>
                                                            <span>( Student )</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <h3>{studentProfileInfo.class_name}</h3>
                                            <h4>Class</h4>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <h3>{studentProfileInfo.card_id}</h3>
                                            <h4>Card-ID</h4>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <h3>{studentProfileInfo.performance}</h3>
                                            <h4>Percentage</h4>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <h3>{studentProfileInfo.attendance}</h3>
                                            <h4>Attendance</h4>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.questionset.loading,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
		subComponentStatus:state.profile.subComponentStart,
        getStudentInfoDetails: state.profile.AttendanceStudentView,
        studentProfileInfo: state.student.studentProfile,
        loginProfile: state.profile.profileData
    }
}

export default connect(mapStateToProps, { fetchStudentProfile, stopYearData, stopSubComponentYear })(StudentProfileInfo)
