import * as yup from 'yup';
import { questionSetValidation } from '../../services/Constants';

export const ImportQuestionValidate = yup.object().shape({
    question_name: yup.string()
      .required('Please enter the Question Set Name')
      .max(50, questionSetValidation.questionMsg)
      .min(3, questionSetValidation.questionMinMsg),
    select_class: yup.string()
        .required("Please select the Grade"),
    select_subject: yup.string()
        .required("Please select a Subject"),
    lessons: yup.string()
        .required("Please select the lesson"),
    question_count: yup.number()
        .required("Please enter the question count")
        .integer("Question Count must be an integer")
        .positive("Question count should be Positive Integer")
        .typeError("Question Count should be Integer Value"),
    difficulty_level: yup.string()
        .required("Please select the Difficulty Level"),
    question_category: yup.string()
        .required("Please select the Question Category"),
    questionlist:  yup.mixed()
    .when('questionSubmit', {
      is: (sck) => {
        // console.log(sck)
        return sck;
      },
    then: yup.array().of(
        yup.object().shape({
              question: yup.string()
                .required('Please enter the Question')
                .max(1000, questionSetValidation.questionMaxMsg),
              add_topics: yup.string()
                .max(1000, "Add Topics should not be more than 1000 characters")
                .required('Please Add Topics'),
              question_name_A: yup.string()
                .required('Please enter Option A')
                .max(1000, questionSetValidation.optionMsg)
                .test('is-unique', 'Option A must be unique', function (value) {
                  return (
                    value !== this.parent.question_name_B &&
                    value !== this.parent.question_name_C &&
                    value !== this.parent.question_name_D
                  );
                }),
              question_name_B: yup.string()
                .required('Please enter Option B')
                .max(1000, questionSetValidation.optionMsg)
                .test('is-unique', 'Option B must be unique', function (value) {
                  return (
                    value !== this.parent.question_name_A &&
                    value !== this.parent.question_name_C &&
                    value !== this.parent.question_name_D
                  );
                }),
              question_name_C: yup.string()
                .required('Please enter Option C')
                .max(1000, questionSetValidation.optionMsg)
                .test('is-unique', 'Option C must be unique', function (value) {
                  return (
                    value !== this.parent.question_name_A &&
                    value !== this.parent.question_name_B &&
                    value !== this.parent.question_name_D
                  );
                }),
              question_name_D: yup.string()
                .required('Please enter Option D')
                .max(1000, questionSetValidation.optionMsg)
                .test('is-unique', 'Option D must be unique', function (value) {
                  return (
                    value !== this.parent.question_name_A &&
                    value !== this.parent.question_name_B &&
                    value !== this.parent.question_name_C
                  );
                }),
              answer: yup.string()
                .required('Please choose the Correct Answer'),
            })
          )})
});
