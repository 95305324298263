import React from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { fetchGetAllClassList } from "../../store/diary/Actions";
import { GetAllSchoolCalendar, CreateSchoolCalendar, EditSchoolCalendar, deleteSchoolCalendar, schoolCalendarDownloadExcelGet } from "../../store/school/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { stopYearData } from '../../store/profile/Actions'
import { Calendar, momentLocalizer,  } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';
import { SchoolCalendarModel } from './schoolCalendarModel';
import { Link } from "react-router-dom";
import { SchoolCalendar } from '../../router/Roles';
import { fetchGradePost, fetchMyClassListGet } from "../../store/classes/Actions";
import { FormGroup, MenuItem } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { UserRoles } from "../../services/Constants";
moment.tz.setDefault('Asia/Kolkata');

const localizer = momentLocalizer(moment);


export type OwnSchoolCalendarProps = {
    loading?: boolean
    DairyLoading?: boolean
    studentLoading?: boolean
    getYear?: any;
    stopYearDate?: any;
    ListGrade: any;
    loginProfile: any;
    GetAllSchoolCalendar: (params: any) => any;
    CreateSchoolCalendar: (params: any) => any;
    EditSchoolCalendar: (params: any) => any;
    fetchGetAllClassList: (postPage: any) => any;
    deleteSchoolCalendar: (postPage: any) => any;
    schoolCalendarDownloadExcelGet:()=>any;
    stopYearData: () => any;
    fetchGradePost: (params: any) => any;
    fetchMyClassListGet: () => any;
    getAllClassList?: any;
    AllStudentList?: any;
    getStudentNextClass?: any;
    schoolcalendarData?: any;
}

interface StateTypes {
    academicYear: null;
    events: any;
    modalShow: boolean;
    isEditing: boolean;
    editingData: any;
    start_date:any;
    end_date:any;
    teacher:any;
    schoolAdmin?:any;
    classList?: any;
    selectedClass?:any;
    selectedMonth?: any;
    selectedYear?: any;
}

class schoolCalendar extends React.Component<OwnSchoolCalendarProps, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props)
        this.state = {
            academicYear: null,
            modalShow: false,
            isEditing: false,
            editingData: [],
            events: [],
            start_date:"",
            end_date:"",
            teacher: false,
            schoolAdmin: false
        };
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.checkUserType();
        // this.getCurrectYear();
        
    }
    // This is life cycle is initial load  component Will Update
    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getYear)
        }
    }

    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData()
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getSchoolCalendar(getYear)
            this.setState({ academicYear: getYear })
        } else {
            this.getSchoolCalendar(this.props.getYear)
            this.setState({ academicYear: this.props.getYear })
        }
    }

    getSchoolCalendar(getYear?: any, selectedClassId?: any) {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        this.setState({ selectedMonth: month, selectedYear: year});
        if(this.state.schoolAdmin){
            const postData = {
                academic_year: getYear,
                month: month,
                year: year
            }
            this.props.fetchGradePost(postData).then(()=>{
                this.props.GetAllSchoolCalendar(postData).then((res: any) => {
                const { schoolcalendarData } = this.props;
                if (schoolcalendarData && schoolcalendarData?.data) {
                    const events = schoolcalendarData.data.map((detail: any) => {
                        const startDate = new Date(detail.start_date)
                        const endDate = new Date(detail.end_date)
                        //const gradeIds = detail.grades.split(",").map(Number);
                        const gradeIds = detail.grade_list
                        let gradeList: any = [];
                        if (this.props.ListGrade) {
                            gradeList = this.props.ListGrade;
                        }
                        return {
                            title: detail.event_desc,
                            start: startDate,
                            end: endDate,
                            start_date: detail.start_date,
                            end_date: detail.end_date,
                            allDay: true,
                            color: detail.is_working_day ? '#809fff' : '#ff9999',
                            grade_id: detail.grade_list,
                            event_type:detail.event_type,
                            uid:detail.uid,
                            is_working_day:detail.is_working_day,
                            selectAll: gradeIds.length == gradeList.length
                        };
                    });

                    this.setState({ events: events });
                } else {
                    this.setState({ events: [] });
                }
            })}) 
    } 
        else {
            
            this.props.fetchMyClassListGet().then(()=>{
                    const { getAllClassList } = this.props;
                    const Allclasslist = getAllClassList;
                    this.setState({ classList: Allclasslist});
                const postData = {
                    academic_year: getYear,
                    grade: selectedClassId || getAllClassList[0].grade_id,
                    month: month,
                    year: year
                }
                this.props.GetAllSchoolCalendar(postData).then((res: any) => {

                const { schoolcalendarData } = this.props;

                if (schoolcalendarData && schoolcalendarData?.data) {
                    const events = schoolcalendarData.data.map((detail: any) => {
                        const startDate = new Date(detail.start_date)
                        const endDate = new Date(detail.end_date)
                        //const gradeIds = detail.grades.split(",").map(Number);
                        const gradeIds = detail.grade_list
                        let gradeList: any = [];
                        if (this.props.ListGrade) {
                            gradeList = this.props.ListGrade;
                        }
                        return {
                            title: detail.event_desc,
                            start: startDate,
                            end: endDate,
                            start_date: detail.start_date,
                            end_date: detail.end_date,
                            allDay: true,
                            color: detail.is_working_day ? '#809fff' : '#ff9999',
                            grade_id: detail.grade_list,
                            event_type:detail.event_type,
                            uid:detail.uid,
                            is_working_day:detail.is_working_day,
                            selectAll: gradeIds.length == gradeList.length
                        };
                    });
    
                    this.setState({ events: events });
                } else {
                    this.setState({ events: [] });
                }
            })}) 
    } 
}

    getSchoolCalendarByMonthYear(getYear?: any, selectedClassId?: any, month?:any, year?:any) {
    if(this.state.schoolAdmin){
    const postData = {
        academic_year: getYear,
        month: month,
        year: year
    }
        this.props.GetAllSchoolCalendar(postData).then((res: any) => {
        const { schoolcalendarData } = this.props;
        if (schoolcalendarData && schoolcalendarData?.data) {
            const events = schoolcalendarData.data.map((detail: any) => {
                const startDate = new Date(detail.start_date)
                const endDate = new Date(detail.end_date)
                //const gradeIds = detail.grades.split(",").map(Number);
                const gradeIds = detail.grade_list
                let gradeList: any = [];
                if (this.props.ListGrade) {
                    gradeList = this.props.ListGrade;
                }
                return {
                    title: detail.event_desc,
                    start: startDate,
                    end: endDate,
                    start_date: detail.start_date,
                    end_date: detail.end_date,
                    allDay: true,
                    color: detail.is_working_day ? '#809fff' : '#ff9999',
                    grade_id: detail.grade_list,
                    event_type:detail.event_type,
                    uid:detail.uid,
                    is_working_day:detail.is_working_day,
                    selectAll: gradeIds.length == gradeList.length
                };
            });

            this.setState({ events: events });
        } else {
            this.setState({ events: [] });
        }
    }) 
} 
    else {
        
            const postData = {
                academic_year: getYear,
                grade: selectedClassId,
                month: month,
                year: year
            }
            this.props.GetAllSchoolCalendar(postData).then((res: any) => {

            const { schoolcalendarData } = this.props;
            if (schoolcalendarData && schoolcalendarData?.data) {
                const events = schoolcalendarData.data.map((detail: any) => {
                    const startDate = new Date(detail.start_date)
                    const endDate = new Date(detail.end_date)
                    //const gradeIds = detail.grades.split(",").map(Number);
                    const gradeIds = detail.grade_list
                    let gradeList: any = [];
                    if (this.props.ListGrade) {
                        gradeList = this.props.ListGrade;
                    }
                    return {
                        title: detail.event_desc,
                        start: startDate,
                        end: endDate,
                        start_date: detail.start_date,
                        end_date: detail.end_date,
                        allDay: true,
                        color: detail.is_working_day ? '#809fff' : '#ff9999',
                        grade_id: detail.grade_list,
                        event_type:detail.event_type,
                        uid:detail.uid,
                        is_working_day:detail.is_working_day,
                        selectAll: gradeIds.length == gradeList.length
                    };
                });

                this.setState({ events: events });
            } else {
                this.setState({ events: [] });
            }
        }) 
} 
}


    checkUserType() {
        const { loginProfile } = this.props;
        const getToken:any = String(loginProfile.usertype);
        if(getToken){
            if(getToken === UserRoles.teacher){
                this.setState({ teacher: true, schoolAdmin: false},() => {
                    this.getCurrectYear();
                });
                
            }
            else if(getToken === UserRoles.schoolAdmin){
                this.setState({ schoolAdmin: true, teacher: false},() => {
                    this.getCurrectYear();
                })
            }
        }
    }

    handleClassChange = (event:any) => {
        const selectedClassId = event.target.value;
        this.setState({ selectedClass: selectedClassId});
        console.log(selectedClassId)
        const {selectedMonth,selectedYear} = this.state;
        this.getSchoolCalendarByMonthYear(this.props.getYear, selectedClassId,selectedMonth,selectedYear);
    }

    handleNavigate = (date:any) => {
        const month = date.getMonth()+1;
        const year = date.getFullYear();        
        this.setState({ selectedMonth: month, selectedYear: year});
        const getYear = this.props.getYear;
        const classId = this.state.selectedClass;
        
        this.getSchoolCalendarByMonthYear(getYear,classId,month,year);
    }

    handleSelect = ({ start, end }: { start: Date; end: Date }) => {
        const adjustedEndDate = new Date(end.getTime() - 1);
    
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(adjustedEndDate).format('YYYY-MM-DD');
    
        this.setState({ modalShow: true, isEditing: false, editingData: [], start_date: formattedStartDate, end_date: formattedEndDate });
    };
    

    handleFormSubmit = (data: any) => {
        const { isEditing,editingData,academicYear } = this.state
        const grade_ids = data.grade.map((teacher: any) => teacher.id);
        const postData = {
            academic_year: academicYear,
            event_desc: data.event_desc,
            "start_date": data.start_date,
            "end_date": data.end_date,
            "event_type": data.event_type,
            "is_working_day": data.is_working_day ? data.is_working_day :  false,
            "grade_list": grade_ids,
            ...(isEditing && { calendar_id: editingData.uid }) 
        }
        if(isEditing){
            this.props.EditSchoolCalendar(postData).then((res: any) => {
                this.getSchoolCalendar(academicYear)
            })
        }else{
            this.props.CreateSchoolCalendar(postData).then((res: any) => {
                this.getSchoolCalendar(academicYear)
            })
        }
        
        this.setState({ modalShow: false })
    }

    handleEventClick = (event: any) => {
        this.setState({ modalShow: true, isEditing: true, editingData: event })
    };
    onCancel = () => {
        this.setState({ modalShow: false })
    }

    onDelete = () => {
        const { editingData, academicYear } = this.state
        const deleteData = {
            academic_year: academicYear,
            calendar_id:editingData.uid
        }
        this.props.deleteSchoolCalendar(deleteData).then((res: any) => {
            this.getSchoolCalendar(academicYear)
        })
        this.setState({ modalShow: false, isEditing: false })
    }

    eventStyleGetter = (event: any) => {
        const backgroundColor = event.color || "#3174ad"; // Default color if not specified
        return {
            style: { backgroundColor }
        };
    };

    render() {
        const { modalShow, editingData, isEditing, start_date, end_date, teacher, classList } = this.state

        const { loading } = this.props;
        const loadingCalendar = { display: loading ? "block" : "none" }; 

        let gradeList: any;
        if (this.props.ListGrade) {
            gradeList = this.props.ListGrade;
        }

        let eventTypes = [{
            name: 'Holiday'
        }, {
            name: 'School Event'
        }, {
            name: 'Special Class'
        }]
        return (
            <div>
                {!this.state.teacher && (
                <SchoolCalendarModel
                    showModal={modalShow}
                    handleFormSubmit={this.handleFormSubmit}
                    onCancel={this.onCancel}
                    editingData={editingData}
                    gradeList={gradeList}
                    eventTypes={eventTypes}
                    editing={isEditing}
                    onDelete={this.onDelete}
                    start_date={start_date}
                    end_date={end_date}
                />
                )}
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['School Calendar']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['School Calendar']}
                            />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-topline-red">
                                        <div className="card-head">
                                            <header>
                                                {this.state.teacher == true ? (
                                                    <div className='d-flex justify-content-between'>
                                                    <div className='mr-3'>
                                                        {classList && classList.length!==0?                                     
                                                            <select name="classlist" className="form-control" onChange={this.handleClassChange}>
                                                                {classList.map((items: any) => (
                                                                    <option value={items.grade_id}> {items.grade_standard} </option>
                                                                ))}
                                                            </select>
                                                            :null}
                                                    </div>
                                                </div>
                                                ) : (
                                                    <Link to={SchoolCalendar.UploadSchoolCalendar} className="btn btn-pink ml-2 mt-1">
                                                    Upload Event
                                                </Link>
                                                )}                                               
                                            </header>
                                            </div>
                                        <div className="card-body no-padding height-9">
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <Calendar
                                                        localizer={localizer}
                                                        events={this.state.events}
                                                        startAccessor="start"
                                                        endAccessor="end"
                                                        style={{ height: 700 }}
                                                        selectable
                                                        onSelectSlot={this.handleSelect}
                                                        onSelectEvent={(event) => this.handleEventClick(event)}
                                                        onNavigate={this.handleNavigate}
                                                        eventPropGetter={this.eventStyleGetter}
                                                        views={["day", "month"]}
                                                        formats={{
                                                            dateFormat: (date:any) => {
                                                                return date.getDate().toString(); 
                                                            },
                                                            weekdayFormat: (date:any) => date.toLocaleString('en-US', { weekday: 'long' })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingCalendar}><SpinnerLoader/></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.schools.loading,
        DairyLoading: state.diary.loading,
        studentLoading: state.student.loading,
        getYear: state.profile.getYear,
        stopYearDate: state.profile.yearStatusFails,
        getAllClassList: state.diary.gradelist,
        AllStudentList: state.student.studentList,
        getStudentNextClass: state.student.studentNextClassDetail,
        schoolcalendarData: state.schools.schoolcalendarData,
        ListGrade: state.classes.gradelist,
        loginProfile:state.profile.profileData,
    }
}

const mapDispatchToProps = {
    fetchGetAllClassList,
    stopYearData,
    GetAllSchoolCalendar,
    CreateSchoolCalendar,
    EditSchoolCalendar,
    deleteSchoolCalendar,
    schoolCalendarDownloadExcelGet,
    fetchGradePost,
    fetchMyClassListGet
}

export default connect(mapStateToProps, mapDispatchToProps)(schoolCalendar)