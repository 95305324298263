import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommonLoader from '../../components/CommonLoader';
import { fetchDownloadQuestionPaperGet, deleteQuestionPaper } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { CommonModel } from "../../components/CommonModel";
import { RowPerPage, RowsPerPageOptions, formValidationPatten } from "../../services/Constants";
import { fetchAssesment, deleteAssessmentById } from "../../store/assessment/Action";
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export type Props = {
    loading?: boolean
    records?: any
    page: number
    per_page: number
    total: number
    totalPage: number
    downloadQuestionPaper?: any;
    history?: any;
    fetchAssesment: (data: any) => any;
    fetchDownloadQuestionPaperGet: (data: any) => any;
    deleteAssessmentById: (id: number) => any
}

interface StateTypes {
    records: any;
    search: string,
    hasMore: boolean,
    page: any,
    page_no: any,
    SortOrderData: string,
    OrderNameData: string,
    showDelete: boolean,
    deleteId: any,
    question_paper_id: any,
    ascOrder?: boolean,
    academicYear?: any,
    sort_by?: string,
    sort_class_name?: string,
    sort_title_name?: string
    per_page?: any
}
export class AssessmentGrid extends Component<Props, StateTypes> {

    constructor(props: any) {
        super(props);
        this.state = {
            records: [],
            search: '',
            hasMore: true,
            page: 1,
            page_no: 1,
            SortOrderData: '',
            OrderNameData: '',
            showDelete: false,
            deleteId:'',
            question_paper_id: '',
            per_page: RowPerPage,
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        if(this.props.history.location.state){
            localStorage.setItem('assessmentDetails', JSON.stringify(this.props.history.location.state))
            this.setState({question_paper_id: this.props.history.location.state.uid})
            this.getQuestionPaperDetails(this.props.history.location.state.uid)
        } else if(localStorage.getItem('assessmentDetails')){
            const savedAssessmentDetails = localStorage.getItem('assessmentDetails');
            if(savedAssessmentDetails){
                const assessmentDetails = JSON.parse(savedAssessmentDetails);
                this.setState({question_paper_id: assessmentDetails.uid})
                this.getQuestionPaperDetails(assessmentDetails.uid)
            }
        }
    }

    getQuestionPaperDetails(uid: any) {
        const params = {
          page_no: 1,
          per_page: this.state.per_page,
          search: this.state.search,
          order_by: this.state.OrderNameData,
          sort_by: this.state.SortOrderData,
          question_paper_id: uid,
        }
        this.props.fetchAssesment(params).then((res: any)=>{
            const { records } = this.props
            this.setState({ hasMore: true, page:1, records: records})
        })
    }

    showFilterData(getValues: any) {
        const { academicYear, page } = this.state;
        if (getValues.sort_by === 'desc') {
          this.setState({
            ascOrder: false,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by
          })
          const postValue = {
            page_no: 1,
            per_page: this.state.per_page,
            academic_year: academicYear,
            search: this.state.search,
            sort_by: getValues.sort_by,
            order_by: getValues.order_by,
            question_paper_id: this.state.question_paper_id
          }
          this.setState({
            hasMore: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
            page: 1,
            sort_by: 'asc',
            sort_class_name: 'fa fa-sort-amount-desc',
            sort_title_name: 'Descending order'
          })
          this.props.fetchAssesment(postValue).then((res: any) => {
            
          });
        } else {
          this.setState({
            ascOrder: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
          })
          const postValue = {
            page_no: 1,
            per_page: this.state.per_page,
            academic_year: academicYear,
            search: this.state.search,
            sort_by: getValues.sort_by,
            order_by: getValues.order_by,
            question_paper_id: this.state.question_paper_id
          }
          this.setState({
            hasMore: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
            page: 1,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order'
          })
          this.props.fetchAssesment(postValue).then((res: any) => {
            
          });
        }
      }


    public fetchMoreStudentData = (newPage?:any,numberOfRows?:any) => {
        const { page } = this.state;
        if (this.state.hasMore === true) {
            if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
                const params = {
                    page_no:newPage,
                    per_page: numberOfRows,
                    search: this.state.search,
                    order_by: this.state.OrderNameData,
                    sort_by: this.state.SortOrderData,
                    question_paper_id: this.state.question_paper_id
                }
                this.props.fetchAssesment(params).then((res: any)=>{
                    // this.setState({ page: page + 1 })
                })
            }  
            if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
                this.setState({
                  hasMore: false,
                })
            }
        }
    }

    downloadQuestionPaper (id: any) {
        if(id){
            const params = {
                question_paper_id : id
            }
            this.props.fetchDownloadQuestionPaperGet(params).then((res:any)=>{
                const { downloadQuestionPaper } = this.props;
            })
        }
    }

    getMuiTheme = () => createTheme({
        components: {
          MuiPopover: {
            styleOverrides: {
              paper: {
                padding: "12px"
              }
            }
          }      
        }
      })

    private renderBluePrint(records: any) {
        const { loading,total } = this.props;
        const { per_page} = this.state;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const columns = [
            {
              name: "uid",
              label: "Id",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "class_name",
              label: "Grade",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "student_name",
              label: "Name",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "secured_marks",
              label: "Marks",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "uid",
              label: "Edit",
              options: {
                sort: false,
                download: false,
                customBodyRender: (uid:any,secured_marks:any) => (
                  <>
                    {uid ? (
                        <Link to={{pathname:`/assessment/edit/${uid}`,
                            state: { uid : uid, totalmarks: Number(secured_marks)}}}>
                        <button className="btn btn-primary btn-xs"
                            title="View"><i className="fa fa-pencil" aria-hidden="true"></i></button></Link> 
                    ) : (
                        <button className="btn btn-disable btn-xs"
                            title="View"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                    )}
                  </>
                ),
              },
            },
            {
                name:"uid",
                label:"Delete",
                options: {
                    sort: false,
                    download: false,
                    customBodyRender: (uid:any) => (
                        <>
                            {uid ? (
                                <button className="btn btn-primary btn-xs" title="Delete" onClick={() => this.showDeletePopup(uid)}>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                ) : (
                                <button className="btn btn-disable btn-xs" title="Delete" disabled>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            )}
                        </>
                    )
                }
            }
          ];
          
  
      const options = {
        rowsPerPage: per_page,
        rowsPerPageOptions: RowsPerPageOptions,
        count: total,
        selectableRows: false,
        page:this.state.page - 1,
        filter:false,
        print:false,
        downloadOptions:{
          filename: "Assessments.csv"
        },
        onChangePage: (newPage:any) => {
            this.setState({ page: newPage +1 }); 
            this.fetchMoreStudentData(newPage + 1,this.state.per_page);
        },
        onColumnSortChange:(changedColumn:any, direction:any) =>{
            const sortData = {
                sort_by: changedColumn,
                order_by: direction
            }
            this.showFilterData(sortData);
        },
        onChangeRowsPerPage: (numberOfRows:any) => {
            const page = 1
            this.setState({ per_page: numberOfRows,page:page });
            this.fetchMoreStudentData(page,numberOfRows);
        },
        onSearchChange: (searchText:any, row:any) => {
            this.handleInputChange({ target: { value: searchText } });
        }
    };
        return (
            <div>
                <ThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                data={records}
                columns={columns}
                options={options}
                />
                </ThemeProvider>
            </div>
        )
    }

    showDeletePopup (getData: any) {
        if(getData){
            this.setState({ showDelete: true, deleteId: getData});
        }
    }

    public hideQuestionPaperDelete = () => {
        this.setState({ showDelete: false });
    }

    public handleDelete = () => {
        this.setState({ showDelete: false, page: 1, hasMore: true });
        this.props.deleteAssessmentById(this.state.deleteId).then((res:any)=>{
            this.getQuestionPaperDetails(this.state.question_paper_id)
        })
    }

    handleInputChange = (e: any) => {
        const { value } = e.target;
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
           if (getSearchValue) {
          const postSearchValue:any = {
            page_no: 1,
            per_page: this.state.per_page,
            search: getSearchValue,
            question_paper_id: this.state.question_paper_id,
          }
          this.props.fetchAssesment(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: getSearchValue
          })
        } else {
          const postSearchValue:any = {
            page_no: 1,
            per_page: this.state.per_page,
            search: '',
            question_paper_id: this.state.question_paper_id,
          }      
          this.props.fetchAssesment(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: ''
          })
        }}
    }

    refreshQuestionBankTable = () =>{
        const postSearchValue:any = {
            page_no: 1,
            search: '',
            question_paper_id: this.state.question_paper_id
        }
        this.setState({
          search: '',
          SortOrderData:'',
          OrderNameData:'',
        }, () => {
            this.props.fetchAssesment(postSearchValue)
        });  
    }

    render() {
        const { loading, records} = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const { showDelete } = this.state;
        const postValue:any = {
            title:'Please Confirm',
            action_status:'delete',
            action_name:'Assessment'
        }
        return (
            <div className="page-wrapper">
                 <CommonModel
                    onState={showDelete}
                    currentState={postValue}
                    onConfirm={this.handleDelete}
                    onCancel={this.hideQuestionPaperDelete}
                />
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Assessment']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Assessment']} />

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-topline-red">
                                    <div className="card-head">
                                    <header>
                                        <Link to={QuestionBank.AssessmentCreate} className="ml-2 mt-1">
                                            <button className="btn btn-pink">Create Assessment</button>
                                        </Link>
                                    </header>
                                </div>
                                    <div className="card-body no-padding height-9">
                                        <div className="row">
                                            <div className="table-responsive">
                                                {this.renderBluePrint(records)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.Assessment.loading,
        records: state.Assessment.records,
        page: state.Assessment.page,
        per_page: state.Assessment.per_page,
        total: state.Assessment.total,
        totalPage: state.Assessment.totalPage,
        downloadQuestionPaper: state.QuestionBank.downloadQuestionPaper
    }
}

export default connect(mapStateToProps, {
    fetchAssesment,
    fetchDownloadQuestionPaperGet,
    deleteAssessmentById
})(AssessmentGrid)