import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import Switch from '@material-ui/core/Switch';
import { fetchTeachersPost, deletePost } from '../../store/teacher/Actions';
import { LoadMoreType, CommonState } from '../../components/type';
import CommonLoader from '../../components/CommonLoader';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { FormControlLabel } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formValidationPatten } from '../../services/Constants';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsTeacherProps {
    getYear?:any;
    fetchTeachersPost: (getData: LoadMoreType) => any;
    deletePost: (studentDetailDelete: any) => any;
    stopYearData:()=>any;
    loading: boolean
    deleteDetails: any;
    page: number;
    per_page: number;
    totalPage: number;
    records: any;
    total: number;
    stopYear?:any;
}
export class TeacherView extends React.Component<PostsTeacherProps, CommonState> {
    constructor(props: any) {
        super(props);
        this.state = {
          show: false,
          showDelete: false,
          addShow: false,
          acadamicAdmin: false,
          schoolAdmin: false,
          bothData: false,
          teacher: false,
          parent: false,
          deleteTeacherId: [],
          TeacherData: [],
          hasMore: true,
          prev: 0,
          next: 10,
          acsOrder: true,
          page: 1,
          search: '',
          SortOrderData: '',
          OrderNameData: '',
          role:'Teacher',
          postData:{},
          academicYear:null
        };
        window.scrollTo(0, 0);
      }
      public handleDelete = () => {
        this.setState({ showDelete: false, hasMore: true,  page: 1 });
        this.props.deletePost(this.state.deleteTeacherId).then((res:any)=>{
          const {academicYear} = this.state;
          // this.setState({ showDelete: false, hasMore: true,  page: 1 });
          this.UpdateYearDate(academicYear)
        })
      }
      public hideTeacherDelete = () => {
        this.setState({ showDelete: false, hasMore: true });
      }
      public showTeacherDelete = (getData: any) => {
        let postValue:any
        let titleClassMessage: any;
        if (getData.isActive === true) {
          titleClassMessage = 'Activate';
        } else {
          titleClassMessage = 'Deactivate';
        }
        if(getData && titleClassMessage){
          postValue = {
            title:'Please Confirm',
            action_status:titleClassMessage,
            action_name:'Teacher'
          }
          this.setState({ showDelete: true, deleteTeacherId: getData, postData:postValue });
        }
      }

    componentDidMount(): void {
      window.scrollTo(0, 0);
      this.UpdateYearDate()
      }
      componentWillUpdate(nextProps:any, nextState:any) {
        const {stopYear} = this.props;
        if(stopYear === true){
          this.checkDeleteStatus();
          this.UpdateYearDate(this.props.getYear)
        }
      }
      componentDidUpdate() {
        const {stopYear} = this.props;
        if(stopYear === true){
          this.props.stopYearData()
        }
      }
        UpdateYearDate(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.setState({academicYear:getYear})
            this.getTeacherRecord(getYear)
        }else {
            this.setState({academicYear:this.props.getYear})
            this.getTeacherRecord(this.props.getYear)
        }
        }
      getTeacherRecord(getYear?:any){
        const postValue:any = {
          page_no: 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          role: this.state.role,
          academic_year:getYear
        }
        this.props.fetchTeachersPost(postValue).then((res:any)=>{
          window.scrollTo(0, 0);
          this.setState({hasMore:true, page:1})
        });
      }
      checkDeleteStatus(){
        const selectStudentDataList = this.props.deleteDetails;
        const {academicYear} = this.state
        if (selectStudentDataList === true) {
          const getPostStudent:any = {
            page_no: 1,
            search: this.state.search,
            sort_by: this.state.SortOrderData,
            order_by: this.state.OrderNameData,
            role: this.state.role,
            academic_year:academicYear
          }
          this.props.fetchTeachersPost(getPostStudent).then((res:any)=>{
            window.scrollTo(0, 0);
            this.setState({hasMore:true, page:1})
          });
        }
      }
      handleInputTeacherChange = (e: any) => {
        const { value } = e.target;
        const {academicYear} = this.state
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
          if (getSearchValue) {
            const postSearchValue:any = { 
              page_no: 1, search: getSearchValue, sort_by: this.state.SortOrderData,
              order_by: this.state.OrderNameData, role: this.state.role,
              academic_year:academicYear
            }
            this.props.fetchTeachersPost(postSearchValue);
            this.setState({
              hasMore: true, page: 1, search: getSearchValue
            })
          } else {
            const postSearchValue:any = {
              page_no: 1, 
              search: '',
              sort_by: this.state.SortOrderData,
              order_by: this.state.OrderNameData,
              role: this.state.role,
              academic_year:academicYear
            }      
            this.props.fetchTeachersPost(postSearchValue);
            this.setState({
              hasMore: true,
              page: 1,
              search: ''
            })
          }
        }
       
      }
      public fetchMoreStudentData = () => {
        const {academicYear} = this.state
        if (this.state.hasMore === true) {
          if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
            const postValue:any = {
              page_no:  this.state.page + 1,
              academic_year:academicYear,
              class_id:this.state.class_id,
              sort_by: this.state.SortOrderData,
              order_by: this.state.OrderNameData,
              search:this.state.search,
              role: this.state.role
            }
            this.props.fetchTeachersPost(postValue);
            this.setState({
              page: this.state.page + 1
            })
          }
    
          if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
            this.setState({
              hasMore: false,
            })
          }
        }
      }
      // This is the functio use to filter grit data
  public showFilterTeacher = (getdata: any) => {
    const {academicYear} = this.state
    if (getdata.sort_by === 'desc') {
      this.setState({ 
        acsOrder: false, 
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by
      })
      const postValue:any = {
        page_no: 1,
        academic_year:academicYear,
        search: this.state.search,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        role: this.state.role
      }
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1
      })
      this.props.fetchTeachersPost(postValue);
    } else {
      this.setState({ 
        acsOrder: true, 
        SortOrderData: getdata.sort_by, 
        OrderNameData: getdata.order_by, 
      })
      const postValue:any = {
        page_no: 1,
        search: this.state.search,
        academic_year:academicYear,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        role: this.state.role
      }
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1
      })
      this.props.fetchTeachersPost(postValue);
    }
  }
  //in your component
addDefaultSrc= (ev:any) =>{
  ev.target.src = 'assets/img/user/teacher-profile.jpg'
}
    render() {
        const getTeacherList = this.props.records;
        const { showDelete, postData } = this.state;
        const { loading } = this.props;
        const loadingTeacherFull = { display: loading ? "block" : "none" };
        return (
            <div>
                <CommonModel
                onState={showDelete}
                currentState={postData}
                onConfirm={this.handleDelete}
                onCancel={this.hideTeacherDelete}
                />
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Teachers']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Teachers']} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="">
                                      <div className="row">
                                      <div className="col-md-10"></div>
                                          <div className="col-md-2 col-sm-2 col-xs-12 mb-2">
                                            <h4 className="pull-right">Total: {this.props.total}</h4>
                                          </div>
                                      </div>
                                        <div className="row mb-4">
                                            <div className="col-md-4 col-sm-4 col-xs-12 mb-3">
                                                <header>
                                                    <Link to={'/teacher_add'}>
                                                        <button className="btn btn-pink">Add Teacher</button>
                                                    </Link>
                                                    {this.state.acsOrder ?
                                            <button onClick={() => this.showFilterTeacher({ sort_by: 'desc', order_by: 'firstname' })}
                                              title="Ascending order" className="btn btn-default ml-2">Teacher Name&nbsp;
                                              <i className="fa fa-sort-amount-asc" aria-hidden="true"></i></button> : <button
                                              onClick={() => this.showFilterTeacher({ sort_by: 'asc', order_by: 'firstname' })}
                                              title="Descending order" className="btn btn-default ml-2">
                                              Teacher Name <i className="fa fa-sort-amount-desc" aria-hidden="true"></i></button>}
                                                </header>
                                            </div>
                                            <div className="col-md-6 text-right">                                            
                                            </div>
                                            <div className="col-md-2">
                                                <div className="tools">
                                                        <input
                                                            placeholder="Search"
                                                            name="teacher"
                                                            className="form-control"
                                                            onChange={this.handleInputTeacherChange}
                                                        />
                                                </div>                                           
                                            </div>
                                        </div>
                                        <InfiniteScroll
                                          dataLength={this.props.records.length}
                                          next={this.fetchMoreStudentData}
                                          hasMore={this.state.hasMore}
                                          loader={<h4 style={loadingTeacherFull}>Loading...</h4>}
                                        >
                                        {getTeacherList.length > 0 ?
                                        <div className="row mt-5 w-100">
                                        { getTeacherList.map((items:any, index:any) => (
                                                <div className="col-md-3">
                                                    <div className="card">
                                                        <div className="m-b-20">
                                                            <div className="doctor-profile">
                                                                <div id="studentList" className='profile-header bg-b-purple'>
                                                                <div className="pull-right">  
                                                                </div>
                                                                </div>
                                                                {items.image ? 
                    
                                                                <img onError={this.addDefaultSrc} src={items.image}
                                                                className="user-img mb-2" alt="Student Profile" />
                                                                : <img src="assets/img/user/teacher-profile.jpg" className="user-img mb-2" alt="" />} 
                                                                <div>
                                                                <div className="user-name" title={`${items.firstname}${items.lastname}`}>{items.firstname} {items.lastname}</div>
                                                                    <p>
                                                                        <i className="fa fa-phone"></i> &nbsp; 
                                                                         {items.phone_number}
                                                                    </p>
                                                                    <p className='user-email' title={`${items.email_id}`}>
                                                                    <i className="fa fa-envelope-o" aria-hidden="true"></i> &nbsp;
                                                                        {items.email_id}
                                                                    </p>
                                                                </div>
                                                                <div className="profile-userbuttons">
                                                                  {items.is_active ?
                                                                <Link to={`/teacher_edit/${items.ldap_id}`} className="btn btn-circle btn-default btn-sm" title="Edit Teacher"><i
                                                                className="fa fa-pencil-square-o" aria-hidden="true"></i></Link>
                                                              :<Link to={'#'} className="btn btn-circle btn-disable btn-sm" title="Edit Teacher"><i
                                                              className="fa fa-pencil-square-o" aria-hidden="true"></i></Link>}
                                                                <Link to={`/teacher/${items.ldap_id}`}
                                                                className="btn btn-circle btn-pink btn-sm" title="View Teacher"
                                                                ><i className="fa fa-eye"
                                                                    aria-hidden="true"></i></Link>
                                                            </div>
                                                            <div className="pull-right">                  
                                                                    {items.is_active ? <FormControlLabel
                                                                      value="start"
                                                                      control={<Switch
                                                                        checked={items.is_active}
                                                                        onClick={() => this.showTeacherDelete({ ldapId: String(items.ldap_id), isActive: false })}
                                                                        name="checkedA"
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                        title="Deactivate Teacher" 
                                                                      />}
                                                                      className="mr-2 mt-2"
                                                                      label="Active"
                                                                      labelPlacement="start"
                                                                    /> : 
                                                                    <FormControlLabel
                                                                      value="end"
                                                                      control={<Switch
                                                                        checked={items.is_active}
                                                                        onClick={() => this.showTeacherDelete({ ldapId: String(items.ldap_id), isActive: true })}
                                                                        name="checkedA"
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                        title="Activate Teacher" 
                                                                      />}
                                                                      className="mr-3 mt-2"
                                                                      label="Inactive"
                                                                      labelPlacement="end"
                                                                    />
                                                                    }
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                        ))}  
                                        </div>
                                        : <CommonLoader/>}
                                  </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTeacherFull}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        getYear:state.profile.getYear,
        stopYear:state.profile.yearStatusFails,
        loading: state.teacher.loading,
        userDetails: state.teacher.items,
        total: state.teacher.total,
        per_page: state.teacher.per_page,
        records: state.teacher.records,
        page: state.teacher.page,
        totalPage: state.teacher.totalPage,
        deleteDetails: state.teacher.isAuthenticated
      };
}
export default connect(mapStateToProps, { fetchTeachersPost, deletePost, stopYearData })(TeacherView)
