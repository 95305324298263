import React, { Component } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { Link } from "react-router-dom";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { addControlPanelget } from "../../store/HomeStudy/Actions";
import { internalGradleGet, fetchBluePrintQuestionTypeDropdownGet, fetchBluePrintDifficultyLevelDropdownGet, fetchBluePrintQuestionCategoryDropdownGet } from "../../store/QuestionBank/Actions";
import { connect } from "react-redux";
import { stopYearData } from "../../store/profile/Actions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { HomeStudySchema } from "./HomeStudyValidation"; 

export type Props = {
    loading: boolean;
    getChangeYearData?: any;
    stopYearDate?: any;
    getInternalGradle?: any;
    bluePrintQuestionTypeDropdown?: any;
    bluePrintDifficultyLevelDropdown?: any;
    bluePrintQuestionCategoryDropdown?:any;
    stopYearData: () => any;
    internalGradleGet: () => any;
    addControlPanelget: (data: any)  => any;
    fetchBluePrintQuestionTypeDropdownGet: (data: any) => any;
    fetchBluePrintDifficultyLevelDropdownGet: (date: any) => any;
    fetchBluePrintQuestionCategoryDropdownGet: () => any;
}

interface StateTypes {
    academicYear: any;
    internalGradle: any;
    grade?: any;
    grade_id?: any;
    question_type?: any;
    question_type_marks?: any;
    question_category?:any;
    difficulty_level?: any;
    autocompleteValues?: any;
}

const initialValues = {    
    grade_id: "",
    controlpanel_details :[
        {
            question_type: "",
            question_type_marks: "",
            question_category: "",            
            difficulty_level: "",
            no_of_question: "", 
        },
    ],       
};

export class AddControlPanel extends Component<Props, StateTypes>{
    formik:any;
    constructor(props: any){
        super(props);
        this.state = {
            academicYear: null,
            internalGradle:[],
            grade: [],
            grade_id: "",
            question_type: [],
            question_type_marks: [],
            question_category:[],
            difficulty_level: [],
            autocompleteValues: {
                grade_id: null
            }
        }
    }

    componentDidMount(): void {
        window.scrollTo(0,0);
        this.getCurrectYear();
        this.getInternalGradleList();
    }

    getInternalGradleList() {
        this.props.internalGradleGet().then((res: any)=>{
            const {getInternalGradle} = this.props
            this.setState({
                internalGradle: getInternalGradle
            })
        })
    }    

    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getChangeYearData);
        }
    }
    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData();
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getBluePrintdetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getBluePrintdetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getBluePrintdetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
        this.props.fetchBluePrintQuestionTypeDropdownGet(getData).then((res: any) => {
            const { bluePrintQuestionTypeDropdown } = this.props;
            this.setState({ question_type: bluePrintQuestionTypeDropdown });
          });
          this.props.fetchBluePrintDifficultyLevelDropdownGet(getData).then((res: any) => {
            const { bluePrintDifficultyLevelDropdown } = this.props;
            this.setState({ difficulty_level: bluePrintDifficultyLevelDropdown });
        });
        this.props.fetchBluePrintQuestionCategoryDropdownGet().then((res: any) => {
            const { bluePrintQuestionCategoryDropdown } = this.props;
            this.setState({ question_category: bluePrintQuestionCategoryDropdown });
        });       
    }

    getMarkOptions = (typeName: any) => {
        const { question_type } = this.state;
        const questionType = question_type.find((type: any) => type.id === typeName);
        return questionType ? questionType.marks : [];
    };

    handleAutocompleteChange (key:any, newValue:any) {
        this.setState((prevState) => ({
            autocompleteValues: {
            ...prevState.autocompleteValues,
            [key]: newValue ? newValue : ''
          }
        }));
    };
    
   render(){
    const {internalGradle, grade_id, question_type, question_type_marks,difficulty_level, autocompleteValues, question_category} = this.state;
    const { loading } = this.props;
    const loadingQuestionFull = {display: loading ? "block": "none"};

    return(
        <div className="page-wrapper">
            <div className="page-content-wrapper">
                <div className="page-content pt-3">
                    <BreadCrumb
                        titleName={['Create Control Panel']}
                        homeName={['Home']}
                        url={['Dashboard']}
                        mainPageTitle={['Create Control Panel']} />
                    <div className="row">
                        <div className="col-md-12">
                            <Formik
                                ref={node => this.formik = node}
                                initialValues={initialValues}
                                validationSchema={HomeStudySchema}
                                validate={(values) => {
                                    const errors: { [key: string]: any } = {};
                                        return errors;
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    const getControlPanelValue: any ={
                                        grade_id: parseInt(values.grade_id),
                                        controlpanel_details:values.controlpanel_details.map((item:any)=>({
                                            "question_type_id": item.question_type,
                                            "question_type_marks": item.question_type_marks,
                                            "category_id": item.question_category,
                                            "difficulty_level_id": item.difficulty_level,
                                            "no_of_question": parseInt(item.no_of_question)
                                        }))
                                    };
                                    this.props.addControlPanelget(getControlPanelValue).then((res: any)=>{

                                    })
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    isSubmitting,
                                    setFieldValue,
                                    handleChange
                                }) => {
                                    const controlPanelDetailsIsEmpty = values.controlpanel_details.length === 0;
                                    return(
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Autocomplete
                                                                fullWidth
                                                                id="checkboxes-tags-demo"
                                                                options={internalGradle}
                                                                noOptionsText={'No matching records found.'}
                                                                value={autocompleteValues.grade_id}
                                                                getOptionLabel={(option) => option ? option.grade : ''}
                                                                onChange={(e, value:any, resonse) => {
                                                                this.handleAutocompleteChange('grade_id',value)                                                                                        
                                                                setFieldValue('grade_id', value ? value.id : '');                                                                                        
                                                                }}
                                                                onInputChange={(event, newInputValue) => {
                                                                if (!newInputValue) {
                                                                    this.handleAutocompleteChange('grade_id','')                                                                                                                                                                                       
                                                                    }
                                                                }}
                                                                disableClearable
                                                                className="mb-0 mt-1"
                                                                renderInput={(params) => (
                                                                    <Field
                                                                        component={TextField}
                                                                            name="grade_id"
                                                                            {...params} label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>}
                                                                        />
                                                                )}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <FieldArray
                                                        name="controlpanel_details"
                                                        render={({ insert, remove, push}) =>(
                                                            <>
                                                          {values.controlpanel_details.length > 0 && 
                                                                values.controlpanel_details.map(
                                                                    (controlpanel:any, index: any) => (
                                                                        <div className="row" key={index}>
                                                                            <div className="col-md-12">
                                                                                <div className="card-box mt-4">
                                                                                    <div className="card-body pb-5 row">
                                                                                        <div className="col-md-12 text-right">
                                                                                            <button className="" type="button" title="Delete Question" onClick={() =>{
                                                                                                remove(index)
                                                                                            }}>
                                                                                                <span className="deleteIcon"><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <FormGroup className="w-100">
                                                                                                <Field
                                                                                                label={<><span>Question Type</span><span className="formmandatorycolor"> *</span></>}                                                                                                
                                                                                                name={`controlpanel_details.${index}.question_type`}
                                                                                                select
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                disabled={false}
                                                                                                onChange={(e:any) => {
                                                                                                    setFieldValue(`controlpanel_details.${index}.question_type_marks`, '');                                                                                                    
                                                                                                    setFieldValue(`controlpanel_details.${index}.question_type`, e.target.value);                                                                                                    
                                                                                                }}
                                                                                                >
                                                                                                    {question_type && question_type.length > 0 && question_type.map((item: any) => (
                                                                                                                <MenuItem value={item.id} >
                                                                                                                    {item.name}
                                                                                                                </MenuItem>
                                                                                                            ))}

                                                                                                </Field>
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <FormGroup className="w-100">
                                                                                                <Field
                                                                                                label={<><span>Marks</span><span className="formmandatorycolor"> *</span></>} 
                                                                                                name={`controlpanel_details.${index}.question_type_marks`}     
                                                                                                select
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                onChange={(e:any)=> {
                                                                                                    setFieldValue(`controlpanel_details.${index}.question_type_marks`, e.target.value);
                                                                                                    handleChange(e);
                                                                                                }}        
                                                                                                >
                                                                                                    {this.getMarkOptions(controlpanel.question_type).map((item: any) => (

                                                                                                        <MenuItem value={item.value} >
                                                                                                            {item.label}
                                                                                                        </MenuItem>
                                                                                                        ))}
                                                                                                </Field>                                                                              
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <FormGroup className="w-100">
                                                                                                <Field
                                                                                                label={<><span>Question Category</span><span className="formmandatorycolor"> *</span></>}                                                                                                
                                                                                                name={`controlpanel_details.${index}.question_category`}
                                                                                                select
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                disabled={false}
                                                                                                onChange={(e:any) => {
                                                                                                    setFieldValue(`controlpanel_details.${index}.question_category`, e.target.value);                                                                                                    
                                                                                                }}
                                                                                                >
                                                                                                    {question_category && question_category.length > 0 && question_category.map((item: any) => (
                                                                                                                <MenuItem value={item.uid} >
                                                                                                                    {item.name}
                                                                                                                </MenuItem>
                                                                                                            ))}

                                                                                                </Field>
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <FormGroup className="w-100">
                                                                                                <Field
                                                                                                label={<><span>Difficulty Level</span><span className="formmandatorycolor"> *</span></>}
                                                                                                select
                                                                                                name={`controlpanel_details.${index}.difficulty_level`}
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                disabled={false}
                                                                                                >
                                                                                                    {difficulty_level && difficulty_level.length > 0 && difficulty_level.map((item: any) => (
                                                                                                                <MenuItem value={item.uid} >
                                                                                                                    {item.name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                </Field>
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <FormGroup className="w-100">
                                                                                                <Field
                                                                                                label={<><span>No. of Question</span><span className="formmandatorycolor"> *</span></>}
                                                                                                name={`controlpanel_details.${index}.no_of_question`}
                                                                                                type="text"
                                                                                                component={TextField}
                                                                                                className="textfield__input"
                                                                                                disabled={false}
                                                                                                onChange={(e:any) =>{
                                                                                                    setFieldValue(`controlpanel_details.${index}.no_of_question`, e.target.value);
                                                                                                    handleChange(e);
                                                                                                }}
                                                                                                ></Field>
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    </div> 
                                                                    )                                                                    
                                                                    )}                                                                    
                                                                <div className="col-md-12 text-center">
                                                                    <Link
                                                                        to={'#'}
                                                                        title="Add Question"
                                                                        className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                        data-upgraded=",MaterialButton"
                                                                        onClick={()=> 
                                                                            push({
                                                                                question_type: "",
                                                                                question_type_marks: "",
                                                                                question_category: "",
                                                                                difficulty_level: "",
                                                                                no_of_question: ""
                                                                                })}
                                                                        > <i className="material-icons">add</i>
                                                                    </Link>                                                                        
                                                                </div>
                                                            </>
                                                        )}                                                      
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-right mb-3 mr-2 mt-4">
                                                <ErrorMessage name="total_errors" component="div" className="errorcolor"/>
                                                <Button className="btn btn-pink mr-1 ml-1" type="submit" disabled={controlPanelDetailsIsEmpty}>Submit</Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div style={loadingQuestionFull}><SpinnerLoader /></div>
        </div>
    )
   }
}

const mapStateToProps = (state:any) => {
    return{
        loading: state.HomeStudy.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        getChangeYearData: state.profile.getYear,
        getInternalGradle: state.QuestionBank.getInternalGradle,
        bluePrintQuestionTypeDropdown: state.QuestionBank.bluePrintQuestionTypeDropdown,
        bluePrintDifficultyLevelDropdown: state.QuestionBank.bluePrintDifficultyLevelDropdown,
        bluePrintQuestionCategoryDropdown: state.QuestionBank.bluePrintQuestionCategoryDropdown,
    }
}

export default connect(mapStateToProps, {
    stopYearData,
    internalGradleGet,
    fetchBluePrintQuestionTypeDropdownGet,
    fetchBluePrintDifficultyLevelDropdownGet,
    fetchBluePrintQuestionCategoryDropdownGet,
    addControlPanelget
}) (AddControlPanel)