export interface TeacherType {
        id?:number;
        ldap_id?:string;
        firstname: string;
        lastname: string;
        email_id: string;
        phone_number: string;
        role: string;
        school_id?: string,
        timezone?: string,
        school_name?:string,
        image?:any,
        is_active?:any,
        date_of_birth?: string,
        gender?: string,
        nationality?: any,
        blood_group?: any,
        marital_status?: any,
        qualification?: any,
        address?: string,
        city?: any,
        state?: any,
        pincode?: string,
        country?: any,
        staffcode?: any,
        joining_date?: any,
        aadhar_card_id?: any,
        pan_card_id?: any,
        emergency_contact_name?: any,
        emergency_contact_relation?: any,
        emergency_contact_phone?: any,
        emergency_contact_email?: any,
        residential_address?: any,
        permanent_address?: any,
        emergency_contact_address?: any,

}
export interface TeacherTypes {
    [id: number]: TeacherType;
}
export interface TeacherState {
    items: TeacherTypes,
    loading: boolean,
    isAuthenticated: boolean | null,
    modelPop?:boolean,
    errors?:any;
    page: number,
    per_page: number,
    records: any,
    total: number,
    TeacherId:string,
    GetTeacherProfile?:any,
    getSubjectClass?:any,
    classPage: number,
    classPer_page: number,
    classRecords: any,
    classTotal: number,
    getSubjectMapping?:any,
    SubjectTotal?:any,
    classCount?:any,
    imageUpload?:any,
    getTeacherPerformanceCount?:any,
    getSubjectPerformance?:any,
    getLeastAttendees?:any,
    GetTeacherName?:any,
    RecordsTeacherAttendance?:any,
    PageTeacherAttendance?:any,
    PerPageTeacherAttendance?:any,
    TotalTeacherAttendance?:any,
    mappingClassIncharge?:boolean,
    Add_Mapping_Teacher_id?:string,
    RecordsLeastAttendees?:any,
    PageLeastAttendees?:any,
    PerPageLeastAttendees?:any,
    TotalLeastAttendees?:any,
    isLeastAttendeeLoader?:boolean
    GetTeacherActiveName?:any,
    GetNationality?: any,
    GetBloodGroup?: any,
    GetDegreeDropdownData?: any,
    GetMaritalStatus?: any,
    GetCityData?: any,
    GetCountryData?: any,
    GetStateData?: any
}

export interface teacherDetails {
    teacherfirstname: string,
    teacherlastname: string,
    teacheremailid: string,
    teacherphonenumber: string,
    teacherrole: string,
    school_id?: string,
    timezone?:string,
    image?:any,
    id?:number,
    ldap_id?:string,
    date_of_birth?: string,
    gender?: string,
    nationality?: any,
    bloodgroup?: any,
    maritalStatus?: any,
    degree?: any
    qualification?:any
    staff_code?: string,
    joining_date?: any,
    aadharcard_id?: string,
    pancard_id?: string,
    emergency_contact_name?: string,
    emergency_contact_relation?: string,
    emergency_contact_number?: string,
    emergency_contact_email?: string,
    blood_group?: any,
    marital_status?: any,
    residential_address?: string,
    city?: any,
    state?: any,
    pincode?: any,
    country?: any,
    residential_state?: any,
    residential_country?: any,
    residential_city?: any,
    residential_pincode?: any,
    permanent_country?: any,
    permanent_state?: any,
    permanent_city?: any,
    permanent_pincode?: any,
    emergency_state?: any,
    emergency_country?: any,
    emergency_city?: any,
    emergency_pincode?: any,
    permanent_address?: any,
    emergency_address?: any,
}

//TEACHER ACTION TYPES
export enum TeacherActionTypes {
    FETCH_TEACHER = 'FETCH_TEACHER',
    FETCH_TEACHER_SUCCESS = 'FETCH_TEACHER_SUCCESS',
    FETCH_TEACHER_FAIL = 'FETCH_TEACHER_FAIL',
    FETCH_TEACHER_MAPPING = 'FETCH_TEACHER_MAPPING',
    FETCH_TEACHER_MAPPING_SUCCESS = 'FETCH_TEACHER_MAPPING_SUCCESS',
    FETCH_TEACHER_MAPPING_FAIL = 'FETCH_TEACHER_MAPPING_FAIL',    
    FETCH_CLASS_INCHARGE_MAPPING = 'FETCH_CLASS_INCHARGE_MAPPING',
    FETCH_CLASS_INCHARGE_MAPPING_SUCCESS = 'FETCH_CLASS_INCHARGE_MAPPING_SUCCESS',
    FETCH_CLASS_INCHARGE_MAPPING_FAIL = 'FETCH_CLASS_INCHARGE_MAPPING_FAIL',
    FETCH_TEACHER_ID = 'FETCH_TEACHER_ID',
    FETCH_TEACHER_SUCCESS_ID = 'FETCH_TEACHER_SUCCESS_ID',
    FETCH_TEACHER_FAIL_ID = 'FETCH_TEACHER_FAIL_ID',
    ADD_TEACHER = 'ADD_TEACHER',
    ADD_TEACHER_SUCCESS = 'ADD_TEACHER_SUCCESS',
    ADD_TEACHER_FAIL = 'ADD_TEACHERT_FAIL',
    EDIT_TEACHER = 'EDIT_TEACHER',
    EDIT_TEACHER_SUCCESS = 'EDIT_TEACHER_SUCCESS',
    EDIT_TEACHER_FAIL = 'EDIT_TEACHER_FAIL',
    DELETE_TEACHER = 'DELETE_TEACHER',
    DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS',
    DELETE_TEACHER_FAIL = 'DELETE_TEACHER_FAIL',
    TEACHER_CLASS_COUNT = 'TEACHER_CLASS_COUNT',
    TEACHER_CLASS_COUNT_SUCCESS = 'TEACHER_CLASS_COUNT_SUCCESS',
    TEACHER_CLASS_COUNT_FAIL = 'TEACHER_CLASS_COUNT_FAIL',
    TEACHER_IMAGE_UPLOAD = 'TEACHER_IMAGE_UPLOAD',
    TEACHER_IMAGE_UPLOADT_SUCCESS = 'TEACHER_IMAGE_UPLOAD_SUCCESS',
    TEACHER_IMAGE_UPLOAD_FAIL = 'TEACHER_IMAGE_UPLOAD_FAIL',
    Teacher_Performance_Count = 'Teacher_Performance_Count',
    Teacher_Performance_Count_SUCCESS = 'Teacher_Performance_Count_SUCCESS',
    Teacher_Performance_Count_FAIL = 'Teacher_Performance_Count_FAIL',
    Least_Attendees_Report = 'Least_Attendees_Report',
    Least_Attendees_Report_Success = 'Least_Attendees_Report_Success',
    Least_Attendees_Report_Fail = 'Least_Attendees_Report_Fail',
    Subject_Performance = 'Subject_Performance',
    Subject_Performance_Success = 'Subject_Performance_Success',
    Subject_Performance_Fail = 'Subject_Performance_Fail',
    Get_Teacher_Name_List = 'Get_Teacher_Name_List',
    Get_Teacher_Name_List_Success = 'Get_Teacher_Name_List_Success',
    Get_Teacher_Name_List_Fail = 'Get_Teacher_Name_List_Fail',
    Get_Teacher_Attendance_Report = 'Get_Teacher_Attendance_Report',
    Get_Teacher_Attendance_Report_Success = 'Get_Teacher_Attendance_Report_Success',
    Get_Teacher_Attendance_Report_Fail = 'Get_Teacher_Attendance_Report_Fail',
    Reset_Teacher_Details = 'Reset_Teacher_Details',
    DELETE_SUBJECT_MAPPING = 'DELETE_SUBJECT_MAPPING',
    DELETE_SUBJECT_MAPPING_SUCCESS = 'DELETE_SUBJECT_MAPPING_SUCCESS',
    DELETE_SUBJECT_MAPPING_FAILURE = 'DELETE_SUBJECT_MAPPING_FAILURE',
    Get_Teacher_Active_Name_List = 'Get_Teacher_Active_Name_List',
    Get_Teacher_Active_Name_List_Success = 'Get_Teacher_Active_Name_List_Success',
    Get_Teacher_Active_Name_List_Fail = 'Get_Teacher_Active_Name_List_Fail',
    RESET_TEACHER_IMAGE_UPLOAD = 'RESET_TEACHER_IMAGE_UPLOAD',
    GET_NATIONALITY = 'FETCH_NATIONALITY',
    GET_NATIONALITY_SUCCESS = 'FETCH_NATIONALITY_SUCCESS',
    GET_NATIONALITY_FAIL = 'FETCH_NATIONALITY_FAIL',
    GET_BLOOD_GROUP = 'GET_BLOOD_GROUP',
    GET_BLOOD_GROUP_SUCCESS = 'GET_BLOOD_GROUP_SUCCESS',
    GET_BLOOD_GROUP_FAIL = 'GET_BLOOD_GROUP_FAIL',
    GET_MARITAL_STATUS = 'GET_MARITAL_STATUS',
    GET_MARITAL_STATUS_SUCCESS = 'GET_MARITAL_STATUS_SUCCESS',
    GET_MARITAL_STATUS_FAIL = 'GET_MARITAL_STATUS_FAIL',
    GET_DEGREE_DROPDOWN = 'GET_DEGREE_DROPDOWN',
    GET_DEGREE_DROPDOWN_SUCCESS = 'GET_DEGREE_DROPDOWN_SUCCESS',
    GET_DEGREE_DROPDOWN_FAIL = 'GET_DEGREE_DROPDOWN_FAIL',
    GET_CITY_DROPDOWN = 'GET_CITY_DROPDOWN',
    GET_CITY_DROPDOWN_SUCCESS = 'GET_CITY_DROPDOWN_SUCCESS',
    GET_CITY_DROPDOWN_FAIL = 'GET_CITY_DROPDOWN_FAIL',
    GET_STATE_DROPDOWN = 'GET_STATE_DROPDOWN',
    GET_STATE_DROPDOWN_SUCCESS = 'GET_STATE_DROPDOWN_SUCCESS',
    GET_STATE_DROPDOWN_FAIL = 'GET_STATE_DROPDOWN_FAIL',
    GET_COUNTRY_DROPDOWN = 'GET_COUNTRY_DROPDOWN',
    GET_COUNTRY_DROPDOWN_SUCCESS = 'GET_COUNTRY_DROPDOWN_SUCCESS',
    GET_COUNTRY_DROPDOWN_FAIL = 'GET_COUNTRY_DROPDOWN_FAIL'
   }

   export interface EditClasslist {
    ClassList:any
}

export interface teacherMappingView {
    acsOrder:boolean,
    page:any,
    search:string,
    SortOrderData:string,
    OrderNameData:string,
    hasMore:boolean,
    classInchanger?:boolean,
    teacherProfileId?:string,
    academicYear?:any,
    isModalEnabled:boolean,
    setModalData?:any,
    teacher?:any,
    schoolAdmin?:any
}