export interface UserType {
    id?: number;
    ldap_id?: string;
    firstname?: string;
    lastname?: string;
    email_id: string;
    phone_number?: string;
    role?: string;
    school_id?: string,
    timezone?: string,
    school_name?: string,
    is_active?: any,
    new_password?:any,
    old_password?:any,
    confirmpassword?:any,
    date_of_birth?: string,
}


export interface UserEditType {
    id?: number;
    ldap_id?: string;
    firstnameUser: string;
    lastnameUser: string;
    emailId: string;
    phoneNumber: string;
    roleUser: string;
    schoolId: string,
    timezone: string,
    schoolName?: string,
    is_active?: string,
    date_of_birth: string,
}

export interface UserManageTypes {
    [id: number]: UserType;
}
export interface CategoryFieldsType {
    id?: number,
    school_name: string;
}
export interface UserState {
    loading: boolean;
    items: UserManageTypes;
    isAuthenticated: boolean | null;
    category: CategoryFieldsType[];
    page: number,
    per_page: number,
    records: any,
    total: number,
    errorMessage: any;
    downloadUserGuideFiles:any;
    mostRecentRequestStartTime:any;
}

export enum UserActionTypes {
    FETCH_USER = 'FETCH_USER',
    FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
    FETCH_USER_FAIL = 'FETCH_USER_FAIL',
    FETCH_USER_ID = 'FETCH_USER',
    FETCH_USER_SUCCESS_ID = 'FETCH_USER_SUCCESS_ID',
    FETCH_USER_FAIL_ID = 'FETCH_USER_FAIL_ID',
    FETCH_USER_CATEGORY = 'FETCH_USER_CATEGORY',
    FETCH_USER_SUCCESS_CATEGORY = 'FETCH_USER_SUCCESS_CATEGORY',
    FETCH_USER_FAIL_CATEGORY = 'FETCH_USER_FAIL_CATEGORY',
    ADD_USER = 'ADD_USER',
    ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
    ADD_USER_FAIL = 'ADD_USER_FAIL',
    EDIT_USER = 'EDIT_USER',
    EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
    EDIT_USER_FAIL = 'EDIT_USER_FAIL',
    DELETE_USER = 'DELETE_USER',
    DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
    DELETE_USER_FAIL = 'DELETE_USER_FAIL',
    Reset_UserManage_Details = 'Reset_UserManage_Details',
    POST_UPDATE_USER_DATA = 'POST_UPDATE_USER_DATA',
    POST_UPDATE_USER_DATA_SUCCESS = 'POST_UPDATE_USER_DATA_SUCCESS',
    POST_UPDATE_USER_DATA_FAIL = 'POST_UPDATE_USER_DATA_FAIL',
    DOWNLOAD_USER_GUIDE = 'DOWNLOAD_USER_GUIDE',
    DOWNLOAD_USER_GUIDE_SUCCESS = 'DOWNLOAD_USER_GUIDE_SUCCESS',
    DOWNLOAD_USER_GUIDE_FAIL = 'DOWNLOAD_USER_GUIDE_FAIL',
}


export interface ISearchBarState {
    show: boolean,
    showDelete: boolean,
    addShow: boolean,
    acadamicAdmin: boolean,
    schoolAdmin: boolean,
    bothData: boolean,
    teacher: boolean,
    parent: boolean,
    deleteUserManageId: any,
    hasMore: boolean,
    userManageData: any,
    prev: number,
    next: number,
    acsOrder: boolean,
    page: number,
    search: string,
    SortOrderData: string,
    OrderNameData: string,
    role?: string,
    postData: any | undefined,
    sort_by?: string,
    sort_class_name?: string,
    sort_title_name?: string,
    academicYear?: any,
    per_page?: any,
}
