import React from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb'
import { EditTeacherPost, fetchTeacherPostId,GetNationality,GetMaritalStatus, GetDegreeDropdown, GetBloodGroup, GetCityDropdown,GetCountryDropdown,GetStateDropdown } from '../../store/teacher/Actions';
import { teacherDetails } from '../../store/teacher/Type';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../store/Index';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, FormLabel, FormControlLabel,FormHelperText, Radio, Checkbox } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { TextField, RadioGroup as FormikRadioGroup } from 'formik-material-ui';
import { TeacherType } from '../../store/teacher/Type';
import { TeacherValidation } from './TeacherValidation';
import TeacherImageUpload from './teacherImageUpload';
import { maxDate } from '../../services/Constants';
import { MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
interface OwnTeacherEditFormProps extends RouteComponentProps<OwnPropsParams> {
    EditTeacherPost: (student: any) => any;
    fetchTeacherPostId: (id: string) => any;
    GetNationality: () => any;
    GetMaritalStatus: () => any;
    GetDegreeDropdown: () => any;
    GetBloodGroup: () => any;
    GetCityDropdown: (data:any) => any;
    GetCountryDropdown: (data:any) => any;
    GetStateDropdown: (data:any) => any;
    loading: boolean;    
    errorMessage: any;
    getTeacherId:any;
    getTeacherData:any;
    getImageURL?: any;
    getErrorMsg?:any;
    nationality?: any;
    maritalStatus?: any;
    getQualification?: any;
    getBloodGroup?: any;
    getCity?: any;
    getCountry?: any;
    getState?: any;
};

interface propsTeacherTypes {
    teacherImage?: any,
    imageFail?: any,
    nationalityData?: any,
    country?: any,
    selectedCountry?: any,
    selectedState?: any,
    residentialState?: any,
    permanentState?: any,
    emergencyState?: any,
    residentialCity: any,
    permanentCity: any,
    emergencyCity: any
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class EditTeacher extends React.Component<OwnTeacherEditFormProps, propsTeacherTypes> {
    formikTeacher: any

    constructor(props: any) {
        super(props);
        this.state = {
            teacherImage: '',
            imageFail: '',
            nationalityData:[],
            country: [],
            selectedCountry: '',
            selectedState: '',
            residentialState: [],
            permanentState: [],
            emergencyState: [],
            residentialCity: [],
            permanentCity: [],
            emergencyCity: []
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        
        this.getLocationDropdown();
        
        this.props.fetchTeacherPostId(String(this.props.match.params.id)).then((res:any) => {
            this.getNationalityDropdown();
        })
        
    }

    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) { 
        const { getTeacherData } = this.props;
        let formikTeacherData: any = this.formikTeacher;
        if(prevProps.getTeacherData != getTeacherData){
            if(getTeacherData && typeof getTeacherData === 'object' && Object.keys(getTeacherData).length !== 0){
                setTimeout(() => {
                    const staffCode:any = (getTeacherData.staffcode) ? getTeacherData.staffcode : '';
                    const joiningDate:any = (getTeacherData.joining_date) ? getTeacherData.joining_date : null;
                    const maritalStatus:any = (getTeacherData.marital_status) ? getTeacherData.marital_status : 0;
                    const bloodGroup:any = (getTeacherData.blood_group) ? getTeacherData.blood_group : 0;
                    const aadharCardId:any = (getTeacherData.aadhar_card_id) ? getTeacherData.aadhar_card_id : '';
                    const panCardId:any = (getTeacherData.pan_card_id) ? getTeacherData.pan_card_id : '';
                    const nationalty:any = (getTeacherData.nationality) ? getTeacherData.nationality : 0;
                    const emerContactName:any = (getTeacherData.emergency_contact_name) ? getTeacherData.emergency_contact_name : '';
                    const emerContactRelation:any = (getTeacherData.emergency_contact_relation) ? getTeacherData.emergency_contact_relation : '';
                    const emerContactNumber:any = (getTeacherData.emergency_contact_phone) ? getTeacherData.emergency_contact_phone : '';
                    const emerContactEmail:any = (getTeacherData.emergency_contact_email) ? getTeacherData.emergency_contact_email : '';
                    const resiAddress:any = (getTeacherData.residential_address?.address) ? getTeacherData.residential_address?.address : '';
                    const resiPincode:any = (getTeacherData.residential_address?.pincode) ? getTeacherData.residential_address?.pincode : '';
                    const permAddress:any = (getTeacherData.permanent_address?.address) ? getTeacherData.permanent_address?.address : '';
                    const permPincode:any = (getTeacherData.permanent_address?.pincode) ? getTeacherData.permanent_address?.pincode : '';
                    const emerAddress:any = (getTeacherData?.emergency_contact_address?.address) ? getTeacherData?.emergency_contact_address?.address : '';
                    const emerPincode:any = (getTeacherData?.emergency_contact_address?.pincode) ? getTeacherData?.emergency_contact_address?.pincode : '';

                    formikTeacherData.setFieldValue('staff_code', staffCode)
                    formikTeacherData.setFieldValue('joining_date', joiningDate)
                    formikTeacherData.setFieldValue('marital_status', maritalStatus)
                    formikTeacherData.setFieldValue('blood_group', bloodGroup)
                    formikTeacherData.setFieldValue('aadharcard_id', aadharCardId)
                    formikTeacherData.setFieldValue('pancard_id', panCardId)
                    formikTeacherData.setFieldValue('nationality', nationalty)
                    formikTeacherData.setFieldValue('emergency_contact_name', emerContactName)
                    formikTeacherData.setFieldValue('emergency_contact_relation', emerContactRelation)
                    formikTeacherData.setFieldValue('emergency_contact_number', emerContactNumber)
                    formikTeacherData.setFieldValue('emergency_contact_email', emerContactEmail)
                    formikTeacherData.setFieldValue('residential_address', resiAddress)
                    formikTeacherData.setFieldValue('residential_pincode', resiPincode)
                    formikTeacherData.setFieldValue('permanent_address', permAddress)
                    formikTeacherData.setFieldValue('permanent_pincode', permPincode)
                    formikTeacherData.setFieldValue('emergency_address', emerAddress)
                    formikTeacherData.setFieldValue('emergency_pincode', emerPincode)
                }, 500); 

                const data = []
                    data.push(getTeacherData?.residential_address?.city)
                    data.push(getTeacherData?.permanent_address?.city)
                    data.push(getTeacherData?.emergency_contact_address?.city)
                    const result = data.filter(item => item !== '').join(',');
                    const getData = {
                        uid: result
                    }
                    this.props.GetCountryDropdown(getData).then((res:any) => {
                        const { getCountry } = this.props;
                        const res_city = getTeacherData?.residential_address?.city
                        const per_city = getTeacherData?.permanent_address?.city
                        const emer_city = getTeacherData?.emergency_contact_address?.city
                        if(res_city && getCountry[res_city]){
                          this.setState({ residentialState: getCountry[res_city]?.state_list, residentialCity:getCountry[res_city]?.city_list})
                          
                          if (formikTeacherData) {
                            setTimeout(() => {
                              if (formikTeacherData) {
                                formikTeacherData.setFieldValue('residential_country', getCountry[res_city].country);
                                formikTeacherData.setFieldValue('residential_state', getCountry[res_city].state);
                                formikTeacherData.setFieldValue('residential_city', res_city);
                              }
                            }, 200); 
                            }
                        }  

                        if(per_city && getCountry[per_city]){
                            this.setState({ permanentState: getCountry[per_city]?.state_list, permanentCity:getCountry[per_city]?.city_list})
                            
                            if (formikTeacherData) {
                              setTimeout(() => {
                                if (formikTeacherData) {
                                  formikTeacherData.setFieldValue('permanent_country', getCountry[per_city].country);
                                  formikTeacherData.setFieldValue('permanent_state', getCountry[per_city].state);
                                  formikTeacherData.setFieldValue('permanent_city', per_city);
                                }
                              }, 200); 
                              }
                          }  

                          if(emer_city && getCountry[emer_city]){
                            this.setState({ emergencyState: getCountry[emer_city]?.state_list, emergencyCity:getCountry[emer_city]?.city_list})
                            
                            if (formikTeacherData) {
                              setTimeout(() => {
                                if (formikTeacherData) {
                                  formikTeacherData.setFieldValue('emergency_country', getCountry[emer_city].country);
                                  formikTeacherData.setFieldValue('emergency_state', getCountry[emer_city].state);
                                  formikTeacherData.setFieldValue('emergency_city', emer_city);
                                }
                              }, 200); 
                              }
                          }  
                    });
                    
            }
        }    

    }
    getNationalityDropdown= () => {
        let formikTeacherData: any = this.formikTeacher;
        this.props.GetNationality().then((res:any) => {
            const { nationality } = this.props;  
            this.setState({nationalityData: nationality})
        });
        this.props.GetBloodGroup().then((res:any) => {
            const { getBloodGroup } = this.props;
        });
        this.props.GetDegreeDropdown().then((res:any) => {
            const { getQualification, getTeacherData } = this.props;
            setTimeout(() => {
                if (formikTeacherData) {
                    const filterData = getQualification.filter((qualification: any) => (getTeacherData.qualification || []).includes(qualification.id));
                    formikTeacherData.setFieldValue('qualification', filterData)
                }
              }, 500); 

            

        });
        this.props.GetMaritalStatus().then((res:any) => {
            const { maritalStatus } = this.props;
        });
    }
    getLocationDropdown = () => {
        const getData = {
            category: 'country'
        }
        this.props.GetCountryDropdown(getData).then((res:any) => {
            const { getCountry } = this.props;
            this.setState({country: getCountry})
        });

    }

    getStateDropdown = (country: string, type: string) => {
        const data = {
            category: 'state',
            country: country
        }
        this.props.GetStateDropdown(data).then((res: any) => {
            const { getState } = this.props;
            if (type === 'residential') {
                this.setState({residentialState: getState})
            }
            else if (type === 'permanent') {
                this.setState({permanentState: getState})
            }
            else {
                this.setState({emergencyState: getState})
            }
        });
    }

    getCityDropdown = (selectState: string, type:any, values:any) => {
        let country = ''
        if (type === 'residential') {
            country = values.residential_country
        }else if (type === 'permanent') {
            country = values.permanent_country
        }
        else if (type === 'emergency'){
            country = values.emergency_country
        }

        const Data = {
            category: 'city',
            country: country,
            state: selectState
        }
        this.props.GetCityDropdown(Data).then((res: any) => {
            const { getCity } = this.props;
            if (type === 'residential') {
                this.setState({residentialCity: getCity})
            }
            else if (type === 'permanent') {
                this.setState({permanentCity: getCity})
            }
            else if (type === 'emergency'){
                this.setState({emergencyCity: getCity})
            }
        })
    }


    handleCountryChange = (e: any, type: any) => {
        const selectedCountry = e.target.value;
        this.setState({ selectedCountry });
        this.getStateDropdown(selectedCountry, type)
    };

    handleStateChange = (e: any, type: any, values:any) => {
        const selectedState = e.target.value;
        this.setState({ selectedState });
        this.getCityDropdown(selectedState, type, values);
    };

    render() {
        const { loading,nationality, maritalStatus, getBloodGroup,
            getQualification, getCity, getCountry, getState } = this.props;
            const { nationalityData, country, residentialState, permanentState, emergencyState, residentialCity, permanentCity, emergencyCity } = this.state
        const loadingTeacher = { display: loading ? "block" : "none" };
        const getEditDetails:any = this.props.getTeacherData;
        const getTeacherData = getEditDetails;
        let teacherfirstname: any;
        let teacherlastname: any;
        let teacheremailId: any;
        let teacherphoneno: any;
        let teacherrole: any;
        let teacherschoolId: any;
        let teacheruserId: any;
        let teacherldapId: any;
        let teacherImage:any;
        let date_of_birth: any;
        let gender: any;
        let staff_code: any;
        let joining_date: any;
        let aadharcard_id:any;
        let pancard_id: any;
        let emergency_contact_name: any;
        let emergency_contact_relation: any;
        let emergency_contact_number: any;
        let emergency_contact_email: any;
        let blood_group:any;
        let degree: any;
        let marital_status: any;
        let residential_address: any;
        let city: any;
        let state: any;
        let pincode: any;
            if (getTeacherData) {
                teacherfirstname = getTeacherData.firstname;
                teacherlastname = getTeacherData.lastname;
                teacheremailId = getTeacherData.email;
                teacherphoneno = getTeacherData.phone_number;
                teacherrole = getTeacherData.role;
                teacherschoolId = getTeacherData.school_id;
                teacheruserId = getTeacherData.id;
                teacherldapId = this.props.match.params.id;
                teacherImage = getTeacherData.image;
                date_of_birth = getTeacherData.date_of_birth;
                gender = getTeacherData.gender;
                staff_code = getTeacherData.staffcode;
                joining_date = getTeacherData.joining_date;
                aadharcard_id = getTeacherData.aadhar_card_id;
                pancard_id = getTeacherData.pan_card_id;
                emergency_contact_name = getTeacherData.emergency_contact_name;
                emergency_contact_relation = getTeacherData.emergency_contact_relation;
                emergency_contact_number = getTeacherData.emergency_contact_phone;
                emergency_contact_email = getTeacherData.emergency_contact_email;
                blood_group = getTeacherData.blood_group;
                degree = getTeacherData.degree;
                marital_status = getTeacherData.marital_status;
                residential_address = getTeacherData.residential_address;
                city = getTeacherData.city;
                state = getTeacherData.state;
                pincode = getTeacherData.pincode;
            }
        const initialTeacherEditValues: teacherDetails = {
            teacherfirstname: teacherfirstname,
            teacherlastname: teacherlastname,
            teacheremailid: teacheremailId,
            teacherphonenumber: teacherphoneno,
            teacherrole: teacherrole,
            id: teacheruserId,
            school_id: teacherschoolId,
            ldap_id: teacherldapId,
            image: teacherImage,
            timezone: 'Asia/Kolkata',
            date_of_birth: date_of_birth,
            gender: gender,
            staff_code: '',
            joining_date: null,
            aadharcard_id: '',
            pancard_id: '',
            emergency_contact_name: '',
            emergency_contact_relation: '',
            emergency_contact_number: '',
            emergency_contact_email: '',
            qualification:[],
            nationality:0,
            blood_group: 0,
            degree: 0,
            marital_status: 0,
            residential_address: '',
            city: city,
            state: state,
            pincode: pincode,
            residential_state:0,
            residential_country:0,
            residential_city:0,
            residential_pincode:'',
            permanent_address: '',
            permanent_country:0,
            permanent_state:0,
            permanent_city:0,
            permanent_pincode:'',
            emergency_address: '',
            emergency_state:0,
            emergency_country:0,
            emergency_city:0,
            emergency_pincode:'',
            
        }
        if(this.props.getImageURL){
            const getUrlpath = this.props.getImageURL.url;
            if(getUrlpath){
                getUrlpath.forEach((items:any)=>{
                    teacherImage = items;
                })
            }
        } 
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Teacher']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Teacher']}
                                baseURL={['teacher']}
                                mainPageTitle={['Edit Teacher']} />
                                { teacherfirstname && teacheremailId ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-box">
                                            <div className="card-head">
                                                <header>Edit Teacher</header>
                                            </div>
                                            <div className="card-body">
                                            <Formik
                            ref={node => this.formikTeacher = node}
                            initialValues={initialTeacherEditValues}
                            validationSchema={TeacherValidation}
                            onSubmit={(values: any, actions) => {
                                const qualificationIds = values.qualification.map((qualification:any) => qualification.id);
                                const initialTeacherValue: TeacherType = {
                                    firstname: values.teacherfirstname,
                                    lastname: values.teacherlastname,
                                    email_id: values.teacheremailid,
                                    phone_number: values.teacherphonenumber,
                                    role: 'Teacher',
                                    id: values.id,
                                    ldap_id: values.ldap_id,
                                    image: values.image,
                                    timezone: 'Asia/Kolkata',
                                    date_of_birth: values.date_of_birth,
                                    gender: values.gender,
                                    staffcode: values.staff_code,
                                    joining_date: values.joining_date,
                                    nationality: values.nationality,
                                    blood_group: values.blood_group,
                                    marital_status: values.marital_status,
                                    aadhar_card_id: values.aadharcard_id,
                                    pan_card_id: values.pancard_id,
                                    emergency_contact_name: values.emergency_contact_name,
                                    emergency_contact_relation: values.emergency_contact_relation,
                                    emergency_contact_phone: values.emergency_contact_number,
                                    emergency_contact_email: values.emergency_contact_email,
                                    qualification: qualificationIds,
                                    residential_address: {
                                        address: values.residential_address,
                                        city: values.residential_city,
                                        state: values.residential_city,
                                        pincode: values.residential_pincode,
                                        country: values.residential_city,
                                    },
                                    permanent_address: {
                                        address: values.permanent_address,
                                        city: values.permanent_city,
                                        state: values.permanent_city,
                                        pincode: values.permanent_pincode,
                                        country: values.permanent_city,
                                    },
                                    emergency_contact_address: {
                                        address: values.emergency_address,
                                        city: values.emergency_city,
                                        state: values.emergency_city,
                                        pincode: values.emergency_pincode,
                                        country: values.emergency_city,
                                    }
                                }
                                this.props.EditTeacherPost(initialTeacherValue).then((res:any)=>{
                                    const { getErrorMsg } = this.props;
                                    let getErrorMessage: any = this.formikTeacher;
                                    if (getErrorMessage) {
                                        if (getErrorMsg) {
                                            if (getErrorMsg.firstname
                                                || getErrorMsg.lastname
                                                || getErrorMsg.email_id
                                                || getErrorMsg.phone_number) {
                                                getErrorMessage.setFieldError('teacherfirstname', this.props.getErrorMsg.firstname)
                                                getErrorMessage.setFieldError('teacherlastname', this.props.getErrorMsg.lastname)
                                                getErrorMessage.setFieldError('teacheremailid', this.props.getErrorMsg.email_id)
                                                getErrorMessage.setFieldError('teacherphonenumber', this.props.getErrorMsg.phone_number)
                                            }
                                        }
                                    }
                                })
                            }}
                            render={({
                                values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                            }: FormikProps<any>) => {

                                const isEmpty = (!values.teacherfirstname || !values.teacheremailid || !values.teacherphonenumber);
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <div className="row">
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>First Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="teacherfirstname"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>
                                                                <Field
                                                                    label={<><span>Last Name</span></>}
                                                                    type="text"
                                                                    name="teacherlastname"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 p-t-20">
                                                        <FormControl component="fieldset" error={touched.gender && Boolean(errors.gender)}>
                                                            <FormLabel component="legend">Gender <span className='formmandatorycolor'> *</span></FormLabel>
                                                            <Field component={FormikRadioGroup} name="gender">
                                                            <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                            <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                            <FormControlLabel value="O" control={<Radio />} label="Other" />
                                                            </Field>
                                                            <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="teacherphonenumber"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>
                                                                <Field
                                                                    label={<><span>Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                    type="text"
                                                                    name="teacheremailid"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 p-t-20 pl-0'>                                                                                                          
                                                        <FormGroup>
                                                            <legend className="MuiFormLabel-root">
                                                                <span>Date of Birth</span><span className='formmandatorycolor'> *</span>
                                                            </legend>
                                                            <Field
                                                                type="date"
                                                                name="date_of_birth"
                                                                component={TextField}                                                            
                                                                disabled={false}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        max: maxDate, // Disable future dates
                                                                        },
                                                                    }}
                                                            />                                                        
                                                        </FormGroup>                                               
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-t-20">
                                                    <TeacherImageUpload postImage={teacherImage} setFieldValue={setFieldValue}/>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Staff Code</span></>}
                                                                                    type="text"
                                                                                    name="staff_code"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={!!staff_code}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <legend className="MuiFormLabel-root">
                                                                                    <span>Joining Date</span>
                                                                                </legend>
                                                                                <Field
                                                                                    type="date"
                                                                                    name="joining_date"
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                    InputProps={{
                                                                                        inputProps: {
                                                                                            max: new Date().toISOString().split("T")[0],
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Aadhaar Card</span></>}
                                                                                    type="text"
                                                                                    name="aadharcard_id"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Pan Card</span></>}
                                                                                    type="text"
                                                                                    name="pancard_id"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emergency Contact Name</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_name"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emergency Contact Relation</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_relation"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emeregency Contact Number</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_number"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emeregency Contact Email</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_email"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Nationality</span></>}
                                                                                    name="nationality"
                                                                                    select
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                    onChange={(e: any) => {
                                                                                        setFieldValue('nationality', e.target.value)
                                                                                    }}
                                                                                >
                                                                                    {nationalityData && nationalityData.length > 0 && nationalityData.map((item: any) => (
                                                                                        <MenuItem key={item.id} value={item.id}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Blood Group</span></>}
                                                                                    name="blood_group"
                                                                                    select
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                >
                                                                                    {getBloodGroup && getBloodGroup.length > 0 && getBloodGroup.map((item: any) => (
                                                                                        <MenuItem key={item.id} value={item.id}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Autocomplete
                                                                                    fullWidth
                                                                                    multiple
                                                                                    id="checkboxes-tags-demo"
                                                                                    options={ getQualification|| []}
                                                                                    disableCloseOnSelect
                                                                                    noOptionsText={'No matching records found.'}
                                                                                    getOptionLabel={(option: any) => option.value}
                                                                                    value={values.qualification}
                                                                                    onChange={(e, value, resonse) => {
                                                                                    setFieldValue('qualification', value);
                                                                                    }}
                                                                                    renderOption={(option, { selected }) => {
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                        <Checkbox
                                                                                            icon={icon}
                                                                                            checkedIcon={checkedIcon}
                                                                                            style={{ marginRight: 8 }}
                                                                                            checked={selected}
                                                                                        />
                                                                                        {option.value}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                    }}
                                                                                    className="mb-0 mt-1"
                                                                                    renderInput={(params) => (
                                                                                    <Field
                                                                                        component={TextField}
                                                                                        name="search_qualification"
                                                                                        error={!!errors.search_qualification}
                                                                                        helperText={errors.search_qualification}
                                                                                        {...params} label={<><span>Qualification</span></>}
                                                                                    />
                                                                                    )}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Marital Status</span></>}
                                                                                    name="marital_status"
                                                                                    select
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                >
                                                                                    {maritalStatus && maritalStatus.length > 0 && maritalStatus.map((item: any) => (
                                                                                        <MenuItem key={item.id} value={item.id}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <div className='mt-3'>
                                                                                RESIDENTIAL :
                                                                                <FormGroup className='ml-3'>
                                                                                    <Field
                                                                                        label={<><span>Address</span></>}
                                                                                        name='residential_address'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <Field
                                                                                        label={<><span>Country</span></>}
                                                                                        name="residential_country"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleCountryChange(e, 'residential');
                                                                                            setFieldValue('residential_country', e.target.value)
                                                                                            setFieldValue('residential_state', '');
                                                                                            setFieldValue('residential_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {country && country.length > 0 && country.map((item: any) => (
                                                                                            <MenuItem key={item.country} value={item.country}>
                                                                                                {item.country}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>State</span></>}
                                                                                        name='residential_state'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleStateChange(e, 'residential', values);
                                                                                            setFieldValue('residential_state', e.target.value);
                                                                                            setFieldValue('residential_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {residentialState && residentialState.length > 0 && residentialState.map((item: any) => (
                                                                                            <MenuItem key={item.state} value={item.state}>
                                                                                                {item.state}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>City</span></>}
                                                                                        name='residential_city'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            setFieldValue('residential_city', e.target.value);
                                                                                        }}
                                                                                    >
                                                                                        {residentialCity && residentialCity.length > 0 && residentialCity.map((item: any) => (
                                                                                            <MenuItem key={item.uid} value={item.uid}>
                                                                                                {item.city}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>Pincode</span></>}
                                                                                        name='residential_pincode'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <div className='mt-3'>
                                                                                PERMANENT :
                                                                                <FormGroup className='ml-3'>
                                                                                    <Field
                                                                                        label={<><span>Address</span></>}
                                                                                        name='permanent_address'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <Field
                                                                                        label={<><span>Country</span></>}
                                                                                        name="permanent_country"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleCountryChange(e, 'permanent');
                                                                                            setFieldValue('permanent_country', e.target.value)
                                                                                            setFieldValue('permanent_state', '');
                                                                                            setFieldValue('permanent_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {country && country.length > 0 && country.map((item: any) => (
                                                                                            <MenuItem key={item.country} value={item.country}>
                                                                                                {item.country}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>State</span></>}
                                                                                        name='permanent_state'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleStateChange(e, 'permanent',  values);
                                                                                            setFieldValue('permanent_state', e.target.value);
                                                                                            setFieldValue('permanent_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {permanentState && permanentState.length > 0 && permanentState.map((item: any) => (
                                                                                            <MenuItem key={item.state} value={item.state}>
                                                                                                {item.state}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>City</span></>}
                                                                                        name='permanent_city'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    >
                                                                                        {permanentCity && permanentCity.length > 0 && permanentCity.map((item: any) => (
                                                                                            <MenuItem key={item.uid} value={item.uid}>
                                                                                                {item.city}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>Pincode</span></>}
                                                                                        name='permanent_pincode'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <div className='mt-3'>
                                                                                EMERGENCY :
                                                                                <FormGroup className='ml-3'>
                                                                                    <Field
                                                                                        label={<><span>Address</span></>}
                                                                                        name='emergency_address'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <Field
                                                                                        label={<><span>Country</span></>}
                                                                                        name="emergency_country"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleCountryChange(e, 'emergency');
                                                                                            setFieldValue('emergency_country', e.target.value)
                                                                                            setFieldValue('emergency_state', '');
                                                                                            setFieldValue('emergency_city', '')
                                                                                        }}
                                                                                    >
                                                                                        {country && country.length > 0 && country.map((item: any) => (
                                                                                            <MenuItem key={item.country} value={item.country}>
                                                                                                {item.country}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>State</span></>}
                                                                                        name='emergency_state'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleStateChange(e, 'emergency', values);
                                                                                            setFieldValue('emergency_state', e.target.value);
                                                                                            setFieldValue('emergency_city', '')
                                                                                        }}
                                                                                    >
                                                                                        {emergencyState && emergencyState.length > 0 && emergencyState.map((item: any) => (
                                                                                            <MenuItem key={item.state} value={item.state}>
                                                                                                {item.state}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>City</span></>}
                                                                                        name='emergency_city'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    >
                                                                                        {emergencyCity && emergencyCity.length > 0 && emergencyCity.map((item: any) => (
                                                                                            <MenuItem key={item.uid} value={item.uid}>
                                                                                                {item.city}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>Pincode</span></>}
                                                                                        name='emergency_pincode'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                        
                                            <div className="text-right mb-3 mr-2 mt-4">
                                                <Button className="btn btn-pink mr-1 ml-1 w-15"
                                                    disabled={isEmpty || isValidating ||
                                                        !!(errors.teacheremailid && touched.teacheremailid) ||
                                                        !!(errors.teacherphonenumber && touched.teacherphonenumber) ||
                                                        !!(errors.teacherlastname && touched.teacherlastname) ||
                                                        !!(errors.teacherfirstname && touched.teacherfirstname)} type="submit">Submit</Button>
                                                <Link to="/teacher">
                                                    <Button className="btn btn-default mr-1 ml-1 w-15">Cancel</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }
                            }
                        />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : <SpinnerLoader />}
                        </div>
                    </div>
                </div>
                <div style={loadingTeacher}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = ({teacher}: RootState, ownProps: RouteComponentProps<OwnPropsParams>) => {
    return {
        getTeacherId:teacher.items[Number(ownProps.match.params.id)],
        loading:teacher.loading,
        getErrorMsg: teacher.errors,
        getImageURL:teacher.imageUpload,
        getTeacherData:teacher.GetTeacherProfile,
        nationality: teacher.GetNationality,
        maritalStatus: teacher.GetMaritalStatus,
        getBloodGroup: teacher.GetBloodGroup,
        getQualification: teacher.GetDegreeDropdownData,
        getCity: teacher.GetCityData,
        getCountry: teacher.GetCountryData,
        getState: teacher.GetStateData
    };
}

export default connect(mapStateToProps, 
    { 
        fetchTeacherPostId, 
        EditTeacherPost, 
        GetBloodGroup,
        GetCityDropdown,
        GetCountryDropdown,
        GetDegreeDropdown,
        GetMaritalStatus,
        GetNationality,
        GetStateDropdown
    })(EditTeacher)
