//Schools fetch details
export enum StudentActionTypes {
    FETCH_STUDENT = 'FETCH_STUDENT',
    FETCH_STUDENT_SUCCESS = 'FETCH_STUDENT_SUCCESS',
    FETCH_STUDENT_FAIL = 'FETCH_STUDENT_FAIL',
    FETCH_STUDENT_ID = 'FETCH_STUDENT_ID',
    FETCH_STUDENT_SUCCESS_ID = 'FETCH_STUDENT_SUCCESS_ID',
    FETCH_STUDENT_FAIL_ID = 'FETCH_STUDENT_FAIL_ID',
    FETCH_PARENT = 'FETCH_PARENT',
    FETCH_PARENT_SUCCESS = 'FETCH_PARENT_SUCCESS',
    FETCH_PARENT_FAIL = 'FETCH_PARENT_FAIL',
    ADD_STUDENT = 'ADD_STUDENT',
    ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS',
    ADD_STUDENT_FAIL = 'ADD_STUDENT_FAIL',
    EDIT_STUDENT = 'EDIT_STUDENT',
    EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS',
    EDIT_STUDENT_FAIL = 'EDIT_STUDENT_FAIL',
    DELETE_STUDENT = 'DELETE_STUDENT',
    DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS',
    DELETE_STUDENT_FAIL = 'DELETE_STUDENT_FAIL',
    FETCH_STUDENT_IMAPGE = 'FETCH_STUDENT_IMAPGE',
    FETCH_STUDENT_IMAPGE_SUCCESS = 'FETCH_STUDENT_IMAPGE_SUCCESS',
    FETCH_STUDENT_IMAPGE_FAIL = 'FETCH_STUDENT_IMAPGE_FAIL',
    FETCH_STUDENT_PROFILE = 'FETCH_STUDENT_PROFILE',
    FETCH_STUDENT_PROFILE_SUCCESS = 'FETCH_STUDENT_PROFILE_SUCCESS',
    FETCH_STUDENT_PROFILE_FAIL = 'FETCH_STUDENT_PROFILE_FAIL',
    Student_Profile_Topic = 'Student_Profile_Topic',
    Student_Profile_Topic_Success = 'Student_Profile_Topic_Success',
    Student_Profile_Topic_Fail = 'Student_Profile_Topic_Fail',
    Student_Profile_Subject = 'Student_Profile_Subject',
    Student_Profile_Subject_Success = 'Student_Profile_Subject_Success',
    Student_Profile_Subject_Fail = 'Student_Profile_Subject_Fail',
    Student_Profile_Line = 'Student_Profile_Line',
    Student_Profile_Line_Success = 'Student_Profile_Line_Success',
    Student_Profile_Line_Fail = 'Student_Profile_Line_Fail',
    Removed_Image_Upload = 'Removed_Image_Upload',
    Removed_Image_Upload_Success = 'Removed_Image_Upload_Success',
    Removed_Image_Upload_Fail = 'Removed_Image_Upload_Fail',
    Student_Name_List = 'Student_Name_List',
    Student_Name_List_Success = 'Student_Name_List_Success',
    Student_Name_List_Fail = 'Student_Name_List_Fail',
    Update_Attendance = 'Update_Attendance',
    Update_Attendance_Success = 'Update_Attendance_Success',
    Update_Attendance_Fail = 'Update_Attendance_Fail',
    Reset_Student_Details = 'Reset_Student_Details',
    RESET_DROPDOWN_DATA='RESET_DROPDOWN_DATA',
    STUDENT_PROMOTION = 'STUDENT_PROMOTION',
    STUDENT_PROMOTION_SUCCESS = 'STUDENT_PROMOTION_SUCCESS',
    STUDENT_PROMOTION_FAIL = 'STUDENT_PROMOTION_FAIL',
    FETCH_STUDENT_ACADEMIC_YEAR = 'FETCH_STUDENT_ACADEMIC_YEAR',
    FETCH_STUDENT_ACADEMIC_YEAR_SUCCESS = 'FETCH_STUDENT_ACADEMIC_YEAR_SUCCESS',
    FETCH_STUDENT_ACADEMIC_YEAR_FAIL = 'FETCH_STUDENT_ACADEMIC_YEAR_FAIL',
    STUDENT_NEXT_CLASS = 'STUDENT_NEXT_CLASS',
    STUDENT_NEXT_CLASS_SUCCESS = 'STUDENT_NEXT_CLASS_SUCCESS',
    STUDENT_NEXT_CLASS_FAIL = 'STUDENT_NEXT_CLASS_FAIL',
    CREATE_ATTENDANCE = 'CREATE_ATTENDANCE',
    CREATE_ATTENDANCE_SUCCESS = 'CREATE_ATTENDANCE_SUCCESS',
    CREATE_ATTENDANCE_FAIL = 'CREATE_ATTENDANCE_FAIL',
    SIBLINGS_EMIS_DROPDOWN = 'SIBLINGS_EMIS_DROPDOWN',
    SIBLINGS_EMIS_DROPDOWN_SUCCESS = 'SIBLINGS_EMIS_DROPDOWN_SUCCESS',
    SIBLINGS_EMIS_DROPDOWN_FAIL = 'SIBLINGS_EMIS_DROPDOWN_FAIL',
    HOUSE_DROPDOWN = 'HOUSE_DROPDOWN',
    HOUSE_DROPDOWN_SUCCESS = 'HOUSE_DROPDOWN_SUCCESS',
    HOUSE_DROPDOWN_FAIL = 'HOUSE_DROPDOWN_FAIL',
    MODE_OF_TRANSPORT = 'MODE_OF_TRANSPORT',
    MODE_OF_TRANSPORT_SUCCESS = 'MODE_OF_TRANSPORT_SUCCESS',
    MODE_OF_TRANSPORT_FAIL = 'MODE_OF_TRANSPORT_FAIL'
}
//  academic_year property is added in Student Type
export interface StudentType {
    id?:number,
    school_id?:string,
    phone_number:string,
    email_id:string,
    student_id?:string,
    student_name:string,
    grade:string,
    standard:string,
    parent_firstname?:string,
    parent_lastname?:string,
    profile_picture?:string,
    user_id?:string,
    academic_year?:string,
    gender?:string,
    date_of_birth?: string,
    emis_id?: string,
    admission_no?: string,
    roll_no?: string,
    nationality?: any,
    medical_conditions?: string,
    blood_group?: any,
    house?: any,
    mode_of_transport?: any,
    vehicle_reg_no?: string,
    driver_name?: string,
    driver_contact_no?: string,
    hobbies?: any,
    identification_marks?: any,
    father_name?: any,
    father_contact_no?: string,
    father_email_id?: string,
    father_occupation?: string,
    father_annual_income?: number,
    mother_name?: string,
    mother_contact_no?:string,
    mother_email_id?: string,
    mother_occupation?: string,
    mother_annual_income?: number,
    sibling_details?: any,
    residential_address?: any,
    address?: string,
    city?: any,
    state?: any,
    pincode?: string,
    country?: any,
    search_sibling_name?: string,
}
export interface StudentTypes {
    [id: number]: StudentType;
}

export interface StudentState {
    loading: boolean;
    items: StudentTypes;
    isAuthenticated: boolean | null;
    errors?:any
    page: number,
    per_page: number,
    records: any,
    total: number,
    parentDetails?: any,
    getParentStatus?:boolean | null,
    parentList?:boolean,
    ImageURL?:any,
    getStudentEditResponse?:any,
    imageUploadstatus?:any,
    studentProfile?:any,
    studentProfileTopic?:any,
    studentProfileSubject?:any,
    studentProfileLine?:any,
    getStudentNameList?:any,
    onUpdateAttendanceStatu?:any,
    newlyCreatedStudentInfo?:any,
    updateAttendanceLoader?:boolean,
    studentList?:any,
    studentNextClassDetail?: any
    onCreateAttendanceStatus?: any,
    getHouseData?: any,
    getTransportData?: any,
    getSiblingsData?: any
}

export interface CategoryStudentType  {
    id?:number,
    name: string;
}

export interface studentInputTypes {
    id?:number,
    school_id?:string,
    phone_number:string,
    email_id:string,
    student_id?:string,
    student_name:string,
    grade:string,
    standard:string,
    parent_firstname?:string,
    parent_lastname?:string,
    academicYear?:any,
    profile_picture?:string,
    user_id?:string,
    checkParentList?:boolean,
    formik?:any,
    getSelectClass?:boolean,
    getClass?:any,
    getSection?:any,
    date_of_birth?: any,
    emis_id?: string,
    admission_no?: string,
    roll_no?: string,
    nationality?: any,
    medical_conditions?: string,
    blood_group?: any,
    house?: any,
    mode_of_transport?: any,
    vehicle_reg_no?: string,
    name?: string,
    contact_no?: string,
    hobbies?: any,
    identification_marks?: any,
    relationship?: string,
    email?: any,
    occupation?: string,
    annual_income?: number,
    sibling_details?: any,
    residential_address?: any,
    address?: string,
    city?: any,
    state?: any,
    pincode?: string,
    country?: any,
    selectedCountry?: any,
    selectedState?: any,
    phone_no?: any,
    siblings_data?: any
}