import * as yup from 'yup';
import { formValidationSize, userFormValidations, formValidationPatten } from '../../services/Constants';

export const TeacherValidation = yup.object({
    teacherfirstname: yup.string()
        .required(userFormValidations.firstName)
        .min(userFormValidations.firstNameSizeMin, userFormValidations.firstNameMin)
        .max(userFormValidations.firstNameSizeMax, userFormValidations.firstNameMax)
        .matches(formValidationPatten.namePatten, userFormValidations.firstNameInvalid),
    teacheremailid: yup.string()
        .required(userFormValidations.emailId)
        .matches(formValidationPatten.emailPatten, userFormValidations.emailIdInvalid),
    teacherlastname: yup.string()
        .min(userFormValidations.lastNameSizeMin, userFormValidations.lastNameMin)
        .max(userFormValidations.lastNameSizeMax, userFormValidations.lastNameMax)
        .matches(formValidationPatten.namePatten, userFormValidations.lastNameInvalid),
    teacherphonenumber: yup.string()
        .required(userFormValidations.phoneNumber)
        .matches(formValidationPatten.phoneRegExp, userFormValidations.phoneNumbervalid)
        .min(formValidationSize.mobileNoSize, userFormValidations.phoneNumbervalid)
        .max(formValidationSize.mobileNoSize, userFormValidations.phoneMaxMsg),
    gender: yup.string().required('Gender is required'),
    date_of_birth: yup.string().required('Date of Birth is required'),
    aadharcard_id: yup.string()
    .matches(formValidationPatten.aadharRegex, "Invalid Aadhar Card ID. It must be a 12-digit numeric value.")
    .nullable(),
  pancard_id: yup.string()
    .matches(formValidationPatten.panRegex, "Invalid PAN Card ID. It must follow the format AAAAA1234A.")
    .nullable(),
  emergency_contact_name: yup.string()
    .matches(formValidationPatten.nameRegex, "Invalid Emergency Contact Name. Must be 2-50 alphabetic characters.")
    .nullable(),
  emergency_contact_relation: yup.string()
    .matches(formValidationPatten.relationRegex, "Invalid Emergency Contact Relation. Must be 2-30 alphabetic characters.")
    .nullable(),
  emergency_contact_phone: yup.string()
    .matches(formValidationPatten.phoneRegex, "Invalid Emergency Contact Phone. It must be a 10-digit numeric value.")
    .nullable(),
  emergency_contact_email: yup.string()
    .matches(formValidationPatten.emailRegex, "Invalid Emergency Contact Email. Please provide a valid email address.")
    .nullable(),
  nationality: yup.string().nullable(),
  residential_address: yup.string()
      .matches(formValidationPatten.addressRegex, "Invalid Residential Address. Use valid characters.")
      .min(5, "Address must be at least 5 characters long.")
      .max(100, "Address must not exceed 100 characters.")
      .nullable(),
  residential_city: yup.string().when('residential_country', {
    is: (val) => {
      return val;
    },
    then:yup.string()
    .required('city is required'),
  }),

  residential_state: yup.string().when('residential_country', {
    is: (val) => {
      return val;
    },
    then:yup.string()
    .required('state is required'),
  }),
  residential_pincode:yup.string().nullable(),
  residential_country: yup.string().nullable(),
  permanent_address:yup.string()
  .matches(formValidationPatten.addressRegex, "Invalid Residential Address. Use valid characters.")
  .min(5, "Address must be at least 5 characters long.")
  .max(100, "Address must not exceed 100 characters.")
  .nullable(),
  permanent_city:yup.string().when('permanent_country', {
    is: (val) => {
      return val;
    }, 
    then:yup.string()
    .required('city is required'),
  }),
  permanent_state:yup.string().when('permanent_country', {
    is: (val) => {
      return val;
    },
    then:yup.string()
    .required('state is required'),
  }),
  permanent_address_pincode:yup.string().nullable(),
  permanent_country:yup.string().nullable(),
  emergency_address:yup.string()
  .matches(formValidationPatten.addressRegex, "Invalid Residential Address. Use valid characters.")
  .min(5, "Address must be at least 5 characters long.")
  .max(100, "Address must not exceed 100 characters.")
  .nullable(),
  emergency_city:yup.string().when('emergency_country', {
    is: (val) => {
      return val;
    },
    then:yup.string()
    .required('city is required'),
  }),
  emergency_state:yup.string().when('emergency_country', {
    is: (val) => {
      return val;
    }, 
    then:yup.string()
    .required('state is required'),
  }),
  emergency_pincode:yup.string().nullable(),
  emergency_country:yup.string().nullable(),
})

export const TeacherSubjectValidation = yup.object({
    subjectName: yup.string()
        .required('Please select the subject')
})

export const editTeacherSubjectValidation = yup.object({
    subjectName: yup.string()
        .required('Please select the subject')
})