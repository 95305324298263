import React from 'react'
import { connect } from 'react-redux'
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import BreadCrumb from '../../components/BreadCrumb';
import { addStudent } from '../../store/student/Actions';
import { fetchGradePost, fetchGradeStandardPost } from '../../store/classes/Actions';
import { studentInputTypes, StudentType } from '../../store/student/Types';
import { fetchParent } from '../../store/student/Actions';
import { Button, FormGroup, MenuItem, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText, Checkbox } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField,  RadioGroup as FormikRadioGroup } from 'formik-material-ui';
import { StudentValidation } from './StudentValidation';
import history from '../../History';
import ImageUpload  from './StudentImage';
import { fetchMyClassListGet, fetchMySectionListGet } from '../../store/classes/Actions';
import { getStudentClassesData } from '../../store/profile/Actions';
import { stopYearData } from '../../store/profile/Actions';
import { fetchLicenseBySchoolGet } from '../../store/license/Actions';
import toaster from "toasted-notes";
import { notificationMsg, maxDate } from '../../services/Constants';
import { GetNationality, GetBloodGroup, GetCountryDropdown, GetStateDropdown, GetCityDropdown} from '../../store/teacher/Actions';
import { getHousedropdown, getSiblingsEmisDropdown, GetModeofTransport} from '../../store/student/Actions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChipInput from 'material-ui-chip-input'

export type OwnStudentFormProps = {
    getChangeYearData?:any;
	stopYearDate?:any;
    addStudent: (student: any) => any;
    fetchGradePost:(postValue:any) => void;
    fetchGradeStandardPost:() => any;
    fetchParent: (getParent:any) => any;
    fetchMyClassListGet:() => any;
    fetchMySectionListGet:(grade:any) => any;
    getStudentClassesData:(postData:any)=>any;
    stopYearData:()=>any;
    fetchLicenseBySchoolGet: (getData: any) => any;
    GetNationality: () => any;
    GetBloodGroup: () => any;
    getHousedropdown: () => any;
    GetModeofTransport: () => any;
    GetCountryDropdown: (getData: any) => any;
    GetStateDropdown: (data:any) => any;
    GetCityDropdown: (data:any) => any;
    getSiblingsEmisDropdown: (data:any) => any;
    loading: boolean;    
    errorMessage: any;
    ListSection:any;
    ListClass:any;
    getParentResponse:any;
    checkParent:any;
    getImageURL:any;
    getProfile:any;
    license?: any;
    licenseBySchool?: any;
    nationality?: any;
    getBloodGroup?: any;
    getHouse?: any;
    Transport?: any;
    country?: any;
    state?: any;
    city?: any;
    siblingsData?: any;
};
 const initialAddValues: StudentType = {
    phone_number: '',
    email_id: '',
    student_name:  '',
    academic_year:'',
    grade: '',
    standard:'',
    parent_firstname:  '',
    parent_lastname:  '',
    profile_picture: '',
    user_id:'sdad',
    gender:'',
    date_of_birth: '',
    emis_id: '',
    admission_no: '',
    roll_no: '',
    nationality: 0,
    medical_conditions: '',
    blood_group: 0,
    house: 0,
    mode_of_transport: 0,
    vehicle_reg_no: '',
    driver_name: '',
    driver_contact_no: '',
    hobbies: [],
    identification_marks: [],
    father_name: '',
    father_contact_no: '',
    father_email_id: '',
    father_occupation: '',
    father_annual_income: 0,
    mother_name: '',
    mother_contact_no: '',
    mother_email_id: '',
    mother_occupation: '',
    mother_annual_income: 0,
    sibling_details: [],
    city: 0,
    state: 0,
    country: 0,
    residential_address: '',
    pincode: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class AddStudent extends React.Component<OwnStudentFormProps, studentInputTypes> {  
    formik:any;
    parentName:any;
    getSectionList:any;
    getImage:any;
    constructor(props: any) {        
        super(props);        
        this.state = {
            phone_number:'',
            email_id:'',
            student_name:'',
            academicYear:null,
            grade:'',
            standard:'',
            parent_firstname:'',
            parent_lastname:'',
            profile_picture:'',
            user_id:'',
            checkParentList:false,
            getSelectClass:true,
            getClass:'',
            getSection:'',
            date_of_birth: '',
            siblings_data: []
        }  
    }
    componentDidMount(): void {     
        window.scrollTo(0, 0);  
        this.getCurrectYear();
        this.getNationalityDropdown();
        this.getLocationDropdown();
    }
     // This is life cycle is initial load  component Will Update
     componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}
    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getAddStudent(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getAddStudent(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    getAddStudent(getYear?:any){
        this.getImage = '';
        const postValue = {
            academic_year:getYear
        }
        const getLicense = {
            module_name: 'student_module',
            academic_year: getYear
          }

          this.props.fetchLicenseBySchoolGet(getLicense).then((res:any) => {
            const { licenseBySchool } = this.props
            const studentLicense =  licenseBySchool;
            if(studentLicense.remaining_license && studentLicense.remaining_license <= 0){
                toaster.notify(studentLicense.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                  });

                history.push("/student");
            }
          })  
       
        this.props.fetchGradePost(postValue);
        this.props.fetchGradeStandardPost();  
        this.props.fetchMyClassListGet();  
        this.getSectionList = true;  
    }
    findParantDetail= (e: any) => {
        const { getProfile } = this.props;
        const { value } = e.target;
        if(value.length === 10) {
            const getPhone = {
                phone_number:value,
                school_id:getProfile.school_id
            };
            this.props.fetchParent(getPhone).then((res:any) => {
                if(this.props.checkParent === false){
                    this.formik.setFieldValue('email_id', '')  
                    this.formik.setFieldValue('parent_firstname', '')
                    this.formik.setFieldValue('parent_lastname', '')
                }
            });          
        }        
    }
   goBack(){
    history.push("/student");
   }
    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {   
        const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}      
         // This is the function used to update old parent details
        this.parentName = false;
        if(this.props.getParentResponse.length === undefined){ 
            this.parentName = false;
            this.formik.setFieldValue('email_id', this.props.getParentResponse.email_id)  
            this.formik.setFieldValue('parent_firstname', this.props.getParentResponse.parent_firstname)
            this.formik.setFieldValue('parent_lastname', this.props.getParentResponse.parent_lastname)
        }
      }
      selectClassname(getValue:any){
        const { value } = getValue.target;
        const getGrade = {
            grade:value
        }
        this.setState({getClass:value})
        this.props.fetchMySectionListGet(getGrade);
      }
      selectStandard(e:any){
        const { value } = e.target;
        this.setState({getSection:value})
      }
      getNationalityDropdown= () => {
        this.props.GetNationality().then((res:any) => {
            const { nationality } = this.props;           
        });
        this.props.GetBloodGroup().then((res:any) => {
            const { getBloodGroup } = this.props;
        });
        this.props.getHousedropdown().then((res:any) => {
            const { getHouse } = this.props;
        });
        this.props.GetModeofTransport().then((res:any) => {
            const { Transport } = this.props;
        });
    }
    getLocationDropdown = () => {
        const getData = {
            category: 'country'
        }
        this.props.GetCountryDropdown(getData).then((res:any) => {
            const { country } = this.props;
        });
    }
    getStateDropdown =(country:any) => {
        const getData = {
            category: 'state',
            country: country
        }
        this.props.GetStateDropdown(getData).then((res:any) => {
            const { state } = this.props;
        })
    }
    getCityDropdown = (selectState: string) => {
        const Data = {
            category: 'city',
            country: this.state.selectedCountry,
            state: selectState
        }
        this.props.GetCityDropdown(Data).then((res: any) => {
            const { city } = this.props;
        });
    }
    handleCountryChange = (e: any) => {
        const selectedCountry = e.target.value;
        this.setState({ selectedCountry });
        this.getStateDropdown(selectedCountry);
    };

    handleStateChange = (e: any) => {
        const selectedState = e.target.value;
        this.setState({ selectedState });
        this.getCityDropdown(selectedState);
    };

    getSiblingsDropdown = (values:any) => {
        if(values && values.phone_number && values.phone_number.length === 10 && values.email_id !== ''){
            const data = {
                academic_year: this.state.academicYear,
                email_id: values.email_id,
                mobile: values.phone_number
            }
            this.props.getSiblingsEmisDropdown(data).then((res:any) => {
                const { siblingsData } = this.props;
                this.setState({ siblings_data: siblingsData })
            })
        }
        
    }
    render() { 
        const { loading, ListSection,nationality,getBloodGroup,
            getHouse, Transport, country, state, city, siblingsData } = this.props;  
        const { getClass, getSection } =  this.state;      
        const loadingStudent = { display: loading ? "block" : "none" }; 
        
        if(this.props.getImageURL){
            const getUrlpath = this.props.getImageURL.url;
            if(getUrlpath){
                getUrlpath.forEach((items:any)=>{
                    this.getImage = items;
                })
            }
        }  
        if(getClass && getSection) {
            let postClassesDetails:any = {
                class_name: getClass, 
                section_name: getSection
            }
            this.props.getStudentClassesData(postClassesDetails);
        }
        if(ListSection.length > 0){
            this.getSectionList = false;
        }   
        return (
            <div>
                 <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb 
                        titleName={['Student']} 
                        homeName={['Home']} 
                        url={['dashboard']} 
                        baseName={['Student']} 
                        baseURL={['student']} 
                        mainPageTitle={['Add Student']}/>
                        <div className="row">
                        <div className="col-md-12">
                        <div className="card-box">
                        <div className="card-head">
                        <header>Add Student</header>
                        <div className="tools">
                      </div>
                        </div>
                        <div className="card-body">                          
                            <div className="row">
                            <div className="col-md-12">                                                      
                                <Formik
                                    ref={node=>this.formik = node}
                                    validationSchema={StudentValidation}
                                    initialValues={initialAddValues}
                                    onSubmit={(values:any, actions) => { 
                                        const {academicYear} = this.state
                                        const postValue:any = {
                                            phone_number: values.phone_number,
                                            email_id: values.email_id.toLowerCase(),
                                            student_name: values.student_name,
                                            academic_year:academicYear,
                                            grade: values.grade,
                                            standard:values.standard,
                                            parent_firstname: values.parent_firstname,
                                            parent_lastname: values.parent_lastname,
                                            profile_picture: values.profile_picture,
                                            user_id:'sdad',
                                            gender:values.gender,
                                            date_of_birth:values.date_of_birth,
                                            personal_details : {
                                                emis_id : values.emis_id,
                                                admission_no: values.admission_no,
                                                roll_no: values.roll_no,
                                                nationality: values.nationality,
                                                medical_conditions: values.medical_conditions,
                                                blood_group: values.blood_group,
                                                house: values.house,
                                                transport_details: [{
                                                    mode_of_transport: values.mode_of_transport,
                                                    vehicle_reg_no: values.vehicle_reg_no,
                                                    driver_name: values.driver_name,
                                                    contact_no: values.driver_contact_no
                                                }],
                                                hobbies: values.hobbies,
                                                identification_marks: values.identification_marks,                                                
                                                family_details: [
                                                    {
                                                        "name": values.father_name,
                                                        "relationship": "Father",
                                                        "contact_no": values.father_contact_no,
                                                        "email": values.father_email_id,
                                                        "occupation": values.father_occupation,
                                                        "annual_income": parseFloat(values.father_annual_income)
                                                    },
                                                    {
                                                        "name": values.mother_name,
                                                        "relationship": "Mother",
                                                        "contact_no": values.mother_contact_no,
                                                        "email": values.mother_email_id,
                                                        "occupation": values.mother_occupation,
                                                        "annual_income": parseFloat(values.mother_annual_income)
                                                    }
                                            ],
                                                sibling_details: values.sibling_details,                                                
                                                residential_address: {
                                                    "address": values.residential_address,
                                                    "city": values.city,
                                                    "state": values.city,
                                                    "pincode": values.pincode,
                                                    "country": values.city,
                                                },
                                            }
                                        }          
                                               console.log(postValue)                          
                                        this.props.addStudent(postValue).then((res:any)=>{
                                            if(this.props.errorMessage && this.props.errorMessage !== null && this.props.errorMessage !== undefined){
                                                this.formik.setFieldError("phone_number", this.props.errorMessage.phone_number);
                                            }
                                            
                                        })
                                    }}>
                                        {({ values, errors, isSubmitting, isValidating, dirty, touched, setFieldValue }) => {
                                            const isStudentEmpty = (!values.student_name 
                                                || !values.grade 
                                                || !values.standard 
                                                || !values.phone_number 
                                                || !values.email_id 
                                                || !values.parent_firstname);
                                        return(
                                        <Form>                                    
                                            <div className="row">
                                            <div className="col-md-6 p-t-20 pl-0 pr-0">
                                            <div className="col-md-12 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Student Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="student_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 p-t-20">
                                                <FormControl component="fieldset" error={touched.gender && Boolean(errors.gender)}>
                                                    <FormLabel component="legend">Gender <span className='formmandatorycolor'> *</span></FormLabel>
                                                    <Field component={FormikRadioGroup} name="gender">
                                                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="O" control={<Radio />} label="Other" />
                                                    </Field>
                                                    <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                </FormControl>
                                                </div>
                                                <div className='col-md-12 p-t-20'>                                                                                                          
                                                    <FormGroup>
                                                        <legend className="MuiFormLabel-root">
                                                            <span>Date of Birth</span><span className='formmandatorycolor'> *</span>
                                                        </legend>
                                                        <Field
                                                            type="date"
                                                            name="date_of_birth"
                                                            component={TextField}                                                            
                                                            disabled={false}
                                                            InputProps={{
                                                                inputProps: {
                                                                    max: maxDate, // Disable future dates
                                                                },
                                                            }}
                                                        />                                                        
                                                    </FormGroup>                                               
                                                </div>
                                                <div className="col-md-12 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                    <Field 
                                                    label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>}
                                                    name="grade"  
                                                    select
                                                    component={TextField}        
                                                    disabled={false} 
                                                    onChange={(e:any) => {
                                                        setFieldValue('grade', e.target.value)
                                                        this.selectClassname(e);
                                                      }}   
                                                    >
                                                        { this.props.ListClass.map((item:any, index:any) =>(
                                                                    <MenuItem value={item.grade_id} key={index}>{item.value}</MenuItem>
                                                            ))
                                                        }
                                                    </Field> 
                                                    </FormGroup> 
                                                    </div>
                                                </div>
                                                <div className="col-md-12 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                    <Field 
                                                    label={<><span>Section</span><span className='formmandatorycolor'> *</span></>}
                                                    name="standard"  
                                                    select
                                                    component={TextField}        
                                                    disabled={this.getSectionList} 
                                                    fullwidth 
                                                    onChange={(e:any) => {
                                                        setFieldValue('standard', e.target.value)
                                                        this.selectStandard(e);
                                                      }} 
                                                    >
                                                        { this.props.ListSection.map((item:any, index:any) =>(
                                                                    <MenuItem value={item.value} key={index}>{item.value}</MenuItem>
                                                            ))
                                                        }
                                                    </Field> 
                                                    </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-t-20">
                                                <ImageUpload postImage={this.getImage} setFieldValue={setFieldValue} />
                                            </div>                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                            <Field
                                                                label={<><span>Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="phone_number"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false} 
                                                                onChange={(e:any) => {
                                                                    setFieldValue('phone_number', e.target.value)
                                                                    this.findParantDetail(e);
                                                                    setFieldValue('sibling_details', []);
                                                                    this.setState({ siblings_data: [] }); 
                                                                }}                                                     
                                                            /> 
                                                    </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                type="email"
                                                                name="email_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={this.parentName}
                                                                onChange={(e:any) => {
                                                                    setFieldValue('email_id', e.target.value);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Parent First Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="parent_firstname"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={this.parentName}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label='Parent Last Name'
                                                                type="text"
                                                                name="parent_lastname"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={this.parentName}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Emis Id</span></>}
                                                                type="text"
                                                                name="emis_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Admission Id</span></>}
                                                                type="text"
                                                                name="admission_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Roll Number</span></>}
                                                                type="text"
                                                                name="roll_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Nationality</span></>}
                                                                    select
                                                                    name="nationality"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                >
                                                                    {nationality && nationality.length > 0 && nationality.map((item:any) => (
                                                                        <MenuItem key={item.id} value={item.id}>
                                                                            {item.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Medical Conditions</span></>}
                                                                type="text"
                                                                name="medical_conditions"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Blood Group</span></>}
                                                                select
                                                                name="blood_group"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {getBloodGroup && getBloodGroup.length > 0 && getBloodGroup.map((item: any) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.value}
                                                                </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>House</span></>}
                                                                select
                                                                name="house"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {getHouse && getHouse.length > 0 && getHouse.map((item: any) => (
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        {item.house_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Mode of Transport</span></>}
                                                                select
                                                                name="mode_of_transport"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {Transport && Transport.length > 0 && Transport.map((item: any) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.value}
                                                                </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Vehicle Register Number</span></>}
                                                                    type="text"
                                                                    name="vehicle_reg_no"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Driver Name</span></>}
                                                                    type="text"
                                                                    name="driver_name"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Driver Contact Number</span></>}
                                                                    type="text"
                                                                    name="driver_contact_no"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>
                                                                <ChipInput
                                                                    label={<><span>Hobbies</span></>}
                                                                    value={values.hobbies}
                                                                    onAdd={(hobby) => setFieldValue("hobbies", [...values.hobbies, hobby])}
                                                                        onDelete={(hobby, index) =>
                                                                            setFieldValue(
                                                                            "hobbies",
                                                                            values.hobbies.filter((tag:any, i:any) => i !== index)
                                                                            )
                                                                        }                                                                   
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <ChipInput
                                                                    label={<><span>Identification Marks</span></>}
                                                                    value={values.identification_marks}
                                                                    onAdd={(marks) => setFieldValue("identification_marks", [...values.identification_marks, marks])}
                                                                        onDelete={(marks, index) =>
                                                                            setFieldValue(
                                                                            "identification_marks",
                                                                            values.identification_marks.filter((tag:any, i:any) => i !== index)
                                                                            )
                                                                        }
                                                                    // placeholder='Identification Marks'                                                                    
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>                                                    
                                                    <div className='col-md-6 p-t-0 d-flex'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0 pb-0 col-md-8">
                                                        <FormGroup>
                                                        <Autocomplete
                                                            fullWidth
                                                            multiple
                                                            id="checkboxes-tags-demo"
                                                            options={ this.state.siblings_data|| []}
                                                            disableCloseOnSelect
                                                            noOptionsText={'No matching records found.'}
                                                            getOptionLabel={(option: any) => option.student_name}
                                                            value={values.sibling_details}
                                                            onChange={(e, value, resonse) => {
                                                            setFieldValue('sibling_details', value);
                                                            }}
                                                            renderOption={(option, { selected }) => {
                                                            return (
                                                                <React.Fragment>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.student_name}
                                                                </React.Fragment>
                                                            )
                                                            }}
                                                            className="mb-0 mt-1"
                                                            renderInput={(params) => (
                                                            <Field
                                                                component={TextField}
                                                                name="sibling_details"
                                                                error={!!errors.search_sibling_name}
                                                                helperText={errors.search_sibling_name}
                                                                {...params} label={<><span>Siblings Details</span></>}
                                                            />                                                            
                                                            )}                                                            
                                                        />                                                       
                                                        </FormGroup>                                                        
                                                    </div>                                                  
                                                    <div className="text-right mb-2 mr-1 mt-2 col-md-3">                                               
                                                            <Button className="btn btn-pink mr-1 ml-1" 
                                                            type="button" 
                                                            onClick={() => this.getSiblingsDropdown(values)}
                                                            disabled={!values.phone_number || !values.email_id}>Search</Button>                                                                                              
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Father Name</span></>}
                                                                type="text"
                                                                name="father_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Contact Number</span></>}
                                                                type="text"
                                                                name="father_contact_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Email</span></>}
                                                                type="text"
                                                                name="father_email_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />        
                                                            <Field
                                                                label={<><span>Occupation</span></>}
                                                                type="text"
                                                                name="father_occupation"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                            
                                                            <Field
                                                                label={<><span>Annual Income</span></>}
                                                                type="text"
                                                                name="father_annual_income"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                                                                              
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Mother Name</span></>}
                                                                type="text"
                                                                name="mother_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Contact Number</span></>}
                                                                type="text"
                                                                name="mother_contact_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Email</span></>}
                                                                type="text"
                                                                name="mother_email_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />        
                                                            <Field
                                                                label={<><span>Occupation</span></>}
                                                                type="text"
                                                                name="mother_occupation"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                            
                                                            <Field
                                                                label={<><span>Annual Income</span></>}
                                                                type="text"
                                                                name="mother_annual_income"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                                                                              
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                        <FormGroup>
                                                            <Field
                                                            label={<><span>Residential Address</span></>}
                                                            name='residential_address'
                                                            component={TextField}
                                                            disabled={false}
                                                            /> 
                                                            <Field
                                                            label={<><span>Country</span></>}
                                                            name="country"
                                                            select 
                                                            component={TextField}
                                                            disabled={false}
                                                            onChange={(e:any) => {
                                                                this.handleCountryChange(e);
                                                                setFieldValue('country', e.target.value)
                                                                setFieldValue('state', '');
                                                                setFieldValue('city', '');
                                                            }}
                                                            >
                                                            {country && country.length > 0 && country.map((item:any) => (
                                                                <MenuItem key={item.country} value={item.country}>
                                                                    {item.country}
                                                                </MenuItem>
                                                            ))}
                                                            </Field>  
                                                            <Field
                                                            label={<><span>State</span></>}
                                                            name='state'
                                                            select
                                                            component={TextField}
                                                            disabled={false}
                                                            onChange={(e:any) => {
                                                                this.handleStateChange(e);
                                                                setFieldValue('state', e.target.value);
                                                                setFieldValue('city', '');
                                                            }}
                                                            >
                                                            {state && state.length > 0 && state.map((item: any) => (
                                                                <MenuItem key={item.state} value={item.state}>
                                                                    {item.state}
                                                                </MenuItem>
                                                            ))}
                                                            </Field>        
                                                            <Field
                                                            label={<><span>City</span></>}
                                                            name='city'
                                                            select
                                                            component={TextField}
                                                            disabled={false}
                                                            >
                                                            {city && city.length > 0 && city.map((item: any) => (
                                                                <MenuItem key={item.uid} value={item.uid}>
                                                                    {item.city}
                                                                </MenuItem>
                                                            ))}
                                                            </Field>                                                             
                                                            <Field
                                                            label={<><span>Pincode</span></>}
                                                            name='pincode'
                                                            component={TextField}
                                                            disabled={false}
                                                            />                                                                                                               
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-right mb-3 mr-2 mt-4">
                                                <Button className="btn btn-pink mr-1 ml-1" 
                                                disabled={isStudentEmpty 
                                                || isValidating 
                                                || !dirty || !!(errors.student_name && touched.student_name) || !!(errors.grade && touched.grade)||
                                                !!(errors.standard && touched.standard) || 
                                                !!(errors.phone_number && touched.phone_number) || 
                                                !!(errors.email_id && touched.email_id) || 
                                                !!(errors.parent_firstname && touched.parent_firstname)} type="submit">Submit</Button>
                                                <Button className="btn btn-default mr-1 ml-1" onClick={this.goBack}>Cancel</Button>
                                            </div>
        
                                    </Form>
                                    )}
                                }
                                </Formik>
                               
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div style={loadingStudent}><SpinnerLoader/></div>
        </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        ListClass:state.classes.my_class,
        ListSection:state.classes.my_section,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        loading:state.student.loading,
        errorMessage: state.student.errors,
        getParentResponse:state.student.parentDetails,
        checkParent:state.student.getParentStatus,
        getImageURL:state.student.ImageURL,
        getProfile:state.profile.profileData,
        license: state.license.license,
        licenseBySchool: state.license.licenseBySchool,
        nationality: state.teacher.GetNationality,
        getBloodGroup: state.teacher.GetBloodGroup,
        getHouse: state.student.getHouseData,
        Transport: state.student.getTransportData,
        country: state.teacher.GetCountryData,
        state: state.teacher.GetStateData,
        city: state.teacher.GetCityData,
        siblingsData: state.student.getSiblingsData
    };
  };

export default connect(mapStateToProps, { addStudent, 
    fetchGradePost, 
    fetchGradeStandardPost, 
    fetchParent, 
    fetchMyClassListGet, 
    fetchMySectionListGet,
    getStudentClassesData, 
    stopYearData, 
    fetchLicenseBySchoolGet,
    GetBloodGroup,
    GetNationality,
    getHousedropdown,
    getSiblingsEmisDropdown,
    GetModeofTransport,
    GetCountryDropdown,
    GetStateDropdown,
    GetCityDropdown
 })(AddStudent)
