import * as yup from 'yup';

export const HomeStudySchema = yup.object().shape({
    grade_id: yup.string()
        .required("Please select the Grade"),
    controlpanel_details: yup.array().of(
        yup.object().shape({
            question_type: yup.string()
                .required("Please select the Question Type"),
            question_type_marks: yup.string()
                .required("Please select the Mark"),
            question_category: yup.string()
                .required("Please select the Question Category"),
            difficulty_level: yup.string()
                .required("Please select the difficulty level"),
            no_of_question: yup.string()
                .required('Please enter the No of Question')
                .typeError('Please enter a valid number')
                .matches(/^[1-9][0-9]*$/,'Value must be greater than 0')
        })
    )
});

export const StudyViewSchema = yup.object().shape({
    grade: yup.string()
        .required("Please select the Grade"),
    standard: yup.string()
        .required("Please select the Section"),
    student: yup.string()
        .required("Please select a Student"),
    subject: yup.string()
        .required("Please select the Subject")
});