class FileUploadPlugin {
    loader: any;
    props: any;
    url: any;
    constructor(loader: any, props: any, url: any) {
        this.loader = loader;
        this.props = props;
        this.url = url;
    }
    async upload() {
        return this.loader.file
            .then(async (file: File) => {
                const maxSizeInBytes = 1024 * 1024 * 6; // 6MB file size limit

                // Validate file size
                if (file.size > maxSizeInBytes) {
                    return Promise.reject('File size exceeds 6MB. Please upload a smaller file.');
                }

                // Convert file to Base64
                const base64 = await this.convertFileToBase64(file);

                // Return Base64 string as response
                return {
                    default: base64,
                };
            });
    }

    // Helper method: Convert file to Base64
    private convertFileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result as string); // Base64-encoded string
            };

            reader.onerror = (error) => {
                reject('Error converting file to Base64: ' + error);
            };

            reader.readAsDataURL(file); // Reads the file as Base64
        });
    }
}

export default FileUploadPlugin;
