import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import { LoadMoreType } from '../../components/type';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { StudentType } from '../../store/student/Types';
import { CommonState } from '../../components/type';
import { fetchGradePost, fetchGradeStandardPost } from '../../store/classes/Actions';
import { FormControlLabel, FormGroup, MenuItem } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Switch from '@material-ui/core/Switch';
import { deleteStudent, fetchStudentPost, refreshData } from '../../store/student/Actions';
import CommonLoader from '../../components/CommonLoader';
import { UserRoles, notificationMsg } from '../../services/Constants'
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchMyClassListGet, fetchMySectionListGet } from '../../store/classes/Actions';
import { fetchLicenseBySchoolGet } from '../../store/license/Actions';
import { formValidationPatten } from '../../services/Constants';
import toaster from "toasted-notes";
import history from '../../History';
import { CommonModel } from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsStudentProps {
  loading: boolean;
  getYear?: any;
  loadingClass: boolean;
  getClassSectionData?: any;
  studentDetails: StudentType;
  studentDetailDelete: StudentType;
  deleteStudentDetails: any;
  ListGrade: any;
  ListStandard: any;
  fetchStudentPost: (loadMoreType: LoadMoreType) => any;
  fetchLicenseBySchoolGet: (getData: any) => any;
  deleteStudent: (studentDetailDelete: any) => any;
  addStudent: (student: StudentType) => any;
  fetchGradePost: () => any;
  fetchGradeStandardPost: () => any;
  fetchMyClassListGet: () => any;
  fetchMySectionListGet: (grade: any) => any;
  stopYearData: () => any;
  refreshData: () => any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  sectionLoadError?: any;
  stopYear?: any;
  newlyCreatedStudentInfo?: any;
  license?: any;
  licenseBySchool?: any;
  profileInfo?: any;
}
export interface listProps {
  grade: string,
  standard: string
}

export class ViewStudentDetails extends React.Component<PostsStudentProps, CommonState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteStudentId: [],
      query: '',
      studentData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 0,
      acsOrder: true,
      descOrder: false,
      page: 1,
      page_no: 1,
      search: '',
      SortOrderData: '',
      OrderNameData: '',
      checkedstudent: true,
      academicYear: null,
      class_id: 7,
      grade: '',
      standard: '',
      postData: {},
      licenseBySchool: {}
    };
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.UpdateYearDate();
  }

  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.UpdateYearDate(this.props.getYear)
    }
  }
  componentDidUpdate() {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.props.stopYearData()
    }
  }
  UpdateYearDate(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear })
      this.getSchoolDetails(getYear)
    } else {
      this.setState({ academicYear: this.props.getYear })
      this.getSchoolDetails(this.props.getYear)
    }
  }

  // This is function used to get student details and grade and section
  getSchoolDetails(getYear?: any) {
    const { getClassSectionData, newlyCreatedStudentInfo } = this.props;
    this.props.fetchGradePost();
    
    const getLicenseBySchool = {
      academic_year: getYear,
      module_name:'student_module'
    }
    this.props.fetchLicenseBySchoolGet(getLicenseBySchool).then((res: any) => {
      const { licenseBySchool } = this.props
      const studentLicense =  licenseBySchool;
      this.setState({ licenseBySchool: studentLicense });
    })

    if (getClassSectionData && getClassSectionData !== null && getClassSectionData !== undefined && getClassSectionData.length > 0) {
      let getValue: any = getClassSectionData;
      if (getValue) {
        this.setState({ grade: getValue.class_name, standard: getValue.section_name })
        this.getChangeStudnetDetails(getValue, getYear);
      }
    } else {
      this.props.fetchMyClassListGet().then((res: any) => {
        const { ListGrade } = this.props;
        let selectedValue: any;
        if (ListGrade.length) {
          selectedValue = ListGrade[0].grade_id;
          if (newlyCreatedStudentInfo && newlyCreatedStudentInfo.grade) {
            selectedValue = newlyCreatedStudentInfo.grade;
          }
          this.setState({ grade: selectedValue })
        }
        const getClassName = {
          grade: selectedValue
        }
        if (getClassName) {
          this.props.fetchMySectionListGet(getClassName).then((res: any) => {
            const { ListStandard } = this.props;
            let selectStandard: any;
            if (ListStandard) {
              if (ListStandard.length) {
                selectStandard = ListStandard[0].value;
                if (newlyCreatedStudentInfo && newlyCreatedStudentInfo.standard) {
                  selectStandard = newlyCreatedStudentInfo.standard;
                }
                this.setState({ standard: selectStandard })
              }
              this.getStudnetDetails(getYear);
              this.props.refreshData();
            } else {
              let errorMessage: any = this.props.sectionLoadError;
              if (errorMessage.status === false) {
                toaster.notify(errorMessage.message, {
                  position: 'top',
                  duration: notificationMsg.errorNotificationDuration
                });
                history.push('/');
              }

            }
          })
        }

      });
    }

  }
  getStudnetDetails(getYear?: any) {
    const { grade, standard } = this.state;
    const getPostStudent = {
      page_no: 1,
      academic_year: getYear,
      search: this.state.search,
      grade: grade,
      standard: standard
    }
    this.setState({
      page: 1
    })
    this.props.fetchStudentPost(getPostStudent).then((res: any) => {
      window.scrollTo(0, 0);
    });
  }
  getChangeStudnetDetails(getData: any, getYear: any) {
    let getValue: any = getData;
    if (getValue) {
      const getPostStudent = {
        page_no: 1,
        academic_year: getYear,
        search: this.state.search,
        grade: getValue.class_name,
        standard: getValue.section_name
      }
      this.props.fetchStudentPost(getPostStudent).then((res: any) => {
        window.scrollTo(0, 0);
      });
    }


  }
  checkDeleteStatus() {
    const { academicYear } = this.state;
    const selectStudentDataList = this.props.deleteStudentDetails;
    if (selectStudentDataList === true) {
      const getPostStudent = {
        page_no: 1,
        academic_year: academicYear,
        class_id: this.state.class_id,
        search: this.state.search,
        grade: this.state.grade,
        standard: this.state.standard
      }
      this.props.fetchStudentPost(getPostStudent).then((res: any) => {
        window.scrollTo(0, 0);
      });
    }
  }
  handleInputStudentChange = (e: any) => {
    const { value } = e.target;
    const { academicYear } = this.state;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if (intRegex.test(getSearchValue) || getSearchValue === '') {
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          academic_year: academicYear,
          class_id: this.state.class_id,
          search: getSearchValue,
          grade: this.state.grade,
          standard: this.state.standard
        }
        this.props.fetchStudentPost(postSearchValue);
        this.setState({
          hasMore: true,
          page_no: 1,
          search: getSearchValue
        })
      } else {
        const postSearchValue = {
          page_no: 1,
          search: '',
          academic_year: academicYear,
          class_id: this.state.class_id,
          grade: this.state.grade,
          standard: this.state.standard
        }
        this.props.fetchStudentPost(postSearchValue);
        this.setState({
          hasMore: true,
          page_no: 1,
          search: ''
        })
      }
    }

  }
  public handleDelete = () => {
    this.setState({ showDelete: false, hasMore: true });
    this.props.deleteStudent(this.state.deleteStudentId).then((items: any) => {
      window.scrollTo(0, 0);
      this.UpdateYearDate();
    });
  }
  public hideStudentDelete = () => {
    this.setState({ showDelete: false, hasMore: true });
  }
  public showStudentDelete = (getData: any) => {
    let postValue: any
    let titleClassMessage: any;
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if (getData && titleClassMessage) {
      postValue = {
        title: 'Please Confirm',
        action_status: titleClassMessage,
        action_name: 'Student'
      }
      this.setState({ showDelete: true, deleteStudentId: getData, postData: postValue });
    }
  }

  public fetchMoreStudentData = () => {
    const { academicYear } = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: this.state.page + 1,
          academic_year: academicYear,
          class_id: this.state.class_id,
          search: this.state.search,
          grade: this.state.grade,
          standard: this.state.standard,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchStudentPost(postValue);
        this.setState({
          page: this.state.page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  getGradeOption = (e: any) => {
    const { academicYear } = this.state;
    const { value } = e.target;
    const getValue = value;
    this.setState({ grade: getValue, hasMore: true });
    const getClassName = {
      grade: getValue
    }
    if (getClassName) {
      this.props.fetchMySectionListGet(getClassName)
    }
    setTimeout(() => {
      this.getStudnetDetails(academicYear)
    }, notificationMsg.getDuration);
  }

  getStandardOption = (e: any) => {
    const { academicYear } = this.state;
    const { value } = e.target;
    const getStandard = value;
    this.setState({ standard: getStandard, hasMore: true });
    setTimeout(() => {
      this.getStudnetDetails(academicYear)
    }, notificationMsg.getDuration);
  }
  //in your component
  addDefaultSrc = (ev: any) => {
    ev.target.src = 'assets/img/user/teacher-profile.jpg'
  }

  renderStudentDetails() {
    const { loading, loadingClass, profileInfo } = this.props;
    // this.props.records.sort((a: any, b: any) => a.studentclass_details.card_id - b.studentclass_details.card_id);
    const { academicYear } = this.state;
    const loadingStudent = { display: loading || loadingClass ? "block" : "none" };
    return (
      <div>
        <InfiniteScroll
          dataLength={this.props.records.length}
          next={this.fetchMoreStudentData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingStudent}>Loading...</h4>}
        >
          {this.props.records.length > 0 ?
            <div className="row mt-5 w-100">
              {
                this.props.records.map((items: any, index: any) => (
                  <div className="col-md-3" key={index}>
                    <div className="card">
                      <div className="m-b-20">
                        <div className="doctor-profile">
                          <div id="studentList" className='profile-header bg-b-danger'>
                            <div className="pull-right">
                            </div>
                          </div>
                          {items.studentclass_details.profile_picture ?

                            <img onError={this.addDefaultSrc} src={items.studentclass_details.profile_picture}
                              className="user-img mb-2" alt="Student Profile" />
                            : <img src="assets/img/user/teacher-profile.jpg" className="user-img mb-2" alt="" />}
                          <div>
                            <div className="user-name mt-0">{items.student_name}</div>
                            <p>Card Id: {items.studentclass_details.card_id}</p>
                            {items.studentclass_details.is_internal_transferred ?
                              <div className="pt-3 m-4">
                                <label className='label-warning'>Internally Transferred</label>
                              </div>
                              : ''}
                          </div>
                          {items.studentclass_details.is_internal_transferred ? '' : <>
                            <div className="profile-userbuttons">

                            {profileInfo && String(profileInfo.usertype) === UserRoles.schoolAdmin ? 
                            <>
                              <Link 
                                to={items.studentclass_details.is_active ? `/student_transfer/${items.id}` : "#"} 
                                className={`btn btn-circle ${items.studentclass_details.is_active ? 'btn-pink': 'btn-disable'} btn-sm`} 
                                title="Transfer Student"
                              >
                                <i className="fa fa-exchange" aria-hidden="true"></i>
                              </Link>
                              <Link 
                                to={items.studentclass_details.is_active ? `/student_edit/${items.id}` : "#"} 
                                className={`btn btn-circle ${items.studentclass_details.is_active ? 'btn-default' : 'btn-disable'} btn-sm`} 
                                title="Edit Student"
                              >
                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                              </Link>
                            </>: null }
                              <Link 
                                to={`/student_profile/${items.id}`} 
                                className={`btn btn-circle btn-pink btn-sm`} 
                                title="View Student Profile"
                              >
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </Link>
                            </div>
                            {profileInfo && String(profileInfo.usertype) === UserRoles.schoolAdmin ? 
                            <div className="pull-right">
                              {items.studentclass_details.is_active ? <FormControlLabel
                                value="start"
                                control={<Switch
                                  checked={items.studentclass_details.is_active}
                                  onClick={() => this.showStudentDelete({ id: String(items.id), isActive: false, academic_year: academicYear })}
                                  name="checkedA"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  title="Deactivate Student"
                                />}
                                className="mr-1 mt-2"
                                label="Active"
                                labelPlacement="start"
                              /> :
                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={items.studentclass_details.is_active}
                                    onClick={() => this.showStudentDelete({ id: String(items.id), isActive: true, academic_year: academicYear })}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title="Activate Student"
                                  />}
                                  className="mr-3 mt-2"
                                  label="Inactive"
                                  labelPlacement="end"
                                />
                              }
                            </div> : null }
                          </>}

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            : <CommonLoader />}
        </InfiniteScroll>
      </div>
    )
  }
  // This is the functio use to filter grit data
  public showFilterStudent = (getdata: any) => {
    const { academicYear } = this.state;
    if (getdata.sort_by === 'desc') {
      this.setState({
        acsOrder: false,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        role: this.state.role,
        hasMore: true,
        page: 1
      })
      const postValue = {
        page_no: 1,
        academic_year: academicYear,
        class_id: this.state.class_id,
        search: this.state.search,
        grade: this.state.grade,
        standard: this.state.standard,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by
      }
      this.props.fetchStudentPost(postValue);
    } else {
      this.setState({
        acsOrder: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by, 
        hasMore: true,
        page: 1
      })
      const postValue = {
        page_no: 1,
        academic_year: academicYear,
        class_id: this.state.class_id,
        search: this.state.search,
        grade: this.state.grade,
        standard: this.state.standard,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by
      }
      this.props.fetchStudentPost(postValue);
    }
  }
  render() {
    const { grade, standard, showDelete, postData, licenseBySchool } = this.state;
    this.checkDeleteStatus();
    const { loading, ListStandard, license } = this.props;
    const loadingStudent = { display: loading ? "block" : "none" };
    let gradeList: any;
    if (this.props.ListGrade) {
      gradeList = this.props.ListGrade;
    }
    const initialListValues: listProps = {
      grade: this.state.grade,
      standard: this.state.standard
    }
    return (
      <div>
        <CommonModel
          onState={showDelete}
          currentState={postData}
          onConfirm={this.handleDelete}
          onCancel={this.hideStudentDelete}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Students']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Students']} />
              <div>
                {ListStandard && gradeList ?
                  <div className="row">
                    <div className="col-md-12 col-lg-12 mt-2 mb-5">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="overview-panel purple
                                            text-center mt-2 mb-1">
                            <div className="value white">
                              <p className="mb-1 mt-1"> Total License</p>
                              <h5 className="mb-2 mt-1 color-pink">
                                {licenseBySchool && licenseBySchool.total_license}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="overview-panel purple
                                            text-center mt-2 mb-1">
                            <div className="value white">
                              <p className="mb-1 mt-1"> Remaining License</p>
                              <h5 className="mb-2 mt-1 color-pink">
                                {licenseBySchool && licenseBySchool.remaining_license}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-4">
                        <div className="col-md-4 col-sm-4 col-xs-12">
                          <header className="mt-3 mb-3">
                            <Link to={'/student_add'}>
                              <button className="btn btn-pink">Add Student</button>
                            </Link>
                            {this.state.acsOrder ?
                              <button onClick={() => this.showFilterStudent({ sort_by: 'desc', order_by: 'student_name' })}
                                title="Ascending order" className="btn btn-default ml-2">Student Name&nbsp;
                                <i className="fa fa-sort-amount-asc" aria-hidden="true"></i></button> : <button
                                  onClick={() => this.showFilterStudent({ sort_by: 'asc', order_by: 'student_name' })}
                                  title="Descending order" className="btn btn-default ml-2">
                                Student Name <i className="fa fa-sort-amount-desc" aria-hidden="true"></i></button>}
                          </header>
                        </div>
                        <div className="col-md-5 mt-0">
                          <Formik
                            initialValues={initialListValues} onSubmit={(values, actions) => {
                            }}>
                            {({ values, errors, isSubmitting, isValidating, handleChange, dirty, touched }) => (
                              <Form>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <FormGroup>
                                      <Field label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>} name="grade" value={grade} select component={TextField} disabled={false} onChange={this.getGradeOption}>
                                        {gradeList.map((item: any, index:any) => (
                                          <MenuItem value={item.grade_id} key={index}>{item.value}</MenuItem>
                                        ))
                                        }
                                      </Field>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <FormGroup>
                                      <Field label={<><span>Section</span><span className='formmandatorycolor'> *</span></>} name="standard" select value={standard} component={TextField}
                                        onChange={this.getStandardOption} disabled={false} fullwidth>
                                        {this.props.ListStandard.map((item: any, index:any) => (
                                          <MenuItem value={item.value} key={index}>{item.value}</MenuItem>
                                        ))
                                        }
                                      </Field>
                                    </FormGroup>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                        <div className="col-md-3">
                          <div className="row">
                            <div className="col-md-8 col-sm-8 col-8 mt-3">
                              <div className="tools">
                                <input
                                  placeholder="Search"
                                  name="search"
                                  className="form-control"
                                  onChange={this.handleInputStudentChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-4 mt-3 pl-0">
                              <h4 className="pull-right">Total: {this.props.total}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.renderStudentDetails()}
                    </div>
                  </div>
                  : <div style={loadingStudent}><SpinnerLoader /></div>}
              </div>
            </div>
          </div>
        </div>
        <div style={loadingStudent}><SpinnerLoader /></div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    getYear: state.profile.getYear,
    stopYear: state.profile.yearStatusFails,
    getClassSectionData: state.profile.getStudentClasses,
    ListGrade: state.classes.my_class,
    ListStandard: state.classes.my_section,
    studentDetails: state.student.items,
    loading: state.student.loading,
    loadingClass: state.classes.loading,
    total: state.student.total,
    per_page: state.student.per_page,
    records: state.student.records,
    page: state.student.page,
    totalPage: state.student.totalPage,
    deleteStudentDetails: state.student.isAuthenticated,
    sectionLoadError: state.classes.errors,
    newlyCreatedStudentInfo: state.student.newlyCreatedStudentInfo,
    license: state.license.license,
    licenseBySchool: state.license.licenseBySchool,
    profileInfo:state.profile.profileData,
  };
}

export default connect(mapStateToProps, {
  fetchGradePost,
  fetchGradeStandardPost,
  deleteStudent,
  fetchStudentPost,
  fetchMyClassListGet,
  fetchMySectionListGet, stopYearData, refreshData, fetchLicenseBySchoolGet
})(ViewStudentDetails)
