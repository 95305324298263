import React, { createRef } from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb';
import { diaryList } from '../../store/diary/Types';
import { StudentList } from '../../components/StudentList';
import ImageUpload from '../../components/ImageUpload';
import {
  fetchDiaryPost, fetchGetAllClassList,
  fetchGetStudent,
  addDiaryPost,
  fetchSearchAllStudent,
  ResetDiary
} from '../../store/diary/Actions';
import CommonLoader from '../../components/CommonLoader';
import { Button, FormGroup } from '@material-ui/core';
import { Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { NoticesBoardValidation } from './NoticeBoardValidation';
import { NoticesBoard } from '../../services/Config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField as MTextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { studentValida, FormvalidationMessage, notificationMsg } from '../../services/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toaster from "toasted-notes";
import history from '../../History';
import '../diary/DiaryStyle.scss';
import { stopYearData } from '../../store/profile/Actions'
import { icon, checkedIcon } from '../../components/checkBoxTypes'
import {AddNoticeBoard} from './Types';
import {imageValidateS} from '../../services/Constants'
import { editorImageUpload } from '../../store/student/Actions';
import FileUploadPlugin from '../../components/FileUploadPlugin';

export interface PostsDiaryProps {
  getYear?:any;
  fetchDiaryPost: (getDiary: any, url: any) => any;
  fetchGetAllClassList: (loadMoreType: any) => any;
  fetchGetStudent: (getStudentList: any) => any;
  addDiaryPost: (addDiaryPost: any, URLPath: any) => any;
  fetchSearchAllStudent: (searchStudent: any) => any;
  editorImageUpload:(data:any, url:any)=>void;
  stopYearData:()=>any;
  ResetDiary:()=>any;
  loading: boolean
  DiaryDetails: any;
  AllClassList: any;
  AllStudentList: any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  getDiaryImageURL: any;
  loadingStudent: boolean;
  getResponseClassList: any;
  getResponseStudent: any;
  errorMessage: any;
  getProfile: any;
  getSearchStudentList: any;
  updateStatuRecord?: any;
  stopYear?:any;
  recordLoader?:boolean;
  isBoardError?:boolean;
}

const initialNoticeBoard: AddNoticeBoard = {
  your_title: '',
  message: '',
  images: '',
  diaryMessageDetails: true
}

export class ViewNoticeBoard extends React.Component<PostsDiaryProps, diaryList>  {
  formikNoticeBoard: any
  getAllStudentDetails: any = [];
  getStudentList: any;
  getClassList: any = [];
  postStudentList: any = [];
  getDiaryImage: any;
  editorRef: any
  state = {
    checkedListAll: [],
    ItemsChecked: false,
    academicYear:null,
    ShowStudentList: false,
    images: [],
    setImages: [],
    maxNumber: 65,
    AllStudent: [],
    getClass: "1st",
    page: 1,
    search: '',
    SortOrderData: '',
    OrderNameData: '',
    SelectValue: 'pl-2',
    standardlist: [],
    studentlist: [],
    allstudentslist: [],
    currentCheckboxAllStudent: false,
    currentSelectedStandard: [],
    selectedDiariesList: [],
    getDiaryImage: '',
    hasMore: true,
    responseData: [],
    message: '',
    diaryMessageDetails: true,
    studentAutoCompleteData:[],
    viewNoticeBoardForm:false,
    isExpanded:false
  };
  constructor(props: any) {
    super(props);
    this.handleCheckStudent = this.handleCheckStudent.bind(this);
    this.editorRef = createRef();
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.checkUserTypes()
    this.getUpdateYear() // This is function called getUpdateYear()
  } 

  // This is function used to get update year
  getUpdateYear(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.setState({academicYear:getYear})
        this.getNoticeBoard(getYear)
      }else {
        this.setState({academicYear:this.props.getYear})
        this.getNoticeBoard(this.props.getYear)
      }
}
 // This is function used get currect year and called initial values
getNoticeBoard(getYear?:any){
  this.getNoticeBoardData(getYear)
  const loadMoreType = {
    academic_year:getYear
  }
  this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {
    const { AllClassList, AllStudentList } = this.props
    if (AllClassList && AllClassList.length > 0) {
      this.setState({ currentSelectedStandard: AllClassList[0] });
      this.setState({ standardlist: AllClassList });
      this.props.fetchGetStudent({
        class_id: AllClassList[0].id,
        academic_year:getYear
      });
    }
    this.setState({ studentlist: AllStudentList });

  })

  this.props.fetchSearchAllStudent({
    search: ' ',
    academic_year:getYear
  })
}
  checkUserTypes(){
    const {getProfile} = this.props;
    if(getProfile && getProfile !== undefined && getProfile !== null){
      if (getProfile.usertype === 1 || getProfile.usertype === 2) {
        this.setState({viewNoticeBoardForm:true})
      } else if (getProfile.usertype === 3) {
        this.setState({viewNoticeBoardForm:false})
      }
    }
  }
  componentWillUpdate(nextProps:any, nextState:any) {
    const {stopYear} = this.props;
    if(stopYear === true){
      this.getUpdateYear(this.props.getYear)
    }
  }
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    const {stopYear} = this.props;
    if(stopYear === true){
      this.props.stopYearData()
    }
    if (this.props.getDiaryImageURL) {
      const getUrlpath = this.props.getDiaryImageURL.url;
      const noticeImage:any = [];
      if (getUrlpath) {
        getUrlpath.forEach((items:any)=>{
          const diaryNotices:any = {
            url:items
          };
          noticeImage.push(diaryNotices);
        })
        this.getDiaryImage = noticeImage;
      }
      else {
        this.getDiaryImage = noticeImage;
      }
    }
    if (this.props.errorMessage.data) {
      this.formikNoticeBoard.setFieldError('your_title', this.props.errorMessage.data.title)
      this.formikNoticeBoard.setFieldError('message', this.props.errorMessage.data.message)
    }
  }
  
  getNoticeBoardData(getYear?:any) {
    const { search, SortOrderData, OrderNameData } = this.state;
    const postValue = {
      page_no: 1,
      search: search,
      sort_by: SortOrderData,
      order_by: OrderNameData,
      academic_year: getYear
    }
    this.setState({page:1});
    this.props.fetchDiaryPost(postValue, NoticesBoard.noticeboard).then((res: any) => {
      window.scrollTo(0, 0);
      const {records} = this.props;
      if(records && records !== null && records !== undefined && records.length !==0){
        this.getRecordData()
        this.setState({ responseData: this.props.records })
      }else {
        this.getRecordData()
        this.setState({ responseData: [] })
      }
      
    });
  }

  standardonSelect(e: any, data: any) {
    const { selectedDiariesList, academicYear } = this.state;
    const { AllStudentList } = this.props;
    const { items } = data;
    const standardlistele: any = document.getElementById("standardlist");
    if (standardlistele.querySelector('.active')) {
      standardlistele.querySelector('.active').classList.remove('active');
    }
    this.setState({ currentSelectedStandard: items });
    e.target.classList.add('active')
    const getStudent = {
      class_id: items.id,
      academic_year: academicYear
    }
    this.props.fetchGetStudent(getStudent).then((res: any) => {
      this.setState({ studentlist: AllStudentList });
      this.unSelectAll();
      let findDiariesList = selectedDiariesList.find((item: any) => item.id === getStudent.class_id);
      if (findDiariesList) {
        this.selectPreviousSelectedStudents(findDiariesList);
      }
    });

  }

  handleCheckStudent(e: any) {
    const { selectedDiariesList, currentSelectedStandard } = this.state;
    const { AllStudentList } = this.props;
    const standardId: any = currentSelectedStandard;
    const checkbox: any = document.getElementById('student_' + e.currentTarget.dataset.id);
    if (checkbox.checked) {
      checkbox['checked'] = true;

      const availableDiareslist = selectedDiariesList.filter((item: any) => item.id === standardId.id);
      if (availableDiareslist && availableDiareslist.length) {
        const findDiariesList = selectedDiariesList.map((item: any) => {
          if (item.id === standardId.id) {
            item.studentslist.push(AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id))[0])
            if (item.studentslist.length === AllStudentList.length) {
              let selectAllCheckbox: any = document.getElementById('student_all');
              selectAllCheckbox['checked'] = true;
              item.all = true;
              checkbox['checked'] = true;
            }
          }
          return item;
        });
        this.setState({ selectedDiariesList: findDiariesList });
      } else {
        let selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = false;
        selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id));
        if(AllStudentList.length == 1){
          selectedAllStandardStudents['all'] = true;
          const selectAllCheckbox: any = document.getElementById('student_all');
          selectAllCheckbox['checked'] = true;
        }
        this.setState({ selectedDiariesList: [...selectedDiariesList, selectedAllStandardStudents] })
      }

    } else {

      const findDiariesList = selectedDiariesList.map((item: any) => {
        if (item.id === standardId.id) {
          let studentslist;
          if (item.all) {
            studentslist = AllStudentList.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          } else {
            studentslist = item.studentslist.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          }
          item.studentslist = studentslist;
          item.all = false;
        }
        return item;
      })
      this.setState({ selectedDiariesList: findDiariesList });
      this.setState({ currentCheckboxAllStudent: false });

      const selectAllCheckbox: any = document.getElementById('student_all');
      selectAllCheckbox['checked'] = false;
      checkbox['checked'] = false;
    }
    this.checkValuelist('', '', '', checkbox)
  }

  selectPreviousSelectedStudents(data: any) {
    const { AllStudentList } = this.props;

    const { all } = data;
    const selectAllCheckbox: any = document.getElementById('student_all');

    if (all) {

      AllStudentList.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })

      selectAllCheckbox['checked'] = true;
      this.setState({ currentCheckboxAllStudent: true })
    } else {

      data.studentslist.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })
      if (data.studentslist.length === AllStudentList.length) {
        const selectAllCheckbox: any = document.getElementById('student_all');
        selectAllCheckbox['checked'] = true;
      }
    }

  }

  //This is function used to search values
  checkValuelist = (e?: any, data?: any,reason?:any, handleCheck?:any) => {
    const { AllStudentList } = this.props;
    const { selectedDiariesList, standardlist, currentSelectedStandard  } = this.state;   
   if(selectedDiariesList){
    const getStudentData:any = [];
    selectedDiariesList.forEach((items:any, index:any)=>{
      if(items.studentslist){
        items.studentslist.forEach((items:any, index:any)=>{
          getStudentData.push(items)
        })
      }
    })
    if(getStudentData && getStudentData.length > 0){
      this.setState({studentAutoCompleteData: getStudentData})
    }else {
      this.setState({studentAutoCompleteData: []})
    }
   }
    if(reason==="clear"){
      const removeSelectedStudent:any=[]
      const selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = false;
        selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id));
        this.setState({ selectedDiariesList: [...selectedDiariesList, selectedAllStandardStudents] })
      this.setState({ selectedDiariesList: removeSelectedStudent });    
    }
    else if(reason==="remove-option"){
      const selectedStudent = data;
      selectedStudent.forEach((selStudent: any, index: any) => {
        const availableDiareslist = selectedDiariesList.filter((item: any) => item.id === parseInt(selStudent.studentclass_details[0].class_id)); 
        if (availableDiareslist && availableDiareslist.length) {
          const findDiariesList = selectedDiariesList.map((item: any) => {
            if (item.id === parseInt(selStudent.studentclass_details[0].class_id)) {
              const studentexists = item.studentslist.filter((d: any) => d.id === parseInt(selStudent.id));
              if (!studentexists.length) {
                const checkbox: any = document.getElementById('student_' + selStudent.id);
                // item.studentslist.push(selStudent);
                item.studentslist.push(selectedStudent.filter((item: any) => item.id === parseInt(studentexists.id)))
                if (checkbox) checkbox['checked'] = false;
              }
              else{
                item.studentslist.splice(index,1)
              }
          }
          return item;
          
        });
        this.setState({ selectedDiariesList: findDiariesList });
      }
  })
}
    else if(reason==="select-option"){
      const selectedStudent = data;
      selectedStudent.forEach((selStudent: any, index: any) => {
        const availableDiareslist = selectedDiariesList.filter((item: any) => item.id === parseInt(selStudent.studentclass_details[0].class_id));
        if (availableDiareslist.length) {
          let findDiariesList = selectedDiariesList.map((item: any) => {
            if (item.id === parseInt(selStudent.studentclass_details[0].class_id)) {
  
              const studentexists = item.studentslist.filter((d: any) => d.id === parseInt(selStudent.id));
              if (!studentexists.length) {
                const checkbox: any = document.getElementById('student_' + selStudent.id);
                item.studentslist.push(selStudent);
                if (checkbox) checkbox['checked'] = true;
              }
            }
            return item;
          });
          this.setState({ selectedDiariesList: findDiariesList });
        } else {
  
          const selectedClass = standardlist.filter((item: any) => item.id === parseInt(selStudent.studentclass_details[0].class_id))[0];
          const selectedAllStandardStudents: any = selectedClass;
          selectedAllStandardStudents['all'] = false;
          selectedAllStandardStudents['studentslist'] = [selStudent]
          const checkbox: any = document.getElementById('student_' + selStudent.id);
          if (checkbox) checkbox['checked'] = true;
          this.setState({ selectedDiariesList: [...selectedDiariesList, selectedAllStandardStudents] })
        }
      })

    }
   
  }

  unSelectAll() {
    // unseleted all
    const { AllStudentList } = this.props;
    const selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      const checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = false;
    })
    this.setState({ currentCheckboxAllStudent: false })
    selectAllCheckbox['checked'] = false;
  }

  SelectAll() {
    const { AllStudentList } = this.props;
    const selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      const checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = true;
    })
    selectAllCheckbox['checked'] = true;
    this.setState({ currentCheckboxAllStudent: true })
  }

  selectAllStudents() {
    const { AllStudentList } = this.props;
    const { currentSelectedStandard, selectedDiariesList } = this.state;
    if (!this.state.currentCheckboxAllStudent) {
      if (AllStudentList && AllStudentList.length === 0) {
        this.unSelectAll();
      }
      else {
        // Selected All
        this.SelectAll();
        const selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = true;
        selectedAllStandardStudents['studentslist'] = AllStudentList; // if you want to add all studentlist in add AllStudent array
        const filteredPeople = selectedDiariesList.filter((item) => item !== currentSelectedStandard);
        this.setState({ selectedDiariesList: [...filteredPeople, selectedAllStandardStudents] })
      }
    } else {
      // unseleted all
      this.unSelectAll();
      const filteredPeople = selectedDiariesList.filter((item) => item !== currentSelectedStandard);
      this.setState({ selectedDiariesList: filteredPeople })
    }
  }
  showStudentList = (e: any, getValue: any) => {
    if(!this.state.ShowStudentList){
    if (getValue === true) {
      this.setState({ ShowStudentList: getValue })
    } else {
      this.setState({ ShowStudentList: false })
    }
    setTimeout(() => {
      const standardlistele: any = document.getElementById("standardlist");
      if(standardlistele.firstChild === null) {
        toaster.notify(FormvalidationMessage.addClassStudent, {
          position: 'top',
          duration: notificationMsg.errorNotificationDuration
      });
      history.push('/');
      } else {
        standardlistele.firstChild.classList.add('active')
      } 
    }, 500);
  }
  }
  HiddenStudentList(getValue: any) {
    this.setState({ ShowStudentList: getValue })
  }
  onSubmitNoticeBoard = (values: any, resetForm:any) => {
    const { AllClassList } = this.props
    const getProfile = this.props.getProfile;
    var today = new Date();
    const { selectedDiariesList, message, academicYear } = this.state;
    this.getClassList = []
    this.postStudentList = []
    let studentList: any;
    selectedDiariesList.forEach((items: any, index: any) => {
      items.studentslist.forEach((students: any) => {
        studentList = {
          student_id: students.id,
          student_name: students.student_name,
          parent_id: students.user_id
        }
        this.postStudentList.push(studentList)
      })
      const classlist = {
        class_id: items.id,
        is_all: items.all,
        student_list: this.postStudentList
      }
      this.getClassList.push(classlist)
      if (items.studentslist.length === this.postStudentList.length) {
        this.postStudentList = []
      }
    })
    if (message && values) {
      const noticeImage:any = [];
      if (values.images) {
          const diaryNotices:any = {
            url:values.images
          };
          noticeImage.push(diaryNotices);
      }
      
      const postValue: any = {
        academic_year:academicYear,
        school_id: getProfile.school_id,
        class_list: this.getClassList,
        selected_value: "sdfsdfsdfsd",
        title: values.your_title,
        message: message,
        image: noticeImage,
        date: today,
        posted_by: getProfile.firstname +' '+ getProfile.lastname
      }
      this.props.addDiaryPost(postValue, NoticesBoard.noticeboard).then((res: any) => {
        const {isBoardError} = this.props;
        if(!isBoardError){
          // this.getNoticeBoardData(this.props.getYear)
          this.getNoticeBoard(academicYear)
          resetForm()
          this.setState({ isExpanded:false, currentSelectedStandard:[], selectedDiariesList:[],  ShowStudentList: false,  setImages: [], currentCheckboxAllStudent: false });
          if (this.editorRef.current) {
            this.editorRef.current.editor.setData('');
          }
        }
      });
    }
  }

  setImagesState = (setImages:any) => {
    this.setState({ setImages });
  };

  replaceDomain = (message:string) => {
    const getLocation = function(href:string) {
       var l = document.createElement("a");
       l.href = href;
       return l;
   };
     const parser = new DOMParser()
     const doc = parser.parseFromString(message,'text/html')
     const images = doc.querySelectorAll('img')
     images.forEach((img:any) => 
     {
       const location = getLocation(img.src)
       const baseUrl = location.protocol+'//'+location.hostname+':'+location.port
       img.src = img.src.replace(baseUrl, process.env.REACT_APP_API_URL)
       
     })
     return doc.body.innerHTML
   }

  public fetchMoreNoticeBoardData = () => {
    const { page, search, SortOrderData, OrderNameData, academicYear } = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: page + 1,
          search: search,
          sort_by: SortOrderData,
          order_by: OrderNameData,
          academic_year:academicYear
        }
        this.props.fetchDiaryPost(postValue, NoticesBoard.noticeboard).then((res:any)=>{
          const {records} = this.props;
          if(records && records !== null && records !== undefined){
            this.setState({ responseData: this.props.records })
          }else {
            this.setState({ responseData: [] })
          }
          this.getRecordData()
        });
        this.setState({
          page: this.state.page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  getCkEditorValue = (editordata: any) => {
    if (editordata) {
      this.setState({ message: this.addSpaceAroundLinks(editordata) })
      this.state.message && this.state.message.length > 0 ?
        this.setState({ diaryMessageDetails: true }) :
        this.setState({ diaryMessageDetails: false })
    }
    else {
      this.setState({ diaryMessageDetails: false })
    }
  }
  addSpaceAroundLinks(data: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, "text/html");
    const links = doc.querySelectorAll("a");
    links.forEach((link: any) => {
      link.innerHTML = " " + link.innerHTML + " ";
    });
    return doc.body.innerHTML;
  }
getRecordData(){
  const {records} = this.props;
  const {standardlist} = this.state;
   if (records && records.length > 0 && records !== undefined && records !== null ) {
    records.forEach((items: any) => {
      let getClass = items.class_list;
      if (getClass) {
        let studentDetails:any = [];
        getClass.forEach((ClassList: any, index:any) => {
          if(ClassList.is_all === false){
            studentDetails.push(ClassList)
          }

          if (standardlist && standardlist.length > 0) {
            const getClassList: any = standardlist.find((item: any) => item.id === parseInt(ClassList.class_id))
            if (getClassList) {
              ClassList["grade_standard"] = getClassList.grade_standard;
            }
          }
        })
        items['student_name_list'] = studentDetails;
      }
    })
    if(records){
      records.forEach((diaryItems:any)=>{
          let studentNameList:any = [];
          diaryItems.class_list.forEach((classItem:any)=>{
            classItem.student_list.forEach((student:any)=>{
              studentNameList.push({
                student_name: student.student_name,
                class_name: classItem.class_name                
            });
            })
          })
        diaryItems['student_name_false'] = studentNameList;
      })
    }
  }
}

componentWillUnmount() {
  this.props.ResetDiary()
}

handleAccordionChange = () => {
  this.setState(prevState => ({
    isExpanded: !prevState.isExpanded
  }));
};
  render() {
    const { loading, loadingStudent, getSearchStudentList, records, AllStudentList,recordLoader } = this.props;
    const {viewNoticeBoardForm} = this.state;
    const loadingDiary = { display: loading || loadingStudent ? "block" : "none" };
    const recordLoaderVisibility = { display: recordLoader ? "block" : "none" };
    const { standardlist, selectedDiariesList, ShowStudentList, diaryMessageDetails, isExpanded } = this.state;
    let studentAutoComplete:any;
    if(selectedDiariesList && selectedDiariesList !== undefined && selectedDiariesList !== null && selectedDiariesList.length > 0){
      let studentSelectList:any = []
      selectedDiariesList.forEach((items:any)=>{
        items.studentslist.forEach((studentName:any)=>{
          studentSelectList.push(studentName)
        })        
      })
      studentAutoComplete = studentSelectList;
    }else {
      studentAutoComplete = []
    }
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Notice Board']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Notice Board']} />
              {viewNoticeBoardForm ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="card ">
                      <Accordion expanded={isExpanded} onChange={this.handleAccordionChange}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <label className="font-style">Add Notice Board</label>
                        </AccordionSummary>
                        <AccordionDetails>

                          <div className="col-md-12">
                            <Formik
                              ref={node => this.formikNoticeBoard = node}
                              validationSchema={NoticesBoardValidation}
                              initialValues={initialNoticeBoard}
                              onSubmit={(values, { resetForm }) => {
                                this.onSubmitNoticeBoard(values, resetForm)
                              }}
                              render={({
                                values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                              }: FormikProps<any>) => {
                                let getStudentDetails: any = false;
                                let selectStudentList:any = false;
                                if(selectedDiariesList && selectedDiariesList.length){
                                  selectedDiariesList.forEach((items:any, index:any)=>{
                                    items.studentslist.length > 0 ? 
                                    selectStudentList = true
                                    :selectStudentList = false
                                  })
                                }
                                selectedDiariesList && selectedDiariesList.length > 0 && selectStudentList?
                                  getStudentDetails = true :
                                  getStudentDetails = false;

                                const isNoticeEmpty = (!values.your_title
                                  || !this.state.message);
                                return (
                                  <Form>
                                    <FormGroup>
                                      <Field
                                        label={<><span>Your Title</span><span className='formmandatorycolor'> *</span></>}
                                        type="text"
                                        name="your_title"
                                        component={TextField}
                                        className="textfield__input mb-3"
                                        disabled={false}

                                        onClick={(e: any) => this.showStudentList(e, true)}
                                      />
                                    </FormGroup>
                                    {/* here print the select values */}
                                    <div>
                                      {/* {this.getAllStudentDetails ? */}
                                      <div className="row">
                                        <div className="col-12">
                                          {selectedDiariesList ?
                                            <>
                                              {selectedDiariesList.map((item: any, index: any) => (
                                                <>
                                                  {item.all ?
                                                    <span className="btn btn-circle btn-circle btn-info disabled mr-2 mb-3">{item.grade_standard} </span>
                                                    : <>{item.studentslist.map((slist: any, index: any) => (
                                                      <span className="btn btn-circle btn-circle btn-danger disabled mr-2 mb-3"><span className="userNameTitle">{slist.student_name}</span> ({item.grade_standard})</span>
                                                    )
                                                    )}</>
                                                  }

                                                </>
                                              ))}
                                            </> : 'No Selected'}
                                        </div>
                                      </div>
                                      {/* This is the function used to multiple student list view on autocomplete option */}
                                      {ShowStudentList ?
                                        <div>
                                          <>		
                                            {getStudentDetails ?		
                                              null		
                                              : <span className="diaryerrorcolor">{studentValida.diraySelectStudent}</span>}		
                                          </>
                                          <div className="row mb-3 p-3">
                                            {/* List the class details list */}
                                            <div className="col-md-6">
                                              <ul className="list-group docListWindow small-slimscroll-style autoselection" id="standardlist">
                                                {standardlist ?
                                                  <>
                                                    {standardlist.map((items: any, index: any) => (
                                                      <li className="list-group-item pointer" key={index} onClick={(e) => this.standardonSelect(e, { items, index })}>{items.grade_standard}
                                                        <span className="icon-angle-right float-right">&gt;</span>
                                                      </li>
                                                    ))}
                                                  </>
                                                  : <CommonLoader />}
                                              </ul>
                                            </div>
                                            {/* This is the list of student details list */}
                                            <div className="col-md-6">
                                              <ul className="docListWindow small-slimscroll-style autoselection">
                                                <li className="title-sticky list-group-item" onClick={() => this.selectAllStudents()} >
                                                  <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" defaultChecked={this.state.currentCheckboxAllStudent} id={'student_all'} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Select All</label>
                                                  </div>
                                                </li>
                                                {AllStudentList && AllStudentList !== undefined && AllStudentList !== null?
                                                <StudentList studentlist={AllStudentList} handleCheckStudent={this.handleCheckStudent} />
                                                :null}
                                                
                                              </ul>
                                            </div>
                                          </div>
                                          {/* This is the function called hidden studnet list*/}
                                          {/* <div className="mt-3 text-center">
                                            <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-info"
                                              onClick={() => this.HiddenStudentList(false)}>
                                              <i className="fa fa-angle-up" aria-hidden="true"></i>
                                            </button>
                                          </div> */}
                                        </div>
                                        : null}
                                      <div className="row  pt-3">
                                        <div className="col-md-12">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data=""
                                            onReady={(editor: any) => {
                                              // You can store the "editor" and use when it is needed.
                                              editor.plugins.get('FileRepository').createUploadAdapter = (loader:any) => new FileUploadPlugin(loader, this.props, NoticesBoard.noticeboardImage);
                                              this.editorRef.current = { editor };
                                            }}
                                            config={{
                                              link: {
                                                defaultProtocol: "http://",
                                              },
                                              image: {
                                                upload: {
                                                    types: ['jpg','jpeg','png','JPG','JPEG','PNG'],  // Only allow JPG/JPEG images
                                                }
                                              },
                                            }}
                                            onChange={(event: any, editor: any) => {
                                              const data = editor.getData();
                                              this.getCkEditorValue(data)
                                            }}
                                            onBlur={(event: any, editor: any) => {
                                            }}
                                            onFocus={(event: any, editor: any) => {
                                            }}
                                          />

                                        </div>
                                        <>
                                          {diaryMessageDetails ?
                                            null
                                            : <span className="diaryerrorcolor ml-3">{studentValida.diaryMessage}</span>}
                                        </>
                                      </div>
                                      <div className="row  pt-3">
                                        <div className="col-md-12">
                                      <div className="pos-rel text-center  pl-0">
                                            <ImageUpload URLLink={NoticesBoard.noticeboardImage} setImages={this.state.setImages} setImagesState={this.setImagesState} setfield={setFieldValue} />
                                          </div>
                                          </div>
                                          <div>{imageValidateS}</div>   
                                          </div>

                                      {/* This is the tag use import multiple image upload  */}

                                      <div className="form-group text-right mt-3 mb-1">
                                        <Button type="submit" className="btn btn-pink mr-1 ml-1 w-15"
                                          disabled={
                                            isNoticeEmpty  || !diaryMessageDetails
                                            || isValidating 
                                            || !dirty 
                                            || !!(errors.your_title && touched.your_title)
                                            || !!(errors.message && touched.message) 
                                            || !getStudentDetails || !selectStudentList} value="Submit">Submit</Button>
                                      </div>
                                      <div>

                                      </div>
                                    </div>
                                  </Form>
                                )
                              }}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
                : null}
              <div>
              
                <InfiniteScroll
                  dataLength={this.props.records.length}
                  next={this.fetchMoreNoticeBoardData}
                  hasMore={this.state.hasMore}
                  loader={<h4 style={loadingDiary}>Loading...</h4>}
                >
                  <>
                    {records && records.length > 0 && records !== undefined && records !== null?
                      <>
                        {
                          records.map((item: any) => (
                            <div className="card card-topline-red">
                              <div className="row p-4">
                                <div className="col-md-12">

                                  <p>To:
                                    <>
                                      {item.class_list.map((classList: any, index: any) => (
                                        <>
                                          {classList.is_all ? (
                                              <span className="btn btn-xs btn-circle btn-danger mr-1 ml-2 mb-2 cursorPoint titleCapitalize" key={index}>{classList.class_name}</span>
                                            ) : (
                                                item.student_name_false && item.student_name_false
                                                  .filter((student:any) => student.class_name === classList.class_name).slice(0,9).map((studentList:any, i:any) => (
                                                    <span className="btn btn-xs btn-circle btn-info mr-1 ml-2 mb-2 cursorPoint titleCapitalize" key={i}><span className="userNameTitle">{studentList.student_name}</span> ({studentList.class_name})</span>
                                                )))
                                          }
                                        </>
                                      ))}                                          
                                    </>
                                    <div className="btn-group mr-1 ml-2 mb-2">
                                    {item.student_name_false && item.student_name_false.length !==0 ?
                                    <>
                                         { item.student_name_false.length > 10 && item.student_name_false !== null && item.student_name_false !== undefined && item.student_name_false ?
                                         <>
                                         <button className="btn  btn-xs btn-circle  deepPink-bgcolor btn-outline dropdown-toggle"
                                          data-toggle="dropdown">more
                                          <i className="fa fa-angle-down"></i>
                                      </button>
                                          <ul className="dropdown-menu">
                                            { item.student_name_false.slice(10).map((studentName: any, index: any) => (
                                                <li key={index} className="pl-2 pr-2 pt-1 titleCapitalize">{studentName.student_name}</li>
                                            ))}
                                            </ul> 
                                            </>
                                            : null}
                                            </>
                                            : null
                                          
                                        }
                                            </div>
                                  </p>

                                </div>
                                <div className="col-md-12">
                                  <h3 className="titleCapitalize color-pink mt-3"><strong>{item.title}</strong></h3>
                                  <hr className="mt-0" />
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={this.replaceDomain(item.message)}
                                      disabled={true} 
                                      config={{
                                        toolbar: [],
                                        link: {
                                          addTargetToExternalLinks: true,
                                        }
                                      }}
                                    />
                                  <div className="row">
                                    <div className="col-md-5">
                                      {item.image ?
                                      item.image.map((items:any)=>(
                                        <img src={`${items.url}`} className="w-100 p-3" alt="" />
                                      )): null}
                                    </div>

                                  </div>

                                </div>

                                <div className="col-md-12 p-3 text-right">
                                  Posted by <strong>{item.posted_by}</strong><span> {moment(item?.created_at).fromNow()}</span>
                                </div>
                              </div>
                            </div>

                          ))
                        }
                      </>

                      : <CommonLoader/>}
                     
                  </>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        <div style={loadingDiary}><SpinnerLoader /></div>
        <div style={recordLoaderVisibility}><SpinnerLoader /></div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
    updateStatuRecord: state.diary.updateRecord,
    total: state.diary.total,
    per_page: state.diary.per_page,
    records: state.diary.records,
    page: state.diary.page,
    totalPage: state.diary.totalPage,
    loading: state.diary.loading,
    loadingStudent: state.student.loading,
    DiaryDetails: state.diary.items,
    AllClassList: state.diary.gradelist,
    AllStudentList: state.diary.standardList,
    getDiaryImageURL: state.student.ImageURL,
    getResponseClassList: state.diary.getClassList,
    getResponseStudent: state.diary.getStudentList,
    errorMessage: state.diary.errors,
    getProfile: state.profile.profileData,
    getSearchStudentList: state.diary.getSearchStudentList,
    recordLoader:state.diary.recordLoader,
    isBoardError:state.diary.isBoardError
  };
};


export default connect(mapStateToProps, {
  fetchDiaryPost,
  fetchGetAllClassList,
  fetchGetStudent,
  addDiaryPost,
  fetchSearchAllStudent,
  stopYearData,
  ResetDiary,
  editorImageUpload
})(ViewNoticeBoard)
