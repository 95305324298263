import React from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import { UserRoles } from '../../services/Constants';
import { fetchUserPost, deletePost } from '../../store/usermanage/Actions';
import { UserManageTypes, ISearchBarState } from '../../store/usermanage/Type';
import { LoadMoreType } from '../../components/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import history from '../../History';
import Switch from '@material-ui/core/Switch';
import { formValidationPatten,RowPerPage,RowsPerPageOptions } from '../../services/Constants';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface PostsListProps {
  getYear?:any,
  loading: boolean;
  loginProfile:any;
  userDetails: UserManageTypes;
  deleteDetails: any,
  fetchUserPost: (getData: LoadMoreType, typeId?:string) => any;
  deletePost: (subjectDetailDelete: number) => any;
	stopYearData:() => any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
}

class UserManagement extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteUserManageId: [],
      userManageData: [],
      hasMore: true,
      prev: 0,
      next: 10,
      acsOrder: true,
      page: 1,
      search: '',
      SortOrderData: '',
      OrderNameData: '',
      role:'School Admin',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      academicYear:null,
      per_page: RowPerPage
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getUpdateYear();
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear, deleteDetails} = this.props;
    if(deleteDetails === true) {
      this.checkStatusProcess()
    }
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getUpdateYear(getYearData?:any){
    if(getYearData && getYearData !== undefined && getYearData !== null){
      this.setState({academicYear:getYearData})
      this.getUserManagement(getYearData)
    }else {
      this.setState({academicYear:this.props.getYear})
      this.getUserManagement(this.props.getYear)
    }
  }
  getUserManagement(getYearData?:any){
    const postValue = {
      page_no: 1,
      per_page: this.state.per_page,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      role: this.state.role,
      academic_year:getYearData
    }
    this.props.fetchUserPost(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
    });;
  }

  checkStatusProcess() {
    const {academicYear} = this.state;
    const selectDataList = this.props.deleteDetails;
    if (selectDataList === true) {
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        search: this.state.search,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData,
        role: this.state.role,
        academic_year:academicYear
      }
      this.props.fetchUserPost(postValue).then((res:any)=>{
        window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
      });
    }
  }

  public handleUserDelete = () => {
    this.props.deletePost(this.state.deleteUserManageId).then((res:any)=>{
      window.scrollTo(0, 0);
    });
    this.setState({ showDelete: false, page: 1, hasMore:true });
  }

  public hideUserDelete = () => {
    this.setState({ showDelete: false })
  }
  public showUserDelete = (getData: any) => { 
    let postValue:any
    let titleClassMessage: any;
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'User'
      }
      this.setState({ showDelete: true, deleteUserManageId: getData, postData:postValue });
    }
  }

  public showFilterData = (getdata: any) => {
    const {academicYear} = this.state;
    if (getdata.sort_by === 'desc') {
      this.setState({ acsOrder: false })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        role: this.state.role,
        academic_year:academicYear
      }
      this.props.fetchUserPost(postValue);
      this.setState({
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        hasMore:true,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      })
    } else {
      this.setState({ acsOrder: true })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        role: this.state.role,
        academic_year:academicYear
      }
      this.props.fetchUserPost(postValue);
      this.setState({
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        hasMore:true,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      })
    }
  }
  checkUserType() {
    const {loginProfile} = this.props;
    const getUserToken:any = String(loginProfile.usertype);
    if(getUserToken){
    if (getUserToken === UserRoles.acadamicAdmin) {
      this.setState({ acadamicAdmin: true })
    } else if (getUserToken === UserRoles.schoolAdmin) {
      this.setState({ schoolAdmin: true })
    } else if (getUserToken === UserRoles.teacher) {
      this.setState({ teacher: true })
    } else if (getUserToken === UserRoles.parent) {
      this.setState({ parent: true })
    }
  }
  }


  public fetchMoreUserData = (newPage?:any,numberOfRows?:any) => {
    const {academicYear} = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no:newPage,
          per_page: numberOfRows,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          role: this.state.role,
          academic_year:academicYear
        }
        this.props.fetchUserPost(postValue);
        this.setState({
          page: this.state.page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
          page: this.state.page
        })
      }
    }
  }

  handleInputUserChange = (e: any) => {
    const { academicYear } = this.state;
    const { value } = e.target;
    const getSearchValue = value || "";
    var intRegex = formValidationPatten.alphanumericTest;
    if (intRegex.test(getSearchValue) || getSearchValue === "") {
      const postSearchValue = {
        page_no: 1,
        search: getSearchValue,
        role: this.state.role,
        academic_year: academicYear,
      };

      this.props.fetchUserPost(postSearchValue, 'userManagement').then((res: any) => {
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue,
        });
      });
    }
  };
  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            display: 'flex !important',
            padding: '8px !important'
          }
        }
      }      
    }
  })

  private renderUserManageView(getUserResponseData: any) {
    const { loading,total } = this.props;
    const {sort_title_name, sort_by, sort_class_name,per_page} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getUserToken = localStorage.getItem('token');
    if (!getUserToken) {
      history.push("/");
    }   
    const columns = [
      {
        name: "firstname",
        label: "User Name",
        options: {
          customBodyRender: (tableMeta:any,rowData:any,rowIndex:any) => {
            const { firstname, lastname } = getUserResponseData[rowData.rowIndex];
            return `${firstname || ''} ${lastname || ''}`;
          },
        },
      },
      {
        name: "email_id",
        label: "Email Id",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: "phone_number",
        label: "Phone Number",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: "school_name",
        label: "School Name",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: "role",
        label: "Role",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
        sort: false,
        download: false,
          customBodyRender: (tableMeta:any,rowData:any,rowIndex:any) => {
            const data = getUserResponseData[rowData.rowIndex];
            return (
              <div>
                {data.is_active ? (
                  <Link to={`/edit_user/${data.ldap_id}`}>
                    <button className="btn btn-primary btn-xs" title="Edit User">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                  </Link>
                ) : (
                  <button className="btn btn-disable btn-xs" title="Edit User" disabled>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                )}
                <Switch
                  checked={data.is_active}
                  onChange={() => this.showUserDelete({ ldapId: String(data.ldap_id), isActive: !data.is_active })}
                  name="checkedUser"
                  inputProps={{ 'aria-label': data.is_active ? 'Deactivate User' : 'Activate User' }}
                  title={data.is_active ? "Deactivate User" : "Activate User"}
                />
              </div>
            );
          },
        },
      },
    ];
    

const options = {
  rowsPerPage: per_page,
  rowsPerPageOptions: RowsPerPageOptions,
  count: total,
  selectableRows: false,
  page:this.state.page - 1,
  filter:false,
  print:false,
  downloadOptions:{
    filename: "UsersList.csv"
  },
  onChangePage: (newPage:any) => {
      this.setState({ page: newPage + 1}); 
      this.fetchMoreUserData(newPage + 1,this.state.per_page);
  },
  onColumnSortChange:(changedColumn:any, direction:any) =>{
      const sortData = {
          sort_by: direction,
          order_by: changedColumn
      }
      this.showFilterData(sortData);
  },
  onChangeRowsPerPage: (numberOfRows:any) => {
    const page = 1
    this.setState({ per_page: numberOfRows,page:page });
    this.fetchMoreUserData(page,numberOfRows);
  },
  onSearchChange: (searchText:any, row:any) => {
      this.handleInputUserChange({ target: { value: searchText } });
  }
}; 
    return (
      <div>
        <ThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
        data={getUserResponseData}
        columns={columns}
        options={options}
        />
        </ThemeProvider>
      </div>

    )
  }
  refreshUserManageTable = () => {
    this.getUserManagement()
  }
  render() {
    const { loading } = this.props;
    const { showDelete, postData } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const userTotal = this.props.total;  
    return (
      <div>
        <CommonModel
        onState={showDelete}
        currentState={postData}
        onConfirm={this.handleUserDelete}
        onCancel={this.hideUserDelete}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Users']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Users']} />
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">
                    <div className="card-head">
                      <header>
                        <Link to="/add_user">
                          <button className="btn btn-pink">Add User</button>
                        </Link>
                      </header>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">
                        <div className="table-responsive">
                          {this.renderUserManageView(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
    stopYear:state.profile.yearStatusFails,
    loading: state.userManage.loading,
    loginProfile:state.profile.profileData,
    userDetails: state.userManage.items,
    total: state.userManage.total,
    per_page: state.userManage.per_page,
    records: state.userManage.records,
    page: state.userManage.page,
    totalPage: state.userManage.totalPage,
    deleteDetails: state.userManage.isAuthenticated
  };
};
export default connect(
  mapStateToProps, { fetchUserPost, deletePost, stopYearData }
)(UserManagement);