import React, { Component } from 'react'
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import CommonLoader from '../../components/CommonLoader';
import { fetchQuestionSetPost } from '../../store/question/Actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getYearData, stopYearData, stopSubComponentYear } from '../../store/profile/Actions'
import { fetchQuestionnaireMappingStudents, setPsychometricDataStorage } from '../../store/psychometric/Actions';
import { RouteComponentProps } from 'react-router';
import history from '../../History';
import { Button, MenuItem, FormGroup, Checkbox } from "@material-ui/core";
import { ModalView } from './ModalView';
import { PsychometricOtherTeacherList } from './PsychometricOtherTeacherList';
import MUIDataTable from 'mui-datatables';
import { RowPerPage, RowsPerPageOptions } from '../../services/Constants';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface OwnPropsParams {
  id: string;
}

interface OwnQuestSetProps extends RouteComponentProps<OwnPropsParams> {
  getYear?: any;
  fetchQuestionSetPost: (getPostValue: any) => any;
  fetchQuestionnaireMappingPost: (getPostValue: any) => any;
  fetchQuestionnaireMappingStudents: (postValue: any) => any;
  getYearData: (postvalue?: any) => any;
  stopYearData: () => any;
  setPsychometricDataStorage: (postValue: any) => any;
  loading: boolean;
  acsOrder: boolean;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?: any;
  questionnaireStudentRecords: any;
  psychometricDataStorage?: any;
  questionnaireDetails: any;
}

interface StateTypes {
  acsOrder?: boolean,
  recordPage?: any,
  academicYear?: any,
  search: string,
  grade?: string,
  standard?: string,
  hasMore: boolean,
  SortOrderData: string,
  OrderNameData: string,
  page?: any,
  page_no?: any,
  sort_by?: string,
  sort_class_name?: string,
  sort_title_name?: string,
  student_list?: any,
  modalShow: boolean;
  categoryQuestionList: any;
  otherTeacherList:any;
  otherTeacherModalShow: boolean;
  dashboardCound: any;
  ascOrder?: boolean,
  per_page?:any
}

export class StudentListPsychometricReport extends Component<OwnQuestSetProps, StateTypes> {
  hasMoreClass: any;
  constructor(props: any) {
    super(props);
    this.state = {
      acsOrder: true,
      academicYear: null,
      search: '',
      hasMore: true,
      page: 1,
      page_no: 1,
      SortOrderData: 'asc',
      OrderNameData: 'created_at',
      recordPage: 1,
      sort_by: 'desc',
      sort_class_name: 'fa fa-sort-amount-asc',
      sort_title_name: 'Ascending order',
      student_list: [],
      modalShow: false,
      categoryQuestionList: [],
      otherTeacherList: [],
      otherTeacherModalShow:false,
      dashboardCound: [],
      per_page: RowPerPage,
    }
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);

    const dashboardCound: any = [
      {
        'bg_class': "bg-success",
        "icon_class": "fa fa-user",
        "title": "Parent",
        "count": "9"
      },
      {
        'bg_class': "bg-orange",
        "icon_class": "fa fa-chalkboard-teacher",
        "title": "Class Teacher",
        "count": "9"
      },
      {
        'bg_class': "bg-purple",
        "icon_class": "fa fa-users",
        "title": "Other Teachers",
        "count": "9"
      }
    ];
    this.setState({ dashboardCound: dashboardCound })

    this.getUpdateYear()
  }
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.getUpdateYear(this.props.getYear)
    }



  }
  componentDidUpdate() {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.props.stopYearData()
    }
  }
  getUpdateYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear })
      this.getQuestionData(getYear)
    } else {
      this.setState({ academicYear: this.props.getYear })
      this.getQuestionData(this.props.getYear)
    }
  }
  getQuestionData(getYear?: any) {
    this.getQuestionSetDetails(getYear)
  }
  getQuestionSetDetails(getYear?: any) {
    const value: any = {
      unique_id: this.props.match.params.id,
      academic_year: getYear,
      page_no: 1,
      per_page: this.state.per_page,
    }

    this.props.fetchQuestionnaireMappingStudents(value).then((res: any) => {
      const { questionnaireStudentRecords, questionnaireDetails } = this.props
      this.setState({ 
          student_list: questionnaireStudentRecords, 
          categoryQuestionList: questionnaireDetails.questionnaire_details,
          otherTeacherList: questionnaireDetails.additional_teacher_data 
        })
      window.scrollTo(0, 0);
    });
  }
  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        ascOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by,
        unique_id: this.props.match.params.id
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchQuestionnaireMappingStudents(postValue).then((res: any) => {
        
      });
    } else {
      this.setState({
        ascOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by,
        unique_id: this.props.match.params.id
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchQuestionnaireMappingStudents(postValue).then((res: any) => {
        
      });
    }
  }
  public fetchMoreStudentData = (newPage?:any,numberOfRows?:any) => {
    const { academicYear } = this.state;
    if (this.state.hasMore === true) {
     if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {   
        const postValue = {
          page_no: newPage,
          per_page: numberOfRows,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          unique_id: this.props.match.params.id,
          academic_year: academicYear,
        }              
        this.props.fetchQuestionnaireMappingStudents(postValue).then((res: any) => {
          const { questionnaireStudentRecords, questionnaireDetails } = this.props
          this.setState({
            // page: this.state.page + 1,
            student_list: questionnaireStudentRecords, 
          })
        });;
       
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }

  getStudentReportDetails = (getValue: any) => {
    if (getValue) {
      let postValue: any = {
        unique_id: this.props.match.params.id,
      }
      this.props.setPsychometricDataStorage(postValue).then((res: any) => {
        history.push({
          pathname: `/psychometric_student/${getValue.student_id}`,

        });
      });
    }
  }
  handleInputChange = (e: any) => {
    const { value } = e.target;
    const { academicYear } = this.state;
    const getSearchValue = value || '';

      const postSearchValue = {
        page_no: 1,
        per_page: this.state.per_page,
        search: getSearchValue,
        academic_year: academicYear,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData,
        unique_id: this.props.match.params.id
      }
      this.props.fetchQuestionnaireMappingStudents(postSearchValue).then((res: any) => {
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      });
      this.hasMoreClass = true;
    
  }
  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            display: 'flex !important',
            padding: '8px !important'
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root:{
            color: 'rgba(0, 0, 0, 0.54) !important',
            margin: '3px !important'
          }
        }
      }      
    }
  })

  private renderStudentListView(records: any,) {
    const { sort_by, sort_title_name,per_page } = this.state;
    const { loading,total } = this.props
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    const columns = [
      {
          name: "student_name",
          label: "Student Name",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name: "class_name",
          label: "Class",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name: "parent_name",
          label: "Parent Name",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name: "class_teacher_name",
          label: "Class Teacher Name",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name:"test_status",
          label:  "View",
          options: {
            sort: false,
            download: false,
            customBodyRender: (tableMeta:any,rowData:any,rowIndex:any) => {
              const items = records[rowData.rowIndex];
              if(!items) return null;
              return(
                <>
                {(items.student_id && items.test_status && (items.test_status == 'inprogress' || items.test_status == 'completed')) ?(
                  <button className="btn btn-primary btn-xs"
                  title="View" onClick={() => this.getStudentReportDetails(items)}><i className="fa fa-eye" aria-hidden="true">
                </i></button>
                ) : (
                  items['test_status'] == 'notstarted' ? 'Not Yet Started' : 'In Progress'
                )
                }
                </>
              )
            }
            }
        },
    ];

    const options = {
      rowsPerPage: per_page,
      rowsPerPageOptions: RowsPerPageOptions,
      count: total,
      selectableRows: false,
      page:this.state.page - 1,
      filter:false,
      print:false,
      downloadOptions:{
        filename: "StudentListPsychometricList.csv"
      },
      onChangePage: (newPage:any) => {
          this.setState({ page: newPage+1 }); 
          this.fetchMoreStudentData(newPage + 1,this.state.per_page);
      },
      onColumnSortChange:(changedColumn:any, direction:any) =>{
          const sortData = {
              sort_by: direction,
              order_by: changedColumn
          }
          this.showFilterData(sortData);
      },
      onChangeRowsPerPage: (numberOfRows:any) => {
          const page = 1
          this.setState({ per_page: numberOfRows,page:page });
          this.fetchMoreStudentData(page,numberOfRows);
      },
      onSearchChange: (searchText:any, row:any) => {
          this.handleInputChange({ target: { value: searchText } });
      }
  };
    return (
      <div>
        <ThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
        data={records}
        columns={columns}
        options={options}
        />
        </ThemeProvider>
      </div>
    )
  }

  openModel = () => {
    this.setState({ modalShow: true });
  }
  openOtherTeacherModal = () => {
    this.setState({ otherTeacherModalShow: true });
  }
  onCancel = (getValue: any) => {
    this.setState({ modalShow: false });
    this.setState({ otherTeacherModalShow: false });
  }


  render() {
    const { loading, questionnaireDetails } = this.props;
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    const { student_list, modalShow, categoryQuestionList, dashboardCound, otherTeacherList, otherTeacherModalShow } = this.state;
    return (
      <div>
        <ModalView
          onState={modalShow}
          categoryQuestionList={categoryQuestionList}
          onCancel={this.onCancel}
        />
        <PsychometricOtherTeacherList
          onState={otherTeacherModalShow}
          otherTeacherList={otherTeacherList}
          onCancel={this.onCancel}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["Student List Psychometric"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Student List Psychometric"]}
                baseName={["Psychometric"]}
                baseURL={["psychometric"]}
              />
              {
                questionnaireDetails ? (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-topline-red">
                          <div className="card-body no-padding height-9">
                            {questionnaireDetails ?
                              <div className="row">
                                <div className="col-md-12 row view-questionnaire-block">
                                  <div className="row col-md-9 questionnaire-name-desc">
                                    <div className="ml-3 d-flex">
                                      <div className="text-center">
                                        <h3>{questionnaireDetails.questionnaire_name}</h3>
                                        <span>{questionnaireDetails.questionnaire_desc}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row col-md-3 questionnaire-details">
                                    <Button className="btn btn-pink mr-1 ml-1" onClick={this.openModel}>
                                      View Questions
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {dashboardCound && dashboardCound !== null && dashboardCound.length !== 0 ?
                        dashboardCound.map((items: any) => (
                          <div className="col-xl-4 col-md-6 col-12" >
                            <div className={`info-box p-0 pl-3 ${items.bg_class}`}>
                              <span className="info-box-icon push-bottom">
                                <i className={items.icon_class} aria-hidden="true"></i>
                              </span>
                              <div className="info-box-content">
                                <span className="info-box-text">{items.title}</span>
                                <span className="progress-description">
                                  {
                                    items.title == 'Parent' ? (questionnaireDetails.is_parent ? "Active" : "Not Active") :
                                      items.title == 'Class Teacher' ? (questionnaireDetails.is_class_teacher ? "Active" : "Not Active") :
                                        (questionnaireDetails.additional_teacher_data && questionnaireDetails.additional_teacher_data.length > 0 ?
                                          <div className="d-flex justify-content-between">
                                              <span>Active</span>
                                              <div>
                                                <Button className="btn btn-purple mr-1 ml-1" onClick={this.openOtherTeacherModal}>
                                                    View Other Teachers
                                                  </Button>
                                              </div> 
                                          </div> : "Not Active"
                                          )
                                  }
                                </span>
                              </div>
                              {/* info-box-content */}
                            </div>
                            {/* info-box */}
                          </div>
                        )) :
                        null
                      }
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-topline-red">
                          <div className="card-body no-padding height-9">
                            <div className="row">
                              <div className="table-responsive">
                                {student_list && student_list.length > 0 ?
                                  this.renderStudentListView(student_list) : <SpinnerLoader />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>) : null}
            </div>
          </div>
          <div style={loadingQuestionFull}><SpinnerLoader /></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.psychometric.loading,
    getYear: state.profile.getYear,
    stopYear: state.profile.yearStatusFails,
    total: state.psychometric.total,
    per_page: state.psychometric.per_page,
    records: state.psychometric.records,
    page: state.psychometric.page,
    totalPage: state.psychometric.totalPage,
    questionnaireStudentRecords: state.psychometric.questionnaireStudentRecords,
    questionnaireDetails: state.psychometric.questionnaireDetailsById,
    psychometricDataStorage: state.psychometric.psychometricDataStorage
  }
}

const mapDispatchToProps = {
  fetchQuestionSetPost,
  fetchQuestionnaireMappingStudents,
  stopYearData,
  getYearData,
  setPsychometricDataStorage
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentListPsychometricReport)
