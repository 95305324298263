import React, { Component } from 'react'
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import CommonLoader from '../../components/CommonLoader';
import { QuestionTypes } from '../../store/question/Types';
import { Question } from '../../router/Roles';
import { fetchQuestionSetPost } from '../../store/question/Actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { GetCorrectSubject } from '../../store/subject/Actions';
import { getYearData, stopYearData, stopSubComponentYear } from '../../store/profile/Actions'
import MUIDataTable from "mui-datatables";
import { RowPerPage, RowsPerPageOptions } from '../../services/Constants';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export type OwnQuestSetProps = {
  getYear?: any;
  fetchQuestionSetPost: (getPostValue: any) => any;
  GetCorrectSubject: (postValue: any) => void;
  fetchGetAllClassList: (postValue: any) => void;
  getYearData: (postvalue?: any) => any;
  stopSubComponentYear: (postvalue?: any) => any;
  stopYearData: () => any;
  loading: boolean;
  acsOrder: boolean;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  getClassList: any;
  getSubjectlist: any;
  stopYear?: any;
  subComponentStatus?: any
}
export class ViewQuestion extends Component<OwnQuestSetProps, QuestionTypes> {
  hasMoreClass: any;
  constructor(props: any) {
    super(props);
    this.state = {
      acsOrder: true,
      academicYear: null,
      search: '',
      hasMore: true,
      page: 1,
      page_no: 1,
      SortOrderData: '',
      OrderNameData: '',
      recordPage: 1,
      sort_by: 'desc',
      sort_class_name: 'fa fa-sort-amount-asc',
      sort_title_name: 'Ascending order',
      per_page: RowPerPage,
    }
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getUpdateYear()
  }
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.getUpdateYear(this.props.getYear)
    }
  }
  componentDidUpdate() {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.props.stopYearData()
    }
  }
  getUpdateYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear })
      this.getQuestionData(getYear)
    } else {
      this.setState({ academicYear: this.props.getYear })
      this.getQuestionData(this.props.getYear)
    }
  }
  getQuestionData(getYear?: any) {
    this.getQuestionSetDetails(getYear)
    const postValue = {
      academic_year: getYear
    }
    this.props.GetCorrectSubject(postValue);
    this.props.fetchGetAllClassList(postValue);
  }
  getQuestionSetDetails(getYear?: any) {
    const getPostStudent = {
      page_no: 1,
      per_page: this.state.per_page,
      academic_year: getYear,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData
    }
    this.props.fetchQuestionSetPost(getPostStudent).then((res: any) => {
      this.setState({ hasMore: true, page: 1 })
      this.getViewQuestion()
    });
  }
  getViewQuestion() {
    const { records, getClassList, getSubjectlist } = this.props;
    if (records.length > 0) {
      const getSubjectName: any = getSubjectlist.data;
      if (getSubjectName) {
        records.forEach((items: any) => {
          // get class Name list
          const gradeId: any = getClassList.find((item: any) => item.id === parseInt(items.grade_id))
          if (gradeId) {
            items['class_name'] = gradeId.grade_standard;
          }
          // get Subject name list
          const subjectName: any = getSubjectName.find((item: any) => item.id === parseInt(items.subject_id))
          if (subjectName) {
            items['subject_name'] = subjectName.name;
          }
        })
      }
    }
  }
  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        acsOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchQuestionSetPost(postValue).then((res: any) => {
        this.getViewQuestion()
      });
    } else {
      this.setState({
        acsOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchQuestionSetPost(postValue).then((res: any) => {
        this.getViewQuestion()
      });
    }
  }
  public fetchMoreStudentData = (newpage?:any,numberOfRows?:any) => {
    const { page, academicYear } = this.state;

    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: newpage,
          per_page: numberOfRows,
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          search: this.state.search
        }
        this.props.fetchQuestionSetPost(postValue).then((res: any) => {
          this.getViewQuestion()
        });
        // this.setState({
        //   page: page + 1
        // })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      }      
    }
  })
  private renderQuestionView(records: any) {
    const { sort_by, sort_class_name, sort_title_name, per_page} = this.state;
    const { loading,total } = this.props;
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    const columns = [
      { name: "question_name", label: "Question Name" },
      { name: "class_name", label: "Class Name" },
      { name: "subject_name", label: "Subject Name" },
      { name: "question_count", label: "Question Count" },
      { name: "quiz_count", label: "Quiz Count" },
      { name: "posted_by", label: "Posted By" },
      { name: "created_at", label: "Date Time",
        options: {
          customBodyRender: (tableMeta:any,rowData:any) => {
            const item = records[rowData.rowIndex];
            return  (
             <>
             {item.date_time}
             </>
            ) 
          }
        }},
      {
        name: "is_active",
        label: "View",
        options: {
          sort: false,
          download: false,
          customBodyRender: (tableMeta:any,rowData:any) => {
            const item = records[rowData.rowIndex];
            return item.is_active ? (
              <Link to={`/question/${item.id}`}>
                <button className="btn btn-primary btn-xs" title="View">
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </button>
              </Link>
            ) : (
              <button className="btn btn-disable btn-xs" title="View" disabled>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
            );
          },
        },
      },
    ];
    

const options = {
  rowsPerPage: per_page,
  rowsPerPageOptions: RowsPerPageOptions,
  count: total,
  selectableRows: false,
  page:this.state.page - 1,
  filter:false,
  print:false,
  downloadOptions:{
    filename: "QuestionSets.csv"
  },
  onChangePage: (newPage:any) => {
      this.setState({ page: newPage +1}); 
      this.fetchMoreStudentData(newPage + 1,this.state.per_page);
  },
  onColumnSortChange:(changedColumn:any, direction:any) =>{
      const sortData = {
          sort_by: direction,
          order_by: changedColumn
      }
      this.showFilterData(sortData);
  },
  onChangeRowsPerPage: (numberOfRows:any) => {
    const page = 1
    this.setState({ per_page: numberOfRows,page:page });
    this.fetchMoreStudentData(page,numberOfRows);
  },
  onSearchChange: (searchText:any, row:any) => {
      this.handleInputQuestionSet({ target: { value: searchText } });
  }
};
    return (
      <ThemeProvider theme={this.getMuiTheme()}>
      <MUIDataTable
      data={records}
      columns={columns}
      options={options}
      />
      </ThemeProvider>
    )
  }

  handleInputQuestionSet = (e: any) => {
    const { value } = e.target;
    const { academicYear } = this.state;
    const getSearchValue = value;
    if (getSearchValue) {
      const postSearchValue = {
        page_no: 1,
        per_page: this.state.per_page,
        search: getSearchValue,
        academic_year: academicYear,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData
      }
      this.props.fetchQuestionSetPost(postSearchValue).then((res: any) => {
        this.getViewQuestion()
      });
      this.setState({
        hasMore: true,
        page: 1,
        search: getSearchValue
      })
      this.hasMoreClass = true;
    } else {
      const postSearchValue = {
        page_no: 1,
        per_page: this.state.per_page,
        search: '',
        academic_year: academicYear,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData
      }
      this.props.fetchQuestionSetPost(postSearchValue).then((res: any) => {
        this.getViewQuestion()
      });
      this.setState({
        hasMore: true,
        page: 1,
        search: ''
      })
      this.hasMoreClass = true;
    }
  }

  refreshQuestionSetTable = () => {
    const { academicYear } = this.state;
    this.setState({
      search: ''
    }, () => {
      this.getQuestionSetDetails(academicYear)
    });  
  }
  onOpenUploadOption = () => {

  }
  render() {
    const { loading } = this.props;
    const loadingQuestionFull = { display: loading ? "block" : "none" };

    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Question Set']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Question Set']} />
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">

                    <div className="card-head">
                      <header>
                        <Link to={Question.AddQuestion}>
                          <button className="btn btn-pink">Add Question Set</button>
                        </Link>
                        <Link to={Question.UploadQuestionSet} className="btn btn-pink ml-2 mt-1" title="CSV File Upload">
                          Upload Question Set
                        </Link>
                        <Link to={Question.ImportQuestion} className="ml-2 mt-1" title="Import Question">
                          <button className="btn btn-pink">Import Question Set</button>
                        </Link>
                      </header>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">
                        <div className="table-responsive">

                          {this.renderQuestionView(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={loadingQuestionFull}><SpinnerLoader /></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.questionset.loading,
    getYear: state.profile.getYear,
    stopYear: state.profile.yearStatusFails,
    subComponentStatus: state.profile.subComponentStart,
    total: state.questionset.total,
    per_page: state.questionset.per_page,
    records: state.questionset.records,
    page: state.questionset.page,
    totalPage: state.questionset.totalPage,
    getClassList: state.diary.gradelist,
    getSubjectlist: state.subjects.category,
  }
}

const mapDispatchToProps = {
  fetchQuestionSetPost,
  fetchGetAllClassList,
  GetCorrectSubject,
  stopYearData,
  getYearData,
  stopSubComponentYear
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestion)
