import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import "./QuestionBankStyle.scss";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { fetchGenerateQuestions, fetchReloadQuestions, createQuestionPaper } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import ImageComponent from "../../components/ImageComponent";
import { ModalReloadAlert } from './ModalReloadAlert';
import { ModelImageView } from './ModelImageView';
import GenerateQuestionsImageUpload from "../../components/generateQuestions/GenerateQuestionsImageUpload";


const initialValues = {
    question_name: "",
    question_image: [
        {
            question_image_key: '',
            question_image_value: '',
            question_image_view:[]
        }
    ],
    questionlist: [{ value: '', required: true }],
    options: [{ option: '', content: '' }],
    question_correct_answer: "",
    empty_questions:"",
    questionsoptions: [
        { value: '', answer_image: [
            {
                answer_image_key: '',
                answer_image_value: '',
                answer_image_view:[]
            }
        ] },
        { value: '', answer_image: [
            {
                answer_image_key: '',
                answer_image_value: '',
                answer_image_view:[]
            }
        ] },
        { value: '', answer_image: [
            {
                answer_image_key: '',
                answer_image_value: '',
                answer_image_view:[]
            }
        ] },
        { value: '', answer_image: [
            {
                answer_image_key: '',
                answer_image_value: '',
                answer_image_view:[]
            }
        ] }],
    answer: "",
    answer_image: [
        {
            answer_image_key: '',
            answer_image_value: '',
            answer_image_view:[]
        }
    ],
    sub_questions: [
        {
            question: '',
            question_image: [
                {
                    sub_question_image_key: '',
                    sub_question_image_value: '',
                    sub_question_image_view:[]
                }
            ],
            marks: 1
        }
    ],
};


export type Props = {
    loading?: any;
    generateQuestions?: any;
    reloadQuestions?: any;
    errors?:any;
    fetchGenerateQuestions: (data: any) => any;
    fetchReloadQuestions: (data: any) => any;
    createQuestionPaper: (data: any) => any;
}

interface StateTypes {
    academicYear?: any;
    questions?: any;
    editableId?: any;
    editableQuestionType?: any;
    questionImage?: any;
    options?: any;
    modalAlertShow?: boolean,
    reloadId?: any,
    reloadQuestionType?: any,
    reloadErrMsg?: any,
    answerImage?: any,
    subQuestionImage?: any;
    image?: any;
    showImageView?: any;
}
export class GenerateQuestions extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            academicYear: '',
            questions: {},
            editableId: '',
            editableQuestionType: '',
            questionImage: [],
            options: {},
            modalAlertShow:false,
            reloadId: '',
            reloadQuestionType:'',
            reloadErrMsg: '',
            answerImage:[],
            subQuestionImage: [],
            image: "",
            showImageView: false,
        }
    }
    componentDidMount(): void {
        const { generateQuestions } = this.props;
        const reorder = this.reOrderQuestions(generateQuestions)
        if(reorder){
            generateQuestions.question_records = reorder
        }
        this.setState({ questions: generateQuestions })
        window.scrollTo(0, 0);
    }

    reOrderQuestions = (generateQuestions: any) => {
        if(generateQuestions?.question_records){
            const questionRecords = generateQuestions.question_records;
            const order: Record<string, number> = {
                "MCQ": 1,
                "FIB": 2,
                "Regular-2 Marks": 3,
                "Regular-5 Marks": 4,
                "Regular-10 Marks": 5
            };
             const sortedQuestions = questionRecords.sort((a:any, b:any) => {
                return order[a.db_question_type] - order[b.db_question_type];
            });
            return sortedQuestions
        }
        return '';
    }
    handleQuestionImageChange = (event: any, index:any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            // reader.onloadend = (e: any) => {
            //     this.setState(prevState => ({
            //         questionImage: [reader.result]
            //     }));

            // };
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    questionImage: {
                        ...prevState.questionImage,
                        [index]: { ...prevState.questionImage[index], image: reader.result }
                    }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    handleRemoveQuestionImageChange = (index:any) => {

        // this.setState((prevState) => ({
        //     questionImage: prevState.questionImage.filter((_:any, i:any) => i !== index),
        //   }));

        // for (var property in this.state.questionImage) {
        //     if (this.state.questionImage.hasOwnProperty(property)) {
        //       property == index && delete this.state.questionImage[property];
        //     }
        // }

        this.setState((prevState) => {
            const updatedQuestionImage = { ...prevState.questionImage };
            delete updatedQuestionImage[index];
            return { questionImage: updatedQuestionImage };
          });

    }

    handleAnswerImageChange = (event: any, index:any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            // reader.onloadend = (e: any) => {
            //     this.setState(prevState => ({
            //         answerImage: [reader.result]
            //     }));

            // };
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    answerImage: {
                        ...prevState.answerImage,
                        [index]: { ...prevState.answerImage[index], image: reader.result }
                    }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    handleImageChange = (event: any, option: any, index:any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                this.setState(prevState => ({
                    options: {
                        ...prevState.options,
                        [option]: { 
                            ...prevState.options[option],
                            [index]: { 
                                ...prevState.options[option]?.index,
                                image: reader.result 
                            } 
                            
                        }
                    }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    handleReadPassageSubImageChange = (event: any, index: any, sub_question_index:any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                // this.setState(prevState => ({
                //     subQuestionImage: {
                //         ...prevState.subQuestionImage,
                //         [index]: { ...prevState.subQuestionImage[index], image: reader.result }
                //     }
                // }));
                this.setState(prevState => ({
                    subQuestionImage: {
                        ...prevState.subQuestionImage,
                        [index]: { 
                            ...prevState.subQuestionImage[index],
                            [sub_question_index]: { 
                                ...prevState.subQuestionImage[index]?.sub_question_index,
                                image: reader.result 
                            } 
                            
                        }
                    }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    toRoman(num:any) {
        const roman = [
          { value: 1000, symbol: "M" },
          { value: 900, symbol: "CM" },
          { value: 500, symbol: "D" },
          { value: 400, symbol: "CD" },
          { value: 100, symbol: "C" },
          { value: 90, symbol: "XC" },
          { value: 50, symbol: "L" },
          { value: 40, symbol: "XL" },
          { value: 10, symbol: "X" },
          { value: 9, symbol: "IX" },
          { value: 5, symbol: "V" },
          { value: 4, symbol: "IV" },
          { value: 1, symbol: "I" },
        ];
      
        let result = "";
        for (let i = 0; i < roman.length; i++) {
          while (num >= roman[i].value) {
            result += roman[i].symbol;
            num -= roman[i].value;
          }
        }
        return result.toLowerCase(); // Convert to lowercase for i, ii, iii...
      }

    showImage = (image: any) => {
        this.setState({ image: image, showImageView: true });
    }

    onClose = () => {
        this.setState({ showImageView: false });
    }

    private renderQuestionSectionView(records: any, values: any, setTouched: any, setValues: any, setFieldTouched: any, errors: any, touched: any, setFieldValue: any) {
        const { editableId, editableQuestionType, options, subQuestionImage } = this.state;
        console.log(values)
        console.log(this.state.questionImage)
        return (
            <div>
                {records?.question_records && records.question_records.length > 0 ?
                    records.question_records.map((items: any) => (
                        <div className="question_section">
                            <div className="question_section_title">
                                <div>
                                    {items.question_type}
                                </div>
                                <div>
                                    {items.marks}
                                </div>
                            </div>
                            {items.question.length > 0 && items.question !== undefined ?
                                items.question.map((ques: any, index: any) => (
                                    <>
                                        {editableId === ques.uid && editableQuestionType == items.db_question_type ? (
                                            <>
                                                <div className="questions_block">
                                                <div className="question-lesson-difficulty-level mb-3 mr-2 mt-4 color-pink">
                                                    Lesson Name : {ques.lesson_name}  (Difficulty level : {ques.difficulty_level_name})  
                                                </div>
                                                    {index + 1}.
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Question Name</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name="question_name"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={false}
                                                        />
                                                    </FormGroup>
                                                    {items.en_question_type !== "MatchPairQ1" && ( 
                                                    <FieldArray name="question_image">
                                                    {({ push, remove }) => {
                                                    return ( <>
                                                   
                                                    {values.question_image.map((item:any, index:any) => (
                                                    <>
                                                    <div className="d-flex mt-3">
                                                        <FormGroup className="w-50">
                                                            <Field
                                                                label={<><span>key</span></>}
                                                                type="text"
                                                                name={`question_image[${index}].question_image_key`}
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                        <div className="mt-4">
                                                            <GenerateQuestionsImageUpload setImages={item.question_image_view} setfield={setFieldValue} clickImage={this.showImage} imageView={`question_image[${index}].question_image_view`} imageValue={`question_image[${index}].question_image_value`} questionImageIndex={index}/>
                                                        </div>
                                                        {/* <div className="mt-4 ml-4">
                                                            <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                this.handleQuestionImageChange(event, index)
                                                                const file = event.target.files[0];
                                                                if (file) {
                                                                    const reader = new FileReader();
                                                                    reader.onload = (e: any) => {
                                                                        const base64Image = e.target.result;
                                                                        setFieldValue(`question_image[${index}].question_image_value`, base64Image);
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }
                                                            }} />
                                                            {values.question_image?.[index]?.question_image_value && <img src={values.question_image?.[index]?.question_image_value} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                        </div> */}
                                                        <div className="mt-4 ml-4">
                                                            <button
                                                                className=""
                                                                type="button"
                                                                title="Delete Question"
                                                                onClick={() =>{
                                                                    remove(index);
                                                                }}
                                                            >
                                                                <span className="deleteIcon1">
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ErrorMessage name={`question_image.${index}`} component="div" className="errorcolor" />
                                                    </div>
                                                 
                                                    </>
                                                    )) }
                                                       <div className="col-md-12 text-center">
                                                            <Link
                                                                to={"#"}
                                                                title="Add Question"
                                                                className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                data-upgraded=",MaterialButton"
                                                                onClick={() =>
                                                                    push({
                                                                        question_image_key: "",
                                                                        question_image_value: '',
                                                                        question_image_view:[]
                                                                    })
                                                                }
                                                            >
                                                                <i className="material-icons">add</i>
                                                            </Link>
                                                        </div>
                                                    </>
                                                    );
                                                    }}
                                                    </FieldArray> )}

                                                    {items.db_question_type === "MCQ" && (
                                                        <FieldArray name={`questionsoptions`}>
                                                            {({ push: pushOption, remove }) => (
                                                                <div>
                                                                    {ques.mcq_options.map((option: any, optionIndex: any) => (
                                                                       
                                                                        <div key={optionIndex}>
                                                                            <div className="d-flex mt-3">
                                                                            <div className="mt-4 mr-3 ml-2">
                                                                                            <Field
                                                                                                type="radio"
                                                                                                name={`question_correct_answer`}
                                                                                                checked={option.option == values.question_correct_answer}
                                                                                                value={option.option}
                                                                                            />
                                                                                        </div>
                                                                                <div className="mr-3 mt-4">
                                                                                    {option.option}.
                                                                                </div>
                                                                                <FormGroup className="w-50">

                                                                                    <Field
                                                                                        label={<><span>Option</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name={`questionsoptions.${optionIndex}.value`}
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    // validate={(value: any) => {

                                                                                    //     let error;
                                                                                    //     if (!value) {
                                                                                    //         error = 'Option content is required';
                                                                                    //     }
                                                                                    //     return error;
                                                                                    // }}
                                                                                    />

                                                                                    {/* {errors[`questionsoptions.${optionIndex}.value`] && touched[`questionsoptions.${optionIndex}.value`] && (
                                                                                        <div className="error"></div>
                                                                                    )} */}
                                                                                    <ErrorMessage name={`questionsoptions.${optionIndex}.value`} component="div" className="errorcolor" />
                                                                                </FormGroup>  
                                                                            </div>
                                                                            <FieldArray name={`questionsoptions.${optionIndex}.answer_image`}>
                                                                            {({ push, remove }) => {
                                                                        
                                                                            return ( 
                                                                            
                                                                            <>
                                                                            {  
                                                                            values.questionsoptions[optionIndex].answer_image.map((item:any, index:any) => (
                                                                                <>
                                                                            <div className="d-flex mt-3">
                                                                                <FormGroup className="w-50">
                                                                                    <Field
                                                                                        label={<><span>key</span></>}
                                                                                        type="text"
                                                                                        name={`questionsoptions.${optionIndex}.answer_image.${index}.answer_image_key`}
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                                <div className="mt-4">
                                                                                    <GenerateQuestionsImageUpload setImages={item.answer_image_view} setfield={setFieldValue} clickImage={this.showImage} imageView={`questionsoptions.${optionIndex}.answer_image.${index}.answer_image_view`} imageValue={`questionsoptions.${optionIndex}.answer_image.${index}.answer_image_value`} questionImageIndex={index}/>
                                                                                </div>
                                                                                
                                                                                    {/* <div className="mt-4 ml-4">
                                                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                            this.handleImageChange(event, optionIndex,index)
                                                                                            const file = event.target.files[0];
                                                                                            if (file) {
                                                                                                const reader = new FileReader();
                                                                                                reader.onload = (e: any) => {
                                                                                                    const base64Image = e.target.result;
                                                                                                    setFieldValue(`questionsoptions.${optionIndex}.answer_image.${index}.answer_image_value`, base64Image);
                                                                                                };
                                                                                                reader.readAsDataURL(file);
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        />
                                                                                        {options?.[optionIndex]?.[index]?.image && <img src={options?.[optionIndex]?.[index].image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                                    </div> */}
                                                                                    <div className="mt-4 ml-4">
                                                                                        <button
                                                                                            className=""
                                                                                            type="button"
                                                                                            title="Delete Question"
                                                                                            onClick={() =>{
                                                                                                remove(index)
                                                                                            }}
                                                                                        >
                                                                                            <span className="deleteIcon1">
                                                                                                <i
                                                                                                    className="fa fa-trash"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>

                                                                                </div>
                                                                                {/* <div>
                                                                                    <ErrorMessage
                                                                                        name={`questionsoptions[${optionIndex}].answer_image[${index}].answer_image_key`}
                                                                                        component="div"
                                                                                        className="errorcolor"
                                                                                    />
                                                                                </div> */}
                                                                                </>
                                                                                 )) }
                                                                                 <div className="col-md-12 text-center">
                                                                                      <Link
                                                                                          to={"#"}
                                                                                          title="Add Question"
                                                                                          className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                                          data-upgraded=",MaterialButton"
                                                                                          onClick={() =>
                                                                                              push({
                                                                                                  answer_image_key: "",
                                                                                                  answer_image_value: '',
                                                                                                  answer_image_view:[]
                                                                                              })
                                                                                          }
                                                                                      >
                                                                                          <i className="material-icons">add</i>
                                                                                      </Link>
                                                                                  </div>
                                                                              </>
                                                                              );
                                                                              }}
                                                                              </FieldArray>
                                                                           
                                                                        </div>
                                                                    ))}
                                                                     <div className="text-center mb-3 mr-2 mt-4">
                                                                        <ErrorMessage name="question_correct_answer" component="div" className="errorcolor" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    )}
                                                    {items.en_question_type === "MatchPairQ" && (
                                                    <>
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Answer</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name="answer"
                                                            component={TextField}
                                                            className="textfield__input"
                                                            disabled={false}
                                                        />
                                                    </FormGroup>
                                                    

                                                    <FieldArray name="answer_image">
                                                    {({ push, remove }) => {
                                                    return ( <>
                                                    {values.answer_image.map((item:any, index:any) => (
                                                    <>
                                                    <div className="d-flex mt-3">
                                                        <FormGroup className="w-50">
                                                            <Field
                                                                label={<><span>key</span></>}
                                                                type="text"
                                                                name={`answer_image[${index}].answer_image_key`}
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                        <div className="mt-4">
                                                            <GenerateQuestionsImageUpload setImages={item.answer_image_view} setfield={setFieldValue} clickImage={this.showImage} imageView={`answer_image[${index}].answer_image_view`} imageValue={`answer_image.${index}.answer_image_value`} questionImageIndex={index}/>
                                                        </div>
                                                        {/* <div className="mt-4 ml-4">
                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                            this.handleAnswerImageChange(event,index)
                                                            const file = event.target.files[0];
                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onload = (e: any) => {
                                                                    const base64Image = e.target.result;
                                                                    setFieldValue(`answer_image[${index}].answer_image_value`, base64Image);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }} />
                                                        {this.state.answerImage?.[index]?.image && <img src={this.state.answerImage?.[index]?.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                    </div> */}
                                                   
                                                        <div className="mt-4 ml-4">
                                                            <button
                                                                className=""
                                                                type="button"
                                                                title="Delete Question"
                                                                onClick={() =>{
                                                                    remove(index)
                                                                }
                                                                
                                                                }
                                                            >
                                                                <span className="deleteIcon1">
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ErrorMessage name={`answer_image.${index}`} component="div" className="errorcolor" />
                                                    </div>
                                                 
                                                    </>
                                                    )) }
                                                       <div className="col-md-12 text-center">
                                                            <Link
                                                                to={"#"}
                                                                title="Add Question"
                                                                className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                data-upgraded=",MaterialButton"
                                                                onClick={() =>
                                                                    push({
                                                                        answer_image_key: "",
                                                                        answer_image_value: '',
                                                                        answer_image_view:[]
                                                                    })
                                                                }
                                                            >
                                                                <i className="material-icons">add</i>
                                                            </Link>
                                                        </div>
                                                    </>
                                                    );
                                                    }}
                                                    </FieldArray>
                                                    </>
                                                    )}
                                                     {items.en_question_type === "ReadPassageQ" && (
                                                     <FieldArray name="sub_questions">
                                                     {({ push }) => {
                                                     if (values.sub_questions.length !==  Number(items.en_question_mark)) {
                                                         values.sub_questions.length = 0;
                                                         for (let i = 0; i <  Number(items.en_question_mark); i++) {
                                                             push({
                                                                 question: "",
                                                                 question_image: [
                                                                    {
                                                                        sub_question_image_key: '',
                                                                        sub_question_image_value: '',
                                                                    }
                                                                ],
                                                                 marks: 1
                                                             })
                                                         }
                                                     }
                                                     return (
                                                         <>
                                                         {values.sub_questions.map((item:any, index:any) => (
                                                             <div className='row' key={index}>
                                                             <div className="col-md-12">                                                                                                            
                                                                         <div className="d-flex mt-1 w-100">
                                                                             <div className="mr-3 mt-4">
                                                                                 {this.toRoman(index + 1)}.
                                                                             </div>
                                                                             <FormGroup className="w-100">
                                                                                 <Field
                                                                                     label={<><span>Write Sub Question </span><span className='formmandatorycolor'> *</span></>}
                                                                                     type="text"
                                                                                     name={`sub_questions[${index}].question`}
                                                                                     component={
                                                                                         TextField
                                                                                     }
                                                                                     className="textfield__input"
                                                                                     disabled={false}
                                                                                 />
                                                                                  {/* <ErrorMessage name={`sub_questions.${index}`} component="div" className="errorcolor" /> */}
                                                                             </FormGroup>
                                                                             {/* <div className="mt-4 ml-4">
                                                                                    <div className="mt-4">
                                                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                            this.handleReadPassageSubImageChange(event, index)
                                                                                            const file = event.target.files[0];
                                                                                            if (file) {
                                                                                                const reader = new FileReader();
                                                                                                reader.onload = (e: any) => {
                                                                                                    const base64Image = e.target.result;
                                                                                                    setFieldValue(`sub_questions.${index}.question_image`, base64Image);
                                                                                                };
                                                                                                reader.readAsDataURL(file);
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        />
                                                                                        {subQuestionImage?.[index]?.image && <img src={subQuestionImage?.[index]?.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                            <FieldArray name={`sub_questions.${index}.question_image`}>
                                                                            {({ push, remove }) => {
                                                                            return ( 
                                                                            <>
                                                                            { 
                                                                            values.sub_questions[index].question_image.map((item:any, sub_question_index:any) => (
                                                                                <>
                                                                            <div className="d-flex mt-3">
                                                                                <FormGroup className="w-50">
                                                                                    <Field
                                                                                        label={<><span>key</span></>}
                                                                                        type="text"
                                                                                        name={`sub_questions.${index}.question_image.${sub_question_index}.sub_question_image_key`}
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                                <div className="mt-4">
                                                                                    <GenerateQuestionsImageUpload 
                                                                                        setImages={item.sub_question_image_view} 
                                                                                        setfield={setFieldValue} 
                                                                                        clickImage={this.showImage} 
                                                                                        imageView={`sub_questions.${index}.question_image.${sub_question_index}.sub_question_image_view`} 
                                                                                        imageValue={`sub_questions.${index}.question_image.${sub_question_index}.sub_question_image_value`} 
                                                                                        questionImageIndex={index} />
                                                                                </div>
                                                                                    {/* <div className="mt-4 ml-4">
                                                                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                            this.handleReadPassageSubImageChange(event, index, sub_question_index)
                                                                                            const file = event.target.files[0];
                                                                                            if (file) {
                                                                                                const reader = new FileReader();
                                                                                                reader.onload = (e: any) => {
                                                                                                    const base64Image = e.target.result;
                                                                                                    setFieldValue(`sub_questions.${index}.question_image.${sub_question_index}.sub_question_image_value`, base64Image);
                                                                                                };
                                                                                                reader.readAsDataURL(file);
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        />
                                                                                        {subQuestionImage?.[index]?.[sub_question_index]?.image && <img src={subQuestionImage?.[index]?.[sub_question_index].image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                                    </div> */}
                                                                                    <div className="mt-4 ml-4">
                                                                                        <button
                                                                                            className=""
                                                                                            type="button"
                                                                                            title="Delete Question"
                                                                                            onClick={() =>{
                                                                                                remove(sub_question_index)
                                                                                            }}
                                                                                        >
                                                                                            <span className="deleteIcon1">
                                                                                                <i
                                                                                                    className="fa fa-trash"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {/* <ErrorMessage
                                                                                        name={`sub_questions[${index}].question_image[${sub_question_index}].sub_question_image_key`}
                                                                                        component="div"
                                                                                        className="errorcolor"
                                                                                    /> */}
                                                                                </div>
                                                                                </>
                                                                                 )) }
                                                                                 <div className="col-md-12 text-center">
                                                                                      <Link
                                                                                          to={"#"}
                                                                                          title="Add Question"
                                                                                          className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                                          data-upgraded=",MaterialButton"
                                                                                          onClick={() =>
                                                                                              push({
                                                                                                  sub_question_image_key: "",
                                                                                                  sub_question_image_value: '',
                                                                                                  sub_question_image_view: [],
                                                                                              })
                                                                                          }
                                                                                      >
                                                                                          <i className="material-icons">add</i>
                                                                                      </Link>
                                                                                  </div>
                                                                              </>
                                                                              );
                                                                              }}
                                                                              </FieldArray>
                                                                         </div> 

                                                                        
                                                                     </div>
                                                         ))}
                                                         </>
                                                     );
                                                     }}
                                                 </FieldArray>
                                                    )}
                                                    <div className="text-right mb-3 mr-2 mt-4" >
                                                        <Button className="btn red mr-1 ml-1" name="submitButton1" onClick={() => this.handleQuestionSubmit(ques.uid, values, setTouched, setFieldTouched, items.db_question_type)}>
                                                            Submit
                                                        </Button>
                                                        <Button className="btn red mr-1 ml-1" onClick={() => this.handleCancel(ques.uid)}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="generate_question questions_block">
                                                 <div className="question-lesson-difficulty-level mb-3 mr-2 mt-4 color-pink">
                                                    Lesson Name : {ques.lesson_name}  (Difficulty level : {ques.difficulty_level_name})  
                                                </div>
                                                <div className="question_name"><div className="mr-2">{index + 1}.</div>    {this.renderQuestionWithImage(ques.question, ques.question_image)}</div >
                                                {/* <div className="question_image">
                                                    {ques.question_image ?
                                                        <img src={ques.question_image} width={200} height={200}></img>
                                                        : null}
                                                </div > */}
                                                <div className="answer">
                                                    {ques.answer ?
                                                        this.renderQuestionWithImage(ques.answer, ques.answer_image)
                                                        : null}
                                                </div>
                                                {/* <div className="answer">
                                                    {ques.answer_image ?
                                                         <img src={ques.answer_image} width={200} height={200}></img>
                                                        : null}
                                                </div> */}
                                                {
                                                    ques.mcq_options ? (
                                                        <>
                                                            {ques.mcq_options.length > 0 ? (
                                                                ques.mcq_options.map((option: any, index: any) => (
                                                                    <>
                                                                        <div className="mt-4 mcq_options"><div className="mr-2">{option.option ? option.option : index + 1}.</div>    {this.renderQuestionWithImage(option.answer, option.answer_image)}</div>
                                                                        {/* {
                                                                        Object.entries(option.answer_image).map(([key, value]:any) => {
                                                                            return (
                                                                                <div className="question_image">
                                                                                <img src={value} width={200} height={200}></img>
                                                                                </div>
                                                                            )
                                                                          })
                                                                        } */}
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null

                                                }
                                                {
                                                    ques.sub_questions ? (
                                                        <>
                                                            {ques.sub_questions.length > 0 ? (
                                                                ques.sub_questions.map((option: any, index: any) => (
                                                                    <>
                                                                        <div className="mt-4 sub_questions"><div className="mr-2">{this.toRoman(index + 1)}.</div>    {this.renderQuestionWithImage(option.question, option.question_image)}</div>
                                                                        {/* {option.question_image ? (
                                                                            Object.entries(option.question_image).map(([key, value]:any) => {
                                                                                return (
                                                                                    <div className="question_image">
                                                                                    <img src={value} width={200} height={200}></img>
                                                                                    </div>
                                                                                )
                                                                              })
                                                                        //     option.question_image.map((question_image: any, index: any) => (
                                                                        //     <div className="question_image">
                                                                        //        <img src={question_image} width={200} height={200}></img>
                                                                        //    </div>
                                                                        //    ))
                                                                           ) : null} */}
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null

                                                }
                                                < div className="text-right mb-3 mr-2 mt-4">
                                                {ques.add_new_question ? <>                                                     
                                                    <Button className="btn btn-pink mr-1 ml-1" onClick={() => this.handleEdit(ques.uid, items.db_question_type)}>
                                                        <i className="fa fa-edit" aria-hidden="true"></i> &nbsp; Add
                                                    </Button> </> : 
                                                    <> 
                                                    {typeof ques?.reload === 'undefined' || ques?.reload !== false ?
                                                    <Button className="btn btn-pink mr-1 ml-1" onClick={() => this.handleReload(items, ques)}>
                                                        <i className="fa fa-refresh" aria-hidden="true"></i> &nbsp; Reload
                                                    </Button> : null}
                                                    <Button className="btn btn-pink mr-1 ml-1" onClick={() => this.handleEdit(ques.uid, items.db_question_type)}>
                                                        <i className="fa fa-edit" aria-hidden="true"></i> &nbsp; Edit
                                                    </Button>
                                                    </>}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))
                                : null}
                        </div >

                    ))
                    : null}
            </div >)
    }

    handleRefresh = () => {
        const { generateQuestions } = this.props;
        const params = {
            blueprint_id: generateQuestions.uid,
            question_paper_id_list: generateQuestions.question_paper_id_list,
        }
        this.props.fetchGenerateQuestions(params).then((res: any) => {
            const { generateQuestions } = this.props;
            const reorder = this.reOrderQuestions(generateQuestions)
            if(reorder){
                generateQuestions.question_records = reorder
            }
            this.setState({ questions: generateQuestions })
        })

    };
    renderQuestionWithImage = (question: any, question_image:any) => {
        let formattedQuestion = question;
        // Replace placeholders with images
        for (const [key, value] of Object.entries(question_image)) {
          const imgTag = `<img src="${value}" alt="${key}" style="max-width: 100%; max-height: 200px;" />`;
          formattedQuestion = formattedQuestion.replace(`<${key}>`, imgTag);
        }
    
        return (
          <div
            dangerouslySetInnerHTML={{ __html: formattedQuestion }}
            style={{ fontSize: "16px", lineHeight: "1.5" }}
          />
        );
    }
    handleEdit = (id: any, db_question_type:any) => {
        const { questions } = this.state;

        const record = questions.question_records.find(
            (record:any) => record.db_question_type === db_question_type
          );

            // Find the specific question by uid
        const foundQuestion = record ? record.question.find((q:any) => q.uid === id) : null;
            
        // const foundQuestion = questions.question_records.reduce((found: any, record: any) => {
        //     if (found) return found;
        //     const question = record.question.find((question: any) => question.uid === id);
        //     return question ? question : null;
        // }, null);

        if (foundQuestion) {
            this.formik.setFieldValue(
                "question_name",
                foundQuestion?.is_edit ? foundQuestion.question : ''
            );
            //this.setState({questionImage:foundQuestion?.is_edit ? foundQuestion.question_image : ''})  
            // this.formik.setFieldValue(
            //     "question_image",
            //     foundQuestion?.is_edit ? foundQuestion.question_image : ''
            // );
            this.formik.setFieldValue(
                `question_image`,
                [
                    
                ]
            )
            Object.entries(foundQuestion.question_image).map(([key, value]:any,index:any) => {
                this.formik.setFieldValue(
                    `question_image.${index}.question_image_key`,
                    foundQuestion?.is_edit ? key : ''
                )

                this.formik.setFieldValue(
                    `question_image.${index}.question_image_value`,
                    foundQuestion?.is_edit ? value : ''
                )

                this.formik.setFieldValue(
                    `question_image.${index}.question_image_view`,
                    foundQuestion?.is_edit ? [{
                        "data_url": value,
                    }] : []
                )

                // this.setState(prevState => ({
                //     questionImage: {
                //         ...prevState.questionImage,
                //         [index]: { ...prevState.questionImage[index], image: foundQuestion?.is_edit ? value : '' }
                //     }
                // }));
            })

            if(Object.keys(foundQuestion.question_image).length == 0){
                this.formik.setFieldValue(
                    `question_image`,
                    [
                        {
                            question_image_key: '',
                            question_image_value: '',
                            question_image_view: [],
                        }
                    ]
                )
            }

            this.formik.setFieldValue(
                "answer",
                foundQuestion?.is_edit ? foundQuestion.answer : ''
            );
            // this.setState({answerImage:foundQuestion?.is_edit ? foundQuestion.answer_image : ''})  
            // this.formik.setFieldValue(
            //     "answer_image",
            //     foundQuestion?.is_edit ? foundQuestion.answer_image : ''
            // );
            if(foundQuestion?.answer_image){
            this.formik.setFieldValue(
                `answer_image`,
                [
                    
                ]
            )
            Object.entries(foundQuestion.answer_image).map(([key, value]:any,index:any) => {
                this.formik.setFieldValue(
                    `answer_image.${index}.answer_image_key`,
                    foundQuestion?.is_edit ? key : ''
                )

                this.formik.setFieldValue(
                    `answer_image.${index}.answer_image_value`,
                    foundQuestion?.is_edit ? value : ''
                )

                this.formik.setFieldValue(
                    `answer_image.${index}.answer_image_view`,
                    foundQuestion?.is_edit ? [{
                        "data_url": value,
                    }] : []
                )

                // this.setState(prevState => ({
                //     answerImage: {
                //         ...prevState.answerImage,
                //         [index]: { ...prevState.answerImage[index], image: foundQuestion?.is_edit ? value : '' }
                //     }
                // }));
            })

            if(Object.keys(foundQuestion.answer_image).length == 0){
                this.formik.setFieldValue(
                    `answer_image`,
                    [
                        {
                            answer_image_key: '',
                            answer_image_value: '',
                            answer_image_view: [],
                        }
                    ]
                )
            }
            }

            {
                
                (foundQuestion.mcq_options && foundQuestion.mcq_options.length > 0) && (
                    foundQuestion.mcq_options.map((option: any, index: any) => {
                        this.formik.setFieldValue(
                            `questionsoptions.${index}.value`,
                            foundQuestion?.is_edit ? option.answer : ''
                        )
                        // this.formik.setFieldValue(
                        //     `questionsoptions.${index}.answer_image`,
                        //     foundQuestion?.is_edit ? option.answer_image : ''
                        // )
                        this.formik.setFieldValue(
                            `questionsoptions.${index}.answer_image`,
                            [
                                
                            ]
                        )
                        Object.entries(option.answer_image).map(([key, value]:any,optionindex:any) => {
                            this.formik.setFieldValue(
                                `questionsoptions.${index}.answer_image.${optionindex}.answer_image_key`,
                                foundQuestion?.is_edit ? key : ''
                            )
            
                            this.formik.setFieldValue(
                                `questionsoptions.${index}.answer_image.${optionindex}.answer_image_value`,
                                foundQuestion?.is_edit ? value : ''
                            )

                            this.formik.setFieldValue(
                                `questionsoptions.${index}.answer_image.${optionindex}.answer_image_view`,
                                foundQuestion?.is_edit ?  [{
                                    "data_url": value,
                                }] : []
                            )
                            
                            // this.setState(prevState => ({
                            //     options: {
                            //         ...prevState.options,
                            //         [index]: { 
                            //             ...prevState.options[index], 
                            //             [optionindex]: { 
                            //                 ...prevState.options[index]?.optionindex,
                            //              image: foundQuestion?.is_edit ? value : '' 
                            //             }
                            //         }
                            //     }
                            // }));
                        })

                        if(Object.keys(option.answer_image).length == 0){
                            this.formik.setFieldValue(
                                `questionsoptions.${index}.answer_image`,
                                [
                                    {
                                        answer_image_key: '',
                                        answer_image_value: '',
                                        answer_image_view:[],
                                    }
                                ]
                            )
                        }

                        if(foundQuestion?.is_edit){
                            const correctOption = option.is_correct_answer == true ? option.option : '';
                            if(correctOption){
                                this.formik.setFieldValue(
                                    `question_correct_answer`,
                                    foundQuestion?.is_edit ? correctOption : ''
                                )
                            }
                        }else{
                            this.formik.setFieldValue(
                                `question_correct_answer`,
                               ''
                            )
                        }
                        
                        // this.setState(prevState => ({
                        //     options: {
                        //         ...prevState.options,
                        //         [index]: { ...prevState.options[index], image: foundQuestion?.is_edit ? option.answer_image : '' }
                        //     }
                        // }));

                    })
                )
            }

            {
                (foundQuestion.sub_questions && foundQuestion.sub_questions.length > 0) && (
                    foundQuestion.sub_questions.map((subQuestion: any, index: any) => {
                        this.formik.setFieldValue(
                            `sub_questions.${index}.question`,
                            foundQuestion?.is_edit ? subQuestion.question : ''
                        )
                        // this.formik.setFieldValue(
                        //     `sub_questions.${index}.question_image`,
                        //     foundQuestion?.is_edit ? subQuestion.question_image : ''
                        // )
                        // this.setState(prevState => ({
                        //     subQuestionImage: {
                        //         ...prevState.subQuestionImage,
                        //         [index]: { ...prevState.subQuestionImage[index], image: foundQuestion?.is_edit ? subQuestion.question_image : '' }
                        //     }
                        // }));
         
                        this.formik.setFieldValue(
                            `sub_questions.${index}.question_image`,
                            [
                                
                            ]
                        )
                        Object.entries(subQuestion.question_image).map(([key, value]:any,optionindex:any) => {
                            this.formik.setFieldValue(
                                `sub_questions.${index}.question_image.${optionindex}.sub_question_image_key`,
                                foundQuestion?.is_edit ? key : ''
                            )
            
                            this.formik.setFieldValue(
                                `sub_questions.${index}.question_image.${optionindex}.sub_question_image_value`,
                                foundQuestion?.is_edit ? value : ''
                            )

                            this.formik.setFieldValue(
                                `sub_questions.${index}.question_image.${optionindex}.sub_question_image_view`,
                                foundQuestion?.is_edit ? [{
                                    "data_url": value,
                                }] : []
                            )
                            
                            // this.setState(prevState => ({
                            //     subQuestionImage: {
                            //         ...prevState.subQuestionImage,
                            //         [index]: { 
                            //             ...prevState.subQuestionImage[index], 
                            //             [optionindex]: { 
                            //                 ...prevState.subQuestionImage[index]?.optionindex,
                            //              image: foundQuestion?.is_edit ? value : '' 
                            //             }
                            //         }
                            //     }
                            // }));
                        })

                        if(Object.keys(subQuestion.question_image).length == 0){
                            this.formik.setFieldValue(
                                `sub_questions.${index}.question_image`,
                                [
                                    {
                                        sub_question_image_key: '',
                                        sub_question_image_value: '',
                                        sub_question_image_view:[]
                                    }
                                ]
                            )
                        }

                    })
                )
            }
        }
        this.setState({ editableId: id, editableQuestionType: db_question_type})
    };

    handleCancel = (id: any) => {
        this.setState({ editableId: '', editableQuestionType: '' })
    };
    handleQuestionSubmit = (id: any, values: any, setTouched: any, setFieldTouched: any, db_question_type: any) => {
        const { questions } = this.state;
        this.formik.validateForm().then((errors: any) => {
            setFieldTouched('question_name', true);
            //setFieldTouched('question_image', true);
            setFieldTouched('question_correct_answer', true);
            setFieldTouched('answer', true);
            // const touched = Object.keys(values).reduce((acc:any, key:any) => {
            //     acc[key] = true;
            //     return acc;
            //   }, {});
            //   setTouched(touched, false);


            //           const userFi = {questionsoptions:[

            //   {
            //       "value": true,
            //   },
            //   {
            //     "value": true,
            // },
            // {
            //     "value": true,
            // }
            // ,
            // {
            //     "value": true,
            // },
            // ]}

            //      this.formik.setTouched(userFi, false);

            values.question_image.forEach((user: any, index: any) => {
                setFieldTouched(`question_image.${index}.question_image_key`, true);
            });

            values.answer_image.forEach((user: any, index: any) => {
                setFieldTouched(`answer_image.${index}.question_image_key`, true);
            });

            values.questionsoptions.forEach((options: any, index: any) => {
                setFieldTouched(`questionsoptions.${index}.value`, true);
                options.answer_image.forEach((option: any, optionindex: any) => {
                    setFieldTouched(`questionsoptions.${index}.answer_image.${optionindex}.answer_image_key`, true);
                });
            });

            values.sub_questions.forEach((sub_questions: any, index: any) => {
                setFieldTouched(`sub_questions.${index}.question`, true);
                sub_questions.question_image.forEach((option: any, optionindex: any) => {
                    setFieldTouched(`sub_questions.${index}.question_image.${optionindex}.sub_question_image_key`, true);
                });
            });




            if (Object.keys(errors).length === 0) {
                // No validation errors, proceed with further actions
                const question_records = questions.question_records.map((obj: any) => {
                    const question = obj.question.map((ques: any) => {
                        if (ques.uid === id && obj.db_question_type == db_question_type) {
                            ques.question = values.question_name
                            //ques.question_image = values.question_image
                            if (values?.question_image) {
                                ques.question_image = values.question_image.reduce((acc:any, item:any) => {
                                    acc[item.question_image_key] = item.question_image_value;
                                    return acc;
                                }, {})
                            }
                            ques.is_edit = true
                            ques.answer = values.answer
                            //ques.answer_image = values.answer_image
                            if (values?.answer_image) {
                                ques.answer_image = values.answer_image.reduce((acc:any, item:any) => {
                                    acc[item.answer_image_key] = item.answer_image_value;
                                    return acc;
                                }, {})
                            }
                            if(ques.add_new_question){
                                ques.add_new_question = false
                                ques.reload = false
                            } 
                            if (ques?.mcq_options) {
                                ques.mcq_options = ques.mcq_options.map((item: any, index: any) => {
                                    return {
                                        ...item,
                                        answer: values.questionsoptions[index].value,
                                        answer_image: values.questionsoptions[index].answer_image.reduce((acc:any, item:any) => {
                                            acc[item.answer_image_key] = item.answer_image_value;
                                            return acc;
                                        }, {}),
                                        is_correct_answer: item.option == values.question_correct_answer ? true : false,
                                    };
                                });
                            }
                            if (ques?.sub_questions) {
                                ques.sub_questions = ques.sub_questions.map((item: any, index: any) => {
                                    return {
                                        ...item,
                                        question: values.sub_questions[index].question,
                                        question_image: values.sub_questions[index].question_image.reduce((acc:any, item:any) => {
                                            acc[item.sub_question_image_key] = item.sub_question_image_value;
                                            return acc;
                                        }, {}),
                                        marks:1
                                    };
                                });
                            }


                            return ques;
                        }
                        return ques;
                    });

                    return { ...obj, question };
                })

                questions.question_records = question_records;
                this.setState({ questions: questions, editableId: '', editableQuestionType: '' })
                this.formik.setFieldValue(
                    "question_name",
                    ''
                );
            } else {
                // Validation errors present
                console.log('Validation errors:', errors);
            }
        });
    };
    getQuestionType = () => {
        const { questions, editableId, editableQuestionType } = this.state;
        let dbQuestionType: any;

        const record = questions.question_records.find(
            (record:any) => record.db_question_type === editableQuestionType
          );
          
        if (record) {
            dbQuestionType = record.en_question_type;
        }
        // for (const record of questions.question_records) {
        //     const question = record.question.find((question: any) => question.uid === editableId);
        //     if (question) {
        //         dbQuestionType = record.en_question_type;
        //         break;
        //     }
        // }
        return dbQuestionType;
    }
    isQuestionUnique = (questionText: any) => {
        const { questions, editableId, editableQuestionType } = this.state;
        // let dbQuestionType: any;
        // for (const record of questions.question_records) {
        //     const question = record.question.find((question: any) => question.uid === editableId);
        //     if (question) {
        //         dbQuestionType = record.db_question_type;
        //         break;
        //     }
        // }
        const questionsOfType = questions.question_records
            .filter((record: any) => record.db_question_type === editableQuestionType)
            .flatMap((record: any) => record.question)
            .filter((question: any) => question.uid !== editableId); // Exclude the current question
        const questionTextToCheck = questionsOfType.find((question: any) => question.question === questionText);
        return !questionTextToCheck;
    }

    checkAddQuestionExit = () => {
        const { questions } = this.state;

        const questionsFil =  questions.question_records.map((record: any) => ({
        ...record,
        question: record.question.filter((question: any) => question.add_new_question === true)
        })).filter((record: any) => record.question.length > 0);

        let count = 0;
        questions.question_records.forEach((record:any) => {
          record.question.forEach((question:any) => {
            if (question.add_new_question) {
              count += 1;
            }
          });
        });
        return count;

    //     const questionsOfType = questions.question_records
    //     .filter((record: any) => record.db_question_type === '')
    //     .flatMap((record: any) => record.question)
    //     .filter((question: any) => question.uid !== editableId); // Exclude the current question
    // const questionTextToCheck = questionsOfType.find((question: any) => question.question === questionText);


    }
    handleReload = (items: any, ques: any) => {

        const { questions } = this.state;
        const { generateQuestions } = this.props;
        const uids = items.question.map((item: any) => item.uid);

        const params = {
            question_paper_id_list: [],
            language_code: questions.language_code,
            uid_list: uids,
            grade_id: ques.grade_id,
            subject_id: ques.subject_id,
            lesson_id: ques.lesson_id,
            question_type_id: ques.question_type_id,
            category_id:ques.category_id,
            marks: ques.marks,
            difficulty_level_id: ques.difficulty_level_id,
        }

        this.props.fetchReloadQuestions(params).then((res: any) => {
            const { reloadQuestions, errors } = this.props;
            if(Object.keys(reloadQuestions).length != 0){
            const question_records = questions.question_records.map((obj: any) => {
                const question = obj.question.map((q: any) => {
                    if (q.uid === ques.uid && items.db_question_type == obj.db_question_type) {
                        return reloadQuestions.question[0];
                    }
                    return q;
                });

                return { ...obj, question };
            })
            questions.question_records = question_records;
            this.setState({ questions: questions })
        }else{
            let message = ''
                if(errors?.message){
                    message = errors.message + '\n Do you want Add new Question?'
                }else{
                    message = 'Do you want Add new Question?' 
                }
            this.setState({ modalAlertShow: true, reloadId: ques.uid, reloadQuestionType: items.db_question_type, reloadErrMsg:message})
        }
        })

    };
    onCancel = () => {
        this.setState({ modalAlertShow: false});
     }
    onAdd = () => {
        this.setState({ modalAlertShow: false});
        this.handleEdit(this.state.reloadId, this.state.reloadQuestionType)
     }
    render() {
        const { questions, editableId, modalAlertShow, reloadErrMsg, showImageView, image} = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return (
            <div className="page-wrapper">
            <ModalReloadAlert
            onState={modalAlertShow}
            onCancel={this.onCancel}
            onAdd={this.onAdd}
            message={reloadErrMsg}
            />
            <ModelImageView
                    onState={showImageView}
                    onCancel={this.onClose}
                    image={image}
                />
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Generate Questions']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Generate Questions']} />

                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    ref={node => this.formik = node}
                                    initialValues={initialValues}
                                    validate={(values) => {
                                        const errors: { [key: string]: any } = {};
                                        // const errors: { 
                                        //     [key: string]: any ,
                                        //     questionsoptions?: { 
                                        //         value?: string; 
                                        //         answer_image?: 
                                        //         { answer_image_key?: string; answer_image_value?: string 
                                        //         }[] }[] 
                                        //     } = {};
                                        const uniqueValues = new Set<string>();
                                        const uniqueImages = new Set<string>();
                                        const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                                        if (editableId) {
                                            if (!values.question_name) {
                                                errors.question_name = 'Question is required';
                                            } else {
                                                const isunique = this.isQuestionUnique(values.question_name.trim())
                                                if (!isunique) {
                                                    errors.question_name = 'Question must be unique required';
                                                }
                                            }
                                            
                                            // if (values.question_image) {
                                            //     const fileSizeInBytesquestion_image = (values.question_image.length * 3) / 4 - 2;
                                            //     if(fileSizeInBytesquestion_image >= 2 * 1024 * 1024) {
                                            //         errors.question_image = 'Image size should be less than 2MB'
                                            //     }
                                            // }

                                            values.question_image.forEach((question: any, index: any) => {
                                                const fileSizeInBytes = (question.question_image_value.length * 3) / 4 - 2;
                                                if (!question.question_image_key && question.question_image_value) {
                                                    errors.question_image = errors.question_image || [];
                                                    errors.question_image[index] = 'key is required';
                                                }
                                                else if (!question.question_image_value && question.question_image_key) {
                                                    errors.question_image = errors.question_image || [];
                                                    errors.question_image[index] = 'question image is required';
                                                }
                                                else if (question.question_image_value && fileSizeInBytes >= 2 * 1024 * 1024) {
                                                    errors.question_image = errors.question_image || [];
                                                    errors.question_image[index] = 'Image size should be less than 2MB';
                                                }
                                            });

                                            const questionType = this.getQuestionType();
                                            if (questionType == 'MCQ') {
                                                const { questionsoptions,  question_correct_answer } = values;

                                                errors.questionsoptions = questionsoptions.map((option, index) => {
                                                    const optionErrors: { value?: string; answer_image?: { answer_image_key?: string; answer_image_value?: string }[] } = {};
                                          
                                                    // Validate `value`
                                                    if (!option.value.trim()) {
                                                      optionErrors.value = "Value is required";
                                                    }else if (option.value && uniqueValues.has(option.value.trim())) {
                                                        optionErrors.value = 'Option text must be unique';
                                                    }
                                                    const uniqueOptionValues = new Set<string>();
                                                    // Validate `answer_image` array
                                                    optionErrors.answer_image = option.answer_image.map((image, imageIndex) => {
                                                      const imageErrors: { answer_image_key?: string; answer_image_value?: string } = {};
                                          
                                                      if (!image.answer_image_key && image.answer_image_value) {
                                                        imageErrors.answer_image_key = "Answer image key is required";
                                                      }else if (image.answer_image_key && uniqueOptionValues.has(image.answer_image_key.trim())) {
                                                        imageErrors.answer_image_key = 'key must be unique';
                                                      }
                                          
                                                      else if (!image.answer_image_value && image.answer_image_key) {
                                                        imageErrors.answer_image_key = "Answer image value is required";
                                                      }
                                                      uniqueOptionValues.add(image.answer_image_key.trim());
                                                      return Object.keys(imageErrors).length > 0 ? imageErrors : {};
                                                    });
                                                    uniqueValues.add(option.value.trim());
                                          
                                                    // Return only errors for current option
                                                    return Object.keys(optionErrors).length > 0 ? optionErrors : undefined;
                                                  });


                                                    // if (errors.questionsoptions.every((err:any) => !err)) {
                                                    //     delete errors.questionsoptions;
                                                    // }

                                                    if (errors.questionsoptions) {
                                                        let delete_questionoptions = false

                                                        const areAllAnswerImagesEmpty = errors.questionsoptions.every(
                                                            (option:any) => !option.value && Array.isArray(option.answer_image) && option.answer_image.every(
                                                                (item:any) => 
                                                                  typeof item === "object" && 
                                                                  item !== null && 
                                                                  Object.keys(item).length === 0
                                                              )
                                                          );
                                                        // If questionsoptions becomes an empty array, delete it
                                                        if (areAllAnswerImagesEmpty) {
                                                          delete errors.questionsoptions;
                                                        }
                                                      }


                                                // questionsoptions.forEach((option: any, index: any) => {
                                                //     const fileType = option.answer_image.substring(5, option.answer_image.indexOf(';'));
                                                //     const fileSizeInBytes = (option.answer_image.length * 3) / 4 - 2;
                                                //     if (!option.value.trim() && !option.answer_image) {
                                                //         errors.questionsoptions = errors.questionsoptions || [];
                                                //         errors.questionsoptions[index] = 'Please add text or upload an image';
                                                //     } else if (option.value && uniqueValues.has(option.value.trim())) {
                                                //         errors.questionsoptions = errors.questionsoptions || [];
                                                //         errors.questionsoptions[index] = 'Option text must be unique';
                                                //     } else if (option.answer_image && !imageTypes.includes(fileType)) {
                                                //         errors.questionsoptions = errors.questionsoptions || [];
                                                //         errors.questionsoptions[index] = 'Invalid image type. Supported types are JPEG, JPG, PNG';
                                                //     } else if (option.answer_image && fileSizeInBytes >= 2 * 1024 * 1024) {
                                                //         errors.questionsoptions = errors.questionsoptions || [];
                                                //         errors.questionsoptions[index] = 'Image size should be less than 2MB';
                                                //     } else if (option.answer_image && uniqueImages.has(option.answer_image.trim())) {
                                                //         errors.questionsoptions = errors.questionsoptions || [];
                                                //         errors.questionsoptions[index] = 'Option image must be unique';
                                                //     }
                                                //     uniqueValues.add(option.value.trim());
                                                //     uniqueImages.add(option.answer_image.trim());
                                                // });

                                                if (!question_correct_answer) {
                                                    errors.question_correct_answer = 'Question Correct Answer is required';
                                                }
                                            }
                                            if (questionType == 'MatchPairQ') {
                                                if (!values.answer) {
                                                    errors.answer = 'Answer is required';
                                                }
                                                if (values.answer_image) {
                                                    // const fileSizeInBytesquestion_image = (values.answer_image.length * 3) / 4 - 2;
                                                    // if(fileSizeInBytesquestion_image >= 2 * 1024 * 1024) {
                                                    //     errors.answer_image = 'Image size should be less than 2MB'
                                                    // }

                                                    values.answer_image.forEach((answer: any, index: any) => {
                                                        const fileSizeInBytes = (answer.answer_image_value.length * 3) / 4 - 2;
                                                        if (!answer.answer_image_key && answer.answer_image_value) {
                                                            errors.answer_image = errors.answer_image || [];
                                                            errors.answer_image[index] = 'key is required';
                                                        }
                                                        else if (!answer.answer_image_value && answer.answer_image_key) {
                                                            errors.answer_image = errors.answer_image || [];
                                                            errors.answer_image[index] = 'answer image is required';
                                                        }
                                                        else if (answer.answer_image_value && fileSizeInBytes >= 2 * 1024 * 1024) {
                                                            errors.answer_image = errors.answer_image || [];
                                                            errors.answer_image[index] = 'Image size should be less than 2MB';
                                                        }
                                                    });
        
                                                }
                                                
                                            }
                                            if (questionType == 'ReadPassageQ') {
                                                 const { sub_questions } = values;
                                                //  sub_questions.forEach((question: any, index: any) => {
                                                //     //const fileSizeInBytes = (question.question_image.length * 3) / 4 - 2;
                                                //     if (!question.question.trim()) {
                                                //         errors.sub_questions = errors.sub_questions || [];
                                                //         errors.sub_questions[index] = 'Question is required';
                                                //     }
                                                //     // else if (question.question_image && fileSizeInBytes >= 2 * 1024 * 1024) {
                                                //     //     errors.sub_questions = errors.sub_questions || [];
                                                //     //     errors.sub_questions[index] = 'Image size should be less than 2MB';
                                                //     // }
                                                // });

                                                errors.sub_questions = sub_questions.map((sub_question:any, index:any) => {
                                                    const optionErrors: { question?: string; question_image?: { sub_question_image_key?: string; sub_question_image_value?: string }[] } = {};
                                          
                                                    // Validate `value`
                                                    if (!sub_question.question.trim()) {
                                                      optionErrors.question = "Value is required";
                                                    }else if (sub_question.question && uniqueValues.has(sub_question.question.trim())) {
                                                        optionErrors.question = 'Sub questions must be unique';
                                                    }
                                                    const uniqueOptionValues = new Set<string>();
                                                    // Validate `answer_image` array
                                                    optionErrors.question_image = sub_question.question_image.map((image:any, imageIndex:any) => {
                                                      const imageErrors: { sub_question_image_key?: string; sub_question_image_value?: string } = {};
                                          
                                                      if (!image.sub_question_image_key && image.sub_question_image_values) {
                                                        imageErrors.sub_question_image_key = "Answer image key is required";
                                                      }else if (image.sub_question_image_key && uniqueOptionValues.has(image.sub_question_image_key.trim())) {
                                                        imageErrors.sub_question_image_key = 'key must be unique';
                                                      }
                                          
                                                      else if (!image.sub_question_image_value && image.sub_question_image_key) {
                                                        imageErrors.sub_question_image_key = "Answer image value is required";
                                                      }
                                                      uniqueOptionValues.add(image.sub_question_image_key.trim());
                                                      return Object.keys(imageErrors).length > 0 ? imageErrors : {};
                                                    });
                                                    uniqueValues.add(sub_question.question.trim());
                                          
                                                    // Return only errors for current option
                                                    return Object.keys(optionErrors).length > 0 ? optionErrors : undefined;
                                                  });


                                                    // if (errors.questionsoptions.every((err:any) => !err)) {
                                                    //     delete errors.questionsoptions;
                                                    // }

                                                    if (errors.sub_questions) {

                                                        const areAllAnswerImagesEmpty = errors.sub_questions.every(
                                                            (sub_question:any) => !sub_question.question && Array.isArray(sub_question.question_image) && sub_question.question_image.every(
                                                                (item:any) => 
                                                                  typeof item === "object" && 
                                                                  item !== null && 
                                                                  Object.keys(item).length === 0
                                                              )
                                                          );
                                                        // If questionsoptions becomes an empty array, delete it
                                                        if (areAllAnswerImagesEmpty) {
                                                          delete errors.sub_questions;
                                                        }
                                                      }
                                            }
                                        }else{
                                            const questionType = this.checkAddQuestionExit();
                                            if (questionType) {
                                                errors.empty_questions = 'Some Questions Are empty. Please Add then Sumbit';
                                            }
                                        }

                                        //   const touched: { [key: string]: any } = {};
                                        //   values.questionsoptions.forEach((user:any, index:any) => {
                                        //     touched[`questionsoptions.${index}.value`] = true;
                                        //   });
                                        //   return { ...errors, touched };

                                        //   values.questionlist.forEach((item:any, index:any) => {
                                        //     if (item.required && !item.value.trim()) {
                                        //       errors[`questions[${index}].value`] = 'Item is required';
                                        //     }
                                        //   });

                                        //   if (!values.questionlist || !values.questionlist.length) {
                                        //     errors.items = 'At least one item is required';
                                        //   } else {
                                        //     values.questionlist.forEach((item:any, index:any) => {
                                        //         console.log(item)
                                        //       if (!item) {
                                        //         errors[`questionlist[${index}]`] = 'Item is required';
                                        //       }
                                        //     });
                                        //   }
                                        return errors;

                                    }}

                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        this.props.createQuestionPaper(questions).then((res: any) => {

                                        })

                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValidating,
                                        dirty,
                                        touched,
                                        setTouched,
                                        setFieldTouched,
                                        handleReset,
                                        handleSubmit,
                                        setFieldValue,
                                        setValues
                                    }) => {

                                        return (
                                            <Form>

                                                <div className="row question_paper_row">

                                                    <div className="col-md-8 question_paper">
                                                        <div className="text-right mb-3 mr-2 mt-4" >
                                                            <Button className="btn red mr-1 ml-1" onClick={() => this.handleRefresh()}>
                                                                <i className="fa fa-refresh" aria-hidden="true"></i> &nbsp; Refresh
                                                            </Button>
                                                        </div>
                                                        <div className="question_header">
                                                            <div className="question_header_name">
                                                                <h3>{questions.school_name}</h3>
                                                            </div>
                                                            <div className="question_header_details">
                                                                <div>
                                                                    <h5>{questions.internal_grade_name} std</h5>
                                                                </div>
                                                                <div>
                                                                    <h5>{questions.internal_subject_name}</h5>
                                                                </div>
                                                                <div>
                                                                    <h5>{questions.exam_total_marks} marks</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            {this.renderQuestionSectionView(questions, values, setTouched, setValues, setFieldTouched, errors, touched, setFieldValue)}
                                                        </div>
                                                        <div className="text-center mb-3 mr-2 mt-4">
                                                            <ErrorMessage name="empty_questions" component="div" className="errorcolor" />
                                                        </div>
                                                        <div className="text-right mb-3 mr-2 mt-4" >
                                                            <Button className="btn red mr-1 ml-1" type="submit" name="submitButton2">
                                                                Submit
                                                            </Button>
                                                            <Link to="/generatequestions"
                                                                style={{ display: "inline-block" }}>
                                                                <Button className="btn red mr-1 ml-1">
                                                                    Cancel
                                                                </Button>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        generateQuestions: state.QuestionBank.generateQuestions,
        reloadQuestions: state.QuestionBank.reloadQuestions,
        errors: state.QuestionBank.errors,
    }
}

const mapDispatchToProps = {
    fetchGenerateQuestions,
    fetchReloadQuestions,
    createQuestionPaper
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateQuestions)