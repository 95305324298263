import { Reducer } from "redux";
import { QuestionBankState, QuestionBankTypes } from "./Types";

export const initialState: QuestionBankState = {
    loading: false,
    isAuthenticated: null,
    errors: [],
    items: [],
    page: 1,
    per_page: 10,
    total: 0,
    records: [],
    getExamTypeEdit: [],
    addExamType: [],
    getInternalGradle: [],
    getInternalSubject: [],
    getExternalSubject: [],
    getLanguage: [],
    getMappingEdit: [],
    addSubjectMapping: [],
    reloadQuestions: {},
    bluePrintQuestionCategoryDropdown:[]
}

const reducer: Reducer<QuestionBankState> = (state = initialState, action) => {
    switch (action.type) {
        case QuestionBankTypes.EXAM_TYPE:
        case QuestionBankTypes.ADD_EXAM_TYPE:
        case QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID:
        case QuestionBankTypes.EDIT_EXAM_TYPE:
        case QuestionBankTypes.DELETE_EXAM_TYPE:
        case QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT_QUESTION_CATEGORY_DROPDOWN:
        case QuestionBankTypes.BLUE_PRINT:
        case QuestionBankTypes.ADD_BLUE_PRINT:
        case QuestionBankTypes.EDIT_BLUE_PRINT:
        case QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID:
        case QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID:
        case QuestionBankTypes.INTERNAL_GRADLE:
        case QuestionBankTypes.INTERNAL_SUBJECT:
        case QuestionBankTypes.EXTERNAL_SUBJECT:
        case QuestionBankTypes.LANGUAGE_DROPDOWN:
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID:
        case QuestionBankTypes.ADD_SUBJECT_MAPPING:
        case QuestionBankTypes.EDIT_SUBJECT_MAPPING:
        case QuestionBankTypes.DELETE_SUBJECT_MAPPING:
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING:
        case QuestionBankTypes.FETCH_GENERATE_QUESTIONS:
        case QuestionBankTypes.FETCH_RELOAD_QUESTIONS:
        case QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN:
        case QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN:
        case QuestionBankTypes.FETCH_QUESTION_PAPER:
        case QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS:
        case QuestionBankTypes.CREATE_QUESTION_PAPER:
        case QuestionBankTypes.DOWNLOAD_QUESTION_PAPER:
        case QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID:
        case QuestionBankTypes.ADD_FEEDBACK_QUESTIONS:
        case QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS:
        case QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS:
        case QuestionBankTypes.DELETE_QUESTION_PAPER:
        case QuestionBankTypes.DOWNLOAD_OMR_SHEET:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                errors: [],
            }
        case QuestionBankTypes.EXAM_TYPE_SUCCESS:
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING_SUCCESS:
        case QuestionBankTypes.BLUE_PRINT_SUCCESS:
        case QuestionBankTypes.FETCH_QUESTION_PAPER_SUCCESS:
        case QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_SUCCESS:
            const { payload, records, per_page, page, total } = action;
            if (page === 1) {
                state.records = []
            }
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                items: payload,
                records: [...state.records, ...records],
                page: page,
                per_page: per_page,
                total: total,
                totalPage: Math.ceil(total / per_page),
            }
        case QuestionBankTypes.ADD_EXAM_TYPE_SUCCESS:
        case QuestionBankTypes.EDIT_EXAM_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                addExamType: action.payload
            }
        case QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID_SUCCESS:
            const { getExamTypeEdit } = action
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                items: action.payload,
                getExamTypeEdit: getExamTypeEdit
            }
        case QuestionBankTypes.INTERNAL_GRADLE_SUCCESS:
            const { getInternalGradle } = action
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                getInternalGradle: getInternalGradle
            }
        case QuestionBankTypes.INTERNAL_SUBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                getInternalSubject: action.getInternalSubject
            }
        case QuestionBankTypes.EXTERNAL_SUBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                getExternalSubject: action.getExternalSubject
            }
        case QuestionBankTypes.LANGUAGE_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                getLanguage: action.getLanguage
            }
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_SUCCESS:
            const { getMappingEdit } = action
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                items: action.payload,
                getMappingEdit: getMappingEdit
            }
        case QuestionBankTypes.ADD_SUBJECT_MAPPING_SUCCESS:
        case QuestionBankTypes.EDIT_SUBJECT_MAPPING_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                addSubjectMapping: action.payload
            }
        case QuestionBankTypes.DELETE_SUBJECT_MAPPING_SUCCESS:
            return {
                ...state
            }
        case QuestionBankTypes.EXAM_TYPE_FAIL:
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.BLUE_PRINT_FAIL:
        case QuestionBankTypes.FETCH_QUESTION_PAPER_FAIL:
        case QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_FAIL:
            const { errors } = action;
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: errors,
                records: [],
                total: 0
            }
        case QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintGradeDropdown: action.payload.data,
            }
        case QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintSubjectDropdown: action.payload.data,
            }
        case QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintExamTypeDropdown: action.payload.data,
            }
        case QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintLessonDropdown: action.payload.data,
            }
        case QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintDifficultyLevelDropdown: action.payload.data,
            }
        case QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintQuestionTypeDropdown: action.payload.data,
            }
        case QuestionBankTypes.BLUE_PRINT_QUESTION_CATEGORY_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintQuestionCategoryDropdown: action.payload.data,
            }
        case QuestionBankTypes.ADD_BLUE_PRINT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                addBluePrint: action.payload.data,
            }
        case QuestionBankTypes.EDIT_BLUE_PRINT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                editBluePrint: action.payload.data,
            }
        case QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                deleteBluePrint: action.payload.data,
            }
        case QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintById: action.payload.data,
            }
        case QuestionBankTypes.FETCH_GENERATE_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                generateQuestions: action.payload.data,
            }
        case QuestionBankTypes.FETCH_RELOAD_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                reloadQuestions: action.payload.data,
            }
        case QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                bluePrintDropdown: action.payload.data,
            }
        case QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                questionPaperDropdown: action.payload.data,
            }
        case QuestionBankTypes.CREATE_QUESTION_PAPER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                createQuestionPaper: action.payload.data,
            }
        case QuestionBankTypes.DOWNLOAD_QUESTION_PAPER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                downloadQuestionPaper: action.payload,
            }
        case QuestionBankTypes.DOWNLOAD_OMR_SHEET_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    isAuthenticated: false,
                    downloadOMRSheet: action.payload,
                }
        case QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                feedbackQuestionById: action.payload.data,
            }
        case QuestionBankTypes.ADD_FEEDBACK_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                addFeedbackQuestion: action.payload.data,
            }
        case QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                editFeedbackQuestion: action.payload.data,
            }
        case QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                deleteFeedbackQuestion: action.payload.data,
            }
        case QuestionBankTypes.DELETE_EXAM_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                deleteExamType: action.payload.data,
            }
        case QuestionBankTypes.DELETE_QUESTION_PAPER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                deleteQuestionPaper: action.payload.data,
            }
        case QuestionBankTypes.FETCH_GENERATE_QUESTIONS_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
                generateQuestions: []
            }
        case QuestionBankTypes.ADD_EXAM_TYPE_FAIL:
        case QuestionBankTypes.EDIT_EXAM_TYPE_FAIL:
        case QuestionBankTypes.FETCH_EXAM_TYPE_BY_ID_FAIL:
        case QuestionBankTypes.DELETE_EXAM_TYPE_FAIL:
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_FAIL:
        case QuestionBankTypes.ADD_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.EDIT_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.DELETE_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.BLUE_PRINT_GRADE_DROPDOWN_FAIL:
        case QuestionBankTypes.BLUE_PRINT_SUBJECT_DROPDOWN_FAIL:
        case QuestionBankTypes.BLUE_PRINT_EXAM_TYPE_DROPDOWN_FAIL:
        case QuestionBankTypes.BLUE_PRINT_LESSON_DROPDOWN_FAIL:
        case QuestionBankTypes.BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_FAIL:
        case QuestionBankTypes.BLUE_PRINT_QUESTION_TYPE_DROPDOWN_FAIL:
        case QuestionBankTypes.BLUE_PRINT_QUESTION_CATEGORY_DROPDOWN_FAIL:
        case QuestionBankTypes.ADD_BLUE_PRINT_FAIL:
        case QuestionBankTypes.EDIT_BLUE_PRINT_FAIL:
        case QuestionBankTypes.FETCH_BLUE_PRINT_BY_ID_FAIL:
        case QuestionBankTypes.DELETE_BLUE_PRINT_BY_ID_FAIL:
        case QuestionBankTypes.FETCH_SUBJECT_MAPPING_BY_ID_FAIL:
        case QuestionBankTypes.ADD_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.EDIT_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.DELETE_SUBJECT_MAPPING_FAIL:
        case QuestionBankTypes.FETCH_BLUE_PRINT_DROPDOWN_FAIL:
        case QuestionBankTypes.FETCH_QUESTION_PAPER_DROPDOWN_FAIL:
        case QuestionBankTypes.CREATE_QUESTION_PAPER_FAIL:
        case QuestionBankTypes.DOWNLOAD_QUESTION_PAPER_FAIL:
        case QuestionBankTypes.FETCH_FEEDBACK_QUESTIONS_BY_ID_FAIL:
        case QuestionBankTypes.ADD_FEEDBACK_QUESTIONS_FAIL:
        case QuestionBankTypes.EDIT_FEEDBACK_QUESTIONS_FAIL:
        case QuestionBankTypes.DELETE_FEEDBACK_QUESTIONS_FAIL:
        case QuestionBankTypes.DELETE_QUESTION_PAPER_FAIL:
        case QuestionBankTypes.DOWNLOAD_OMR_SHEET_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload
            }
        case QuestionBankTypes.INTERNAL_GRADLE_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
                getInternalGradle: []
            }
        case QuestionBankTypes.INTERNAL_SUBJECT_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
                getInternalSubject: []
            }
        case QuestionBankTypes.EXTERNAL_SUBJECT_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
                getExternalSubject: []
            }
        case QuestionBankTypes.LANGUAGE_DROPDOWN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                errors: action.payload,
                getLanguage: []
            }
        case QuestionBankTypes.FETCH_RELOAD_QUESTIONS_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                errors: action.payload,
                reloadQuestions: {},
            }
        default:
            return state;
    }
}

export { reducer as QuestionBankReducer }