import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { RootState } from '../store/Index';
import { imageSizeQB, imageTypeQB } from '../services/Constants';

interface ImageUploadState {
  images?: any,
  maxNumber?: number | undefined,
  getImage?: boolean
}
export type OwnUpdateImageFormProps = {
  postImage?: any;
  setImages: any[],
  answerImageIndex: any;
  setImagesState: (images: any[], answerImageIndex:any) => void,
  setfield: (name: string, value: any, shouldValidate?: boolean) => void;
  clickImage: (images:any) => void,
}
export class AnswerImageSelect extends Component<OwnUpdateImageFormProps, ImageUploadState> {
  constructor(props: any) {
    super(props);
    this.state = {
      images: [],
      maxNumber: 65,
      getImage: false
    }
  }
  componentDidMount() {
    const { setImages } = this.props;
    if (setImages && setImages.length > 0) {
      this.setState({ getImage: true });
    }
  }
  onChange = (imageList: any) => {
    const { setImagesState, setfield, answerImageIndex } = this.props
    this.setState({ getImage: true })
    setImagesState(imageList, answerImageIndex)
    setfield(`answer_image[${answerImageIndex}].answer_image_view`, imageList);
    if(imageList && imageList !==undefined && imageList !== null && imageList.length > 0 && imageList[0].file){
        if (imageList.length > 0) {
        if (imageList[0].file) {
            const file = imageList[0].file
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const base64Image = e.target.result;
                setfield(`answer_image[${answerImageIndex}].answer_image_value`, base64Image);
            };
            reader.readAsDataURL(file);
        }
    }
    }else{
        setfield(`answer_image[${answerImageIndex}].answer_image_value`, '');
      }
  };

  render() {
    const { images, maxNumber, getImage } = this.state;
    const { setImages, clickImage } = this.props;
    return (
      <div>
        <ImageUploading
          value={setImages}
          onChange={this.onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          acceptType={['jpg', 'jpeg' , 'png']}
          maxFileSize={6000000}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors
          }) => (
            // write your building UI
            <div className="upload__image-wrapper ml-2">
              <div>
                <div className="ml-3">
                  {setImages && setImages.length > 0 ?
                    <span>
                      {setImages.map((image: any, index: any) => (
                        <div className="image-item">
                         {image['data_url'] ? (
                          <div className="d-flex flex-wrap ml-3">
                            <div>
                              <Link to={'#'} onClick={() => clickImage(image['data_url'])}>
                                <img src={image['data_url']} alt="img" className="multiImage" />
                              </Link>
                            </div>
                            <div className="image-item__btn-wrapper">
                              <Link to={'#'} onClick={() => onImageRemove(index)} className='imageUploadRemove'>
                                <i className="fa fa-trash-o" aria-hidden="true"></i>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          null
                        )}
                        </div>
                      ))}
                    </span>
                    :
                    null
                  }
                </div>
              </div>
              <Link to={'#'} title="Upload"
                className="btn btn-circle btn-info mb-3"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <i className="fa fa-upload" aria-hidden="true"></i> Upload Image
              </Link>
              {
                  errors && <div className='formmandatorycolor'>
                  {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                  {errors.acceptType && <span>{imageTypeQB}</span>}
                  {errors.maxFileSize && <span>{imageSizeQB}</span>}
                  {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                </div>
              }
            </div>
            
          )}
        </ImageUploading>
      </div>
    )
  }
}

const mapStateToProps = ({}: RootState) => {
  return {
  };
};

export default connect(mapStateToProps, { })(AnswerImageSelect)
