import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InfiniteScroll from "react-infinite-scroll-component";
import { TextField } from "formik-material-ui";
import { CommonState } from "../../components/type";
import { fetchMySectionListGet } from "../../store/classes/Actions";
import { RowPerPage, RowsPerPageOptions, notificationMsg } from "../../services/Constants";
import { fetchStudentPost } from "../../store/student/Actions";
import { LoadMoreType } from "../../components/type";
import { FormGroup, MenuItem, Button } from "@material-ui/core";
import { AnyIfEmpty, connect } from "react-redux";
import { stopYearData } from "../../store/profile/Actions";
import { Link } from "react-router-dom";
import CommonLoader from "../../components/CommonLoader";
import { GetStudentNameList } from "../../store/student/Actions";
import { fetchHomeStudyView, fetchSubjectList, getSearchValues, ResetHomeStudyGrid } from "../../store/HomeStudy/Actions";
import { StudyViewSchema } from "./HomeStudyValidation";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { formValidationPatten } from "../../services/Constants";
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface Props{
    loading: boolean;
    getYear?: any;
    page: number;
    per_page: number;
    totalPage: number;
    report_records?: any;
    total: number;
    stopYear?: any;
    ListGrade: any;
    ListStandard: any;
    getSubjectList?: any;
    getStudentList?: any;
    getStudentDetails:any;
    fetchMySectionListGet: (grade: any) => any;
    fetchStudentPost: (loadMoreType: LoadMoreType) => any;
    stopYearData: () => any;
    ResetHomeStudyGrid: () => any;
    fetchHomeStudyView: (data: any) => any;
    fetchSubjectList: (data: any) => any;
    fetchStudentList: (data: any) => any;
    GetStudentNameList:(postValue?:any) => any;
    getStudentNameList: (postValue?:any) => any;
    getSearchValues: (searchValue?: any) => any;
}

interface StateTypes{
    report_records: any;
    search: string;
    hasMore: boolean;
    prev: any;
    next: any;
    page: any;
    page_no: any;
    SortOrderData: string;
    OrderNameData: string;
    acsOrder: boolean;
    descOrder: boolean;
    checkedstudent: boolean;
    grade: string;
    standard: string;
    subjectList: any;
    studentList: any;
    academicYear: any;
    class_id: any;
    student_id: any;
    subject_id: any;
    selectedStandard: any;
    academic_year: any;
    ascOrder?: boolean,
    sort_by?: string,
    sort_class_name?: string,
    sort_title_name?: string,
    per_page?:any
}

const initialValues ={
  grade: '',
  standard: '',
  student: '',
  subject: '',
}

class HomeStudyView extends React.Component<Props, StateTypes>{
    constructor(props: any) {
        super(props);
        this.state = {
            report_records:[],
            hasMore: true,
            prev: 0,
            next: 0,
            acsOrder: true,
            descOrder: false,
            page: 1,
            page_no: 1,
            search: '',
            SortOrderData: '',
            OrderNameData: '',
            checkedstudent: true,
            academicYear: null,
            grade: '',
            standard: '',
            subjectList: [],
            studentList: [],
            class_id: '',
            student_id: '',
            subject_id: '',
            selectedStandard: '',
            academic_year: null,
            ascOrder: true,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order',
            per_page: RowPerPage
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.UpdateYearDate();   
        this.props.ResetHomeStudyGrid();     
      }
    
    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYear } = this.props;
        if (stopYear === true) {
          this.UpdateYearDate(this.props.getYear)
        }
      }
    componentDidUpdate() {
        const { stopYear } = this.props;
        if (stopYear === true) {
          this.props.stopYearData()
        }
      }
    UpdateYearDate(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
          this.setState({ academicYear: getYear })
          this.getSchoolDetails(getYear)
        } else {
          this.setState({ academicYear: this.props.getYear })
          this.getSchoolDetails(this.props.getYear)
        }
      }

    getSchoolDetails(getYear?: any) {
        // const { getClassSectionData, newlyCreatedStudentInfo } = this.props;
        // this.props.fetchGradePost();    
    }      

    getGradeOption = (e: any) => {
        const { academicYear } = this.state;
        const { value } = e.target;
        const getValue = value;
        this.setState({ grade: getValue, hasMore: true });
        const getClassName = {
          grade: getValue
        }
        if (getClassName) {
          this.props.fetchMySectionListGet(getClassName)
        }
    }

    getStandardOption = (e: any) => {
        const { academicYear,grade, class_id } = this.state;
        const {ListStandard} = this.props;
        const { value } = e.target;
        const getStandard = value;

        const selectedStandard = ListStandard.find((standard:any) => standard.value === getStandard);
        if(selectedStandard){
        const getSection = {
          academic_year: academicYear,
          class_id: selectedStandard.id,          
        }
        this.setState({ standard: getStandard, hasMore: true, class_id: selectedStandard.id });        
        this.props.GetStudentNameList(getSection).then((res: any) => {
            window.scrollTo(0, 0);
          });
        }
    }
  
  

    getSubjectlist = (value: any) => {
      const { academicYear } = this.state;
      const getData = {
        academic_year : academicYear,
        grade: value
      }
      this.props.fetchSubjectList(getData).then((res:any) => {
        const { getSubjectList } = this.props;
        this.setState({subjectList: getSubjectList})
      })
    }

    getStudnetDetails(getYear?: any) {
      const { grade, standard, class_id } = this.state;
      const getPostStudent = {
        academic_year: getYear,
        class_id: class_id
      }
      this.setState({
        class_id: class_id
      })
      this.props.GetStudentNameList(getPostStudent).then((res: any) => {
        window.scrollTo(0, 0);
      });
  }

  showFilterData(getValues: any) {
    const { academicYear, page,class_id,subject_id,student_id } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        ascOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        class_id:class_id,
        subject_id: subject_id,
        student_id: student_id,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchHomeStudyView(postValue).then((res: any) => {
        
      });
    } else {
      this.setState({
        ascOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        class_id:class_id,
        subject_id: subject_id,
        student_id: student_id,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchHomeStudyView(postValue).then((res: any) => {
        
      });
    }
  }
    public fetchMoreViewData = (newPage?:any,numberOfRows?:any) => {
      const { page } = this.state;
      if (this.state.hasMore === true) {
          if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
              const params = {
                  page_no: newPage,
                  per_page: numberOfRows,
                  search: this.state.search,
                  order_by: this.state.OrderNameData,
                  sort_by: this.state.SortOrderData,
              }
              this.props.fetchHomeStudyView(params).then((res: any)=>{
                  // this.setState({ page: page + 1 })
              })
          }  
          if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
              this.setState({
                hasMore: false,
              })
          }
      }
  }
  handleInputChange = (e: any) => {
    const {academicYear,class_id,subject_id,student_id} = this.state;
    const { value } = e.target;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
       if (getSearchValue) {
      const postSearchValue:any = {
        page_no: 1,
        per_page: this.state.per_page,
        search: getSearchValue,
        academic_year: academicYear,
        class_id:class_id,
        subject_id: subject_id,
        student_id: student_id
      }
      this.props.fetchHomeStudyView(postSearchValue);
      this.setState({
        hasMore: true,
        page: 1,
        search: getSearchValue
      })
    } else {
      const postSearchValue:any = {
        page_no: 1,
        per_page: this.state.per_page,
        search: '',
        academic_year: academicYear,
        class_id:class_id,
        subject_id: subject_id,
        student_id: student_id
      }      
      this.props.fetchHomeStudyView(postSearchValue);
      this.setState({
        hasMore: true,
        page: 1,
        search: ''
      })
    }
    }
  }

  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            display: 'flex !important',
            padding: '8px !important'
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root:{
            color: 'rgba(0, 0, 0, 0.54) !important',
            margin: '3px !important'
          }
        }
      }   
    }
  })

    private renderHomeStudyDetails(report_records:any){
      const { loading,total } = this.props;
      const {per_page} = this.state;
      const  loadingQuestionFull = { display: loading ? "block" : "none" };

      const columns = [
        {
          name: "uid",
          label: "Id",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name: "name",
          label: "Name",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name: "created_at",
          label: "Created At",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
        {
          name: "transactions_score",
          label: "Transaction Score",
          options: {
            customBodyRender: (value:any) => value || '-',
          },
        },
      ];
      const options = {
        rowsPerPage: per_page,
        rowsPerPageOptions: RowsPerPageOptions,
        count: total,
        selectableRows: false,
        page:this.state.page - 1,
        filter:false,
        print:false,
        downloadOptions:{
          filename: "HomeStudyDetails.csv"
        },
        onChangePage: (newPage:any) => {
            this.setState({ page: newPage +1}); 
            this.fetchMoreViewData(newPage + 1,this.state.per_page);
        },
        onColumnSortChange:(changedColumn:any, direction:any) =>{
            const sortData = {
                sort_by: direction,
                order_by: changedColumn
            }
            this.showFilterData(sortData);
        },
        onChangeRowsPerPage: (numberOfRows:any) => {
          const page = 1
          this.setState({ per_page: numberOfRows,page:page });
          this.fetchMoreViewData(page,numberOfRows);
        },
        onSearchChange: (searchText:any, row:any) => {
            this.handleInputChange({ target: { value: searchText } });
        }
    };

      return(
          <div>
              <ThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                data={report_records?.data?.records || []}
                columns={columns}
                options={options}
                />
              </ThemeProvider>
          </div>
      )
  }
    render(){
        const { academicYear,selectedStandard, grade, standard} = this.state;
        const { loading, ListStandard, report_records, getStudentDetails} = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        let gradeList: any;
        if (this.props.ListGrade) {
        gradeList = this.props.ListGrade;
        }
        
        return(
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                            titleName={["HomeStudy View"]}
                            homeName={["Home"]}
                            url={["dashboard"]}
                            mainPageTitle={["HomeStudy View"]}
                            />
                            <div>
                                <Formik
                                 initialValues={initialValues}
                                 validationSchema={StudyViewSchema}
                                 onSubmit={(values,actions) => {   
                                  const {academicYear, class_id} = this.state;
                                  const { subject, student} = values;                              
      
                                  const getSearch = {
                                    academic_year: academicYear,
                                    class_id: class_id,
                                    subject_id: subject,
                                    student_id: student
                                  };                                  
                                  this.props.getSearchValues(getSearch).then((res:any)=> {
                                    window.scrollTo(0,0);
                                  });                                                                
                                 }}
                                >
                                    {({
                                        values,
                                        setFieldValue
                                    }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <FormGroup>
                                                        <Field
                                                        label={<><span>Grade</span><span className="formmandatorycolor"> *</span></>}
                                                        name="grade"
                                                        select 
                                                        component={TextField}
                                                        disabled={false}                                                       
                                                        onChange={(e:any,value:any)=>{
                                                          this.getGradeOption(e);
                                                          setFieldValue("grade", e.target.value);
                                                          setFieldValue("standard",'');
                                                          setFieldValue("student", '');
                                                          setFieldValue("subject", '');
                                                        }}                                                                                             
                                                        >
                                                            {gradeList.map((item: any) => (
                                                                <MenuItem value={item.grade_id}>{item.value}</MenuItem>
                                                            ))}
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-2">
                                                    <FormGroup>
                                                        <Field
                                                        label={<><span>Section</span><span className="formmandatorycolor"> *</span></>}
                                                        name="standard"                                                        
                                                        select 
                                                        component={TextField}
                                                        disabled={false}                                                        
                                                        onChange={(e:any, value:any) =>{
                                                          this.getStandardOption(e);                                                          
                                                          setFieldValue("standard", e.target.value);
                                                          setFieldValue("student", '');
                                                          setFieldValue("subject", '');
                                                        }}
                                                        >
                                                            {this.props.ListStandard.map((item: any) => (
                                                                <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                ))
                                                            }
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                  <FormGroup>
                                                    <Field
                                                    label={<><span>Student</span><span className="formmandatorycolor"> *</span></>}
                                                    name="student"
                                                    select                                                    
                                                    component={TextField}
                                                    disabled={false}
                                                    onChange={(e:any,value:any)=>{
                                                      this.getSubjectlist(e.target.value);
                                                      setFieldValue("student", e.target.value); 
                                                      setFieldValue("subject",'');                                                     
                                                    }}
                                                    onClick={(e:any) => this.setState({student_id: e.target.value})}
                                                    >
                                                      {getStudentDetails.map((items: any) =>(
                                                        <MenuItem value={items.student_id}>
                                                          {items.student_name}
                                                        </MenuItem>
                                                      ))}                                                      
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                      <Field
                                                      label={<><span>Subject</span><span className="formmandatorycolor"> *</span></>}
                                                      name="subject"
                                                      select 
                                                      component={TextField}
                                                      disabled={false}
                                                      onClick={(e:any) => this.setState({subject_id: e.target.value})}
                                                      >
                                                        {this.state.subjectList.map((item: any) => (
                                                            <MenuItem value={item.id}>
                                                              {item.name}
                                                            </MenuItem>
                                                          ))}
                                                      </Field>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="text-right mb-3 mr-2 mt-4">
                                                <ErrorMessage name="total_errors" component="div" className="errorcolor"/>                                                
                                                <Button className="btn btn-pink mr-1 ml-1" type="submit">Search</Button>                                                                                              
                                            </div>
                                        </Form>
                                    )}                                    
                                </Formik>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card card-topline-red">
                                  <div className="card-body no-padding height-9">
                                    <div className="row">
                                      <div className="table-responsive">
                                        {this.renderHomeStudyDetails(report_records)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div style={loadingQuestionFull}><SpinnerLoader /></div>
                </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return{
        getYear: state.profile.getYear,
        stopYear: state.profile.stopYear,
        ListGrade: state.classes.my_class,
        ListStandard: state.classes.my_section,
        loading: state.HomeStudy.loading,
        total: state.HomeStudy.total,
        per_page: state.HomeStudy.per_page,
        report_records: state.HomeStudy.searchValue,
        page: state.HomeStudy.page,
        getSubjectList: state.HomeStudy.getSubjectList,
        getStudentDetails:state.student.getStudentNameList,
    }
}

export default connect(mapStateToProps, {
    fetchMySectionListGet,
    fetchStudentPost,
    stopYearData,
    fetchHomeStudyView,
    fetchSubjectList,
    GetStudentNameList,
    getSearchValues,
    ResetHomeStudyGrid
}) (HomeStudyView)