import React, { Component } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Formik, Form, Field } from "formik";
import { Button, MenuItem, FormGroup, Checkbox } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { connect } from "react-redux";
import { stopYearData } from "../../store/profile/Actions";
import { fetchMySectionListGet, fetchMyClassListGet } from "../../store/classes/Actions";
import { GetSubjectAnalysisReport, GetTeacherAttendanceReport, GetClassAttendanceReport, 
        GetSubjectPerformanceReport, GetTeacherEngagementReport, GetTeacherPerformanceReport, 
        GetSubjectAttendivenessReport, GetTeacherOverallReport,  GetTeacherDropdown, GetSubjectDropdown, GetSubjectExamReport } from "../../store/Report/Action";
import CommonLoader from '../../components/CommonLoader';
import { Bar } from 'react-chartjs-2';
import { SubjectAnalysisViewReport } from "./SubjectAnalysisViewReport";
import { ClassAttendanceViewReport } from "./ClassAttendanceViewReport";
import { SubjectPerformanceViewReport } from "./SubjectPerformanceViewReport";
import { TeacherAttendaceViewReport } from "./TeacherAttendaceViewReport";
import TeacherEngagementView from './TeacherEngagementView';

import { SubjectAttendivenessViewReport } from "./SubjectAttendivenessViewReport";
import { TeacherOverallViewReport } from "./TeacherOverallViewReport";
import { AssessmentViewReport } from "./AssessmentViewReport";
import { GetStudentNameList } from "../../store/student/Actions";

export type OwnReportProps = {
    loading?: boolean;
    records?: any;
    getChangeYearData?: any;
    stopYearDate?: any;
    listGrade?: any;
    listStandard?: any;
    subjectAnalysisReport?: any;
    teacherAttendaceReport?: any;
    classAttendanceReport?: any;
    subjectPerformanceReport?: any;
    subjectAttendivenessReport?: any;
    teacherOverallReport?: any;
    teacherDropdown?: any;
    subjectDropdown?: any;
    getStudentDetails?: any;
    subjectExamReport?: any;
    stopYearData: () => any;
    fetchMySectionListGet: (grade: any) => any;
    fetchMyClassListGet: () => any;
    GetSubjectAnalysisReport: (data: any) => any;
    GetTeacherAttendanceReport:(data: any) => any;
    GetClassAttendanceReport:(data: any) => any;
    GetSubjectPerformanceReport:(data: any) => any;
    GetSubjectAttendivenessReport:(data: any) => any;
    GetTeacherOverallReport:(data: any) => any;
    GetTeacherEngagementReport:(data: any) => any;
    GetTeacherPerformanceReport:(data: any) => any;
    GetTeacherDropdown:(data: any) => any;
    GetSubjectDropdown:(data: any) => any;
    GetStudentNameList: (data: any) => any;
    GetSubjectExamReport: (data: any) => any;
};

const initialvalues = {
    select_class: '',
    section: '',
    teacher:'',
    subject:'',
    student:'',
}
interface StateTypes {
    academicYear?: null,
    grade?: any,
    section?: any,
    teacher:any,
    subject:any,
    student:any,
    assessmentReport?:any,
    subjectAnalysisReport?: any,
    teacherAttendaceReport?: any,
    classAttendanceReport?: any,
    subjectPerformanceReport?: any,
    subjectAttendivenessReport?: any,
    teacherOverallReport?: any,
    hasMore?: boolean,
    isSectionSelected?: any;
    subjectExamReport?: any;
}
export class ViewReport extends Component<OwnReportProps, StateTypes>{
    formik: any
    constructor(props: any) {
        super(props);
        this.state = {
            grade: '',
            section: '',
            teacher:'',
            subject:'',
            student:'',
            assessmentReport:'',
            subjectAnalysisReport: '',
            teacherAttendaceReport: '',
            classAttendanceReport:'',
            subjectPerformanceReport:'',
            subjectAttendivenessReport:'',
            teacherOverallReport:'',
            isSectionSelected:'',
            subjectExamReport: '',
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear();
    }
    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getOverAllReport(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getOverAllReport(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getOverAllReport(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
        this.props.fetchMyClassListGet().then((res: any) => {
            const { listGrade } = this.props
            this.setState({ grade: listGrade })
        });
     
    }

    getReports = (e: any, getValue?: any, class_id?: any, card_id?: any) => {
        const data = {
            academic_year: this.state.academicYear,
            grade_id: getValue,
            class_id: class_id,
            card_id: card_id || ''
        }
        this.props.GetTeacherDropdown(data).then((res: any) => {
            const { teacherDropdown } = this.props
            this.setState({ teacher: teacherDropdown?.data })
        });

        this.props.GetSubjectAnalysisReport(data).then((res: any) => {
            const { subjectAnalysisReport } = this.props
            this.setState({ subjectAnalysisReport: subjectAnalysisReport?.data })
        });
        this.props.GetSubjectExamReport(data).then((res:any) => {
            const { subjectExamReport } = this.props
            this.setState({ assessmentReport: subjectExamReport?.data})
        });
        this.props.GetTeacherAttendanceReport(data).then((res: any) => {
            const { teacherAttendaceReport } = this.props
            this.setState({ teacherAttendaceReport: teacherAttendaceReport?.data })
        });
        this.props.GetClassAttendanceReport(data).then((res: any) => {
            const { classAttendanceReport } = this.props
            this.setState({ classAttendanceReport: classAttendanceReport?.data })
        });
        this.props.GetSubjectPerformanceReport(data).then((res: any) => {
            const { subjectPerformanceReport } = this.props
            this.setState({ subjectPerformanceReport: subjectPerformanceReport?.data })
        });
        // this.props.GetSubjectAttendivenessReport(data).then((res: any) => {
        //     const { subjectAttendivenessReport } = this.props
        //     this.setState({ subjectAttendivenessReport: subjectAttendivenessReport?.data })
        // });
     
        
    }

    getTeacherReports = (values: any, teacher_id?: any, subject_id?: any, type?: any,) => {

        const data = {
            academic_year: this.state.academicYear,
            grade_id: values.select_class,
            class_id: values.section,
            teacher_id: teacher_id,
            subject_id: subject_id

        }
        if(type == 'teacher'){
            this.props.GetSubjectDropdown(data).then((res: any) => {
                const { subjectDropdown } = this.props
                this.setState({ subject: subjectDropdown?.data })
            });
        }
        this.props.GetTeacherOverallReport(data).then((res: any) => {
            const { teacherOverallReport } = this.props
            this.setState({ teacherOverallReport: teacherOverallReport?.data })
        });
    }

    getGradeOption = (e: any, setFieldValue: any) => {
        const { academicYear } = this.state;
        const { value } = e.target;
        const getValue = value;
        const data = {
            academic_year: this.state.academicYear,
            grade: getValue
        }
        if (data) {
            this.props.fetchMySectionListGet(data).then((res: any) => {
                const { listStandard } = this.props
                const val:any = [ ...listStandard]
                this.setState({ section: val})
            });
            const teacherData = {
                academic_year: this.state.academicYear,
                grade_id: getValue,
                class_id: ''
            }
            this.props.GetTeacherDropdown(teacherData).then((res: any) => {
                const { teacherDropdown } = this.props
                this.setState({ teacher: teacherDropdown?.data })
            });

           
        }
    }

    getStudentOption = (e: any, setFieldValue: any) => {
        const { academicYear } = this.state;
        const { value } = e.target;
        const getValue = value;
        if(value){
            const data = {
                academic_year: academicYear,
                class_id: getValue
            }
            if (data) {
                    this.props.GetStudentNameList(data).then((res:any)=>{
                        const { getStudentDetails } = this.props;
                        const val:any = [...getStudentDetails]
                        this.setState({student: val});
                    })
            }
        }

    }

    render() {
        const { loading } = this.props;
        const { grade, section, teacher, subject,student, subjectAnalysisReport, teacherAttendaceReport, classAttendanceReport, subjectPerformanceReport, subjectAttendivenessReport, teacherOverallReport, assessmentReport } = this.state;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Report']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Report']}
                        />
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <Formik
                                        ref={node => this.formik = node}
                                        initialValues={initialvalues}
                                        onSubmit={(values) => (
                                            console.log(values)
                                        )}
                                    >
                                        {({
                                            values,
                                            setFieldValue
                                        }) => (
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Class</span><span className="formmandatorycolor"> *</span></>}
                                                                name="select_class"
                                                                select
                                                                component={TextField}
                                                                disabled={false}
                                                                // onClick={this.getGradeOption}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "select_class",
                                                                        e.target.value
                                                                    );
                                                                    setFieldValue("student","");
                                                                    this.setState({ isSectionSelected: ''});
                                                                    this.getReports(e, e.target.value, '');
                                                                    this.getGradeOption(e, e.target.value)
                                                                }}
                                                            >
                                                                {grade && grade.length > 0 && grade.map((item: any) => (
                                                                    <MenuItem value={item.grade_id}>
                                                                        {item.grade}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Section</span><span className="formmandatorycolor"> *</span></>}
                                                                name="section"
                                                                select
                                                                component={TextField}
                                                                disabled={false}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "section",
                                                                        e.target.value
                                                                    );
                                                                    setFieldValue("student","");
                                                                    this.setState({ isSectionSelected: e.target.value !== ''});
                                                                    this.getStudentOption(e, e.target.value)
                                                                    this.getReports(e, values.select_class, e.target.value);
                                                                }}
                                                            >
                                                                {section && section.length > 0 && section.map((item: any) => (
                                                                    <MenuItem value={item.id}>
                                                                        {item.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Student</span><span className="formmandatorycolor"> *</span></>}
                                                                name="student"
                                                                select
                                                                component={TextField}
                                                                disabled={!this.state.isSectionSelected}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "student",
                                                                        e.target.value
                                                                    );
                                                                    this.getReports(e, values.select_class,values.section, e.target.value);
                                                                }}
                                                            >
                                                                {student && student.length > 0 && student.map((item: any) => (
                                                                    <MenuItem value={item.card_id}>
                                                                        {item.student_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>                                                    
                                                </div>
                                                <div className="row">
                                                <SubjectAnalysisViewReport subjectAnalysisReport={subjectAnalysisReport} reportType={'class'}/>
                                                <ClassAttendanceViewReport classAttendanceReportData={classAttendanceReport} reportType={values.student !='' ? 'student' : 'class'} />
                                                <SubjectPerformanceViewReport subjectPerformanceReportData={subjectPerformanceReport} reportType={values.student !='' ? 'student' : 'class'} />
                                                <AssessmentViewReport assessmentReportData={assessmentReport} reportType={values.student !='' ? 'student' : 'class'}/>
                                                {/* {subjectAttendivenessReport != undefined && subjectAttendivenessReport?.labels?.length > 0 && <SubjectAttendivenessViewReport SubjectAttendivenessReportData={subjectAttendivenessReport} /> } */}
                                                </div>
                                                <div className="row">
                                                <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Teacher</span><span className="formmandatorycolor"> *</span></>}
                                                                name="teacher"
                                                                className="textfield__input w-100"
                                                                select
                                                                component={TextField}
                                                                disabled={false}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "teacher",
                                                                        e.target.value
                                                                    );
                                                                    this.getTeacherReports(values, e.target.value, '', 'teacher');
                                                                }}
                                                            >
                                                                {teacher && teacher.length > 0 && teacher.map((item: any) => (
                                                                    <MenuItem value={item.teacher_id}>
                                                                        {item.teacher_full_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Subject</span><span className="formmandatorycolor"> *</span></>}
                                                                name="subject"
                                                                select
                                                                component={TextField}
                                                                disabled={false}
                                                                onChange={(e: any, value: any) => {
                                                                    setFieldValue(
                                                                        "subject",
                                                                        e.target.value
                                                                    );
                                                                    this.getTeacherReports(values, values.teacher, e.target.value, 'subject');
                                                                }}
                                                            >
                                                                {subject && subject.length > 0 && subject.map((item: any) => (
                                                                    <MenuItem value={item.id}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                    <TeacherAttendaceViewReport teacherAttendaceReportData={teacherAttendaceReport} reportType={'class'}/>
                                                    {teacherOverallReport != undefined && teacherOverallReport?.labels?.length > 0 && <TeacherOverallViewReport teacherOverallReportData={teacherOverallReport} /> }
                                                
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.report.loading,
        getChangeYearData: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        listGrade: state.classes.my_class,
        listStandard: state.classes.my_section,
        teacherDropdown: state.report.teacherDropdown,
        subjectDropdown: state.report.subjectDropdown,
        subjectAnalysisReport: state.report.subjectAnalysisReport,
        teacherAttendaceReport: state.report.teacherAttendaceReport,
        classAttendanceReport: state.report.classAttendanceReport,
        subjectPerformanceReport: state.report.subjectPerformanceReport,
        subjectAttendivenessReport: state.report.subjectAttendivenessReport,
        teacherOverallReport: state.report.teacherOverallReport,
        getStudentDetails:state.student.getStudentNameList,
        subjectExamReport:state.report.subjectExamReport
    }
}

export default connect(mapStateToProps, {
    stopYearData,
    fetchMySectionListGet,
    fetchMyClassListGet,
    GetSubjectAnalysisReport,
    GetTeacherAttendanceReport,
    GetClassAttendanceReport,
    GetSubjectPerformanceReport,
    GetSubjectAttendivenessReport,
    GetTeacherOverallReport,
    GetTeacherEngagementReport,
    GetTeacherPerformanceReport,
    GetTeacherDropdown,
    GetSubjectDropdown,
    GetStudentNameList,
    GetSubjectExamReport
})(ViewReport)