import React from 'react'
import { connect } from 'react-redux'
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import BreadCrumb from '../../components/BreadCrumb';
import { RootState } from '../../store/Index';
import { RouteComponentProps } from 'react-router';
import { StudentType, studentInputTypes } from '../../store/student/Types';
import { editStudent, fetchStudent } from '../../store/student/Actions';
import { fetchGradePost, fetchGradeStandardPost } from '../../store/classes/Actions';
import { fetchParent } from '../../store/student/Actions';
import { Link } from 'react-router-dom';
import { Button, FormGroup, MenuItem, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText, Checkbox } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { TextField, RadioGroup as FormikRadioGroup } from 'formik-material-ui';
import { StudentValidation } from './StudentValidation';
import ImageUpload  from './StudentImage';
import { fetchMyClassListGet, fetchMySectionListGet } from '../../store/classes/Actions';
import { getStudentClassesData } from '../../store/profile/Actions';
import { stopYearData } from '../../store/profile/Actions';
import { maxDate } from '../../services/Constants';
import { GetNationality, GetBloodGroup, GetCountryDropdown, GetStateDropdown, GetCityDropdown} from '../../store/teacher/Actions';
import { getHousedropdown, getSiblingsEmisDropdown, GetModeofTransport} from '../../store/student/Actions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChipInput from 'material-ui-chip-input'

interface OwnStudentEditProps extends RouteComponentProps<OwnPropsParams> {
    getChangeYearData?:any;
	stopYearDate?:any;
    editStudent: (student: any,isEdit:any) => any;
    fetchGradePost: (postValue:any) => void;
    fetchParent: (getParent:any) => void;
    fetchGradeStandardPost: () => any;
    fetchStudent: (postValue:any,isEdit:any) => any;
    fetchMyClassListGet:() => any;
    fetchMySectionListGet:(grade:any) => any;
    getStudentClassesData:(postData:any)=>any;
    stopYearData:()=>any;
    GetNationality: () => any;
    GetBloodGroup: () => any;
    getHousedropdown: () => any;
    GetModeofTransport: () => any;
    GetCountryDropdown: (getData: any) => any;
    GetStateDropdown: (data:any) => any;
    GetCityDropdown: (data:any) => any;
    getSiblingsEmisDropdown: (data:any) => any;
    loading: boolean;
    errorMessage: any;
    getStudentIt: any;
    getStudentData: any;
    getParentResponse:any;
    getImageURL:any;
    ListSection:any;
    ListClass:any;
    getProfile:any;
    nationality?: any;
    getBloodGroup?: any;
    getHouse?: any;
    Transport?: any;
    country?: any;
    state?: any;
    city?: any;
    siblingsData?: any;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class EditStudent extends React.Component<OwnStudentEditProps, studentInputTypes>  {
    formik:any;
    getSectionList:any;
    parentFirstname:any;
    emailId:any
    getEditData:any;
    paramsValue:any='';
    constructor(props: any) {        
        super(props);        
        this.state = {
            phone_number:'',
            email_id:'',
            student_name:'',
            academicYear:null,
            grade:'',
            standard:'',
            parent_firstname:'',
            parent_lastname:'',
            profile_picture:'',
            user_id:'',
            checkParentList:false,
            getSelectClass:true,
            getClass:'',
            getSection:'',
            date_of_birth: '',
            city: [],
            state: [],
            country: [],
            siblings_data: []
        }  
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear();
        this.getNationalityDropdown();
        this.getLocationDropdown();
    }
  
       // This is life cycle is initial load  component Will Update
       componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}

    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getEditStudent(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getEditStudent(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}

    getEditStudent(getYear?:any){
        const { getStudentData } =  this.props;
        const postValue = {
            academic_year:getYear
        }
        this.props.fetchGradePost(postValue);
        this.props.fetchGradeStandardPost();
        let getStudent:any = {
            id:Number(this.props.match.params.id),
            academic_year:getYear
        }
        
        if(this.props.location.pathname.includes('/student_edit')){
            this.paramsValue = 'Profile_update';
        }else{
            this.paramsValue = 'Internal_transfer';
        }
        this.props.fetchStudent(getStudent,this.paramsValue).then((res:any)=>{
            const {getStudentData}= this.props;
            if(getStudentData){
                if(getStudentData.studentclass_details){
                    let studentData:any = getStudentData.studentclass_details;
                    if(studentData){
                        this.setState({getClass:studentData.grade, getSection:studentData.standard})
                    }
                }
            }
        });
        this.props.fetchMyClassListGet();
        if(getStudentData){
            this.getSectionUpdate();
        }
    }

    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) { 
        const { getStudentData, stopYearDate } = this.props;    
        if(stopYearDate === true){
			this.props.stopYearData()
		}        
        // This is the function used to update old parent details
        let getFormikData:any = this.formik; 
        if(getFormikData){
            if(this.props.getParentResponse.length === undefined){ 
                getFormikData.setFieldValue('email_id', this.props.getParentResponse.email_id)  
                getFormikData.setFieldValue('parent_firstname', this.props.getParentResponse.parent_firstname)
                getFormikData.setFieldValue('parent_lastname', this.props.getParentResponse.parent_lastname)
           } else {
               if(getStudentData){
                getFormikData.setFieldValue('email_id', getStudentData.email_id)  
                getFormikData.setFieldValue('parent_firstname', getStudentData.parent_firstname)
                getFormikData.setFieldValue('parent_lastname', getStudentData.parent_lastname)
               }   
           }
        }
        if(prevProps.getStudentData!=this.props.getStudentData){
            const {getStudentData}= this.props;
            if(getStudentData && typeof getStudentData === 'object' && Object.keys(getStudentData).length !== 0){
                this.getSiblingsDropdown({phone_number:getStudentData.phone_number,email_id:getStudentData.email_id})
                if(getStudentData.personal_details){
                    setTimeout(() => {
                        const emisId:any = (getStudentData.personal_details.emis_id) ? getStudentData.personal_details?.emis_id : '';
                        const admissionNo:any = (getStudentData.personal_details.admission_no) ? getStudentData.personal_details.admission_no : '';
                        const rollNo:any = (getStudentData.personal_details.roll_no) ? getStudentData.personal_details.roll_no : '';
                        const nationalty:any =(getStudentData.personal_details.nationality) ? getStudentData.personal_details.nationality : 0;
                        const medicalConditions:any = (getStudentData.personal_details.medical_conditions) ? getStudentData.personal_details.medical_conditions : '';
                        const bloodGroup:any = (getStudentData.personal_details.blood_group) ? getStudentData.personal_details.blood_group : 0;
                        const house:any = (getStudentData.personal_details.house) ? getStudentData.personal_details.house : 0;
                        getFormikData.setFieldValue('emis_id', emisId)
                        getFormikData.setFieldValue('admission_no', admissionNo)
                        getFormikData.setFieldValue('roll_no', rollNo)
                        getFormikData.setFieldValue('nationality', nationalty)
                        getFormikData.setFieldValue('medical_conditions', medicalConditions)
                        getFormikData.setFieldValue('blood_group', bloodGroup)
                        getFormikData.setFieldValue('house', house)

                    const transportDetails = getStudentData.personal_details.transport_details[0];
                    const modeOfTransport:any = (transportDetails?.mode_of_transport) ? transportDetails?.mode_of_transport : 0;
                    const vehicleRegno:any = (transportDetails?.vehicle_reg_no) ? transportDetails?.vehicle_reg_no : '';
                    const driverName:any = (transportDetails?.driver_name) ? transportDetails?.driver_name : '';
                    const driverContactNo:any = (transportDetails?.contact_no) ? transportDetails?.contact_no : '';
                    getFormikData.setFieldValue('mode_of_transport', modeOfTransport)
                    getFormikData.setFieldValue('vehicle_reg_no', vehicleRegno)
                    getFormikData.setFieldValue('driver_name', driverName)
                    getFormikData.setFieldValue('driver_contact_no', driverContactNo)
                    const hobby:any = (getStudentData.personal_details.hobbies) ? getStudentData.personal_details.hobbies : [];
                    getFormikData.setFieldValue('hobbies', hobby)
                    const identificationMarks:any = (getStudentData.personal_details.identification_marks) ? getStudentData.personal_details.identification_marks : [];
                    getFormikData.setFieldValue('identification_marks', identificationMarks)

                    const father_details = getStudentData.personal_details?.family_details.find((person:any) => person.relationship === 'Father');
                    const fatherName:any = (father_details?.name) ? father_details?.name : '';
                    const fatherEmail:any = (father_details?.email) ? father_details?.email : '';
                    const fatherContactNo:any = (father_details?.contact_no) ? father_details?.contact_no : '';
                    const fatherOccupation:any = (father_details?.occupation) ? father_details?.occupation : '';
                    const fatherAnnuaIncome:any = (father_details?.annual_income) ? father_details?.annual_income : 0;
                    getFormikData.setFieldValue('father_name', fatherName)
                    getFormikData.setFieldValue('father_contact_no', fatherContactNo)
                    getFormikData.setFieldValue('father_email_id', fatherEmail)
                    getFormikData.setFieldValue('father_occupation', fatherOccupation)
                    getFormikData.setFieldValue('father_annual_income',fatherAnnuaIncome)

                    const mother_details = getStudentData.personal_details?.family_details.find((person:any) => person.relationship === 'Mother');
                    const motherName:any = (mother_details?.name) ? mother_details?.name : '';
                    const motherEmail:any = (mother_details?.email) ? mother_details?.email : '';
                    console.log(motherEmail)
                    const motherContactNo:any = (mother_details?.contact_no) ? mother_details?.contact_no : '';
                    const motherOccupation:any = (mother_details?.occupation) ? mother_details?.occupation : '';
                    const motherAnnualIncome:any = (mother_details?.annual_income) ? mother_details?.annual_income : 0;
                    getFormikData.setFieldValue('mother_name', motherName)
                    getFormikData.setFieldValue('mother_contact_no', motherContactNo)
                    getFormikData.setFieldValue('mother_occupation', motherOccupation)
                    getFormikData.setFieldValue('mother_email_id', motherEmail)
                    getFormikData.setFieldValue('mother_annual_income', motherAnnualIncome)

                    const resiAddress:any = (getStudentData.personal_details.residential_address?.address) ? getStudentData.personal_details.residential_address?.address : '';
                    const city:any = (getStudentData.personal_details.residential_address?.city) ? getStudentData.personal_details.residential_address?.city : 0;
                    const state:any = (getStudentData.personal_details.residential_address?.state) ? getStudentData.personal_details.residential_address?.state : 0;
                    const country:any = (getStudentData.personal_details.residential_address?.country) ? getStudentData.personal_details.residential_address?.country : 0;
                    const pincode:any = (getStudentData.personal_details.residential_address?.pincode) ? getStudentData.personal_details.residential_address?.pincode : '';
                    const siblingDetails:any = (getStudentData.personal_details?.sibling_details) ? getStudentData.personal_details?.sibling_details : [];

                    getFormikData.setFieldValue('residential_address', resiAddress)
                    getFormikData.setFieldValue('city', city)
                    getFormikData.setFieldValue('state', state)
                    getFormikData.setFieldValue('country', country)
                    getFormikData.setFieldValue('pincode', pincode)
                    getFormikData.setFieldValue('sibling_details', siblingDetails)
                    
                }, 500); 
                    const data = []
                    data.push(getStudentData.personal_details?.residential_address.city)
                    const result = data.filter(item => item !== '').join(',');
                    const getData = {
                        uid: result
                    }
                    this.props.GetCountryDropdown(getData).then((res:any) => {
                        const { country } = this.props;
                        const res_city = getStudentData.personal_details?.residential_address?.city
    
                        if(res_city && country[res_city]){
                          this.setState({ 
                            state: country[res_city]?.state_list || [], 
                            city:country[res_city]?.city_list || [],
                            selectedCountry:country[res_city].country
                        })
                          
                          if (getFormikData) {
                            setTimeout(() => {
                              if (getFormikData) {
                                getFormikData.setFieldValue('country', country[res_city].country);
                                getFormikData.setFieldValue('state', country[res_city].state);
                                getFormikData.setFieldValue('city', res_city);
                              }
                            }, 200); 
                            }
                        }  
                    });
                    
                    
                }
            }
        }
     }
    getSectionUpdate(){
        const getEditDetails = this.props.getStudentData;
        let getEditData = getEditDetails;
        if(getEditData){
            let studentclass_details:any;
            let getGrade:any;
            let Section:any;
            studentclass_details = getEditData.studentclass_details;
            if(studentclass_details){
                getGrade = studentclass_details.grade_id;
                Section = studentclass_details.standard;
                if(getGrade && Section){
                    const getGradeSelet = {
                        grade:getGrade
                    }
                    this.setState({getClass:getGrade, getSection:Section})
                    this.props.fetchMySectionListGet(getGradeSelet)
                }
            }
        }

    }
    selectEditClassname(getValue:any){
        const { value } = getValue.target;
        if(value){
            const getGrade = {
                grade:value
            }
            this.setState({getClass:value})
            this.props.fetchMySectionListGet(getGrade);
        }
      }
      selectStandard(e:any){
        const { value } = e.target;
        this.setState({getSection:value})
      }
      findParantDetail= (e: any) => {
        const { getProfile } = this.props;
        const { value } = e.target;
        if(value.length === 10) {
            const getPhone = {
                phone_number:value,
                school_id:getProfile.school_id
            };
            this.props.fetchParent(getPhone);          
        }        
    }
    getNationalityDropdown= () => {
        this.props.GetNationality().then((res:any) => {
            const { nationality } = this.props;           
        });
        this.props.GetBloodGroup().then((res:any) => {
            const { getBloodGroup } = this.props;
        });
        this.props.getHousedropdown().then((res:any) => {
            const { getHouse } = this.props;
        });
        this.props.GetModeofTransport().then((res:any) => {
            const { Transport } = this.props;
        });
    }
    getLocationDropdown = () => {
        const getData = {
            category: 'country'
        }
        this.props.GetCountryDropdown(getData).then((res:any) => {
            const { country } = this.props;
            this.setState({ country: country});
        });
    }
    getStateDropdown =(country:any) => {
        const getData = {
            category: 'state',
            country: country
        }
        this.props.GetStateDropdown(getData).then((res:any) => {
            const { state } = this.props;
            this.setState({ state: state });
        })
    }
    getCityDropdown = (selectState: string) => {
        const Data = {
            category: 'city',
            country: this.state.selectedCountry,
            state: selectState
        }
        this.props.GetCityDropdown(Data).then((res: any) => {
            const { city } = this.props;
            this.setState({ city: city });
        });
    }
    handleCountryChange = (e: any) => {
        const selectedCountry = e.target.value;
        this.setState({ selectedCountry });
        this.getStateDropdown(selectedCountry);
    };

    handleStateChange = (e: any) => {
        const selectedState = e.target.value;
        this.setState({ selectedState });
        this.getCityDropdown(selectedState);
    };

    getSiblingsDropdown = (values:any) => {
        let getFormikData:any = this.formik;
        if(values && values.phone_number && values.phone_number.length === 10 && values.email_id !== ''){
            const data = {
                academic_year: this.state.academicYear,
                email_id: values.email_id,
                mobile: values.phone_number
            }
            this.props.getSiblingsEmisDropdown(data).then((res:any) => {
                const { siblingsData } = this.props;
                const siblingFilter = siblingsData?.filter((item:any) => item.id !== this.props.getStudentData.id)
                this.setState({ siblings_data: siblingFilter })
                if(this.props.getStudentData.personal_details?.siblings_details){
                    const siblingIds = this.props.getStudentData.personal_details.siblings_details.map((sibling: any) => sibling.id);
                    const filterData = siblingsData.filter((student: any) => siblingIds.includes(student.id));
                    getFormikData.setFieldValue('sibling_details', filterData)
                }                
            })
        }        
    }

    render() {
        const { loading,nationality,getBloodGroup,
            getHouse, Transport} = this.props;
        const { getClass, getSection, academicYear, country, state, city  } = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const getEditDetails = this.props.getStudentData;
        this.getEditData = getEditDetails;
        let getGrade:any;
        let getStandard:any;
        let studentclass_details:any;
        let getID:any;
        let phoneNo:any;
        let studentName:any;
        let parentLastname:any;
        let userId:any
        let profilePicture:any
        let getUpdateImage:any;
        let diableEditField:any = false;
        let gender:any;
        let date_of_birth: any;
        let personal_details: any;
        let emis_id: any;
        let admission_no: any;
        let roll_no: any;
        let nationlity: any;
        let medical_conditions: any;
        let blood_group: any;
        let house: any;
        let mode_of_transport: any;
        let vehicle_reg_no: any;
        let driver_contact_no: any;
        let driver_name: any;
        let hobbies: any;
        let identification_marks: any;
        let relationship:any;
        let father_name: any;
        let father_contact_no: any;
        let father_email: any;
        let father_occupation: any;
        let father_annual_income: any;
        let residential_address: any;
        let residential_city: any;
        let residential_state: any;
        let pincode: any;
        let residential_country: any;
        if(this.props.location.pathname.includes('/student_edit')){
            diableEditField = true;
        }else{
            diableEditField = false;
        }
        if(this.getEditData){
            this.emailId =  this.getEditData.email_id;
            getID =  this.getEditData.id;
            phoneNo =  this.getEditData.phone_number;
            studentName =  this.getEditData.student_name;
            this.parentFirstname =  this.getEditData.parent_firstname;
            studentclass_details =  this.getEditData.studentclass_details;
            parentLastname =  this.getEditData.parent_lastname;
            userId =  this.getEditData.user_id;
            gender =  this.getEditData.gender;
            date_of_birth = this.getEditData.date_of_birth;
            personal_details = this.getEditData.personal_details;
            if(studentclass_details){
                getGrade = studentclass_details.grade_id;
                getStandard = studentclass_details.standard;
                profilePicture = studentclass_details.profile_picture;
                getUpdateImage = studentclass_details.profile_picture;
            }
            if(personal_details){
            emis_id = this.getEditData?.personal_details?.emis_id;
            admission_no = this.getEditData.personal_details.admission_no;
            roll_no = this.getEditData.personal_details.roll_no;
            }
        }
        const initialEditValues: StudentType = {
            id:getID,
            phone_number:phoneNo,
            email_id:this.emailId,
            student_name:studentName,
            academic_year:academicYear,
            grade: getGrade,
            standard: getStandard,
            parent_firstname:this.parentFirstname,
            parent_lastname:parentLastname,
            profile_picture:profilePicture,
            user_id:userId,
            gender:gender,
            date_of_birth:date_of_birth,
            emis_id:'',
            admission_no:'',
            roll_no:'',
            nationality:0,
            medical_conditions:'',
            blood_group:0,
            house:0,
            mode_of_transport:0,
            vehicle_reg_no:'',
            driver_name:'',
            driver_contact_no: '',
            hobbies: [],
            identification_marks: [],
            father_name: '',
            father_contact_no: '',
            father_email_id: '',
            father_occupation: '',
            father_annual_income: 0,
            mother_name: '',
            mother_contact_no: '',
            mother_occupation: '',
            mother_email_id: '',
            mother_annual_income: 0,
            residential_address: '',
            city: 0,
            state: 0,
            country: 0,
            pincode:'',
            sibling_details: []
        };
        
        if(this.props.getImageURL){
            const getUrlpath = this.props.getImageURL.url;
            if(getUrlpath){
                getUrlpath.forEach((items:any)=>{
                    getUpdateImage = items;
                })
            }
        } 
        if(getClass || getSection) {
            let postClassesDetails:any = {
                class_name: getClass, 
                section_name: getSection
            }
            this.props.getStudentClassesData(postClassesDetails);
        }
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Student']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Student']}
                                baseURL={['student']}
                                mainPageTitle={[diableEditField ? "Edit Student" : "Transfer Student"]} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header> {diableEditField ? "Edit Student" : "Transfer Student"}</header>
                                            <div className="tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            { this.getEditData && this.emailId && this.parentFirstname && this.props.ListSection && this.props.ListClass ?
                                                <Formik
                                                ref={node=>this.formik = node}
                                                validationSchema={StudentValidation}
                                                initialValues={initialEditValues}
                                                onSubmit={(values:any, actions) => {
                                                    const postEditValues: any = {
                                                        id:getID,
                                                        phone_number:values.phone_number,
                                                        email_id:values.email_id.toLowerCase(),
                                                        student_name:values.student_name,
                                                        academic_year:values.academic_year,
                                                        grade: values.grade,
                                                        standard: values.standard,
                                                        parent_firstname:values.parent_firstname,
                                                        parent_lastname:values.parent_lastname,
                                                        profile_picture:values.profile_picture,
                                                        user_id:values.user_id,
                                                        gender:values.gender,
                                                        date_of_birth:values.date_of_birth,
                                                        personal_details : {
                                                            emis_id : values.emis_id,
                                                            admission_no: values.admission_no,
                                                            roll_no: values.roll_no,
                                                            nationality: values.nationality,
                                                            medical_conditions: values.medical_conditions,
                                                            blood_group: values.blood_group,
                                                            house: values.house,
                                                            transport_details: [{
                                                                mode_of_transport: values.mode_of_transport,
                                                                vehicle_reg_no: values.vehicle_reg_no,
                                                                driver_name: values.driver_name,
                                                                contact_no: values.driver_contact_no
                                                            }],
                                                            hobbies: values.hobbies,
                                                            identification_marks: values.identification_marks,                                                
                                                            family_details: [
                                                                {
                                                                    "name": values.father_name,
                                                                    "relationship": "Father",
                                                                    "contact_no": values.father_contact_no,
                                                                    "email": values.father_email_id,
                                                                    "occupation": values.father_occupation,
                                                                    "annual_income": parseFloat(values.father_annual_income)
                                                                },
                                                                {
                                                                    "name": values.mother_name,
                                                                    "relationship": "Mother",
                                                                    "contact_no": values.mother_contact_no,
                                                                    "email": values.mother_email_id,
                                                                    "occupation": values.mother_occupation,
                                                                    "annual_income": parseFloat(values.mother_annual_income)
                                                                }
                                                        ],
                                                            sibling_details: values.sibling_details,                                                
                                                            "residential_address": {
                                                                "address": values.residential_address,
                                                                "city": values.city,
                                                                "state": values.city,
                                                                "pincode": values.pincode,
                                                                "country": values.city,
                                                            },
                                                        }
                                                    }       
                                                    this.props.editStudent(postEditValues,this.paramsValue).then((res:any)=>{
                                                        if(this.props.errorMessage && this.props.errorMessage !== null && this.props.errorMessage !== undefined){
                                                            this.formik.setFieldError("phone_number", this.props.errorMessage.phone_number);
                                                        }
                                                        
                                                    })
                                                }}
                                                render={({
                                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                                }: FormikProps<StudentType>) =>{ 
                                                    const isEditStudentEmpty = (!values.student_name 
                                                        || !values.grade 
                                                        || !values.standard 
                                                        || !values.phone_number 
                                                        || !values.email_id 
                                                        || !values.parent_firstname);

                                                    const isSectionChanged = values.standard !== initialEditValues.standard;
                                                return(
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                            <div className="row">
                                                                <div className="col-md-6 p-t-20">
                                                                <div className="col-md-12 p-t-20 pl-0">
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Student Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                    type="text"
                                                                                    name="student_name"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={!diableEditField}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 p-t-20 pl-0">
                                                                    <FormControl component="fieldset" error={touched.gender && Boolean(errors.gender)} disabled={!diableEditField}>
                                                                        <FormLabel component="legend">Gender <span className='formmandatorycolor'> *</span></FormLabel>
                                                                        <Field component={FormikRadioGroup} name="gender">
                                                                        <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                                        <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                                        <FormControlLabel value="O" control={<Radio />} label="Other" />
                                                                        </Field>
                                                                        <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                                    </FormControl>
                                                                    </div>
                                                                    <div className='col-md-12 p-t-20 pl-0'>                                                                                                          
                                                                        <FormGroup>
                                                                            <legend className="MuiFormLabel-root">
                                                                                <span>Date of Birth</span><span className='formmandatorycolor'> *</span>
                                                                            </legend>
                                                                            <Field
                                                                                type="date"
                                                                                name="date_of_birth"
                                                                                component={TextField}                                                            
                                                                                disabled={false}
                                                                                InputProps={{
                                                                                    inputProps: {
                                                                                        max: maxDate, // Disable future dates
                                                                                    },
                                                                                }}
                                                                            />                                                        
                                                                        </FormGroup>                                               
                                                                    </div>
                                                                    <div className="col-md-12 p-t-20 pl-0">
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                        <FormGroup>
                                                                        <Field 
                                                                        label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>}
                                                                        name="grade"  
                                                                        select
                                                                        component={TextField}        
                                                                        disabled={true} 
                                                                        onChange={(e:any) => {
                                                                            setFieldValue('grade', e.target.value)
                                                                            this.selectEditClassname(e);
                                                                          }}   
                                                                        >
                                                                            { this.props.ListClass.map((item:any) =>(
                                                                                        <MenuItem value={item.grade_id}>{item.value}</MenuItem>
                                                                                ))
                                                                            }
                                                                        </Field> 
                                                                        </FormGroup> 
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 p-t-20 pl-0">
                                                                        <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                                        <FormGroup>
                                                                        <Field 
                                                                        label={<><span>Section</span><span className='formmandatorycolor'> *</span></>}
                                                                        name="standard"  
                                                                        select
                                                                        component={TextField}        
                                                                        disabled={diableEditField} 
                                                                        fullwidth 
                                                                        onChange={(e:any) => {
                                                                            setFieldValue('standard', e.target.value)
                                                                            this.selectStandard(e);
                                                                          }} 
                                                                        >
                                                                            { this.props.ListSection.map((item:any) =>(
                                                                                        <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                                ))
                                                                            }
                                                                        </Field> 
                                                                        </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 p-t-20">
                                                                    <ImageUpload postImage={getUpdateImage} disable={diableEditField ? '': true} setFieldValue={setFieldValue} />                                           
                                                                </div>                                                
                                                                </div>
                                                        <div className="row">
                                                            <div className="col-md-6 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                    <FormGroup>
                                                                        <Field
                                                                        label={<><span>Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                        type="text"
                                                                        name="phone_number"
                                                                        component={TextField}
                                                                        className="textfield__input"
                                                                        disabled={!diableEditField} 
                                                                        onChange={(e:any) => {
                                                                            setFieldValue('phone_number', e.target.value)
                                                                            this.findParantDetail(e);
                                                                            setFieldValue('sibling_details', []);
                                                                            this.setState({ siblings_data: [] }); 
                                                                        }}                                                           
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                                    <FormGroup>
                                                                        <Field
                                                                            label={<><span>Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                            type="email"
                                                                            name="email_id"
                                                                            component={TextField}
                                                                            className="textfield__input"
                                                                            disabled={!diableEditField}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                    <FormGroup>
                                                                        <Field
                                                                            label={<><span>Parent First Name</span><span className='formmandatorycolor'> *</span></>}
                                                                            type="text"
                                                                            name="parent_firstname"
                                                                            component={TextField}
                                                                            className="textfield__input"
                                                                            disabled={!diableEditField}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 p-t-20">
                                                                <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                                    <FormGroup>
                                                                        <Field
                                                                            label='Parent Last Name'
                                                                            type="text"
                                                                            name="parent_lastname"
                                                                            component={TextField}
                                                                            className="textfield__input"
                                                                            disabled={!diableEditField}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Emis Id</span></>}
                                                                type="text"
                                                                name="emis_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={!!emis_id}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Admission Id</span></>}
                                                                type="text"
                                                                name="admission_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={!!admission_no}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Roll Number</span></>}
                                                                type="text"
                                                                name="roll_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={!!roll_no}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Nationality</span></>}
                                                                    select
                                                                    name="nationality"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                >
                                                                    {nationality && nationality.length > 0 && nationality.map((item:any) => (
                                                                        <MenuItem key={item.id} value={item.id}>
                                                                            {item.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Medical Conditions</span></>}
                                                                type="text"
                                                                name="medical_conditions"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Blood Group</span></>}
                                                                select
                                                                name="blood_group"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {getBloodGroup && getBloodGroup.length > 0 && getBloodGroup.map((item: any) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.value}
                                                                </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>House</span></>}
                                                                select
                                                                name="house"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {getHouse && getHouse.length > 0 && getHouse.map((item: any) => (
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        {item.house_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Mode of Transport</span></>}
                                                                select
                                                                name="mode_of_transport"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {Transport && Transport.length > 0 && Transport.map((item: any) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.value}
                                                                </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Vehicle Register Number</span></>}
                                                                    type="text"
                                                                    name="vehicle_reg_no"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Driver Name</span></>}
                                                                    type="text"
                                                                    name="driver_name"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <Field
                                                                    label={<><span>Driver Contact Number</span></>}
                                                                    type="text"
                                                                    name="driver_contact_no"
                                                                    component={TextField}
                                                                    className="textfield__input"
                                                                    disabled={false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>
                                                                <ChipInput
                                                                    label={<><span>Hobbies</span></>}
                                                                    value={values.hobbies}
                                                                    onAdd={(hobby) => setFieldValue("hobbies", [...values.hobbies, hobby])}
                                                                        onDelete={(hobby, index) =>
                                                                            setFieldValue(
                                                                            "hobbies",
                                                                            values.hobbies.filter((tag:any, i:any) => i !== index)
                                                                            )
                                                                        }                                                                   
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 p-t-0'>
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                            <FormGroup>                                                       
                                                                <ChipInput
                                                                    label={<><span>Identification Marks</span></>}
                                                                    value={values.identification_marks}
                                                                    onAdd={(marks) => setFieldValue("identification_marks", [...values.identification_marks, marks])}
                                                                        onDelete={(marks, index) =>
                                                                            setFieldValue(
                                                                            "identification_marks",
                                                                            values.identification_marks.filter((tag:any, i:any) => i !== index)
                                                                            )
                                                                        }
                                                                    // placeholder='Identification Marks'                                                                    
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>                                                    
                                                    <div className='col-md-6 p-t-0'>
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0 pb-0">
                                                        <FormGroup>
                                                        <Autocomplete
                                                            fullWidth
                                                            multiple
                                                            id="checkboxes-tags-demo"
                                                            options={ this.state.siblings_data || []}
                                                            disableCloseOnSelect
                                                            noOptionsText={'No matching records found.'}
                                                            getOptionLabel={(option: any) => option.student_name}
                                                            value={values.sibling_details || []}
                                                            onChange={(e, value, resonse) => {
                                                            setFieldValue('sibling_details', value);
                                                            console.log(value)
                                                            }}
                                                            renderOption={(option, { selected }) => {
                                                            return (
                                                                <React.Fragment>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.student_name}
                                                                </React.Fragment>
                                                            )
                                                            }}
                                                            className="mb-0 mt-1"
                                                            renderInput={(params) => (
                                                            <Field
                                                                component={TextField}
                                                                name="sibling_details"
                                                                error={!!errors.search_sibling_name}
                                                                helperText={errors.search_sibling_name}
                                                                {...params} label={<><span>Siblings Details</span></>}
                                                            />
                                                            )}
                                                        />
                                                            <div className="text-right mb-2 mr-1 mt-2">                                               
                                                                <Button className="btn btn-pink mr-1 ml-1" type="button" onClick={() => this.getSiblingsDropdown(values)}>Search</Button>                                                                                              
                                                            </div> 
                                                        </FormGroup>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Father Name</span></>}
                                                                type="text"
                                                                name="father_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Contact Number</span></>}
                                                                type="text"
                                                                name="father_contact_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Email</span></>}
                                                                type="email"
                                                                name="father_email_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />        
                                                            <Field
                                                                label={<><span>Occupation</span></>}
                                                                type="text"
                                                                name="father_occupation"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                            
                                                            <Field
                                                                label={<><span>Annual Income</span></>}
                                                                type="text"
                                                                name="father_annual_income"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                                                                              
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Mother Name</span></>}
                                                                type="text"
                                                                name="mother_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Contact Number</span></>}
                                                                type="text"
                                                                name="mother_contact_no"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                            <Field
                                                                label={<><span>Email</span></>}
                                                                type="email"
                                                                name="mother_email_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />        
                                                            <Field
                                                                label={<><span>Occupation</span></>}
                                                                type="text"
                                                                name="mother_occupation"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                            
                                                            <Field
                                                                label={<><span>Annual Income</span></>}
                                                                type="text"
                                                                name="mother_annual_income"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />                                                                                                              
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className='row'>
                                                <div className='col-md-6 p-t-0'>
                                                    <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                        <FormGroup>
                                                            <Field
                                                            label={<><span>Residential Address</span></>}
                                                            name='residential_address'
                                                            component={TextField}
                                                            disabled={false}
                                                            /> 
                                                            <Field
                                                            label={<><span>Country</span></>}
                                                            name="country"
                                                            select 
                                                            component={TextField}
                                                            disabled={false}
                                                            onChange={(e:any) => {
                                                                this.handleCountryChange(e);
                                                                setFieldValue('country', e.target.value);
                                                                setFieldValue('state', '');
                                                                setFieldValue('city', '');
                                                            }}
                                                            >
                                                            {country && country.length > 0 && country.map((item:any) => (
                                                                <MenuItem key={item.country} value={item.country}>
                                                                    {item.country}
                                                                </MenuItem>
                                                            ))}
                                                            </Field>  
                                                            <Field
                                                            label={<><span>State</span></>}
                                                            name='state'
                                                            select
                                                            component={TextField}
                                                            disabled={false}
                                                            onChange={(e:any) => {
                                                                this.handleStateChange(e);
                                                                setFieldValue('state', e.target.value);
                                                                setFieldValue('city', '');
                                                            }}
                                                            >
                                                            {state && state.length > 0 && state.map((item: any) => (
                                                                <MenuItem key={item.state} value={item.state}>
                                                                    {item.state}
                                                                </MenuItem>
                                                            ))}
                                                            </Field>        
                                                            <Field
                                                            label={<><span>City</span></>}
                                                            name='city'
                                                            select
                                                            component={TextField}
                                                            disabled={false}
                                                            >
                                                            {city && city.length > 0 && city.map((item: any) => (
                                                                <MenuItem key={item.uid} value={item.uid}>
                                                                    {item.city}
                                                                </MenuItem>
                                                            ))}
                                                            </Field>                                                             
                                                            <Field
                                                            label={<><span>Pincode</span></>}
                                                            name='pincode'
                                                            component={TextField}
                                                            disabled={false}
                                                            />                                                                                                               
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                                        <div className="text-right mb-3 mr-2 mt-4">
                                                            <Button className="btn btn-pink mr-1 ml-1" 
                                                            type="submit" disabled={isEditStudentEmpty 
                                                                || isValidating  || !!(errors.student_name && touched.student_name) || !!(errors.grade && touched.grade)||
                                                                !!(errors.standard && touched.standard) || 
                                                                !!(errors.phone_number && touched.phone_number) || 
                                                                !!(errors.email_id && touched.email_id) || 
                                                                !!(errors.parent_firstname && touched.parent_firstname) ||
                                                                !isSectionChanged && !diableEditField}>Submit</Button>
                                                            <Link to="/student"><Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                        </div>
                    
                                                    </form>
                                                )}
                                            }
                                            />
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = ({ student, classes, profile, teacher}: RootState, ownProps: RouteComponentProps<OwnPropsParams>) => {
    return {
        getStudentIt: student.items[Number(ownProps.match.params.id)],
        ListClass:classes.my_class,
        getChangeYearData:profile.getYear,
        stopYearDate:profile.yearStatusFails,
        ListSection:classes.my_section,
        loading: student.loading,
        errorMessage: student.errors,
        getParentResponse: student.parentDetails,
        getStudentData: student.getStudentEditResponse,
        getImageURL:student.ImageURL,
        getProfile:profile.profileData,
        nationality: teacher.GetNationality,
        getBloodGroup: teacher.GetBloodGroup,
        getHouse: student.getHouseData,
        Transport: student.getTransportData,
        country: teacher.GetCountryData,
        state: teacher.GetStateData,
        city: teacher.GetCityData,
        siblingsData: student.getSiblingsData
    };
};

export default connect(mapStateToProps, { fetchGradePost, 
    fetchGradeStandardPost, 
    editStudent, 
    fetchStudent, 
    fetchMyClassListGet, 
    fetchMySectionListGet, 
    fetchParent, 
    getStudentClassesData, 
    stopYearData,
    GetBloodGroup,
    GetNationality,
    getHousedropdown,
    getSiblingsEmisDropdown,
    GetModeofTransport,
    GetCountryDropdown,
    GetStateDropdown,
    GetCityDropdown
})(EditStudent)
