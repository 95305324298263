export interface ISearchBarState {
    show: boolean,
    showDelete: boolean,
    addShow: boolean,
    acadamicAdmin: boolean,
    schoolAdmin: boolean,
    bothData: boolean,
    teacher: boolean,
    parent: boolean,
    deleteSchoolId: any,
    query: string,
    hasMore: boolean,
    schoolData: any,
    prev: number,
    next: number,
    ascOrder: boolean,
    descOrder: boolean,
    subjectsTotal: number,
    page: number,
    per_page: any,
    search: string,
    OrderNameData: string,
    SortOrderData: string,
    postData: any | undefined,
    sort_by?: string,
    sort_class_name?: string,
    sort_title_name?: string,
    academicYear?: any
}

export const containerStyle = {
    width: '100%',
    height: '400px'
};

export const center = {
    lat: 13.067439,
    lng: 80.237617
};

export const position = {
    lat: 13.067439,
    lng: 80.237617
}

export interface stateTypes {
    selectData: any,
    position: any,
    getStatTime: any,
    getEndTime: any,
    contactPersonsName: any,
    contactPersonsDesignation: any,
    contactPersonsPhoneNumber: any,
    contactPersonsemailId: any,
    latitude: any,
    longitude: any,
    schoolCoordinates: any,
    contactPersons: any,
    school_name: any,
    school_id: any,
    school_address: any,
    school_category: any,
    acadamic_start_month: any,
    acadamic_end_month: any,
    school_coverage_area: number;
    show: boolean,
    showDelete: boolean,
    addShow: boolean,
    acadamicAdmin: boolean,
    schoolAdmin: boolean,
    bothData: boolean,
    teacher: boolean,
    parent: boolean,
    deleteSchoolId: any,
    latitudeedit: string,
    longitudeedit: string,
    getUrlService: string,
    mapStatus: boolean,
    isLoaded: boolean;
    map: any;
    detectCurrentLocation:boolean;
    schoolImage:any;
    image: string;
    showImageView: boolean;
}