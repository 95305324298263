import { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonLoader from "../../components/CommonLoader";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { connect } from "react-redux";
import { QuestionBank } from "../../router/Roles";
import { SubjectMappingView, deleteSubjectMapping } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { CommonModel } from "../../components/CommonModel";
import { RowPerPage, RowsPerPageOptions, formValidationPatten } from "../../services/Constants";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export type OwnExamTypeProps = {
    loading: boolean
    records: any
    page: number
    per_page: number
    total: number
    totalPage: number
    SubjectMappingView: (params: any) => any
    deleteSubjectMapping: (id: number) => any
}

interface StateTypes {
    records?: any,
    search: string,
    hasMore: boolean,
    page: any,
    page_no: any,
    SortOrderData: string,
    OrderNameData: string,
    showDelete: boolean,
    deleteId: any,
    ascOrder?: boolean,
    academicYear?: any,
    sort_by?: string,
    sort_class_name?: string,
    sort_title_name?: string,
    per_page?: any
}

export class SubjectMappingGrid extends Component <OwnExamTypeProps, StateTypes> {
    constructor(props: any ){
        super(props)
        this.state={
            records : [],
            search: '',
            hasMore: true,
            page: 1,
            page_no: 1,
            SortOrderData: '',
            OrderNameData: '',
            showDelete: false,
            deleteId:'',
            ascOrder: true,
            academicYear: null,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order',
            per_page: RowPerPage
        };
    }
    
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getSubjectMapping()
    }
    
    getSubjectMapping() {
        const params = {
            page_no: 1,
            per_page: this.state.per_page,
            search: this.state.search,
            order_by: this.state.OrderNameData,
            sort_by: this.state.SortOrderData,
        }
        this.props.SubjectMappingView(params).then((res: any)=>{
            const {records} = this.props
            this.setState({ hasMore: true, page:1 })
        })
    }

    showFilterData(getValues: any) {
        const { academicYear, page } = this.state;
        if (getValues.sort_by === 'desc') {
          this.setState({
            ascOrder: false,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by
          })
          const postValue = {
            page_no: 1,
            per_page: this.state.per_page,
            academic_year: academicYear,
            search: this.state.search,
            sort_by: getValues.sort_by,
            order_by: getValues.order_by
          }
          this.setState({
            hasMore: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
            page: 1,
            sort_by: 'asc',
            sort_class_name: 'fa fa-sort-amount-desc',
            sort_title_name: 'Descending order'
          })
          this.props.SubjectMappingView(postValue).then((res: any) => {
            
          });
        } else {
          this.setState({
            ascOrder: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
          })
          const postValue = {
            page_no: 1,
            per_page: this.state.per_page,
            academic_year: academicYear,
            search: this.state.search,
            sort_by: getValues.sort_by,
            order_by: getValues.order_by
          }
          this.setState({
            hasMore: true,
            SortOrderData: getValues.sort_by,
            OrderNameData: getValues.order_by,
            page: 1,
            sort_by: 'desc',
            sort_class_name: 'fa fa-sort-amount-asc',
            sort_title_name: 'Ascending order'
          })
          this.props.SubjectMappingView(postValue).then((res: any) => {
            
          });
        }
      }

    public fetchMoreMappedData = (newPage?:any,numberOfRows?:any) => {
        const { page } = this.state;
        if (this.state.hasMore === true) {
            if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
                const params = {
                    page_no: newPage,
                    per_page: numberOfRows,
                    search: this.state.search,
                    order_by: this.state.OrderNameData,
                    sort_by: this.state.SortOrderData,
                }
                this.props.SubjectMappingView(params).then((res: any)=>{
                    // this.setState({ page: page + 1 })
                })
            }  
            if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
                this.setState({
                  hasMore: false,
                })
            }
        }
    }

    getMuiTheme = () => createTheme({
      components: {
        MuiPopover: {
          styleOverrides: {
            paper: {
              padding: "12px"
            }
          }
        }      
      }
    })

    private renderExamType(records: any) {
        const { loading,total } = this.props;
        const { per_page } = this.state;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const columns = [
            {
              name: "internal_grade_name",
              label: "Internal Grade Name",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "internal_subject_name",
              label: "Internal Subject Name",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "wizdomwaves_subject_name",
              label: "Wizdomedge Subject",
              options: {
                customBodyRender: (value:any) => value || '-',
              },
            },
            {
              name: "uid",
              label: "View",
              options: {
                sort: false,
                download: false,
                customBodyRender: (uid:any) => (
                  <>
                    {uid ? (
                      <Link to={`/subjectmapping/edit/${uid}`}>
                        <button className="btn btn-primary btn-xs" title="View">
                          <i className="fa fa-eye" aria-hidden="true" />
                        </button>
                      </Link>
                    ) : (
                      <button className="btn btn-primary btn-xs" title="View" disabled>
                        <i className="fa fa-eye" aria-hidden="true" />
                      </button>
                    )}
                  </>
                ),
              },
            },
            {
                name:"uid",
                label:"Delete",
                options: {
                  sort: false,
                  download: false,
                    customBodyRender: (tableMeta:any,rowData:any,rowIndex:any) => {
                      const items = records[rowData.rowIndex];
                      return items.uid && items.blueprint_is_active == false ? (
                                <button className="btn btn-primary btn-xs" title="Delete" onClick={() => this.showDeletePopup(items.uid)}>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                ) : (
                                <button className="btn btn-disable btn-xs" title="Delete" disabled>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            )}                      
                }
            }
          ];

          const options = {
            rowsPerPage: per_page,
            rowsPerPageOptions: RowsPerPageOptions,
            count: total,
            selectableRows: false,
            page:this.state.page - 1,
            filter:false,
            print:false,
            downloadOptions:{
              filename: "SubjectsMappingList.csv"
            },
            onChangePage: (newPage:any) => {
                this.setState({ page: newPage +1}); 
                this.fetchMoreMappedData(newPage + 1,this.state.per_page);
            },
            onColumnSortChange:(changedColumn:any, direction:any) =>{
                const sortData = {
                    sort_by: changedColumn,
                    order_by: direction
                }
                this.showFilterData(sortData);
            },
            onChangeRowsPerPage: (numberOfRows:any) => {
              const page = 1
              this.setState({ per_page: numberOfRows,page:page });
              this.fetchMoreMappedData(page,numberOfRows);
            },
            onSearchChange: (searchText:any, row:any) => {
                this.handleInputChange({ target: { value: searchText } });
            }
        };
        return(
          <ThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
            data={records}
            columns={columns}
            options={options}
            />
          </ThemeProvider>
        )
    }

    showDeletePopup (getData: any) {
        if(getData){
            this.setState({ showDelete: true, deleteId: getData});
        }
    }

    public hideQuestionSetDelete = () => {
        this.setState({ showDelete: false });
    }

    public handleDelete = () => {
        this.setState({ showDelete: false, page: 1, hasMore: true });
        this.props.deleteSubjectMapping(this.state.deleteId).then((res:any)=>{
            this.getSubjectMapping()
        })
    }

    handleInputChange = (e: any) => {
        const { value } = e.target;
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
           if (getSearchValue) {
          const postSearchValue:any = {
            page_no: 1,
            per_page: this.state.per_page,
            search: getSearchValue,
          }
          this.props.SubjectMappingView(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: getSearchValue
          })
        } else {
          const postSearchValue:any = {
            page_no: 1,
            per_page: this.state.per_page,
            search: '',
          }      
          this.props.SubjectMappingView(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: ''
          })
        }}
    }

    refreshSubjectMappingTable = () =>{
        const postSearchValue:any = {
            page_no: 1,
            search: '',
        }
        this.setState({
          search: '',
          SortOrderData:'',
          OrderNameData:'',
        }, () => {
            this.props.SubjectMappingView(postSearchValue)
        });  
    }

    render(){
        const { showDelete } = this.state;
        const { records, loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const postValue:any = {
            title:'Please Confirm',
            action_status:'delete',
            action_name:'Subject Mapping'
        }
        return(
            <div>
                <CommonModel
                    onState={showDelete}
                    currentState={postValue}
                    onConfirm={this.handleDelete}
                    onCancel={this.hideQuestionSetDelete}
                />
                <div className="page-wrapper" >
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Subjects Mapping']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Subjects Mapping']}
                            />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-topline-red">
                                        <div className="card-head">
                                            <header>
                                                <Link to={QuestionBank.AddSubjectMapping} className="btn btn-pink ml-2 mt-1">
                                                    Create Subject Mapping
                                                </Link>
                                            </header>
                                        </div>
                                        <div className="card-body no-padding height-9">
                                            <div className="row">
                                                <div className="table-responsive">
                                                    {this.renderExamType(records)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={loadingQuestionFull}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return{
        loading: state.QuestionBank.loading,
        records: state.QuestionBank.records,
        page: state.QuestionBank.page,
        per_page: state.QuestionBank.per_page,
        total: state.QuestionBank.total,
        totalPage: state.QuestionBank.totalPage
    }
}

const mapDispatchToProps = {
    SubjectMappingView,
    deleteSubjectMapping
  }

export default connect( mapStateToProps, mapDispatchToProps )(SubjectMappingGrid)