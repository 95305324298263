import * as yup from 'yup';
import { studentValida, formValidationSize, formValidationPatten } from '../../services/Constants';

export const StudentValidation = yup.object({
    student_name: yup.string()
        .required(studentValida.student_name)
        .min(formValidationSize.nameMinSize, studentValida.minstudentname)
        .max(formValidationSize.nameMaxSize, studentValida.maxstudentname)
        .matches(formValidationPatten.namePatten, studentValida.invalidstudentname),
    phone_number: yup.string()
        .required(studentValida.phone_number)
        .min(formValidationSize.mobileNoSize, studentValida.minphoneinvalid)
        .max(formValidationSize.mobileNoSize, studentValida.maxphoneinvalid)
        .matches(formValidationPatten.numberPatten, studentValida.invalidphone),
    email_id: yup.string()
        .required(studentValida.email_id)
        .matches(formValidationPatten.emailPatten, studentValida.invalidemailid),
    grade: yup.string()
        .required(studentValida.grade),
    standard: yup.string()
        .required(studentValida.standard),
    parent_firstname: yup.string()
        .required(studentValida.parentFirstName)
        .min(formValidationSize.nameMinSize, studentValida.minparentFirstName)
        .max(formValidationSize.nameMaxSize, studentValida.maxparentFirstName)
        .matches(formValidationPatten.namePatten, studentValida.invalidparentFirstName),
    gender: yup.string().required('Gender is required'),
    date_of_birth: yup.string().nullable().required('Date of Birth is required'),
    emis_id: yup.string()
        .matches(formValidationPatten.alphanumericTest,  "Invalid EMIS ID (only alphanumeric characters are allowed)")
        .min(5, "EMIS ID must be at least 5 characters long")
        .max(50, "EMIS ID cannot exceed 50 characters"),
    admission_no: yup.string()
        .matches(formValidationPatten.alphanumericTest, "Invalid Admission No (only alphanumeric characters are allowed)")
        .min(5, "Admission No must be at least 5 characters long")
        .max(50, "Admission No cannot exceed 50 characters")
        .nullable(),
    roll_no: yup.string()
        .matches(formValidationPatten.alphanumericTest, "Invalid Roll No (only alphanumeric characters are allowed)")
        .min(5, "Roll No must be at least 5 characters long")
        .max(50, "Roll No cannot exceed 50 characters")
        .nullable(),
    medical_conditions: yup.string()
        .matches(formValidationPatten.namePatten,"Invalid Medical Conditions")
        .min(5, "Medical Conditions must be at least 5 characters long")
        .max(200, "Medical Conditions cannot exceed 200 characters")
        .nullable(),
    house: yup.string().nullable(),
    nationality: yup.string().nullable(),
    blood_group: yup.string().nullable(),
    mode_of_transport:yup.string().nullable(),
    vehicle_reg_no:yup.string()
      .matches(formValidationPatten.vehicleRegNo, "Invalid Vehicle Registration No (only alphanumeric characters are allowed)")
      .nullable(),
    driver_name:yup.string().nullable(),
    driver_contact_no:yup
      .string()
      .matches(formValidationPatten.numberPatten, 'Enter a valid Phone Number')
      .nullable(),
    residential_address: yup.string()
          .matches(formValidationPatten.addressRegex, "Invalid Residential Address. Use valid characters.")
          .min(5, "Address must be at least 5 characters long.")
          .max(100, "Address must not exceed 100 characters.")
          .nullable(),
          country: yup.string().notRequired(),
    city: yup.string().when('country', {
      is: (val) => {
        return val;
      },
      then:yup.string()
      .required('city is required'),
    }),
    state: yup.string().when('country', {
      is: (val) => {
        return val;
      },
      then:yup.string()
      .required('state is required'),
    }),
    pincode:yup.string().nullable(),
    
    father_name:yup.string().nullable(),
    father_contact_no:yup
      .string()
      .matches(formValidationPatten.numberPatten, 'Enter a valid Phone Number')
      .nullable(),
    father_email:yup
      .string()
      .matches(formValidationPatten.emailPatten, 'Please enter valid Email Id')
      .nullable(),
    father_occupation:yup.string().nullable(),
    father_annual_income:yup.number().nullable(),
    mother_name:yup.string().nullable(),
    mother_contact_no:yup
      .string()
      .matches(formValidationPatten.numberPatten, 'Enter a valid Phone Number')
      .nullable(),
    mother_email:yup
      .string()
      .matches(formValidationPatten.emailPatten, 'Please enter valid Email Id')
      .nullable(),
    mother_occupation:yup.string().nullable(),
    mother_annual_income:yup.number().nullable(),
})