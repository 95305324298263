import React from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb';
import { AddTeacherPost, ResetTeacherImage, GetNationality, GetMaritalStatus, GetDegreeDropdown, GetBloodGroup, GetCityDropdown, GetCountryDropdown, GetStateDropdown } from '../../store/teacher/Actions';
import { teacherDetails } from '../../store/teacher/Type';
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, FormLabel, FormControlLabel, Radio, FormHelperText, MenuItem, Checkbox } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { TextField, RadioGroup as FormikRadioGroup } from 'formik-material-ui';
import { TeacherType } from '../../store/teacher/Type';
import { TeacherValidation } from './TeacherValidation';
import TeacherImageUpload from './teacherImageUpload';
import { maxDate } from '../../services/Constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const initialTeacherValues: teacherDetails = {
    teacherfirstname: '',
    teacherlastname: '',
    teacheremailid: '',
    teacherphonenumber: '',
    teacherrole: 'Teacher',
    image: '',
    timezone: 'Asia/Kolkata',
    date_of_birth: '',
    gender: '',
    qualification:[],
    residential_state:0,
    residential_country:0,
    residential_city:0,
    residential_pincode:'',
    permanent_address: '',
    permanent_country:0,
    permanent_state:0,
    permanent_city:0,
    permanent_pincode:'',
    emergency_address: '',
    emergency_state:0,
    emergency_country:0,
    emergency_city:0,
    emergency_pincode:'',
}
export type OwnTeacherFormProps = {
    AddTeacherPost: (userManage: any) => void;
    ResetTeacherImage: () => any;
    GetNationality: () => any;
    GetMaritalStatus: () => any;
    GetDegreeDropdown: () => any;
    GetBloodGroup: () => any;
    GetCityDropdown: (data: any) => any;
    GetCountryDropdown: (data: any) => any;
    GetStateDropdown: (data: any) => any;
    loading: boolean;
    getTeacherImageURL?: any;
    getErrorMsg: any;
    nationality?: any;
    maritalStatus?: any;
    getQualification?: any;
    getBloodGroup?: any;
    getCity?: any;
    getCountry?: any;
    getState?: any;
};
interface propsTeacherTypes {
    teacherImage?: any,
    imageFail?: any,
    country?: any,
    selectedCountry?: any,
    selectedState?: any,
    residentialState?: any,
    permanentState?: any,
    emergencyState?: any,
    residentialCity: any,
    permanentCity: any,
    emergencyCity: any
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class AddTeacher extends React.Component<OwnTeacherFormProps, propsTeacherTypes> {
    formikTeacher: any;
    imageFail: any;
    constructor(props: any) {
        super(props);
        this.state = {
            teacherImage: '',
            imageFail: '',
            country: '',
            selectedCountry: '',
            selectedState: '',
            residentialState: [],
            permanentState: [],
            emergencyState: [],
            residentialCity: [],
            permanentCity: [],
            emergencyCity: []
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.props.ResetTeacherImage();
        this.getNationalityDropdown();
        this.getCountryDropdown();
    }

    componentDidUpdate() {
        const { getErrorMsg } = this.props;
        let getErrorMessage: any = this.formikTeacher;
        if (getErrorMessage) {
            if (getErrorMsg) {
                if (getErrorMsg.firstname
                    || getErrorMsg.lastname
                    || getErrorMsg.email_id
                    || getErrorMsg.phone_number || getErrorMsg.image) {
                    getErrorMessage.setFieldError('teacherfirstname', this.props.getErrorMsg.firstname)
                    getErrorMessage.setFieldError('teacherlastname', this.props.getErrorMsg.lastname)
                    getErrorMessage.setFieldError('teacheremailid', this.props.getErrorMsg.email_id)
                    getErrorMessage.setFieldError('teacherphonenumber', this.props.getErrorMsg.phone_number)
                    this.imageFail = this.props.getErrorMsg.image;
                }
            }

        }

    }
    getNationalityDropdown = () => {
        this.props.GetNationality().then((res: any) => {
            const { nationality } = this.props;
        });
        this.props.GetBloodGroup().then((res: any) => {
            const { getBloodGroup } = this.props;
        });
        this.props.GetDegreeDropdown().then((res: any) => {
            const { getQualification } = this.props;
        });
        this.props.GetMaritalStatus().then((res: any) => {
            const { maritalStatus } = this.props;
        });
    }
    getCountryDropdown = () => {
        const getData = {
            category: 'country'
        }
        this.props.GetCountryDropdown(getData).then((res: any) => {
            const { getCountry } = this.props;
        });
    }

    getStateDropdown = (country: string, type: string) => {
        const data = {
            category: 'state',
            country: country
        }
        this.props.GetStateDropdown(data).then((res: any) => {
            const { getState } = this.props;
            if (type === 'residential') {
                this.setState({residentialState: getState})
            }
            else if (type === 'permanent') {
                this.setState({permanentState: getState})
            }
            else {
                this.setState({emergencyState: getState})
            }
        });
    }

    getCityDropdown = (selectState: string, type:any, values:any) => {
        let country = ''
        if (type === 'residential') {
            country = values.residential_country
        }else if (type === 'permanent') {
            country = values.permanent_country
        }
        else if (type === 'emergency'){
            country = values.emergency_country
        }

        const Data = {
            category: 'city',
            country: country,
            state: selectState
        }
        this.props.GetCityDropdown(Data).then((res: any) => {
            const { getCity } = this.props;
            if (type === 'residential') {
                this.setState({residentialCity: getCity})
            }
            else if (type === 'permanent') {
                this.setState({permanentCity: getCity})
            }
            else if (type === 'emergency'){
                this.setState({emergencyCity: getCity})
            }
        })
    }

    handleCountryChange = (e: any, type: any) => {
        const selectedCountry = e.target.value;
        this.setState({ selectedCountry });
        this.getStateDropdown(selectedCountry, type)
    };

    handleStateChange = (e: any, type: any, values:any) => {
        const selectedState = e.target.value;
        this.setState({ selectedState });
        this.getCityDropdown(selectedState, type, values);
    };

    render() {
        const { loading, getTeacherImageURL, nationality, maritalStatus, getBloodGroup,
            getQualification, getCity, getCountry, getState } = this.props;
            const { residentialState, permanentState, emergencyState, residentialCity, permanentCity, emergencyCity } = this.state
        const loadingTeacherAdd = { display: loading ? "block" : "none" };
        let getImage: any;
        if (getTeacherImageURL) {
            const getUrlpath = getTeacherImageURL.url;
            if (getUrlpath) {
                getUrlpath.forEach((items: any) => {
                    getImage = items;
                })
            } else {

            }
        }
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Teacher']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['Teacher']}
                                baseURL={['teacher']}
                                mainPageTitle={['Add Teacher']} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header>Add Teacher</header>
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                ref={node => this.formikTeacher = node}
                                                initialValues={initialTeacherValues}
                                                validationSchema={TeacherValidation}
                                                onSubmit={(values: any, actions) => {
                                                    const qualificationIds = values.qualification.map((qualification:any) => qualification.id);
                                                    const initialTeacherValue: TeacherType = {
                                                        firstname: values.teacherfirstname,
                                                        lastname: values.teacherlastname,
                                                        email_id: values.teacheremailid,
                                                        phone_number: values.teacherphonenumber,
                                                        role: 'Teacher',
                                                        id: values.id,
                                                        ldap_id: values.ldap_id,
                                                        image: values.image,
                                                        timezone: 'Asia/Kolkata',
                                                        date_of_birth: values.date_of_birth,
                                                        gender: values.gender,
                                                        staffcode: values.staff_code,
                                                        joining_date: values.joining_date,
                                                        nationality: values.nationality,
                                                        blood_group: values.blood_group,
                                                        marital_status: values.marital_status,
                                                        aadhar_card_id: values.aadharcard_id,
                                                        pan_card_id: values.pancard_id,
                                                        emergency_contact_name: values.emergency_contact_name,
                                                        emergency_contact_relation: values.emergency_contact_relation,
                                                        emergency_contact_phone: values.emergency_contact_number,
                                                        emergency_contact_email: values.emergency_contact_mail,
                                                        qualification: qualificationIds,
                                                        residential_address: {
                                                            address: values.residential_address,
                                                            city: values.residential_city,
                                                            state: values.residential_city,
                                                            pincode: values.residential_pincode,
                                                            country: values.residential_city,
                                                        },
                                                        permanent_address: {
                                                            address: values.permanent_address,
                                                            city: values.permanent_city,
                                                            state: values.permanent_city,
                                                            pincode: values.permanent_pincode,
                                                            country: values.permanent_city,
                                                        },
                                                        emergency_contact_address: {
                                                            address: values.emergency_address,
                                                            city: values.emergency_city,
                                                            state: values.emergency_city,
                                                            pincode: values.emergency_pincode,
                                                            country: values.emergency_city,
                                                        }
                                                    }
                                                    this.props.AddTeacherPost(initialTeacherValue)
                                                }}
                                                render={({
                                                    values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                                }: FormikProps<any>) => {

                                                    const isEmpty = (!values.teacherfirstname || !values.teacheremailid || !values.teacherphonenumber);
                                                    return (
                                                        <form onSubmit={handleSubmit}>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-md-6 p-t-20">
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>First Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                    type="text"
                                                                                    name="teacherfirstname"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div>
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Last Name</span></>}
                                                                                        type="text"
                                                                                        name="teacherlastname"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 p-t-20">
                                                                            <FormControl component="fieldset" error={touched.gender && Boolean(errors.gender)}>
                                                                                <FormLabel component="legend">Gender <span className='formmandatorycolor'> *</span></FormLabel>
                                                                                <Field component={FormikRadioGroup} name="gender">
                                                                                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                                                    <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                                                    <FormControlLabel value="O" control={<Radio />} label="Other" />
                                                                                </Field>
                                                                                <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                                    type="text"
                                                                                    name="teacherphonenumber"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div>
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="teacheremailid"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-12 p-t-20 pl-0'>
                                                                            <FormGroup>
                                                                                <legend className="MuiFormLabel-root">
                                                                                    <span>Date of Birth</span><span className='formmandatorycolor'> *</span>
                                                                                </legend>
                                                                                <Field
                                                                                    type="date"
                                                                                    name="date_of_birth"
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                    InputProps={{
                                                                                        inputProps: {
                                                                                            max: maxDate, // Disable future dates
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 p-t-20">
                                                                        <TeacherImageUpload postImage={getImage} setFieldValue={setFieldValue}/>
                                                                        {this.imageFail ?
                                                                            <div className="text-center">
                                                                                <span className="diaryerrorcolor">{this.imageFail}</span>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Staff Code</span></>}
                                                                                    type="text"
                                                                                    name="staff_code"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <legend className="MuiFormLabel-root">
                                                                                    <span>Joining Date</span>
                                                                                </legend>
                                                                                <Field
                                                                                    type="date"
                                                                                    name="joining_date"
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                    InputProps={{
                                                                                        inputProps: {
                                                                                            max: new Date().toISOString().split("T")[0],
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Aadhaar Card</span></>}
                                                                                    type="text"
                                                                                    name="aadharcard_id"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Pan Card</span></>}
                                                                                    type="text"
                                                                                    name="pancard_id"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emergency Contact Name</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_name"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emergency Contact Relation</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_relation"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emeregency Contact Number</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_number"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Emeregency Contact Email</span></>}
                                                                                    type="text"
                                                                                    name="emergency_contact_mail"
                                                                                    component={TextField}
                                                                                    className="textfield__input"
                                                                                    disabled={false}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Nationality</span></>}
                                                                                    name="nationality"
                                                                                    select
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                    onChange={(e: any) => {
                                                                                        setFieldValue('nationality', e.target.value)
                                                                                    }}
                                                                                >
                                                                                    {nationality && nationality.length > 0 && nationality.map((item: any) => (
                                                                                        <MenuItem key={item.id} value={item.id}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Blood Group</span></>}
                                                                                    name="blood_group"
                                                                                    select
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                >
                                                                                    {getBloodGroup && getBloodGroup.length > 0 && getBloodGroup.map((item: any) => (
                                                                                        <MenuItem key={item.id} value={item.id}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Autocomplete
                                                                                    fullWidth
                                                                                    multiple
                                                                                    id="checkboxes-tags-demo"
                                                                                    options={ getQualification|| []}
                                                                                    disableCloseOnSelect
                                                                                    noOptionsText={'No matching records found.'}
                                                                                    getOptionLabel={(option: any) => option.value}
                                                                                    value={values.qualification}
                                                                                    onChange={(e, value, resonse) => {
                                                                                    setFieldValue('qualification', value);
                                                                                    }}
                                                                                    renderOption={(option, { selected }) => {
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                        <Checkbox
                                                                                            icon={icon}
                                                                                            checkedIcon={checkedIcon}
                                                                                            style={{ marginRight: 8 }}
                                                                                            checked={selected}
                                                                                        />
                                                                                        {option.value}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                    }}
                                                                                    className="mb-0 mt-1"
                                                                                    renderInput={(params) => (
                                                                                    <Field
                                                                                        component={TextField}
                                                                                        name="search_qualification"
                                                                                        error={!!errors.search_qualification}
                                                                                        helperText={errors.search_qualification}
                                                                                        {...params} label={<><span>Qualification</span></>}
                                                                                    />
                                                                                    )}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <FormGroup>
                                                                                <Field
                                                                                    label={<><span>Marital Status</span></>}
                                                                                    name="marital_status"
                                                                                    select
                                                                                    component={TextField}
                                                                                    disabled={false}
                                                                                >
                                                                                    {maritalStatus && maritalStatus.length > 0 && maritalStatus.map((item: any) => (
                                                                                        <MenuItem key={item.id} value={item.id}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <div className='mt-3'>
                                                                                RESIDENTIAL :
                                                                                <FormGroup className='ml-3'>
                                                                                    <Field
                                                                                        label={<><span>Address</span></>}
                                                                                        name='residential_address'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <Field
                                                                                        label={<><span>Country</span></>}
                                                                                        name="residential_country"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleCountryChange(e, 'residential');
                                                                                            setFieldValue('residential_country', e.target.value)
                                                                                            setFieldValue('residential_state', '');
                                                                                            setFieldValue('residential_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {getCountry && getCountry.length > 0 && getCountry.map((item: any) => (
                                                                                            <MenuItem key={item.country} value={item.country}>
                                                                                                {item.country}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>State</span></>}
                                                                                        name='residential_state'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleStateChange(e, 'residential', values);
                                                                                            setFieldValue('residential_state', e.target.value);
                                                                                            setFieldValue('residential_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {residentialState && residentialState.length > 0 && residentialState.map((item: any) => (
                                                                                            <MenuItem key={item.state} value={item.state}>
                                                                                                {item.state}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>City</span></>}
                                                                                        name='residential_city'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            setFieldValue('residential_city', e.target.value);
                                                                                        }}
                                                                                    >
                                                                                        {residentialCity && residentialCity.length > 0 && residentialCity.map((item: any) => (
                                                                                            <MenuItem key={item.uid} value={item.uid}>
                                                                                                {item.city}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>Pincode</span></>}
                                                                                        name='residential_pincode'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <div className='mt-3'>
                                                                                PERMANENT :
                                                                                <FormGroup className='ml-3'>
                                                                                    <Field
                                                                                        label={<><span>Address</span></>}
                                                                                        name='permanent_address'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <Field
                                                                                        label={<><span>Country</span></>}
                                                                                        name="permanent_country"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleCountryChange(e, 'permanent');
                                                                                            setFieldValue('permanent_country', e.target.value)
                                                                                            setFieldValue('permanent_state', '');
                                                                                            setFieldValue('permanent_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {getCountry && getCountry.length > 0 && getCountry.map((item: any) => (
                                                                                            <MenuItem key={item.country} value={item.country}>
                                                                                                {item.country}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>State</span></>}
                                                                                        name='permanent_state'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleStateChange(e, 'permanent',  values);
                                                                                            setFieldValue('permanent_state', e.target.value);
                                                                                            setFieldValue('permanent_city', '');
                                                                                        }}
                                                                                    >
                                                                                        {permanentState && permanentState.length > 0 && permanentState.map((item: any) => (
                                                                                            <MenuItem key={item.state} value={item.state}>
                                                                                                {item.state}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>City</span></>}
                                                                                        name='permanent_city'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            setFieldValue('permanent_city', e.target.value);
                                                                                        }}
                                                                                    >
                                                                                        {permanentCity && permanentCity.length > 0 && permanentCity.map((item: any) => (
                                                                                            <MenuItem key={item.uid} value={item.uid}>
                                                                                                {item.city}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>Pincode</span></>}
                                                                                        name='permanent_pincode'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6 p-t-0'>
                                                                        <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0'>
                                                                            <div className='mt-3'>
                                                                                EMERGENCY :
                                                                                <FormGroup className='ml-3'>
                                                                                    <Field
                                                                                        label={<><span>Address</span></>}
                                                                                        name='emergency_address'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <Field
                                                                                        label={<><span>Country</span></>}
                                                                                        name="emergency_country"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleCountryChange(e, 'emergency');
                                                                                            setFieldValue('emergency_country', e.target.value)
                                                                                            setFieldValue('emergency_state', '');
                                                                                            setFieldValue('emergency_city', '')
                                                                                        }}
                                                                                    >
                                                                                        {getCountry && getCountry.length > 0 && getCountry.map((item: any) => (
                                                                                            <MenuItem key={item.country} value={item.country}>
                                                                                                {item.country}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>State</span></>}
                                                                                        name='emergency_state'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            this.handleStateChange(e, 'emergency', values);
                                                                                            setFieldValue('emergency_state', e.target.value);
                                                                                            setFieldValue('emergency_city', '')
                                                                                        }}
                                                                                    >
                                                                                        {emergencyState && emergencyState.length > 0 && emergencyState.map((item: any) => (
                                                                                            <MenuItem key={item.state} value={item.state}>
                                                                                                {item.state}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>City</span></>}
                                                                                        name='emergency_city'
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                        onChange={(e: any) => {
                                                                                            setFieldValue('emergency_city', e.target.value);
                                                                                        }}
                                                                                    >
                                                                                        {emergencyCity && emergencyCity.length > 0 && emergencyCity.map((item: any) => (
                                                                                            <MenuItem key={item.uid} value={item.uid}>
                                                                                                {item.city}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <Field
                                                                                        label={<><span>Pincode</span></>}
                                                                                        name='emergency_pincode'
                                                                                        component={TextField}
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right mb-3 mr-2 mt-4">
                                                                    <Button className="btn btn-pink mr-1 ml-1 w-15"
                                                                        disabled={isEmpty || isValidating ||
                                                                            !!(errors.teacheremailid && touched.teacheremailid) ||
                                                                            !!(errors.teacherphonenumber && touched.teacherphonenumber) ||
                                                                            !!(errors.teacherlastname && touched.teacherlastname) ||
                                                                            !!(errors.teacherfirstname && touched.teacherfirstname)} type="submit">Submit</Button>
                                                                    <Link to="/teacher">
                                                                        <Button className="btn btn-default mr-1 ml-1 w-15">Cancel</Button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTeacherAdd}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.teacher.loading,
        getTeacherImageURL: state.teacher.imageUpload,
        getErrorMsg: state.teacher.errors,
        nationality: state.teacher.GetNationality,
        maritalStatus: state.teacher.GetMaritalStatus,
        getBloodGroup: state.teacher.GetBloodGroup,
        getQualification: state.teacher.GetDegreeDropdownData,
        getCity: state.teacher.GetCityData,
        getCountry: state.teacher.GetCountryData,
        getState: state.teacher.GetStateData
    };
}

export default connect(mapStateToProps, {
    AddTeacherPost,
    ResetTeacherImage,
    GetNationality,
    GetMaritalStatus,
    GetBloodGroup,
    GetDegreeDropdown,
    GetCityDropdown,
    GetCountryDropdown,
    GetStateDropdown
})(AddTeacher)
