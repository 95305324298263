import React from 'react';
import { connect } from 'react-redux';
import { fetchSchoolsPost, fetchSchool, deletePost, addSchool } from '../../store/school/Actions'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { UserRoles } from '../../services/Constants'
import { LoadMoreType } from '../../components/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import CommonLoader from '../../components/CommonLoader';
import Switch from '@material-ui/core/Switch';
import { formValidationPatten, RowsPerPageOptions, RowPerPage } from '../../services/Constants';
import {ISearchBarState} from './Types';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface PostsListProps {
  loading: boolean;
  getYear?:any,
  loginProfile:any;
  schoolDetails: any;
  schoolDetailDelete: any;
  deleteDetails: any;
  fetchSchoolsPost: (loadMoreType: LoadMoreType) => any;
  deletePost: (schoolDetailDelete: any) => void;
  addSchool: (school: any) => void;
	stopYearData:() => any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
}

class ViewSchoolDetails extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteSchoolId: [],
      query: '',
      schoolData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 0,
      ascOrder: true,
      descOrder: false,
      page: 1,
      search: '',
      SortOrderData: '',
      OrderNameData: '',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      academicYear:null,
      per_page: RowPerPage
    };

  }
  componentDidMount(): void {
    window.scrollTo(0, 0);  
    this.getUpdateYear(); 
    this.checkUserType();   
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear, deleteDetails} = this.props;
    if(deleteDetails === true) {
      this.checkStatusProcess()
    }
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getUpdateYear(getYearData?:any){
    if(getYearData && getYearData !== undefined && getYearData !== null){
      this.setState({academicYear:getYearData})
      this.getDataValue(getYearData)
    }else {
      this.setState({academicYear:this.props.getYear})
      this.getDataValue(this.props.getYear)
    }
  }
  getDataValue(getYearData?:any) {
    const postValue = {
      page_no: 1,
      per_page: this.state.per_page,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      academic_year:getYearData
    }
    this.props.fetchSchoolsPost(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
    });
  }
  checkUserType() {
    const {loginProfile} = this.props;
    const getSchoolToken:any = String(loginProfile.usertype);
    if(getSchoolToken){
      if (getSchoolToken === UserRoles.acadamicAdmin) {
        this.setState({ acadamicAdmin: true })
      } else if (getSchoolToken === UserRoles.schoolAdmin) {
        this.setState({ schoolAdmin: true })
      } else if (getSchoolToken === UserRoles.teacher) {
        this.setState({ teacher: true })
      } else if (getSchoolToken === UserRoles.parent) {
        this.setState({ parent: true })
      }
    }
  }

  checkStatusProcess() {
    const selectDataList = this.props.deleteDetails;
    if (selectDataList === true) {
      this.getDataValue()
    }
  }

  public handleDelete = () => {
    this.props.deletePost(this.state.deleteSchoolId);
    this.setState({ showDelete: false, page: 1, hasMore: true });
  }
  public hideSchoolDelete = () => {
    this.setState({ showDelete: false });
  }
  public showSchoolDelete = (getData: any) => {
    let titleClassMessage: any;
    let postValue:any
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'School'
      }
      this.setState({ showDelete: true, deleteSchoolId: getData, postData:postValue });
    }
  }

  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        ascOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchSchoolsPost(postValue).then((res: any) => {
        
      });
    } else {
      this.setState({
        ascOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchSchoolsPost(postValue).then((res: any) => {
        
      });
    }
  }

  public fetchMoreSchoolData = (newPage?:any,numberOfRows?:any) => {
    const {academicYear} = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: newPage,
          per_page: numberOfRows,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:academicYear
        }
        this.props.fetchSchoolsPost(postValue);
        // this.setState({
        //   page: this.state.page + 1
        // })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            display: 'flex !important',
            padding: '8px !important'
          }
        }
      }    
    }
  })
  private renderSchoolManageView(getSchoolData: any) {
    const { loading, total} = this.props;
    const { sort_by, sort_class_name, sort_title_name,per_page } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const columns = [
      {
        name: "school_name",
        label: "School Name",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: "category",
        label: "Category",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: "start_time",
        label: "Start Time",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },
      {
        name: "end_time",
        label: "End Time",
        options: {
          customBodyRender: (value:any) => value || '-',
        },
      },  
      {
        name: "is_active",
        label: "Actions",
        options: {
          sort: false,
          download: false,
          customBodyRender: (tableMeta:any,rowData:any,rowIndex:any) => {
            const data = getSchoolData[rowData.rowIndex];
            return (
              <td>
                {data.is_active ? (
                  <Link to={`/edit_school/${data.id}`}>
                    <button className="btn btn-primary btn-xs" title="Edit School">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                  </Link>
                ) : (
                  <button className="btn btn-disable btn-xs" title="Edit School">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                )}
                {data.is_active ? (
                  <Switch
                    checked={data.is_active}
                    onClick={() => this.showSchoolDelete({ id: String(data.id), isActive: false })}
                    name="checkedSchool"
                    inputProps={{ 'aria-label': 'success checkbox' }}
                    title='Deactivate School'
                  />
                ) : (
                  <Switch
                    checked={data.is_active}
                    onClick={() => this.showSchoolDelete({ id: String(data.id), isActive: true })}
                    name="checkedSchool"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    title='Activate School'
                  />
                )}
              </td>
            );
          },
        },
      },    
    ];
    
    

const options = {
  rowsPerPage: per_page,
  rowsPerPageOptions: RowsPerPageOptions,
  count: total,
  selectableRows: false,
  page:this.state.page - 1,
  filter:false,
  print:false,
  downloadOptions: {
    filename: "Schoolslist.csv"
  },
  onChangePage: (newPage:any) => {
      this.setState({ page: newPage + 1}); 
      this.fetchMoreSchoolData(newPage + 1,this.state.per_page);
  },
  onColumnSortChange:(changedColumn:any, direction:any) =>{
      const sortData = {
          sort_by: direction,
          order_by: changedColumn
      }
      this.showFilterData(sortData);
  },
  onChangeRowsPerPage: (numberOfRows:any) => {
    const page = 1
    this.setState({ per_page: numberOfRows,page:page });
    this.fetchMoreSchoolData(page,numberOfRows);
  },
  onSearchChange: (searchText:any, row:any) => {
      this.handleInputSchoolChange({ target: { value: searchText } });
  }
};
    return (
      <div>
        <ThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
        data={getSchoolData}
        columns={columns}
        options={options}
        />
        </ThemeProvider>
      </div>
    )
  }

  handleInputSchoolChange = (e: any) => {
    const {academicYear} = this.state;
    const { value } = e.target;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          search: getSearchValue,
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSchoolsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      } else {
        const postSearchValue = {
          page_no: 1,
          search: '',
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData
        }
        this.props.fetchSchoolsPost(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: ''
        })
      }
    } else {

    }
  
  }
  refreshSchoolTable = () =>{
    this.getDataValue(); 
  }
  render() {
    const { loading } = this.props;
    const {showDelete, postData} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getSchoolToken = localStorage.getItem('token');
    const schoolTotel = this.props.total;
    if (!getSchoolToken) {
      history.push("/");
    }
      
    return (
      <div>
         <CommonModel
       onState={showDelete}
       currentState={postData}
       onConfirm={this.handleDelete}
       onCancel={this.hideSchoolDelete}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb 
              titleName={['Schools']} 
              homeName={['Home']} 
              url={['dashboard']} 
              mainPageTitle={['Schools']} />
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-topline-red">
                      <div className="card-head" style={{ display: this.state.acadamicAdmin ? "block" : "none" }}>
                        <header>
                          <Link to="/add_school">
                            <button className="btn btn-pink">Add School</button>
                          </Link>
                          <Link to="/upload_school_logo">
                            <button className="btn btn-pink ml-2">Upload School Logo</button>
                          </Link>
                        </header>
                      </div>
                      <div className="card-body no-padding height-9">
                        <div className="row">
                          <div className="table-responsive">
                            {this.renderSchoolManageView(this.props.records)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
    stopYear:state.profile.yearStatusFails,
    loginProfile:state.profile.profileData,
    schoolDetails: state.schools.items,
    loading: state.schools.loading,
    total: state.schools.total,
    per_page: state.schools.per_page,
    records: state.schools.records,
    page: state.schools.page,
    totalPage: state.schools.totalPage,
    deleteDetails: state.schools.isAuthenticated
  };
};
export default connect(
  mapStateToProps, { fetchSchoolsPost, fetchSchool, deletePost,  stopYearData, addSchool }
)(ViewSchoolDetails);
