import React from 'react';
import { connect } from 'react-redux';
import { ClassesType, ClassesTypes, ISearchBarState } from '../../store/classes/Type';
import { deletePost, fetchClassesPost, fetchClassesPagination } from '../../store/classes/Actions';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { RowPerPage, RowsPerPageOptions, UserRoles, formValidationPatten } from '../../services/Constants'
import { LoadMoreType } from '../../components/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import Switch from '@material-ui/core/Switch';
import {CommonModel} from '../../components/CommonModel';
import DataTable from '../../components/datatable/DataTable';
import {tableHeading} from './Types';
import { stopYearData } from '../../store/profile/Actions';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface PostsListProps {
  loading: boolean; 
  getYear?:any;
  getpreDatas: any;
  classesDetails: ClassesTypes;
  classesAllItems: any;
  classesDelete: ClassesType;
  deleteDetails: any;
  checkDelateStatus:any;
  deletePost: (classesDelete: any) => any;
  fetchClassesPost: (loadMoreType: LoadMoreType) => any;
  fetchClassesPagination: (loadMoreType?: LoadMoreType) => any;
  stopYearData:()=>any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
  ascOrder?: boolean,
  academicYear?: any,
  sort_by?: string,
  sort_class_name?: string,
  sort_title_name?: string,
}

class ViewClassesDetails extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteClassesId: [],
      query: '',
      classData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 10,
      acsOrder: true,
      descOrder: false,
      page: 1,
      records: [],
      total: 0,
      totalPage: 0,
      search: '',
      SortOrderData:'',
      OrderNameData:'',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      getCurrectYear: null,
      academicYear: null,
      per_page: RowPerPage
    };
    window.scrollTo(0, 0);
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.UpdateYearDate()
    this.checkUserType(); 
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.UpdateYearDate(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
    this.checkStatusProcess();
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
    UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.setState({getCurrectYear:getYear})
        this.getClassRecord(getYear)
    }else {
        this.setState({getCurrectYear:this.props.getYear})
        this.getClassRecord(this.props.getYear)
    }
    }
  getClassRecord(getYear?:any){
    const postValue:any = {
      page_no: 1,
      per_page: this.state.per_page,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      academic_year:getYear
    }
    this.props.fetchClassesPagination(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
    }); 
  }

checkUserType(getYear?:any) {
    const getClassToken = localStorage.getItem('usertype');
    if (getClassToken === UserRoles.acadamicAdmin) {
      this.setState({ acadamicAdmin: true })
    } else if (getClassToken === UserRoles.schoolAdmin) {
      this.setState({ schoolAdmin: true })
    } else if (getClassToken === UserRoles.teacher) {
      this.setState({ teacher: true })
    } else if (getClassToken === UserRoles.parent) {
      this.setState({ parent: true })
    }
  }

  checkStatusProcess(){
    const selectDataList = this.props.deleteDetails;
    const {getCurrectYear} = this.state;
    if(selectDataList === true){ 
      const postValue:any = {
        page_no: 1,
        per_page: this.state.per_page,
        search: this.state.search,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData,
        academic_year:getCurrectYear
      }    
      this.props.fetchClassesPagination(postValue).then((res:any)=>{
        window.scrollTo(0, 0);
      });     
    }
    
   }

  public handleDelete = () => {
    this.setState({ showDelete: false, page: 1, hasMore: true });
    this.props.deletePost(this.state.deleteClassesId).then((res:any)=>{
     
    });
  }
  public hideClassDelete = () => {
    this.setState({ showDelete: false });
  }
  //this is function use to open model box
  public showClassDelete = (getData: any) => {  
    let titleClassMessage: any;
    let postValue:any
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'Class'
      }
      this.setState({ showDelete: true, deleteClassesId: getData, postData:postValue });
    }
    
  }

  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        ascOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchClassesPagination(postValue).then((res: any) => {
        
      });
    } else {
      this.setState({
        ascOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        per_page: this.state.per_page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchClassesPagination(postValue).then((res: any) => {
        
      });
    }
  }

  public fetchMoreClassData = (newPage?:any,numberOfRows?:any) => {
    const {getCurrectYear} = this.state;
    if (this.state.hasMore === true) {
     if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {   
        const postValue:any = {
          page_no: newPage,
          per_page: numberOfRows,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:getCurrectYear
        }              
        this.props.fetchClassesPagination(postValue);
        // this.setState({
        //   page: this.state.page + 1
        // }) 
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  getMuiTheme = () => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: "12px"
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            display: 'flex !important'
          }
        }
      }
    }
  })
  private renderClassesManageView(records: any) {
    const { loading,total } = this.props;
    const { sort_by, sort_class_name, sort_title_name, per_page } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" }; 
    const columns = [
      {
        name: "grade",
        label: "Grade",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "standard",
        label: "Section",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "is_active",
        label: "Status",
        options: {
          sort: false,
          download: false,
          customBodyRender: (value:any, tableMeta:any) => {
            const data = records[tableMeta.rowIndex];
            return (
              <Switch
                checked={value}
                onClick={() => this.showClassDelete({ id: String(data['id']), isActive: !value })}
                name="checkedSchool"
                inputProps={{ 'aria-label': 'toggle class status' }}
                title={value ? "Deactivate Class" : "Activate Class"}
              />
            );
          },
        },
      },
    ];
    const options = {
      rowsPerPage: per_page,
      rowsPerPageOptions: RowsPerPageOptions,
      count: total,
      selectableRows: false,
      page:this.state.page - 1,
      filter:false,
      print:false,
      downloadOptions:{
        filename: "ClassessList.csv"
      },
      onChangePage: (newPage:any) => {
          this.setState({ page: newPage + 1}); 
          this.fetchMoreClassData(newPage + 1,this.state.per_page);
      },
      onColumnSortChange:(changedColumn:any, direction:any) =>{
          const sortData = {
              sort_by: direction,
              order_by: changedColumn
          }
          this.showFilterData(sortData);
      },
      onChangeRowsPerPage: (numberOfRows:any) => {
        const page = 1
        this.setState({ per_page: numberOfRows,page:page });
        this.fetchMoreClassData(page,numberOfRows);
      },
      onSearchChange: (searchText:any, row:any) => {
          this.handleInputClassChange({ target: { value: searchText } });
      }
  };
    return (
      <div>
        <ThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={records}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    )
  }
  

  handleInputClassChange = (e: any) => {
    const { value } = e.target;
    const {getCurrectYear} = this.state;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
       if (getSearchValue) {
      const postSearchValue:any = {
        page_no: 1,
        per_page: this.state.per_page,
        search: getSearchValue,
        academic_year:getCurrectYear
      }
      this.props.fetchClassesPagination(postSearchValue);
      this.setState({
        hasMore: true,
        page: 1,
        search: getSearchValue
      })
    } else {
      const postSearchValue:any = {
        page_no: 1,
        per_page: this.state.per_page,
        search: '',
        academic_year:getCurrectYear
      }      
      this.props.fetchClassesPagination(postSearchValue);
      this.setState({
        hasMore: true,
        page: 1,
        search: ''
      })
    }
    }
  }
  refreshClassTable = () =>{
    const {getCurrectYear} = this.state;
    this.setState({
      search: '',
      SortOrderData:'',
      OrderNameData:'',
    }, () => {
      this.UpdateYearDate(getCurrectYear)
    });  
    
  }
  render() {
    const { loading } = this.props;
    const {showDelete, postData, search} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getClassToken = localStorage.getItem('token');
    const classTotal:any = this.props.total
    if (!getClassToken) {
      history.push("/");
    }
    return (
      <div>
       <CommonModel
       onState={showDelete}
       currentState={postData}
       onConfirm={this.handleDelete}
       onCancel={this.hideClassDelete}
       />
       <DataTable
       tableRecord={this.props.records}
       headerList={tableHeading}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb titleName={['Classes']} homeName={['Home']} url={['dashboard']} mainPageTitle={['Classes']} />
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-topline-red">                  
                      <div className="card-head" style={{ display: this.state.schoolAdmin ? "block" : "none" }}>                     
                        <header>
                          <Link to={'/add_class'}>
                            <button className="btn btn-pink">Add Class</button>
                          </Link>
                        </header>
                      </div>
                      <div className="card-body no-padding height-9">
                        <div className="row">
                          <div className="table-responsive">
                            {this.renderClassesManageView(this.props.records)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => { 
  return {
    getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
    total: state.classes.total,
    per_page: state.classes.per_page,
    records: state.classes.records,
    page: state.classes.page,
    totalPage: state.classes.totalPage,
    loading:state.classes.loading,
    deleteDetails:state.classes.isAuthenticated,
    checkDelateStatus:state.classes.recordDelete
  };
};
export default connect(
  mapStateToProps, { fetchClassesPost, deletePost, fetchClassesPagination, stopYearData }
)(ViewClassesDetails);
